import {yupResolver} from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import {Button, Grid} from '@mui/material';
import moment from 'moment-timezone';
import React, {useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import QRCode from 'react-qr-code';
import config from 'src/config';
import {i18n} from 'src/i18n';
import yupFormSchemas from 'src/modules/shared/yup/yupFormSchemas';
import DatePickerFormItem from 'src/view/shared/form/items/DatePickerFormItem';
import InputFormItem from 'src/view/shared/form/items/InputFormItem';
import InputNumberFormItem from 'src/view/shared/form/items/InputNumberFormItem';
import FormWrapper, {FormButtons} from 'src/view/shared/styles/FormWrapper';
import TipoPresupuestoAutocompleteFormItem from 'src/view/tipoPresupuesto/autocomplete/TipoPresupuestoAutocompleteFormItem';
import * as yup from 'yup';

const schema = yup.object().shape({
  descripcion: yupFormSchemas.string(i18n('entities.presupuesto.fields.descripcion'), {
    required: true,
  }),
  tipoPresupuesto: yupFormSchemas.relationToOne(i18n('entities.presupuesto.fields.tipoPresupuesto'), {}),
  montoPresupuesto: yupFormSchemas.integer(i18n('entities.presupuesto.fields.montoPresupuesto'), {
    required: true,
  }),
  cuentasContables: yupFormSchemas.relationToMany(i18n('entities.presupuesto.fields.cuentasContables'), {}),
  fechainicio: yupFormSchemas.date(i18n('entities.presupuesto.fields.fechainicio'), {}),
  fechaFin: yupFormSchemas.date(i18n('entities.presupuesto.fields.fechaFin'), {}),
  habilitado: yupFormSchemas.boolean(i18n('entities.presupuesto.fields.habilitado'), {}),
  centrosCostosVinculados: yupFormSchemas.relationToMany(i18n('entities.presupuesto.fields.centrosCostosVinculados'), {}),
});

function PresupuestoForm(props) {
  const [initialValues] = useState(() => {
    const record = props.record || {};

    return {
      descripcion: record.descripcion,
      tipoPresupuesto: record.tipoPresupuesto,
      montoPresupuesto: record.montoPresupuesto,
      cuentasContables: record.cuentasContables || [],
      fechainicio: record.fechainicio ? moment(record.fechainicio, 'YYYY-MM-DD') : null,
      fechaFin: record.fechaFin ? moment(record.fechaFin, 'YYYY-MM-DD') : null,
      habilitado: record.habilitado,
      centrosCostosVinculados: record.centrosCostosVinculados || [],
    };
  });

  const form = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: initialValues as any,
    shouldUnregister: false,
  });

  const onSubmit = (values) => {
    props.onSubmit(props.record?.id, values);
  };

  const onReset = () => {
    Object.keys(initialValues).forEach((key) => {
      form.setValue(key, initialValues[key]);
    });
  };

  const {saveLoading, modal} = props;

  return (
    <FormWrapper>
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          style={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            overflowY: 'auto',
            paddingBottom: '16px',
          }}>
          <Grid spacing={2} container>
            <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
              <h1>{form.watch('descripcion')}</h1>

              <div style={{marginBottom: 15}}>
                <QRCode
                  value={`${config.frontendUrl.protocol}://${config.frontendUrl.host}/presupuesto/${props.record?.id}`}
                  size={140}
                />
              </div>
            </Grid>
            <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
              <InputFormItem
                name="descripcion"
                disabled={props.disabled}
                label={i18n('entities.presupuesto.fields.descripcion')}
                required={true}
                autoFocus
              />
            </Grid>
            <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
              <TipoPresupuestoAutocompleteFormItem
                name="tipoPresupuesto"
                disabled={props.disabled}
                label={i18n('entities.presupuesto.fields.tipoPresupuesto')}
                required={false}
                showCreate={!props.modal}
              />
            </Grid>
            <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
              <InputNumberFormItem
                name="montoPresupuesto"
                disabled={props.disabled}
                label={i18n('entities.presupuesto.fields.montoPresupuesto')}
                required={true}
              />
            </Grid>
            {/* <Grid item lg={props.drawer?12:7} md={8} sm={12} xs={12}>
              <CuentaContableAutocompleteFormItem
                name="cuentasContables"
                disabled={props.disabled}
                label={i18n(
                  'entities.presupuesto.fields.cuentasContables',
                )}
                required={false}
                showCreate={!props.modal}
                mode="multiple"
              />
            </Grid> */}
            <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
              <h2>Intervalos de Inicio y Fin</h2>
              <hr />
            </Grid>

            <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
              <DatePickerFormItem
                name="fechainicio"
                disabled={props.disabled}
                label={i18n('entities.presupuesto.fields.fechainicio')}
                required={false}
              />
            </Grid>
            <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
              <DatePickerFormItem
                name="fechaFin"
                disabled={props.disabled}
                label={i18n('entities.presupuesto.fields.fechaFin')}
                required={false}
              />
            </Grid>
            {/* <Grid item lg={props.drawer?12:7} md={8} sm={12} xs={12}>
              <SwitchFormItem
                name="habilitado"
                disabled={props.disabled}
                label={i18n(
                  'entities.presupuesto.fields.habilitado',
                )}
              />
            </Grid> */}
            {/* <Grid item lg={props.drawer?12:7} md={8} sm={12} xs={12}>
              <CentroCostoAutocompleteFormItem  
                name="centrosCostosVinculados"
                disabled={props.disabled}
                label={i18n('entities.presupuesto.fields.centrosCostosVinculados')}
                required={false}
                showCreate={!props.modal}
                mode="multiple"
              />
            </Grid> */}
          </Grid>
        </form>
        {!props.disabled && (
          <FormButtons
            style={{
              flexDirection: modal ? 'row-reverse' : undefined,
            }}>
            <Button
              variant="contained"
              color="primary"
              disabled={saveLoading}
              type="button"
              onClick={form.handleSubmit(onSubmit)}
              startIcon={<SaveIcon />}
              size="small">
              {i18n('common.save')}
            </Button>

            <Button disabled={saveLoading} onClick={onReset} type="button" startIcon={<UndoIcon />} size="small">
              {i18n('common.reset')}
            </Button>

            {props.onCancel ? (
              <Button
                disabled={saveLoading}
                onClick={() => props.onCancel()}
                type="button"
                startIcon={<CloseIcon />}
                size="small">
                {i18n('common.cancel')}
              </Button>
            ) : null}
          </FormButtons>
        )}
      </FormProvider>
    </FormWrapper>
  );
}

export default PresupuestoForm;
