import {yupResolver} from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import {Box, Button, CardContent, Grid} from '@mui/material';
import ButtonBase from '@mui/material/ButtonBase';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import {makeStyles} from '@mui/styles';
import React, {useEffect, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useSelector} from 'react-redux';
import {i18n} from 'src/i18n';
import authSelectors from 'src/modules/auth/authSelectors';
import comunicacionInternaEnumerators from 'src/modules/comunicacionInterna/comunicacionInternaEnumerators';
import yupFormSchemas from 'src/modules/shared/yup/yupFormSchemas';
import {getHistory} from 'src/modules/store';
import Storage from 'src/security/storage';
import {magicFlexBox} from 'src/theme/schemes/CustomTheme';
import NivelPrioridadAutocompleteFormItem from 'src/view/nivelPrioridad/autocomplete/NivelPrioridadAutocompleteFormItem';
import FilesFormItem from 'src/view/shared/form/items/FilesFormItem';
import ImagesFormItem from 'src/view/shared/form/items/ImagesFormItem';
import InputFormItem from 'src/view/shared/form/items/InputFormItem';
import SelectFormItem from 'src/view/shared/form/items/SelectFormItem';
import TextAreaFormItem from 'src/view/shared/form/items/TextAreaFormItem';
import FormWrapper, {FormButtons} from 'src/view/shared/styles/FormWrapper';
import TemaComunicacionAutocompleteFormItem from 'src/view/temaComunicacion/autocomplete/TemaComunicacionAutocompleteFormItem';
import UbicacionAutocompleteFormItem from 'src/view/ubicacion/autocomplete/UbicacionAutocompleteFormItem';
import UserAutocompleteFormItem from 'src/view/user/autocomplete/UserAutocompleteFormItem';
import * as yup from 'yup';

const schema = yup.object().shape({
  locations: yupFormSchemas.relationToMany(i18n('common.locations'), {}),
  titulo: yupFormSchemas.string(i18n('entities.comunicacionInterna.fields.titulo'), {
    required: false,
  }),
  estado: yupFormSchemas.enumerator(i18n('entities.comunicacionInterna.fields.estado'), {
    required: false,
    options: comunicacionInternaEnumerators.estado,
  }),
  asunto: yupFormSchemas.string(i18n('entities.comunicacionInterna.fields.asunto'), {
    required: true,
  }),
  fotosAdjunta: yupFormSchemas.images(i18n('entities.comunicacionInterna.fields.fotosAdjunta'), {
    required: false,
  }),
  documentosAdjuntos: yupFormSchemas.files(i18n('entities.comunicacionInterna.fields.documentosAdjuntos'), {}),
  mensaje: yupFormSchemas.string(i18n('entities.comunicacionInterna.fields.mensaje'), {}),
  temaComunicacion: yupFormSchemas.relationToOne(i18n('entities.comunicacionInterna.fields.temaComunicacion'), {
    required: true,
  }),
  nivelPrioridad: yupFormSchemas.relationToOne(i18n('entities.comunicacionInterna.fields.nivelPrioridad'), {
    required: true,
  }),
  anadirReferencias: yupFormSchemas.boolean(i18n('entities.comunicacionInterna.fields.anadirReferencias'), {}),
  pendientesAsociados: yupFormSchemas.relationToMany(i18n('entities.comunicacionInterna.fields.pendientesAsociados'), {}),
  tiendasAsociadas: yupFormSchemas.relationToMany(i18n('entities.comunicacionInterna.fields.tiendasAsociadas'), {}),
  empleadosAsociados: yupFormSchemas.relationToMany(i18n('entities.comunicacionInterna.fields.empleadosAsociados'), {}),
  comunicacionesAsociadas: yupFormSchemas.relationToMany(i18n('entities.comunicacionInterna.fields.comunicacionesAsociadas'), {}),
  participantes: yupFormSchemas.relationToMany(i18n('entities.comunicacionInterna.fields.participantes'), {
    required: true,
  }),
  administradores: yupFormSchemas.relationToMany(i18n('entities.comunicacionInterna.fields.administradores'), {
    required: false,
  }),
});

const useStyles = makeStyles((theme: any) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
    maxWidth: 500,
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
}));

function ComunicacionInternaForm(props) {
  const tenantUser = useSelector(authSelectors.selectTenantUser);
  const userLocations = tenantUser?.perfilEmpleado?.ubicacion;

  const currentUser = useSelector(authSelectors.selectCurrentUser);

  const currentRoles = useSelector(authSelectors.selectRoles);

  const [initialValues] = useState(() => {
    const record = props.record || {};

    return {
      locations: record.locations || userLocations || [],
      titulo: record.titulo,
      createdBy: record.createdBy,
      estado: record.estado || 'Abierta',
      asunto: record.asunto,
      mensaje: record.mensaje,
      temaComunicacion: record.temaComunicacion,
      nivelPrioridad: record.nivelPrioridad,
      anadirReferencias: record.anadirReferencias,
      pendientesAsociados: record.pendientesAsociados || [],
      tiendasAsociadas: record.tiendasAsociadas || [],
      empleadosAsociados: record.empleadosAsociados || [],
      comunicacionesAsociadas: record.comunicacionesAsociadas || [],
      participantes: record.participantes || [],
      administradores: record.administradores || [],
      fotosAdjunta: record.fotosAdjunta || [],
      documentosAdjuntos: record.documentosAdjuntos || [],
      passProps: props.passProps,
    };
  });

  useEffect(() => {
    form.register('passProps');

    if (props.isEditing) {
      if (currentRoles.includes('admin') || currentRoles.includes('administrador')) {
        return;
      }

      if (currentUser.id === form.watch('createdBy')) {
        return;
      }

      if (form.watch('administradores').filter((item) => item.id === currentUser.id).length === 0) {
        getHistory().push('/comunicacion-interna');
      }
    }
  }, []);

  const form = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: initialValues as any,
  });

  const onSubmit = (values) => {
    props.onSubmit(props.record?.id, values);
  };

  const onReset = () => {
    Object.keys(initialValues).forEach((key) => {
      form.setValue(key, initialValues[key]);
    });
  };

  const {saveLoading, modal} = props;
  const classes = useStyles();

  return (
    <FormWrapper>
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          style={
            {
              ...magicFlexBox,
              overflow: 'auto',
              paddingRight: 16,
              marginBottom: 8,
            } as any
          }>
          <Grid spacing={2} container direction="column">
            <Grid item lg={props.drawer ? 12 : 6} md={8} sm={12} xs={12}>
              {/*
                <Grid item lg={12} md={8} sm={12} xs={12}>
                  <InputFormItem
                    name="titulo"
                    label={i18n('entities.comunicacionInterna.fields.titulo')}
                    required={true}
                    autoFocus
                  />
                </Grid>
              */}
              <Grid item lg={12} md={8} sm={12} xs={12}>
                <TemaComunicacionAutocompleteFormItem
                  name="temaComunicacion"
                  label={i18n('entities.comunicacionInterna.fields.temaComunicacion')}
                  required={true}
                  showCreate={!props.modal}
                />
              </Grid>
              <Grid item lg={12} md={8} sm={12} xs={12}>
                <InputFormItem name="asunto" label={i18n('entities.comunicacionInterna.fields.asunto')} required={true} />
              </Grid>
              <Grid item lg={12} md={8} sm={12} xs={12}>
                <ImagesFormItem
                  name="fotosAdjunta"
                  label={i18n('entities.comunicacionInterna.fields.fotosAdjunta')}
                  required={false}
                  storage={Storage.values.comunicacionInternaFotosAdjunta}
                  max={3}
                />
              </Grid>
              <Grid item lg={12} md={8} sm={12} xs={12}>
                <FilesFormItem
                  name="documentosAdjuntos"
                  label={i18n('entities.comunicacionInterna.fields.documentosAdjuntos')}
                  required={false}
                  storage={Storage.values.comunicacionInternaDocumentosAdjuntos}
                  max={3}
                  formats={undefined}
                />
              </Grid>
              {form.watch('asunto') && (
                <React.Fragment>
                  {props.isEditing && (
                    <Grid item lg={12} md={8} sm={12} xs={12}>
                      <SelectFormItem
                        name="estado"
                        label={i18n('entities.comunicacionInterna.fields.estado')}
                        options={comunicacionInternaEnumerators.estado.map((value) => ({
                          value,
                          label: i18n(`entities.comunicacionInterna.enumerators.estado.${value}`),
                        }))}
                        required={false}
                      />
                    </Grid>
                  )}
                  <Grid item lg={12} md={8} sm={12} xs={12}>
                    <NivelPrioridadAutocompleteFormItem
                      name="nivelPrioridad"
                      label={i18n('entities.comunicacionInterna.fields.nivelPrioridad')}
                      required={false}
                      showCreate={!props.modal}
                    />
                  </Grid>
                  <Grid item lg={12} md={8} sm={12} xs={12}>
                    <TextAreaFormItem
                      name="mensaje"
                      label={i18n('entities.comunicacionInterna.fields.mensaje')}
                      required={false}
                    />
                  </Grid>
                  <Grid item lg={12} md={8} sm={12} xs={12}>
                    <UserAutocompleteFormItem
                      name="participantes"
                      label={i18n('entities.comunicacionInterna.fields.participantes')}
                      required={false}
                      showCreate={!props.modal}
                      mode="multiple"
                    />
                  </Grid>
                  <Grid item lg={12} md={8} sm={12} xs={12}>
                    <UserAutocompleteFormItem
                      name="administradores"
                      label={i18n('entities.comunicacionInterna.fields.administradores')}
                      required={false}
                      showCreate={!props.modal}
                      mode="multiple"
                    />
                  </Grid>
                  <Grid item lg={12} md={8} sm={12} xs={12}>
                    <UbicacionAutocompleteFormItem
                      name="locations"
                      disabled={props.disabled}
                      label={i18n('common.locations')}
                      required={false}
                      showCreate={false}
                      mode="multiple"
                    />
                  </Grid>
                </React.Fragment>
              )}
            </Grid>
          </Grid>
          <Box sx={{p: 2, mt: 2}}>
            {form.watch('asunto') && (
              <div className={classes.root}>
                <Paper className={classes.paper}>
                  <Grid container spacing={2}>
                    <Grid item>
                      <ButtonBase className={classes.image}>
                        <img className={classes.img} alt="complex" src="/images/logo.png" />
                      </ButtonBase>
                    </Grid>
                    <Grid item xs={12} sm container>
                      <Grid item xs container direction="column" spacing={2}>
                        <CardContent>
                          <Grid item xs>
                            <Typography variant="subtitle2" color="textSecondary">
                              {form.watch('temaComunicacion')?.nombre
                                ? form.watch('temaComunicacion')?.nombre
                                : form.watch('temaComunicacion')?.label}
                            </Typography>

                            <Typography variant="h5" gutterBottom>
                              {form.watch('titulo')}
                            </Typography>
                          </Grid>
                          <Typography variant="body2" color="textSecondary">
                            {form.watch('estado')} -{' '}
                            {form.watch('nivelPrioridad')?.nombre
                              ? form.watch('nivelPrioridad')?.nombre
                              : form.watch('nivelPrioridad')?.label}
                          </Typography>

                          <Typography variant="h6" component="p">
                            {form.watch('asunto')?.substr(0, 200)} {form.watch('asunto')?.length > 200 ? '...' : null}
                          </Typography>
                          <Typography style={{marginTop: 10}} variant="body2" color="textSecondary" component="p">
                            {form.watch('mensaje')?.substr(0, 200)} {form.watch('mensaje')?.length > 200 ? '...' : null}
                          </Typography>

                          {form.watch('administradores').length > 0 ? (
                            <div style={{marginTop: 10}}>
                              <Typography variant="body1">Administradores</Typography>
                              {form.watch('administradores').map((administrador) => (
                                <Typography variant="body2" color="textSecondary">
                                  - {administrador.label || administrador.email}
                                </Typography>
                              ))}
                            </div>
                          ) : null}

                          {form.watch('participantes').length > 0 ? (
                            <div style={{marginTop: 10}}>
                              <Typography variant="body1">Participantes</Typography>
                              {form.watch('participantes').map((participante) => (
                                <Typography variant="body2" color="textSecondary">
                                  - {participante.label || participante.email}
                                </Typography>
                              ))}
                            </div>
                          ) : null}

                          {form.watch('anadirReferencias') ? (
                            <div>
                              <Typography style={{marginTop: 20}} variant="h6">
                                Vinculados
                              </Typography>
                              <hr></hr>

                              {form.watch('pendientesAsociados').length > 0 ? (
                                <div
                                  style={{
                                    marginTop: 10,
                                  }}>
                                  <Typography variant="body1">Pendientes Asociados</Typography>
                                  {form.watch('pendientesAsociados').map((item) => (
                                    <Typography variant="body2" color="textSecondary">
                                      - {item.label || item.asunto}
                                    </Typography>
                                  ))}
                                </div>
                              ) : null}

                              {form.watch('tiendasAsociadas').length > 0 ? (
                                <div
                                  style={{
                                    marginTop: 10,
                                  }}>
                                  <Typography variant="body1">Sucursales Asociadas</Typography>
                                  {form.watch('tiendasAsociadas').map((item) => (
                                    <Typography variant="body2" color="textSecondary">
                                      - {item.label || item.nombre}
                                    </Typography>
                                  ))}
                                </div>
                              ) : null}

                              {form.watch('empleadosAsociados').length > 0 ? (
                                <div
                                  style={{
                                    marginTop: 10,
                                  }}>
                                  <Typography variant="body1">Empleados Asociados</Typography>
                                  {form.watch('empleadosAsociados').map((item) => (
                                    <Typography variant="body2" color="textSecondary">
                                      - {item.label || item.email}
                                    </Typography>
                                  ))}
                                </div>
                              ) : null}

                              {form.watch('comunicacionesAsociadas').length > 0 ? (
                                <div
                                  style={{
                                    marginTop: 10,
                                  }}>
                                  <Typography variant="body1">Otras Comunicaciones Asociadas</Typography>
                                  {form.watch('comunicacionesAsociadas').map((item) => (
                                    <Typography variant="body2" color="textSecondary">
                                      - {item.label || item.asunto}
                                    </Typography>
                                  ))}
                                </div>
                              ) : null}
                            </div>
                          ) : null}
                        </CardContent>
                      </Grid>
                      <Grid style={{marginTop: 10}} item></Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </div>
            )}
          </Box>
        </form>
        <FormButtons
          style={{
            flexDirection: modal ? 'row-reverse' : undefined,
          }}>
          <Button
            variant="contained"
            color="primary"
            disabled={saveLoading}
            type="button"
            onClick={form.handleSubmit(onSubmit)}
            startIcon={<SaveIcon />}
            size="small">
            {i18n('common.send')}
          </Button>

          <Button disabled={saveLoading} onClick={onReset} type="button" startIcon={<UndoIcon />} size="small">
            {i18n('common.reset')}
          </Button>

          {props.onCancel ? (
            <Button disabled={saveLoading} onClick={() => props.onCancel()} type="button" startIcon={<CloseIcon />} size="small">
              {i18n('common.cancel')}
            </Button>
          ) : null}
        </FormButtons>
      </FormProvider>
    </FormWrapper>
  );
}

export default ComunicacionInternaForm;
