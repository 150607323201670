import {i18n} from 'src/i18n';
import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import absenceEnumerators from '../absenceEnumerators';
const isBoolean = (val) => 'boolean' === typeof val;
export default [
  {
    name: 'absenceCode',
    label: i18n('entities.absence.fields.absenceCode'),
    schema: schemas.string(i18n('entities.absence.fields.absenceCode'), {}),
  },
  {
    name: 'isReAdmission',
    label: i18n('entities.absence.fields.isReAdmission'),
    toView: (value) => (isBoolean(value) ? (value ? i18n('common.yes') : i18n('common.no')) : value),
    schema: schemas.boolean(i18n('entities.absence.fields.isReAdmission'), {
      trueValues: ['si', 'sí', 'yes', 'y', 'true', 'pro', 'reingreso'],
    }),
  },
  {
    name: 'withLostDays',
    label: i18n('entities.absence.fields.withLostDays'),
    toView: (value) => (isBoolean(value) ? (value ? i18n('common.yes') : i18n('common.no')) : value),
    schema: schemas.boolean(i18n('entities.absence.fields.withLostDays'), {
      trueValues: ['si', 'sí', 'yes', 'y', 'true', 'ctp'],
    }),
  },
  {
    name: 'initialClassification',
    label: i18n('entities.absence.fields.initialClassification'),
    toView: (value) =>
      absenceEnumerators.initialClassification.includes(value)
        ? i18n(`entities.absence.enumerators.initialClassification.${value}`)
        : value,
    schema: schemas.enumerator(i18n('entities.absence.fields.initialClassification'), {
      options: absenceEnumerators.initialClassification,
      equivalences: {
        trabajo: 'WorkAccident',
        trayecto: 'CommutingAccident',
        'enf. prof.': 'OccupationalDisease',
        'no ley': 'NotCoveredByLaw',
        'n/a': 'NotApplicable',
        ...(() => {
          let translatedOptions: any = {};
          absenceEnumerators.initialClassification.forEach((option) => {
            translatedOptions[i18n(`entities.absence.enumerators.initialClassification.${option}`).toLowerCase()] = option;
          });
          return translatedOptions;
        })(),
      },
    }),
  },
  {
    name: 'employeeName',
    label: i18n('entities.absence.fields.employeeName'),
    schema: schemas.string(i18n('entities.absence.fields.employeeName'), {}),
  },
  {
    name: 'employeeRut',
    label: i18n('entities.absence.fields.employeeRut'),
    schema: schemas.rut(i18n('entities.absence.fields.employeeRut'), {}),
  },
  {
    name: 'incidentDateFrom',
    label: i18n('entities.absence.fields.incidentDateFrom'),
    //toView: (value) => (value ? value.split('T')[0] : value),
    schema: schemas.date(i18n('entities.absence.fields.incidentDateFrom')),
  },
  {
    name: 'mutualitySubmitDate',
    label: i18n('entities.absence.fields.mutualitySubmitDate'),
    //toView: (value) => (value ? value.split('T')[0] : value),
    schema: schemas.date(i18n('entities.absence.fields.mutualitySubmitDate')),
  },
  {
    name: 'startDate',
    label: i18n('entities.absence.fields.startDate'),
    //toView: (value) => (value ? value.split('T')[0] : value),
    schema: schemas.date(i18n('entities.absence.fields.startDate')),
  },
  {
    name: 'endDate',
    label: i18n('entities.absence.fields.endDate'),
    //toView: (value) => (value ? value.split('T')[0] : value),
    schema: schemas.date(i18n('entities.absence.fields.endDate')),
  },
  {
    name: 'incidentLocationName',
    label: i18n('entities.absence.fields.incidentLocationName'),
    schema: schemas.string(i18n('entities.absence.fields.incidentLocationName'), {}),
  },
  {
    name: 'responsibleAdvisorName',
    label: i18n('entities.absence.fields.responsibleAdvisorName'),
    schema: schemas.string(i18n('entities.absence.fields.responsibleAdvisorName'), {}),
  },
  {
    name: 'medicalObservation',
    label: i18n('entities.absence.fields.medicalObservation'),
    schema: schemas.string(i18n('entities.absence.fields.medicalObservation'), {}),
  },
  {
    name: 'finalClassification',
    label: i18n('entities.absence.fields.finalClassification'),
    toView: (value) =>
      absenceEnumerators.finalClassification.includes(value)
        ? i18n(`entities.absence.enumerators.finalClassification.${value}`)
        : value,
    schema: schemas.enumerator(i18n('entities.absence.fields.finalClassification'), {
      options: absenceEnumerators.finalClassification,
      equivalences: {
        trabajo: 'WorkAccident',
        trayecto: 'CommutingAccident',
        'enf. prof.': 'OccupationalDisease',
        'no ley': 'NotCoveredByLaw',
        'n/a': 'NotApplicable',
        ...(() => {
          let translatedOptions: any = {};
          absenceEnumerators.finalClassification.forEach((option) => {
            translatedOptions[i18n(`entities.absence.enumerators.finalClassification.${option}`).toLowerCase()] = option;
          });
          return translatedOptions;
        })(),
      },
    }),
  },
];
