import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'descripcion',
    label: i18n('entities.centroCosto.fields.descripcion'),
    schema: schemas.string(
      i18n('entities.centroCosto.fields.descripcion'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'tipoCentroCosto',
    label: i18n('entities.centroCosto.fields.tipoCentroCosto'),
    schema: schemas.relationToOne(
      i18n('entities.centroCosto.fields.tipoCentroCosto'),
      {},
    ),
  },
  {
    name: 'presupuestosAsociados',
    label: i18n('entities.centroCosto.fields.presupuestosAsociados'),
    schema: schemas.relationToMany(
      i18n('entities.centroCosto.fields.presupuestosAsociados'),
      {},
    ),
  },
  {
    name: 'habilitado',
    label: i18n('entities.centroCosto.fields.habilitado'),
    schema: schemas.boolean(
      i18n('entities.centroCosto.fields.habilitado'),
      {},
    ),
  },
  {
    name: 'perfilesEmpleadosVinculados',
    label: i18n('entities.centroCosto.fields.perfilesEmpleadosVinculados'),
    schema: schemas.relationToMany(
      i18n('entities.centroCosto.fields.perfilesEmpleadosVinculados'),
      {},
    ),
  },
  {
    name: 'activosVinculados',
    label: i18n('entities.centroCosto.fields.activosVinculados'),
    schema: schemas.relationToMany(
      i18n('entities.centroCosto.fields.activosVinculados'),
      {},
    ),
  },
  {
    name: 'serviciosVinculados',
    label: i18n('entities.centroCosto.fields.serviciosVinculados'),
    schema: schemas.relationToMany(
      i18n('entities.centroCosto.fields.serviciosVinculados'),
      {},
    ),
  },
  {
    name: 'ubicacionesVinculados',
    label: i18n('entities.centroCosto.fields.ubicacionesVinculados'),
    schema: schemas.relationToMany(
      i18n('entities.centroCosto.fields.ubicacionesVinculados'),
      {},
    ),
  },
  {
    name: 'trazabilidadesVinculadas',
    label: i18n('entities.centroCosto.fields.trazabilidadesVinculadas'),
    schema: schemas.relationToMany(
      i18n('entities.centroCosto.fields.trazabilidadesVinculadas'),
      {},
    ),
  },
];