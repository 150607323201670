import CheckIcon from '@mui/icons-material/Check';
import {Grid} from '@mui/material';
import Radio from '@mui/material/Radio';
import Tooltip from '@mui/material/Tooltip';
import * as colors from '@mui/material/colors';
import {withStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';

const defaultColor = {hue: 'red', shade: 'A400', color: '#ff1744'};
const hues = Object.keys(colors).slice(1, 17);
const shades = [900, 800, 700, 600, 500, 400, 300, 200, 100, 50, 'A700', 'A400', 'A200', 'A100'];

const styles = (theme: any) => ({
  radioIcon: {
    width: 28,
    height: 28,
    borderRadius: '5px',
  },
  radioIconSelected: {
    width: 28,
    height: 28,
    border: '1px solid white',
    color: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '5px',
  },
});

function ColorPicker(props) {
  const {classes, onChange, defaultValue} = props;

  const shade = shades[11];

  const findHue = (color) => {
    for (let hue of hues) {
      if (colors[hue]?.[shade] === color) {
        return {
          hue,
          shade,
          color,
        };
      }
    }
    return defaultColor;
  };

  const [value, setValue] = React.useState(findHue(defaultValue));

  const handleChangeHue = (event) => {
    const {
      target: {value: hue},
    } = event;
    const color = colors[hue]?.[shade];
    if (!handleChangeColor(color)) {
      return;
    }

    const newValue = {
      hue,
      shade,
      color,
    };

    //console.log('%c⧭ handleChangeHue', 'color: #006dcc', newValue);

    setValue(newValue);
    onChange(newValue);
  };

  const handleChangeColor = (color) => {
    const isRgb = (string) => /rgb\([0-9]{1,3}\s*,\s*[0-9]{1,3}\s*,\s*[0-9]{1,3}\)/i.test(string);

    const isHex = (string) => /^#?([0-9a-f]{3})$|^#?([0-9a-f]){6}$/i.test(string);

    let isValidColor = false;

    if (isRgb(color)) {
      isValidColor = true;
    } else if (isHex(color)) {
      isValidColor = true;
      if (color.indexOf('#') === -1) {
        color = `#${color}`;
      }
    }

    if (isValidColor) {
      // console.log('%c⧭ valid Color', 'color: #731d6d', {
      //   color,
      // });
      return true;
    } else {
      console.log('%c⧭ invalid color', 'color: #731d6d', {
        color,
        isValidColor,
      });
      return false;
    }
  };

  return (
    <Grid
      container
      direction="column"
      spacing={1}
      // justifyContent="center" alignItems="center"
    >
      {/* <Grid item>
        <Input value={value?.color} fullWidth />
      </Grid> */}
      <Grid item>
        <Grid container spacing={1}>
          {hues.map((hue) => {
            const backgroundColor = colors[hue][shade];
            //console.log('%c⧭ value', `color: ${value}`, value?.color);
            //console.log('%c⧭ backgroundColor', `color: ${backgroundColor}`, backgroundColor);
            return (
              <Grid item>
                <Tooltip followCursor placement="right" title={hue} key={hue}>
                  <Radio
                    style={{
                      padding: 0,
                    }}
                    color="default"
                    value={hue}
                    checked={value?.color === backgroundColor}
                    onChange={handleChangeHue}
                    aria-labelledby={`tooltip-${'primary'}-${hue}`}
                    icon={<div className={classes.radioIcon} style={{backgroundColor}} />}
                    checkedIcon={
                      <div className={classes.radioIconSelected} style={{backgroundColor}}>
                        <CheckIcon style={{fontSize: 20}} />
                      </div>
                    }
                    size="small"
                  />
                </Tooltip>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
}

ColorPicker.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  defaultValue: PropTypes.any,
};

export default withStyles(styles)(ColorPicker);
