import {Box, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import ImagesUploader from 'src/view/shared/uploaders/ImagesUploader';

function ImagesViewItem(props) {
  const valueAsArray = () => {
    const {value} = props;

    if (!value) {
      return [];
    }

    if (Array.isArray(value)) {
      return value;
    }

    return [value];
  };

  if (!valueAsArray().length) {
    return null;
  }

  return (
    <Box sx={{mb: 2}}>
      <Typography variant="h5" sx={{color: 'rgba(34, 51, 84, 0.7)'}}>
        {props.label}
      </Typography>
      <ImagesUploader readonly value={valueAsArray()} />
    </Box>
  );
}

ImagesViewItem.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
};

export default ImagesViewItem;
