import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.ubicacion.fields.id'),
  },
  {
    name: 'foto',
    label: i18n('entities.ubicacion.fields.foto'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'nombre',
    label: i18n('entities.ubicacion.fields.nombre'),
  },
  {
    name: 'clasificacion',
    label: i18n('entities.ubicacion.fields.clasificacion'),
  },
  {
    name: 'ubicacionAsociada',
    label: i18n('entities.ubicacion.fields.ubicacionAsociada'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'ubicacionGoogle',
    label: i18n('entities.ubicacion.fields.ubicacionGoogle'),
  },
  {
    name: 'latitud',
    label: i18n('entities.ubicacion.fields.latitud'),
    render: exporterRenders.decimal(),
  },
  {
    name: 'longitud',
    label: i18n('entities.ubicacion.fields.longitud'),
    render: exporterRenders.decimal(),
  },
  {
    name: 'responsables',
    label: i18n('entities.ubicacion.fields.responsables'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'capacidad',
    label: i18n('entities.ubicacion.fields.capacidad'),
    render: exporterRenders.decimal(),
  },
  {
    name: 'documentos',
    label: i18n('entities.ubicacion.fields.documentos'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'estado',
    label: i18n('entities.ubicacion.fields.estado'),
  },
  {
    name: 'correoContacto',
    label: i18n('entities.ubicacion.fields.correoContacto'),
  },
  {
    name: 'telefonoContacto',
    label: i18n('entities.ubicacion.fields.telefonoContacto'),
  },
  {
    name: 'propiedad',
    label: i18n('entities.ubicacion.fields.propiedad'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'empresaExterna',
    label: i18n('entities.ubicacion.fields.empresaExterna'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'visibilidad',
    label: i18n('entities.ubicacion.fields.visibilidad'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.ubicacion.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.ubicacion.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
