import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useRouteMatch} from 'react-router-dom';
import {i18n} from 'src/i18n';
import actions from 'src/modules/planificadorTarea/form/planificadorTareaFormActions';
import selectors from 'src/modules/planificadorTarea/form/planificadorTareaFormSelectors';
import {getHistory} from 'src/modules/store';
import PlanificadorTareaForm from 'src/view/planificadorTarea/form/PlanificadorTareaForm';
import Breadcrumb from 'src/view/shared/Breadcrumb';
import Spinner from 'src/view/shared/Spinner';
import PageTitle from 'src/view/shared/styles/PageTitle';

function PlanificadorTareaFormPage(props) {
  const [dispatched, setDispatched] = useState(false);
  const dispatch = useDispatch();
  const match = useRouteMatch();

  const initLoading = useSelector(selectors.selectInitLoading);
  const saveLoading = useSelector(selectors.selectSaveLoading);
  const record = useSelector(selectors.selectRecord);

  const isEditing = Boolean(props.record?.id || match.params.id);

  const title = isEditing ? i18n('entities.planificadorTarea.edit.title') : i18n('entities.planificadorTarea.new.title');

  useEffect(() => {
    dispatch(actions.doInit(props.record?.id || match.params.id));
    setDispatched(true);
  }, [dispatch, match.params.id]);

  const doSubmit = (id, data) => {
    // console.log('%c⧭ doSubmit', 'color: #e57373', {data});
    // return;
    if (isEditing) {
      dispatch(actions.doUpdate(id, data));
    } else {
      dispatch(actions.doCreate(data));
    }
  };

  return (
    <>
      {(!props.drawer || props.disabled) && (
        <Breadcrumb
          items={[[i18n('dashboard.menu'), '/'], [i18n('entities.planificadorTarea.menu'), '/planificador-tarea'], [title]]}
        />
      )}

      {!props.disabled && <PageTitle caption>{title}</PageTitle>}

      {initLoading && <Spinner />}

      {dispatched && !initLoading && (
        <PlanificadorTareaForm
          disabled={props.disabled}
          drawer={props.drawer}
          planificacion={props.planificacion}
          rowsSelected={props.rowsSelected}
          saveLoading={saveLoading}
          initLoading={initLoading}
          record={record}
          isEditing={isEditing}
          onSubmit={doSubmit}
          onCancel={() => getHistory().push('/planificador-tarea')}
        />
      )}
    </>
  );
}

export default PlanificadorTareaFormPage;
