const planificadorFormularioEnumerators = {
  tipoFormulario: [
    'Evaluacion',
    'Encuesta',
  ],
  dirigidoA: [
    'Puesto',
    'Empleado',
    'Sucursal',
  ],
  tipoPeriodicidad: [
    'days',
    'months',
    'years',
  ],
  tipoTiempoLimite: [
    'days',
    'months',
    'years',
  ],
  clasificacion: [
    'operativa',
    'mantenimiento',
  ],
};

export default planificadorFormularioEnumerators;
