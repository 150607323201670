const activoEnumerators = {
  grupo: ['Equipo', 'Herramienta', 'Repuesto', 'Suministro', 'Componente', 'Digital', 'Mercaderia'],
  inventario: ['sí', 'no'],
  estado: ['Regular', 'Normal', 'Mantenimiento', 'MantenimientoPreventivo', 'MantenimientoCorrectivo', 'Eliminado', 'Arrendado'],
  prioridad: ['Muy Alta', 'Alta', 'Media', 'Baja', 'Muy Baja'],
  tipoMedicionMantenimiento: ['Kilometros', 'Horas', 'NoAplica'],
  tipoDepreciacion: ['Depreciación Lineal', 'Suma de los dígitos del año'],
  certificated: [1, 0],
};

export default activoEnumerators;
