import {Button, Dialog, DialogActions, DialogTitle, Grid, Typography} from '@mui/material';
import React, {useEffect, useRef, useState} from 'react';
import ReactDOM from 'react-dom';
import Geocode from 'react-geocode';
// import StandaloneSearchBox from 'react-google-maps/lib/components/places/StandaloneSearchBox';
import {compose, withProps} from 'recompose';
import {i18n} from 'src/i18n';
const {withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow} = require('react-google-maps');

Geocode.setApiKey('AIzaSyBdnksXevtjy1scvJVYtZ0HlEgkQkOp8Xk');
Geocode.setLanguage('es');
Geocode.setRegion('cl');

function GeolocalizationModal(props) {
  const {
    onClose,
    closeText = i18n('common.close'),
    onConfirm,
    confirmText,
    title = 'Geolocalización',
    defaultCenter = {lat: -36.8269882, lng: -73.0497665},
    zoom = 15,
    enableMap = true,
    item,
    record,
  } = props;
  const {lat, lng} = item;

  const mapRef = useRef<any>(null);
  const searchBoxRef = useRef<any>(null);
  const inputRef = useRef<any>(null);

  const [center, setCenter] = useState({
    lat,
    lng,
  });

  const [address, setAddress] = useState('');

  const handlePlacesChanged = async () => {};

  const onZoomChanged = () => {};

  useEffect(() => {
    Geocode.fromLatLng(lat, lng).then(
      (response) => {
        const address = response.results[0].formatted_address;
        console.log(address);
        setAddress(address);
      },
      (error) => {
        console.error(error);
      },
    );
  }, []);

  const onMapMounted = (ref) => {
    mapRef.current = ref;
  };

  const onToggleOpen = () => {};

  const onDragEnd = (event) => {};

  const onCenterChanged = (event) => {};

  const onBoundsChanged = () => {
    // setBounds(refs.current.map.getBounds());
    // setCenter(refs.current.map.getCenter());
  };

  const onSearchBoxMounted = (ref) => {
    searchBoxRef.current = ref;
    // const places = ref.getPlaces();
    // console.log('%c⧭ onSearchBoxMounted', 'color: #7f7700', places);
  };

  const onPlacesChanged = () => {
    const places = searchBoxRef.current.getPlaces();
    places.forEach((place, index) => {
      console.log('%c⧭ onPlacesChanged', 'color: #7f7700', index, place);
    });
  };

  const GoogleMapComponent = compose(
    withProps({
      loadingElement: <div style={{height: `100%`}} />,
      containerElement: <div style={{height: `400px`}} />,
      mapElement: <div style={{height: `100%`}} />,
    }),
    withGoogleMap,
  )((props) => <GoogleMap {...props} />);

  return ReactDOM.createPortal(
    <Dialog open={true} onClose={props.onClose} maxWidth="md" fullWidth={true}>
      <DialogTitle>
        <span
          style={{
            fontSize: '1.3rem',
            paddingLeft: '8px',
          }}>
          {title}
        </span>
      </DialogTitle>

      <Grid
        style={{height: props.height}}
        sx={{
          px: 4,
          py: 2,
        }}>
        <Grid container direction="column">
          <Grid item>
            <label
              style={{
                fontWeight: 600,
                fontSize: 20,
              }}>
              {record?.titulo} {record?.tituloTarea}
            </label>
          </Grid>
          <Grid item>
            <Typography variant="caption">{address}</Typography>
          </Grid>

          {/* <StandaloneSearchBox onPlacesChanged={onPlacesChanged} ref={onSearchBoxMounted}>
            <TextField
              inputRef={inputRef}
              id={'StandaloneSearchBox'}
              name={'StandaloneSearchBox'}
              label={''}
              margin={'normal'}
              fullWidth
              variant="outlined"
              size="small"
              placeholder={'Ejemplos de busqueda “Concepción,Chile / Lugar Objetivo” '}
              InputLabelProps={{
                shrink: true,
              }}
              disabled={true}
            />
          </StandaloneSearchBox> */}
          <GoogleMapComponent
            ref={onMapMounted}
            onBoundsChanged={onBoundsChanged}
            defaultCenter={defaultCenter}
            center={center}
            defaultZoom={zoom}
            onZoomChanged={onZoomChanged}
            onCenterChanged={onCenterChanged}>
            <Marker
              position={center}
              defaultPosition={defaultCenter}
              onClick={onToggleOpen}
              draggable={false}
              onDragEnd={onDragEnd}></Marker>
          </GoogleMapComponent>
        </Grid>
      </Grid>

      <DialogActions>
        <Button
          onClick={onClose}
          color="primary"
          style={{
            fontSize: '0.9rem',
          }}>
          {closeText}
        </Button>
        {onConfirm && (
          <Button
            onClick={onConfirm}
            color="primary"
            style={{
              fontSize: '0.9rem',
            }}
            autoFocus>
            {confirmText}
          </Button>
        )}
      </DialogActions>
    </Dialog>,
    (document as any).getElementById('modal-root'),
  );
}

export default GeolocalizationModal;
