import React, {useEffect, useRef, useState} from 'react';

import ScheduleTwoToneIcon from '@mui/icons-material/ScheduleTwoTone';
import {Box, Card, Divider, Typography} from '@mui/material';
import {styled} from '@mui/material/styles';
import {useTranslation} from 'react-i18next';

import moment from 'moment-timezone';
import {Scrollbars} from 'react-custom-scrollbars-2';
import {useDispatch, useSelector} from 'react-redux';
import {useRouteMatch} from 'react-router-dom';
import {i18n} from 'src/i18n';
import authSelectors from 'src/modules/auth/authSelectors';
import mensajeInternoListActions from 'src/modules/mensajeInterno/list/mensajeInternoListActions';
import mensajeInternoListSelectors from 'src/modules/mensajeInterno/list/mensajeInternoListSelectors';
import yupFormSchemas from 'src/modules/shared/yup/yupFormSchemas';
import FirmaListItem from 'src/view/firma/list/FirmaListItem';
import InventarioListItem from 'src/view/inventario/list/InventarioListItem';
import PlanificadorTareaListItem from 'src/view/planificadorTarea/list/PlanificadorTareaListItem';
import Spinner from 'src/view/shared/Spinner';
import FilesViewItem from 'src/view/shared/view/FilesViewItem';
import ImagesViewItem from 'src/view/shared/view/ImagesViewItem';
import TareaListItem from 'src/view/tarea/list/TareaListItem';
import UbicacionListItem from 'src/view/ubicacion/list/UbicacionListItem';
import UserListItem from 'src/view/user/list/UserListItem';
import * as yup from 'yup';

const DividerWrapper = styled(Divider)(
  ({theme}: any) => `
      .MuiDivider-wrapper {
        text-transform: none;
        background: ${theme.palette.background.default};
        font-size: ${theme.typography.pxToRem(13)};
        color: ${theme.colors.alpha.black[50]};
      }
`,
);

const CardWrapperPrimary = styled(Card)(
  ({theme}: any) => `
      background: ${theme.colors.primary.main};
      color: ${theme.palette.primary.contrastText};
      padding: ${theme.spacing(2)};
      margin-bottom: ${theme.spacing(2)};
      border-radius: ${theme.general.borderRadiusXl};
      border-top-right-radius: ${theme.general.borderRadius};
      max-width: 380px;
      display: inline-flex;
      word-wrap: anywhere;
`,
);

const CardWrapperSecondary = styled(Card)(
  ({theme}: any) => `
      background: ${theme.colors.alpha.black[10]};
      color: ${theme.colors.alpha.black[100]};
      padding: ${theme.spacing(2)};
      border-radius: ${theme.general.borderRadiusXl};
      border-top-left-radius: ${theme.general.borderRadius};
      max-width: 380px;
      display: inline-flex;
      word-wrap: anywhere;
`,
);

const schema = yup.object().shape({
  comunicacionInterna: yupFormSchemas.relationToOne(i18n('entities.mensajeInterno.fields.comunicacionInterna'), {
    required: true,
  }),
  mensaje: yupFormSchemas.string(i18n('entities.mensajeInterno.fields.mensaje'), {
    required: true,
  }),
  fotosAdjunta: yupFormSchemas.images(i18n('entities.mensajeInterno.fields.fotosAdjunta'), {
    required: false,
  }),
  documentosAdjuntos: yupFormSchemas.files(i18n('entities.mensajeInterno.fields.documentosAdjuntos'), {}),
});

function ChatContent(props) {
  const {t} = useTranslation();
  const refLastMessage: any = useRef(null);

  const match = useRouteMatch();
  const dispatch = useDispatch();
  const rowsMensajes = useSelector(mensajeInternoListSelectors.selectRows);

  const [loading, setLoading] = useState(false);
  const findLoading = useSelector(mensajeInternoListSelectors.selectLoading);

  const currentUser = useSelector(authSelectors.selectCurrentUser);

  useEffect(() => {
    if (refLastMessage.current) {
      refLastMessage.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [rowsMensajes]);

  useEffect(() => {
    dispatch(
      mensajeInternoListActions.doFetch(
        {
          comunicacionInterna: props.recordComunicacionInterna?.id,
        },
        {
          orderBy: 'asc',
        },
      ),
    );
  }, [match.params.idComunicacion, props.recordComunicacionInterna?.id]);

  if (findLoading) {
    return (
      <Box>
        <Spinner />
      </Box>
    );
  }

  if (!findLoading)
    return (
      <Scrollbars autoHide>
        <Box id="" style={{height: 'calc(100vh - 140px)'}} p={2}>
          {rowsMensajes?.map((row, index) =>
            row.createdBy.id !== currentUser.id ? (
              <div key={index} ref={rowsMensajes.length === index + 1 ? refLastMessage : null}>
                <Box display="flex" alignItems="flex-start" justifyContent="flex-start" py={3}>
                  <UserListItem size={40} type={'avatar'} value={row.createdBy} />
                  <Box display="flex" alignItems="flex-start" flexDirection="column" justifyContent="flex-start" ml={2}>
                    <CardWrapperSecondary>{row.mensaje}</CardWrapperSecondary>

                    {row.fotosAdjunta && <ImagesViewItem value={row.fotosAdjunta} />}

                    {row.documentosAdjuntos && <FilesViewItem value={row.documentosAdjuntos} />}

                    {row.tareasReferenciadas && <TareaListItem value={row.tareasReferenciadas} />}

                    {row.firmasReferenciadas && <FirmaListItem value={row.firmasReferenciadas} />}

                    {row.planificadoresTareasReferenciadas && (
                      <PlanificadorTareaListItem value={row.planificadoresTareasReferenciadas} />
                    )}

                    {row.ubicacionesReferenciadas && <UbicacionListItem value={row.ubicacionesReferenciadas} />}

                    {row.inventariosReferenciados && <InventarioListItem value={row.inventariosReferenciados} />}
                    <Typography
                      variant="subtitle1"
                      sx={{
                        pt: 1,
                        display: 'flex',
                        alignItems: 'center',
                      }}>
                      <ScheduleTwoToneIcon sx={{mr: 0.5}} fontSize="small" />
                      {moment(row.createdAt).format('LLLL')}
                    </Typography>
                  </Box>
                </Box>
              </div>
            ) : (
              <div ref={rowsMensajes.length === index + 1 ? refLastMessage : null}>
                <Box display="flex" alignItems="flex-start" justifyContent="flex-end" py={1}>
                  <Box display="flex" alignItems="flex-end" flexDirection="column" justifyContent="flex-end" mr={2}>
                    <CardWrapperPrimary>{row.mensaje}</CardWrapperPrimary>

                    {row.fotosAdjunta && <ImagesViewItem value={row.fotosAdjunta} />}

                    {row.documentosAdjuntos && <FilesViewItem value={row.documentosAdjuntos} />}

                    {row.tareasReferenciadas && <TareaListItem value={row.tareasReferenciadas} />}

                    {row.firmasReferenciadas && <FirmaListItem value={row.firmasReferenciadas} />}

                    {row.planificadoresTareasReferenciadas && (
                      <PlanificadorTareaListItem value={row.planificadoresTareasReferenciadas} />
                    )}

                    {row.ubicacionesReferenciadas && <UbicacionListItem value={row.ubicacionesReferenciadas} />}

                    {row.inventariosReferenciados && <InventarioListItem value={row.inventariosReferenciados} />}

                    <Typography
                      variant="subtitle1"
                      sx={{
                        pt: 1,
                        display: 'flex',
                        alignItems: 'center',
                      }}>
                      <ScheduleTwoToneIcon sx={{mr: 0.5}} fontSize="small" />
                      {moment(row.createdAt).format('LLLL')}
                    </Typography>
                  </Box>
                  <UserListItem size={40} type={'avatar'} value={row.createdBy} />
                </Box>
              </div>
            ),
          )}
        </Box>
      </Scrollbars>
    );

  return <div></div>;
}

export default ChatContent;
