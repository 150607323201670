import {createSelector} from 'reselect';
import authSelectors from 'src/modules/auth/authSelectors';
import PermissionChecker from 'src/modules/auth/permissionChecker';
import Permissions from 'src/security/permissions';

const selectPermissionToRead = createSelector(
  [authSelectors.selectCurrentTenant, authSelectors.selectCurrentUser],
  (currentTenant, currentUser) => new PermissionChecker(currentTenant, currentUser).match(Permissions.values.tareaRead),
);

const selectPermissionToEdit = createSelector(
  [authSelectors.selectCurrentTenant, authSelectors.selectCurrentUser],
  (currentTenant, currentUser) => new PermissionChecker(currentTenant, currentUser).match(Permissions.values.tareaEdit),
);

const selectPermissionToEditClosed = createSelector(
  [authSelectors.selectCurrentTenant, authSelectors.selectCurrentUser],
  (currentTenant, currentUser) => new PermissionChecker(currentTenant, currentUser).match(Permissions.values.tareaEditClosed),
);

const selectPermissionToCreate = createSelector(
  [authSelectors.selectCurrentTenant, authSelectors.selectCurrentUser],
  (currentTenant, currentUser) => new PermissionChecker(currentTenant, currentUser).match(Permissions.values.tareaCreate),
);

const selectPermissionToImport = createSelector(
  [authSelectors.selectCurrentTenant, authSelectors.selectCurrentUser],
  (currentTenant, currentUser) => new PermissionChecker(currentTenant, currentUser).match(Permissions.values.tareaImport),
);

const selectPermissionToDestroy = createSelector(
  [authSelectors.selectCurrentTenant, authSelectors.selectCurrentUser],
  (currentTenant, currentUser) => new PermissionChecker(currentTenant, currentUser).match(Permissions.values.tareaDestroy),
);

const selectPermissionToReassign = createSelector(
  [authSelectors.selectCurrentTenant, authSelectors.selectCurrentUser],
  (currentTenant, currentUser) => new PermissionChecker(currentTenant, currentUser).match(Permissions.values.tareaReassign),
);

const selectPermissionToKanban = createSelector(
  [authSelectors.selectCurrentTenant, authSelectors.selectCurrentUser],
  (currentTenant, currentUser) => new PermissionChecker(currentTenant, currentUser).match(Permissions.values.tareaKanban),
);

const tareaSelectors = {
  selectPermissionToRead,
  selectPermissionToEdit,
  selectPermissionToEditClosed,
  selectPermissionToCreate,
  selectPermissionToDestroy,
  selectPermissionToImport,
  selectPermissionToReassign,
  selectPermissionToKanban,
};

export default tareaSelectors;
