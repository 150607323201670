import {FormControl, FormHelperText, FormLabel} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {useFormContext, useWatch} from 'react-hook-form';
import FormErrors from 'src/view/shared/form/formErrors';

import {Paper} from '@mui/material';
// import {
//   amber,
//   blue,
//   blueGrey,
//   brown,
//   cyan,
//   deepOrange,
//   deepPurple,
//   green,
//   grey,
//   indigo,
//   lightBlue,
//   lightGreen,
//   lime,
//   orange,
//   pink,
//   purple,
//   red,
//   teal,
//   yellow,
// } from '@mui/material/colors';
import ColorPicker from './ColorPicker';
import {DisabledText} from './shared/formItemWrappers';

// const palette = {
//   red: red[500],
//   pink: pink[500],
//   purple: purple[500],
//   deepPurple: deepPurple[500],
//   indigo: indigo[500],
//   blue: blue[500],
//   lightBlue: lightBlue[500],
//   cyan: cyan[500],
//   teal: teal[500],
//   green: green[500],
//   lightGreen: lightGreen[500],
//   lime: lime[500],
//   yellow: yellow[500],
//   amber: amber[500],
//   orange: orange[500],
//   deepOrange: deepOrange[500],
//   brown: brown[500],
//   grey: grey[500],
//   blueGrey: blueGrey[500],
// };
export function InputColorFormItem(props) {
  const {label, name, hint, required, externalErrorMessage, disabled} = props;

  const {
    register,
    formState: {touchedFields, isSubmitted, errors},
    setValue,
    control,
  } = useFormContext();

  useEffect(() => {
    register(name);
  }, []);

  const errorMessage = FormErrors.errorMessage(name, errors, touchedFields, isSubmitted, externalErrorMessage);

  const formHelperText = errorMessage || hint;

  const watchName: any = useWatch({name, control});

  if (disabled) {
    return <DisabledText label={label} value={watchName} />;
  }

  //console.log('InputColorItem', {watchName});

  return (
    <FormControl required={required} fullWidth error={Boolean(errorMessage)} component="fieldset" size="small">
      <FormLabel component="legend" style={{marginBottom: '8px'}}>
        {label}
      </FormLabel>
      <Paper
        sx={{
          //marginLeft: 1,
          mb: 1,
          backgroundColor: watchName,
          width: 40,
          height: 40,
          borderRadius: '3px',
        }}
        square
        elevation={0}
      />

      <ColorPicker
        onChange={(value) => {
          // console.log('ColorPicker onChange', {
          //   value,
          //   //palette,
          //   watchName,
          // });
          const newValue = value?.color;
          setValue(name, newValue);
          props.onChange && props.onChange(newValue);
        }}
        defaultValue={watchName}
      />

      {formHelperText && <FormHelperText style={{marginTop: 0}}>{formHelperText}</FormHelperText>}
    </FormControl>
  );
}

InputColorFormItem.defaultProps = {
  type: 'text',
  required: false,
};

InputColorFormItem.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  type: PropTypes.string,
  label: PropTypes.string,
  hint: PropTypes.string,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  prefix: PropTypes.string,
  placeholder: PropTypes.string,
  autoComplete: PropTypes.string,
  externalErrorMessage: PropTypes.string,
  onChange: PropTypes.func,
  endAdornment: PropTypes.any,
  defaultValue: PropTypes.any,
};

export default InputColorFormItem;
