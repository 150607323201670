import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import {i18n} from 'src/i18n';
import moment from 'moment-timezone';

export default [
  {
    name: 'descripcion',
    label: i18n('entities.presupuesto.fields.descripcion'),
    schema: schemas.string(i18n('entities.presupuesto.fields.descripcion'), {
      required: true,
    }),
  },
  {
    name: 'tipoPresupuesto',
    label: i18n('entities.presupuesto.fields.tipoPresupuesto'),
    schema: schemas.relationToOne(i18n('entities.presupuesto.fields.tipoPresupuesto'), {}),
  },
  {
    name: 'montoPresupuesto',
    label: i18n('entities.presupuesto.fields.montoPresupuesto'),
    schema: schemas.integer(i18n('entities.presupuesto.fields.montoPresupuesto'), {
      required: true,
    }),
  },
  {
    name: 'cuentasContables',
    label: i18n('entities.presupuesto.fields.cuentasContables'),
    schema: schemas.relationToMany(i18n('entities.presupuesto.fields.cuentasContables'), {}),
  },
  {
    name: 'fechainicio',
    label: i18n('entities.presupuesto.fields.fechainicio'),
    schema: schemas.date(i18n('entities.presupuesto.fields.fechainicio'), {}),
    render: value => (value && value instanceof Date ? moment(value).format('YYYY-MM-DD') : value),
  },
  {
    name: 'fechaFin',
    label: i18n('entities.presupuesto.fields.fechaFin'),
    schema: schemas.date(i18n('entities.presupuesto.fields.fechaFin'), {}),
    render: value => (value && value instanceof Date ? moment(value).format('YYYY-MM-DD') : value),
  },
  {
    name: 'habilitado',
    label: i18n('entities.presupuesto.fields.habilitado'),
    schema: schemas.boolean(i18n('entities.presupuesto.fields.habilitado'), {}),
  },
  {
    name: 'centrosCostosVinculados',
    label: i18n('entities.presupuesto.fields.centrosCostosVinculados'),
    schema: schemas.relationToMany(i18n('entities.presupuesto.fields.centrosCostosVinculados'), {}),
  },
];
