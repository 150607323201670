import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/mensajeInterno/importer/mensajeInternoImporterSelectors';
import MensajeInternoService from 'src/modules/mensajeInterno/mensajeInternoService';
import fields from 'src/modules/mensajeInterno/importer/mensajeInternoImporterFields';
import { i18n } from 'src/i18n';

const mensajeInternoImporterActions = importerActions(
  'MENSAJEINTERNO_IMPORTER',
  selectors,
  MensajeInternoService.import,
  fields,
  i18n('entities.mensajeInterno.importer.fileName'),
);

export default mensajeInternoImporterActions;