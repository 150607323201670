import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'meta',
    label: i18n('entities.asignadorFuncion.fields.meta'),
    schema: schemas.relationToOne(
      i18n('entities.asignadorFuncion.fields.meta'),
      {},
    ),
  },
  {
    name: 'nombreFuncion',
    label: i18n('entities.asignadorFuncion.fields.nombreFuncion'),
    schema: schemas.string(
      i18n('entities.asignadorFuncion.fields.nombreFuncion'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'empleados',
    label: i18n('entities.asignadorFuncion.fields.empleados'),
    schema: schemas.relationToMany(
      i18n('entities.asignadorFuncion.fields.empleados'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'funcion',
    label: i18n('entities.asignadorFuncion.fields.funcion'),
    schema: schemas.relationToOne(
      i18n('entities.asignadorFuncion.fields.funcion'),
      {
        "required": true
      },
    ),
  },
];