import list from 'src/modules/turnoLaboral/list/turnoLaboralListReducers';
import form from 'src/modules/turnoLaboral/form/turnoLaboralFormReducers';
import view from 'src/modules/turnoLaboral/view/turnoLaboralViewReducers';
import destroy from 'src/modules/turnoLaboral/destroy/turnoLaboralDestroyReducers';
import importerReducer from 'src/modules/turnoLaboral/importer/turnoLaboralImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
