import list from 'src/modules/habilidad/list/habilidadListReducers';
import form from 'src/modules/habilidad/form/habilidadFormReducers';
import view from 'src/modules/habilidad/view/habilidadViewReducers';
import destroy from 'src/modules/habilidad/destroy/habilidadDestroyReducers';
import importerReducer from 'src/modules/habilidad/importer/habilidadImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
