import { i18n } from 'src/i18n';

class Roles {
  static get values() {
    return {
      admin: 'admin',
      administrador: 'administrador',
      administradorOperaciones: 'administradorOperaciones',
      administradorRecursosHumanos: 'administradorRecursosHumanos',
      administradorAusencias: 'administradorAusencias',
      administradorIncidentes: 'administradorIncidentes',
      administradorInventario: 'administradorInventario',
      administradorCatalogo: 'administradorCatalogo',
      administradorSolicitudes: 'administradorSolicitudes',
      planificadorOperaciones: 'planificadorOperaciones',
      planificadorProcedimientos: 'planificadorProcedimientos',
      aceptadorPlanificaciones: 'aceptadorPlanificaciones',
      validadorPlanificaciones: 'validadorPlanificaciones',
      aceptadorMantenimientoPreventivo: 'aceptadorMantenimientoPreventivo',
      validadorMantenimientoPreventivo: 'validadorMantenimientoPreventivo',
      kanbanAdmin: 'kanbanAdmin',
      ejecutor: 'ejecutor',
      ejecutorIncidentes: 'ejecutorIncidentes',
      reasignadorDeTareas: 'reasignadorDeTareas',
      visualizadorDeActividades: 'visualizadorDeActividades',
      controlInicioActividad: 'controlInicioActividad',
      editorProgreso: 'editorProgreso',
      ejecutorProgreso: 'ejecutorProgreso',
      taskEdit: 'taskEdit',
      cambiarEmpresa: 'cambiarEmpresa',
      accesoIA: 'accesoIA',
      dataLakeAdmin: 'dataLakeAdmin',
      dataAdmin: 'dataAdmin',
      dataUser: 'dataUser',
      dashboard: 'dashboard',
      signingUser: "signingUser",
    };
  }

  static labelOf(roleId) {
    if (!this.values[roleId]) {
      return roleId;
    }

    return i18n(`roles.${roleId}.label`);
  }

  static descriptionOf(roleId) {
    if (!this.values[roleId]) {
      return roleId;
    }

    return i18n(`roles.${roleId}.description`);
  }
}

export default Roles;
