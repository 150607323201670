import PropTypes from 'prop-types';
import React from 'react';

import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import {useSelector} from 'react-redux';
import {i18n} from 'src/i18n';
import selectors from 'src/modules/comunicacionInterna/comunicacionInternaSelectors';
import MessagesView from 'src/view/comunicacionInterna/messenger/';
import DrawerButton from 'src/view/shared/view/DrawerButton';

function ComunicacionInternaListItem(props) {
  const hasPermissionToRead = useSelector(selectors.selectPermissionToRead);

  const valueAsArray = () => {
    const {value} = props;

    if (!value) {
      return [];
    }

    if (Array.isArray(value)) {
      return value;
    }

    return [value];
  };

  const displayableRecord = (record) => {
    if (hasPermissionToRead) {
      return (
        <div style={{marginBottom: 5}} key={record.id}>
          {record?.id && (
            <DrawerButton
              style={{...props.style}}
              drawerId={'DrawerComunicacionFormPage'}
              type={'chip'}
              tooltipTitle={record.asunto ? i18n(record.asunto) : 'Sin asunto'}
              buttonTitle={props.hideButtonTitle ? '' : i18n(record.asunto)}
              width={window.innerWidth * 0.55}
              buttonIcon={<QuestionAnswerIcon />}
              component={<MessagesView drawer={true} record={record} />}
            />
          )}
        </div>
      );
    }

    return <div key={record.id}>{record.asunto}</div>;
  };

  if (!valueAsArray()?.length) {
    return null;
  }

  return <>{valueAsArray()?.map((value) => displayableRecord(value))}</>;
}

ComunicacionInternaListItem.propTypes = {
  value: PropTypes.any,
  style: PropTypes.any,
  hideButtonTitle: PropTypes.bool,
};

export default ComunicacionInternaListItem;
