import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.planificadorTarea.fields.id'),
  },
  {
    name: 'nombre',
    label: i18n('entities.planificadorTarea.fields.nombre'),
  },
  {
    name: 'dirigidoA',
    label: i18n('entities.planificadorTarea.fields.dirigidoA'),
  },
  {
    name: 'empleados',
    label: i18n('entities.planificadorTarea.fields.empleados'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'puestos',
    label: i18n('entities.planificadorTarea.fields.puestos'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'sucursales',
    label: i18n('entities.planificadorTarea.fields.sucursales'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'tareas',
    label: i18n('entities.planificadorTarea.fields.tareas'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'activarTiempoLimite',
    label: i18n('entities.planificadorTarea.fields.activarTiempoLimite'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'tipoTiempoLimite',
    label: i18n('entities.planificadorTarea.fields.tipoTiempoLimite'),
  },
  {
    name: 'tiempoLimite',
    label: i18n('entities.planificadorTarea.fields.tiempoLimite'),
  },
  {
    name: 'programada',
    label: i18n('entities.planificadorTarea.fields.programada'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'programar',
    label: i18n('entities.planificadorTarea.fields.programar'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'inicioProgramacion',
    label: i18n('entities.planificadorTarea.fields.inicioProgramacion'),
  },
  {
    name: 'finProgramacion',
    label: i18n('entities.planificadorTarea.fields.finProgramacion'),
  },
  {
    name: 'tipoPeriodicidad',
    label: i18n('entities.planificadorTarea.fields.tipoPeriodicidad'),
  },
  {
    name: 'periodicidad',
    label: i18n('entities.planificadorTarea.fields.periodicidad'),
  },
  {
    name: 'evaluadores',
    label: i18n('entities.planificadorTarea.fields.evaluadores'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.planificadorTarea.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.planificadorTarea.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
