import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/respuesta/importer/respuestaImporterSelectors';
import RespuestaService from 'src/modules/respuesta/respuestaService';
import fields from 'src/modules/respuesta/importer/respuestaImporterFields';
import { i18n } from 'src/i18n';

const respuestaImporterActions = importerActions(
  'RESPUESTA_IMPORTER',
  selectors,
  RespuestaService.import,
  fields,
  i18n('entities.respuesta.importer.fileName'),
);

export default respuestaImporterActions;