import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/departamento/importer/departamentoImporterSelectors';
import DepartamentoService from 'src/modules/departamento/departamentoService';
import fields from 'src/modules/departamento/importer/departamentoImporterFields';
import { i18n } from 'src/i18n';

const departamentoImporterActions = importerActions(
  'DEPARTAMENTO_IMPORTER',
  selectors,
  DepartamentoService.import,
  fields,
  i18n('entities.departamento.importer.fileName'),
);

export default departamentoImporterActions;