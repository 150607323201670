import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';
import descRegistroEnumerators from '../descRegistroEnumerators';

export default [
  {
    name: 'descripcion',
    label: i18n('entities.descRegistro.fields.descripcion'),
    schema: schemas.string(
      i18n('entities.descRegistro.fields.descripcion'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'categoria',
    label: i18n('entities.descRegistro.fields.categoria'),
    schema: schemas.enumerator(
      i18n('entities.descRegistro.fields.categoria'),
      {
        options: descRegistroEnumerators.categoria,
        "required": true
      },
    ),
  },
  {
    name: 'tipo',
    label: i18n('entities.descRegistro.fields.tipo'),
    schema: schemas.enumerator(
      i18n('entities.descRegistro.fields.tipo'),
      {
        options: descRegistroEnumerators.tipo,
        "required": true
      },
    ),
  },
  {
    name: 'afectaDisponibilidad',
    label: i18n('entities.descRegistro.fields.afectaDisponibilidad'),
    schema: schemas.boolean(
      i18n('entities.descRegistro.fields.afectaDisponibilidad')
    ),
  },
  {
    name: 'prioridad',
    label: i18n('entities.descRegistro.fields.prioridad'),
    schema: schemas.enumerator(
      i18n('entities.descRegistro.fields.prioridad'),
      {
        options: descRegistroEnumerators.prioridad,
        "required": true
      },
    ),
  },
];