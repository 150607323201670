import list from 'src/modules/modeloFormulario/list/modeloFormularioListReducers';
import form from 'src/modules/modeloFormulario/form/modeloFormularioFormReducers';
import view from 'src/modules/modeloFormulario/view/modeloFormularioViewReducers';
import destroy from 'src/modules/modeloFormulario/destroy/modeloFormularioDestroyReducers';
import importerReducer from 'src/modules/modeloFormulario/importer/modeloFormularioImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
