import {Add, ExpandLess, ExpandMore} from '@mui/icons-material';
import {
  Box,
  Grid,
  IconButton,
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Tooltip,
  styled,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';

const Accordion = styled(MuiAccordion)`
  padding: 0;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  &:not(:last-child) {
    border-bottom: 0;
  }
  &:before: {
    display: none;
  }
  & .MuiAccordion-root.Mui-expanded {
    margin: auto;
  }
`;

const AccordionSummary = styled(MuiAccordionSummary)`
  cursor: unset !important;
  padding: 0;
  margin-bottom: -1;
  &.Mui-expanded {
    background-color: rgba(0, 0, 0, 0.045);
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0.025);
  }
  & .MuiAccordionSummary-content.Mui-expanded {
    margin: 10px 0;
  }
  & > MuiInputBase-input-MuiOutlinedInput-input {
    background-color: rgb(255, 255, 255, 0.925);
  }
`;

const AccordionDetails = styled(MuiAccordionDetails)`
  padding: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
`;

const OnHoverBox = styled(Box)`
  opacity: 0;
  &:hover {
    opacity: 1;
  }
`;

export default function CustomAccordion(props) {
  const {
    item,
    index,
    uniqueKey,
    expanded,
    handleChange,
    renderSummary = (item, index) => <Typography>Collapsible Group Item #{index + 1}</Typography>,
    renderDetails = (item, index) => (
      <Typography>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
        eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo
        lobortis eget.
      </Typography>
    ),
    detailTitle,
    topButtonClick = null, //(item, index) => console.log('top button clicked', item, index),
    bottomButtonClick = null, // (item, index) => console.log('bottom button clicked', item, index),
    entityName,
  } = props;

  const isExpanded = expanded === uniqueKey;

  const setAccordionOpen = (uniqueKey) => {
    //console.log('%c⧭ setAccordionOpen', 'color: #ffa640', {uniqueKey, expanded, isExpanded});
    handleChange(uniqueKey)(null, !isExpanded);
  };

  const OnHoverBottom = (bottom = -29) =>
    bottomButtonClick && (
      <Tooltip
        enterDelay={700}
        leaveDelay={150}
        enterNextDelay={400}
        followCursor
        placement={'top-start'}
        title={'Añadir' + (entityName ? ` ${entityName}` : '')}>
        <OnHoverBox
          style={{
            marginTop: -32,
            position: 'relative',
            bottom: bottom,
            height: 32,
            cursor: 'pointer',
            // bottom border radius
            borderBottomLeftRadius: '45%',
            borderBottomRightRadius: '45%',
            borderTopLeftRadius: '45%',
            borderTopRightRadius: '45%',
            zIndex: 1,
            placeContent: 'center',
          }}
          onClick={($event) => {
            $event.stopPropagation();
            bottomButtonClick(item, index);
          }}>
          {/* <div style={{borderTop: '1px solid rgb(0,0,0,.3)'}} /> */}
          <div
            style={{
              position: 'relative',
              bottom: 1,
              height: 20,
              backgroundColor: 'rgb(227 229 239 / 95%)', //.125)',
              //backgroundColor: 'rgb(63, 81, 181,.9)', //.125)',
              // bottom border radius
              borderBottomLeftRadius: '45%',
              borderBottomRightRadius: '45%',
              borderTopLeftRadius: '45%',
              borderTopRightRadius: '45%',
            }}>
            <div style={{position: 'relative', bottom: 5, left: '48%'}}>
              <IconButton
                size="small"
                color="primary"
                style={{backgroundColor: '#fff', border: '1px solid rgb(63, 81, 181,.6)', borderRadius: '50px'}}>
                <Add
                  style={{
                    width: 18,
                    height: 18,
                  }}
                />
              </IconButton>
            </div>
          </div>
        </OnHoverBox>
      </Tooltip>
    );
  return (
    // onChange={handleChange(uniqueKey)}
    <Accordion square expanded={isExpanded}>
      <AccordionSummary aria-controls={`panel${index + 1}d-content`} id={`panel${index + 1}d-header`}>
        <div style={{position: 'relative', width: '100%'}}>
          {index === 0 && topButtonClick && (
            <Tooltip
              enterDelay={700}
              leaveDelay={150}
              enterNextDelay={400}
              followCursor
              placement={'top-start'}
              title={'Añadir' + (entityName ? ` ${entityName}` : '')}>
              <OnHoverBox
                style={{
                  position: 'relative',
                  top: -23,
                  height: 30,
                  cursor: 'pointer',
                  borderBottomLeftRadius: '40%',
                  borderBottomRightRadius: '40%',
                  borderTopLeftRadius: '60%',
                  borderTopRightRadius: '60%',
                }}
                onClick={($event) => {
                  $event.stopPropagation();
                  topButtonClick(item, index);
                }}>
                {/* <div style={{borderTop: '1px solid rgb(0,0,0,.3)'}} /> */}
                <div
                  style={{
                    height: 20,
                    backgroundColor: 'rgb(227 229 239 / 95%)',
                    //backgroundColor: 'rgb(63, 81, 181,.125)',
                    borderBottomLeftRadius: '40%',
                    borderBottomRightRadius: '40%',
                    borderTopLeftRadius: '60%',
                    borderTopRightRadius: '60%',
                  }}>
                  <div style={{position: 'relative', top: -8, left: '48%'}}>
                    <IconButton
                      size="small"
                      color="primary"
                      style={{backgroundColor: '#fff', border: '1px solid rgb(63, 81, 181,.6)', borderRadius: '50px'}}>
                      <Add
                        style={{
                          width: 18,
                          height: 18,
                        }}
                      />
                    </IconButton>
                  </div>
                </div>
              </OnHoverBox>
            </Tooltip>
          )}
          <div style={{padding: '0 16px', marginTop: index === 0 ? -25 : undefined}}>
            <div style={{display: 'inline-flex', width: '100%', alignItems: 'center'}}>
              <div style={{width: '50px'}}>
                <IconButton
                  size="small"
                  style={{transform: 'scale(1.1)'}}
                  onClick={() => {
                    setAccordionOpen(uniqueKey);
                  }}>
                  {isExpanded ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              </div>
              <div style={{width: '100%'}}>{renderSummary(item, index)}</div>
            </div>
          </div>
          {!isExpanded && OnHoverBottom()}
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div style={{width: '100%'}}>
          <Grid container direction="row">
            {/* <Grid item xs={1} style={{maxWidth: 50}}>
              <div style={{display: 'flex', height: '100%'}}>
                <div
                  style={{
                    alignSelf: 'stretch',
                    borderLeft: '3px solid gray',
                    borderBottom: '3px solid gray',
                    //float: 'left',
                    //marginTop: '-90px',
                    marginLeft: 'auto',
                    height: '90%',
                    width: '10px',
                  }}
                />
              </div>
            </Grid> */}
            <Grid item xs={12}>
              <div style={{padding: '32px 16px 64px 16px'}}>
                {detailTitle && (
                  <Typography variant="overline" gutterBottom>
                    {detailTitle}
                  </Typography>
                )}
                {renderDetails(item, index)}
              </div>
            </Grid>
          </Grid>
          {isExpanded && OnHoverBottom(-17)}
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
