import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.descRegistro.fields.id'),
  },
  {
    name: 'descripcion',
    label: i18n('entities.descRegistro.fields.descripcion'),
  },
  {
    name: 'categoria',
    label: i18n('entities.descRegistro.fields.categoria'),
  },
  {
    name: 'tipo',
    label: i18n('entities.descRegistro.fields.tipo'),
  },
  {
    name: 'afectaDisponibilidad',
    label: i18n('entities.descRegistro.fields.afectaDisponibilidad'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'prioridad',
    label: i18n('entities.descRegistro.fields.prioridad'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.descRegistro.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.descRegistro.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
