import React, { useEffect } from 'react';

import CommentTwoToneIcon from '@mui/icons-material/CommentTwoTone';
import NotificationsActiveTwoToneIcon from '@mui/icons-material/NotificationsActiveTwoTone';
import {
  alpha,
  Avatar,
  Badge,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  Popover,
  Tooltip,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { formatDistance, subDays } from 'date-fns';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment-timezone';
import selectors from 'src/modules/notificacion/list/notificacionListSelectors';
import { getHistory } from 'src/modules/store';
import Spinner from 'src/view/shared/Spinner';

import NotificacionService from 'src/modules/notificacion/notificacionService';
import DrawerButton from 'src/view/shared/view/DrawerButton';

import MessagesView from 'src/view/comunicacionInterna/messenger/';
import FirmaFormPage from 'src/view/firma/form/FirmaFormPage';
import GestorDocumentalFormPage from 'src/view/gestorDocumental/form/GestorDocumentalFormPage';
import PlanificadorTareaViewPage from 'src/view/planificadorTarea/view/PlanificadorTareaViewPage';
import TareaFormPage from 'src/view/tarea/form/TareaFormPage';

const AnimatedBadge = styled(Badge)(
  ({theme}) => `
    
    .MuiBadge-badge {
        box-shadow: 0 0 0 2px ${theme.palette.background.paper};
        background-color: #44b700;
        color: #44b700;
        
        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            animation: ripple 1.2s infinite ease-in-out;
            border: 1px solid currentColor;
            content: "";
        }
    }
`,
);

const NotificationsBadge = styled(Badge)(
  ({theme}) => `
    
    .MuiBadge-badge {
        background-color: ${theme.palette.error.main};
        color: ${theme.palette.error.contrastText};
        min-width: 18px; 
        height: 18px;
        padding: 0;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            box-shadow: 0 0 0 1px ${alpha(theme.palette.error.main, 0.3)};
            content: "";
        }
    }
`,
);

const IconButtonPrimary = styled(IconButton)(
  ({theme}: any) => `
    margin-left: ${theme.spacing(1)};
    background: ${theme.colors.alpha.trueWhite[10]};
    color: ${theme.colors.alpha.trueWhite[70]};
    padding: 0;
    width: 42px;
    height: 42px;
    border-radius: 100%;
    transition: ${theme.transitions.create(['background', 'color'])};

    &.active,
    &:active,
    &:hover {
      background: ${alpha(theme.colors.alpha.trueWhite[30], 0.2)};
      color: ${theme.colors.alpha.trueWhite[100]};
    }
`,
);

function HeaderNotifications() {
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [rows, setRows]: any = useState([]);
  const [countSinLeer, setCountSinLeer] = useState(0);

  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [notificacion, setNotificacion]: any = useState(null);

  const findLoading = useSelector(selectors.selectLoading);

  const loading = findLoading;

  const handleOpen = () => {
    NotificacionService.list({markAllReads: true}, null, 0, 0);

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const {t} = useTranslation();

  useEffect(() => {
    NotificacionService.list({}, null, 10, 0).then((response) => {
      setCountSinLeer(response.countSinLeer);
      setRows(response.rows);
    });

    // eslint-disable-next-line
  }, []);

  if (loading) {
    <Spinner />;
  }

  return (
    <>
      <Tooltip followCursor title={t('Notificaciones')}>
        <IconButtonPrimary color="primary" ref={ref} onClick={handleOpen}>
          <NotificationsBadge
            badgeContent={countSinLeer}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}>
            <NotificationsActiveTwoToneIcon />
          </NotificationsBadge>
        </IconButtonPrimary>
      </Tooltip>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        <Box sx={{p: 2}} display="flex" justifyContent="space-between">
          <Typography variant="h5">{t('Notificaciones')}</Typography>
          {/* <Link href="#" variant="caption" sx={{ textTransform: 'none' }}>
            {t('Mark all as read')}
          </Link> */}
        </Box>
        <Divider />
        <List sx={{p: 2}}>
          {rows.map((raw, index) => {
            const avatarSrc =
              raw.createdBy && raw.createdBy.avatars && raw.createdBy.avatars.length && raw.createdBy.avatars[0].downloadUrl;
            return (
              <div key={index}>
                <ListItem
                  sx={{
                    display: {
                      xs: 'block',
                      sm: 'flex',
                      width: window.innerWidth > 768 ? window.innerWidth * 0.2 : null,
                    },
                  }}
                  button
                  onClick={() => {
                    setOpen(false);
                    setVisibleDrawer(true);
                    setNotificacion(raw);
                  }}>
                  <ListItemAvatar sx={{mb: {xs: 1, sm: 0}}}>
                    {!!avatarSrc && <Avatar alt={raw.createdBy?.fullName} src={avatarSrc} />}
                  </ListItemAvatar>
                  <Box flex="1">
                    <Box display={{xs: 'block', sm: 'flex'}} justifyContent="space-between">
                      <Typography sx={{fontWeight: 'bold'}}>{raw.createdBy?.fullName}</Typography>
                      <Typography variant="caption" sx={{textTransform: 'none'}}>
                        {raw.fechaProgramacion ? moment(raw.fechaProgramacion).fromNow() : moment(raw.createdAt).fromNow()}
                      </Typography>
                    </Box>
                    <Grid container>
                      <Typography
                        component="span"
                        variant="body2"
                        sx={
                          {
                            //color: theme => theme.palette.error.main,
                          }
                        }>
                        {raw.asunto.substr(0, 250)}
                      </Typography>
                    </Grid>
                  </Box>
                </ListItem>
                <Divider variant="inset" sx={{my: 1}} component="li" />
              </div>
            );
          })}
          <ListItem sx={{display: {xs: 'block', sm: 'flex'}}}>
            <ListItemAvatar sx={{mb: {xs: 1, sm: 0}}}>
              <Avatar
                sx={{
                  background: (theme) => theme.palette.success.main,
                }}>
                <CommentTwoToneIcon />
              </Avatar>
            </ListItemAvatar>
            <Box flex="1">
              <Box display="flex" justifyContent="space-between">
                <Typography sx={{fontWeight: 'bold'}}>Buzón</Typography>
                <Typography variant="caption" sx={{textTransform: 'none'}}>
                  {formatDistance(subDays(new Date(), 3), new Date(), {
                    addSuffix: true,
                  })}
                </Typography>
              </Box>
              <Typography component="span" variant="body2" fontWeight="bold">
                {countSinLeer}
              </Typography>
              <Typography component="span" variant="body2" color="text.secondary">
                {' '}
                {t('Nuevos mensajes en tu buzón')}
              </Typography>
            </Box>
          </ListItem>
        </List>
        <Divider />
        <Box sx={{m: 1}}>
          <Button onClick={() => getHistory().push('/notificacion')} color="primary" fullWidth>
            {t('Ir al Buzón')}
          </Button>
        </Box>
      </Popover>

      {visibleDrawer && notificacion && notificacion.relacionadoA === 'GestorDocumental' && (
        <DrawerButton
          drawerId={'DrawerGestorDocumentalFormPage'}
          type={null}
          open={moment().utc()}
          width={window.innerWidth * 0.55}
          onClose={() => setVisibleDrawer(false)}
          component={
            <GestorDocumentalFormPage
              record={{
                id: notificacion.documentosRelacionados[0] ?? null,
              }}
              disabled={true}
              drawer={true}
            />
          }
        />
      )}

      {visibleDrawer && notificacion && notificacion.relacionadoA === 'Comunicacion' && (
        <DrawerButton
          drawerId={'DrawerComunicacionFormPage'}
          type={null}
          open={moment().utc()}
          width={window.innerWidth * 0.55}
          onClose={() => setVisibleDrawer(false)}
          component={
            <MessagesView
              drawer={true}
              record={{
                id:
                  notificacion.comunicacionesInternasRelacionadas &&
                  notificacion.comunicacionesInternasRelacionadas.length &&
                  notificacion.comunicacionesInternasRelacionadas[0],
              }}
            />
          }
        />
      )}

      {visibleDrawer && notificacion && notificacion.relacionadoA === 'Tarea' && (
        <DrawerButton
          drawerId={'DrawerTareaFormPage'}
          type={null}
          open={moment().utc()}
          width={window.innerWidth * 0.55}
          onClose={() => setVisibleDrawer(false)}
          component={
            <TareaFormPage
              disabled={false}
              tarea={{
                id:
                  notificacion.tareasRelacionadas && notificacion.tareasRelacionadas.length && notificacion.tareasRelacionadas[0],
              }}
              drawer={true}
            />
          }
        />
      )}

      {visibleDrawer && notificacion && notificacion.relacionadoA === 'Planificacion' && (
        <DrawerButton
          drawerId={'DrawerTareaFormPage'}
          type={null}
          open={moment().utc()}
          width={window.innerWidth * 0.7}
          onClose={() => setVisibleDrawer(false)}
          component={
            <PlanificadorTareaViewPage
              record={{
                id:
                  notificacion.planificacionesRelacionadas &&
                  notificacion.planificacionesRelacionadas.length &&
                  notificacion.planificacionesRelacionadas[0],
              }}
              drawer={true}
            />
          }
        />
      )}

      {/* {visibleDrawer &&
        notificacion &&
        notificacion.relacionadoA === 'Evento' && (
          <DrawerButton
            drawerId={'DrawerEventoFormPage'}
            type={null}
            open={moment().utc()}
            width={window.innerWidth * 0.55}
            onClose={() => setVisibleDrawer(false)}
            component={
              <MessagesView
                drawer={true}
                record={
                  notificacion.eventosRelacionados &&
                  notificacion.eventosRelacionados.length &&
                  notificacion.eventosRelacionados[0]
                }
              />
            }
          />
        )} */}

      {/* {visibleDrawer &&
        notificacion &&
        notificacion.relacionadoA === 'Empleado' && (
          <DrawerButton
            drawerId={'DrawerEmpleadoFormPage'}
            type={null}
            open={moment().utc()}
            width={window.innerWidth * 0.55}
            onClose={() => setVisibleDrawer(false)}
            component={
              <MessagesView
                drawer={true}
                record={
                  notificacion.usuariosRelacionados &&
                  notificacion.usuariosRelacionados
                    .length &&
                  notificacion.usuariosRelacionados[0]
                }
              />
            }
          />
        )} */}

      {visibleDrawer && notificacion && notificacion.relacionadoA === 'Firma' && (
        <DrawerButton
          drawerId={'DrawerFirmaFormPage'}
          type={null}
          open={moment().utc()}
          width={window.innerWidth * 0.55}
          onClose={() => setVisibleDrawer(false)}
          component={
            <FirmaFormPage
              record={{
                id:
                  notificacion.firmasRelacionadas && notificacion.firmasRelacionadas.length && notificacion.firmasRelacionadas[0],
              }}
              disabled={false}
              drawer={true}
            />
          }
        />
      )}

      {visibleDrawer && notificacion && notificacion.relacionadoA === 'Inventario' && (
        <DrawerButton
          drawerId={'DrawerInventarioFormPage'}
          type={null}
          open={moment().utc()}
          width={window.innerWidth * 0.55}
          onClose={() => setVisibleDrawer(false)}
          component={
            <MessagesView
              drawer={true}
              record={
                notificacion.inventarioRelacionados &&
                notificacion.inventarioRelacionados.length &&
                notificacion.inventarioRelacionados[0]
              }
            />
          }
        />
      )}

      {visibleDrawer && notificacion && notificacion.relacionadoA === 'Historial de Inventario' && (
        <DrawerButton
          drawerId={'DrawerHistorialInventarioFormPage'}
          type={null}
          open={moment().utc()}
          width={window.innerWidth * 0.55}
          onClose={() => setVisibleDrawer(false)}
          component={
            <MessagesView
              drawer={true}
              record={
                notificacion.historialInventarioRelacionados &&
                notificacion.historialInventarioRelacionados.length &&
                notificacion.historialInventarioRelacionados[0]
              }
            />
          }
        />
      )}
    </>
  );
}

export default HeaderNotifications;
