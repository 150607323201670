import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/pendiente/importer/pendienteImporterSelectors';
import PendienteService from 'src/modules/pendiente/pendienteService';
import fields from 'src/modules/pendiente/importer/pendienteImporterFields';
import { i18n } from 'src/i18n';

const pendienteImporterActions = importerActions(
  'PENDIENTE_IMPORTER',
  selectors,
  PendienteService.import,
  fields,
  i18n('entities.pendiente.importer.fileName'),
);

export default pendienteImporterActions;