import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/descRegistro/importer/descRegistroImporterSelectors';
import DescRegistroService from 'src/modules/descRegistro/descRegistroService';
import fields from 'src/modules/descRegistro/importer/descRegistroImporterFields';
import { i18n } from 'src/i18n';

const descRegistroImporterActions = importerActions(
  'DESCREGISTRO_IMPORTER',
  selectors,
  DescRegistroService.import,
  fields,
  i18n('entities.descRegistro.importer.fileName'),
);

export default descRegistroImporterActions;