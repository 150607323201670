import list from 'src/modules/asignadorFuncion/list/asignadorFuncionListReducers';
import form from 'src/modules/asignadorFuncion/form/asignadorFuncionFormReducers';
import view from 'src/modules/asignadorFuncion/view/asignadorFuncionViewReducers';
import destroy from 'src/modules/asignadorFuncion/destroy/asignadorFuncionDestroyReducers';
import importerReducer from 'src/modules/asignadorFuncion/importer/asignadorFuncionImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
