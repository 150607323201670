import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/objetivo/importer/objetivoImporterSelectors';
import ObjetivoService from 'src/modules/objetivo/objetivoService';
import fields from 'src/modules/objetivo/importer/objetivoImporterFields';
import { i18n } from 'src/i18n';

const objetivoImporterActions = importerActions(
  'OBJETIVO_IMPORTER',
  selectors,
  ObjetivoService.import,
  fields,
  i18n('entities.objetivo.importer.fileName'),
);

export default objetivoImporterActions;