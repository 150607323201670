import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/habilidad/importer/habilidadImporterSelectors';
import HabilidadService from 'src/modules/habilidad/habilidadService';
import fields from 'src/modules/habilidad/importer/habilidadImporterFields';
import { i18n } from 'src/i18n';

const habilidadImporterActions = importerActions(
  'HABILIDAD_IMPORTER',
  selectors,
  HabilidadService.import,
  fields,
  i18n('entities.habilidad.importer.fileName'),
);

export default habilidadImporterActions;