import {i18n} from 'src/i18n';
import planificadorTareaCustomEnumerators from 'src/modules/planificadorTareaCustom/planificadorTareaCustomEnumerators';
import schemas from 'src/modules/shared/yup/yupImporterSchemas';

export default [
  {
    name: 'nivel',
    label: i18n('entities.planificadorTareaCustom.fields.nivel'),
    schema: schemas.integer(i18n('entities.planificadorTareaCustom.fields.nivel'), {}),
  },
  {
    name: 'nombre',
    label: i18n('entities.planificadorTareaCustom.fields.nombre'),
    schema: schemas.string(i18n('entities.planificadorTareaCustom.fields.nombre'), {}),
  },
  {
    name: 'duracion',
    label: i18n('entities.planificadorTareaCustom.fields.duracion'),
    schema: schemas.string(i18n('entities.planificadorTareaCustom.fields.duracion'), {}),
  },
  {
    name: 'comienzo',
    label: i18n('entities.planificadorTareaCustom.fields.comienzo'),
    schema: schemas.string(i18n('entities.planificadorTareaCustom.fields.comienzo'), {}),
    //schemas.datetime(i18n('entities.planificadorTareaCustom.fields.comienzo'), {fromFormat: 'M/D/YY HH:mm'}),
  },
  {
    name: 'fin',
    label: i18n('entities.planificadorTareaCustom.fields.fin'),
    schema: schemas.string(i18n('entities.planificadorTareaCustom.fields.fin'), {}),
    //schemas.datetime(i18n('entities.planificadorTareaCustom.fields.fin'), {fromFormat: 'M/D/YY HH:mm'}),
  },
  {
    name: 'tipo',
    label: i18n('entities.planificadorTareaCustom.fields.tipo'),
    schema: schemas.string(i18n('entities.planificadorTareaCustom.fields.tipo'), {}),
  },
  {
    name: 'responsable',
    label: i18n('entities.planificadorTareaCustom.fields.responsable'),
    schema: schemas.string(i18n('entities.planificadorTareaCustom.fields.responsable'), {}),
  },
  {
    name: 'visualizacion',
    label: i18n('entities.planificadorTareaCustom.fields.visualizacion'),
    schema: schemas.string(i18n('entities.planificadorTareaCustom.fields.visualizacion'), {
      options: planificadorTareaCustomEnumerators.visualizacion,
    }),
  },
  {
    name: 'prioridad',
    label: i18n('entities.planificadorTareaCustom.fields.prioridad'),
    schema: schemas.string(i18n('entities.planificadorTareaCustom.fields.prioridad'), {}),
  },
  {
    name: 'tipo_periodicidad',
    label: i18n('entities.planificadorTareaCustom.fields.tipo_periodicidad'),
    schema: schemas.enumerator(i18n('entities.planificadorTareaCustom.fields.tipo_periodicidad'), {
      options: planificadorTareaCustomEnumerators.tipo_periodicidad,
    }),
  },
  {
    name: 'periodicidad',
    label: i18n('entities.planificadorTareaCustom.fields.periodicidad'),
    schema: schemas.integer(i18n('entities.planificadorTareaCustom.fields.periodicidad'), {}),
  },
];
