import {yupResolver} from '@hookform/resolvers/yup';
import {Button, Grid, Typography} from '@mui/material';
import moment from 'moment-timezone';
import React, {useState} from 'react';
import {FormProvider, useForm, useWatch} from 'react-hook-form';
import {i18n} from 'src/i18n';
import planificacionEnumerators from 'src/modules/planificacion/planificacionEnumerators';
import yupFormSchemas from 'src/modules/shared/yup/yupFormSchemas';
import Storage from 'src/security/storage';
import DatePickerFormItem from 'src/view/shared/form/items/DatePickerFormItem';
import GoogleAutocompleteFormItem from 'src/view/shared/form/items/GoogleAutocompleteFormItem';
import ImagesFormItem from 'src/view/shared/form/items/ImagesFormItem';
import InputFormItem from 'src/view/shared/form/items/InputFormItem';
import InputNumberFormItem from 'src/view/shared/form/items/InputNumberFormItem';
import SelectFormItem from 'src/view/shared/form/items/SelectFormItem';
import TextAreaFormItem from 'src/view/shared/form/items/TextAreaFormItem';
import FormWrapper from 'src/view/shared/styles/FormWrapper';
import UbicacionAutocompleteFormItem from 'src/view/ubicacion/autocomplete/UbicacionAutocompleteFormItem';
import UserAutocompleteFormItem from 'src/view/user/autocomplete/UserAutocompleteFormItem';
import * as yup from 'yup';

import {Close, SaveOutlined} from '@mui/icons-material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import {useSelector} from 'react-redux';
import authSelectors from 'src/modules/auth/authSelectors';
import planificacionListSelectors from 'src/modules/planificacion/list/planificacionListSelectors';
import {customButtonStyle, magicFlexBox} from 'src/theme/schemes/CustomTheme';
import GestorDocumentalAutocompleteFormItem from 'src/view/gestorDocumental/autocomplete/GestorDocumentalAutocompleteFormItem';
import CheckboxFormItem from 'src/view/shared/form/items/CheckboxFormItem';
import RadioFormItem from 'src/view/shared/form/items/RadioFormItem';
import ConfirmModal from 'src/view/shared/modals/ConfirmModal';
import ClasificacionTagView from 'src/view/shared/view/ClasificacionTagView';
import TerceroAutocompleteFormItem from 'src/view/tercero/autocomplete/TerceroAutocompleteFormItem';
import PlanificacionDependencyView from '../dependency/PlanificacionDependencyView';

const schema = yup.object().shape({
  isFolder: yupFormSchemas.boolean(i18n('entities.planificacion.fields.isFolder'), {}),
  isTemplate: yupFormSchemas.boolean(i18n('entities.planificacion.fields.isTemplate'), {}),
  estado: yupFormSchemas.enumerator(i18n('entities.planificacion.fields.estado'), {
    required: false,
    options: planificacionEnumerators.estado,
  }),
  documentosAdjuntos: yupFormSchemas.files(i18n('entities.planificacion.fields.documentosAdjuntos'), {}),
  fotosAdjuntas: yupFormSchemas.images(i18n('entities.planificacion.fields.fotosAdjuntas'), {
    required: false,
  }),
  activo: yupFormSchemas.relationToMany(i18n('entities.planificacion.fields.activo'), {
    required: false,
  }),
  nombre: yupFormSchemas.string(i18n('entities.planificacion.fields.nombre'), {
    required: true,
  }),
  descripcion: yupFormSchemas.string(i18n('entities.planificacion.fields.descripcion'), {}),
  ubicacion: yupFormSchemas.relationToOne(i18n('entities.planificacion.fields.ubicacion'), {
    required: false,
  }),
  responsables: yupFormSchemas.relationToMany(i18n('entities.planificacion.fields.responsables'), {
    required: false,
  }),
  tercero: yupFormSchemas.relationToOne(i18n('entities.planificacion.fields.tercero'), {
    required: false,
  }),
  tareas: yupFormSchemas.relationToMany(i18n('entities.planificacion.fields.tareas'), {
    required: false,
  }),
  ubicacionGoogle: yupFormSchemas.string(i18n('entities.planificacion.fields.ubicacionGoogle'), {}),
  latitud: yupFormSchemas.decimal(i18n('entities.planificacion.fields.latitud'), {}),
  longitud: yupFormSchemas.decimal(i18n('entities.planificacion.fields.longitud'), {}),
  clasificacion: yupFormSchemas.enumerator(i18n('entities.planificacion.fields.clasificacion'), {
    options: planificacionEnumerators.clasificacion,
    required: false,
  }),
  dependency: yupFormSchemas.generic(i18n('entities.planificacion.fields.dependency')),
  ordenServicio: yupFormSchemas.string(i18n('entities.planificacion.fields.ordenServicio'), {}),
  ordenMantenimiento: yupFormSchemas.string(i18n('entities.planificacion.fields.ordenMantenimiento'), {}),
  fechaEstimadaInicio: yupFormSchemas.date(i18n('entities.planificacion.fields.fechaEstimadaInicio'), {
    required: false,
  }),
  fechaEstimadaFin: yupFormSchemas.date(i18n('entities.planificacion.fields.fechaEstimadaFin'), {
    required: false,
  }),
  presupuestoEstimado: yupFormSchemas.integer(i18n('entities.planificacion.fields.presupuestoEstimado'), {
    required: false,
  }),
  parentLabel: yupFormSchemas.string(i18n('entities.planificacion.fields.parentLabel'), {}),
  planificacionPadre: yupFormSchemas.string(i18n('entities.planificacion.fields.planificacionPadre'), {}),
  planificacionRaiz: yupFormSchemas.string(i18n('entities.planificacion.fields.planificacionRaiz'), {}),
  nivelAnidacion: yupFormSchemas.integer(i18n('entities.planificacion.fields.nivelAnidacion'), {}),
  locations: yupFormSchemas.relationToMany(i18n('common.locations'), {}),
  sequentialExecutionRequired: yupFormSchemas.boolean(i18n('entities.planificacion.fields.sequentialExecutionRequired'), {}),
});

function TabPanel(props) {
  const {children, value, index, simpleView, ...other} = props;

  return (
    <Box
      sx={
        {
          ...magicFlexBox,
          display: value !== index ? 'none' : 'flex',
        } as any
      }
      role="tabpanel"
      style={{width: '100%', marginTop: simpleView ? -30 : undefined}}
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box
          sx={
            {
              ...magicFlexBox,
              overflow: 'auto',
              pr: 1,
            } as any
          }
          p={0}
          pt={2}>
          {children}
        </Box>
      )}
    </Box>
  );
}

function PlanificacionForm(props) {
  const tenantUser = useSelector(authSelectors.selectTenantUser);
  const userLocations = tenantUser?.perfilEmpleado?.ubicacion;

  const [tabValue, setTabValue] = useState(0);
  const [confirm, setConfirm] = useState(false);

  const listFilter = useSelector(planificacionListSelectors.selectFilter);
  const listFilterRaw = useSelector(planificacionListSelectors.selectRawFilter);
  const terceroSelected = listFilterRaw?.tercero;
  //console.log('%c⧭ PlanificacionForm', 'color: #cc7033', {listFilter, terceroSelected});

  const isPlanningTemplates = props.isPlanningTemplates;

  const [initialValues] = useState(() => {
    const record = props.record || {};
    //console.log('%c⧭ PlanificacionForm', 'color: #e50000', {record});

    return {
      isFolder: record && 'isFolder' in record ? record.isFolder : true,
      isTemplate: record && 'isTemplate' in record ? record.isTemplate : isPlanningTemplates,
      dependency: record.dependency,
      ordenServicio: record.ordenServicio,
      ordenMantenimiento: record.ordenMantenimiento,
      fechaEstimadaInicio: record.fechaEstimadaInicio || null,
      fechaEstimadaFin: record.fechaEstimadaFin || null,
      presupuestoEstimado: record.presupuestoEstimado || 0,
      documentosAdjuntos: record.documentosAdjuntos || [],
      fotosAdjuntas: record.fotosAdjuntas || [],
      tareas: record.tareas || [],
      // activo: record.activo || [],
      nombre: record.nombre,
      sequentialExecutionRequired: record.sequentialExecutionRequired || false,
      descripcion: record.descripcion,
      ubicacion: record.ubicacion,
      responsables: record.responsables || [],
      tercero: record?.id ? record.tercero : terceroSelected,
      ubicacionGoogle: record.ubicacionGoogle,
      latitud: record.latitud,
      longitud: record.longitud,
      clasificacion: record.clasificacion || 'Programada',
      estado: record.estado || 'Pendiente',
      planificacionPadre: record.planificacionPadre,
      parentLabel: record.parentLabel,
      planificacionRaiz: record.planificacionRaiz,
      nivelAnidacion: record.nivelAnidacion || 0,
      locations: record.locations || userLocations || [],
    };
  });

  const form = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: initialValues as any,
    shouldUnregister: false,
  });

  const onSubmit = (values) => {
    setConfirm(false);

    props.onSubmit(props.record?.id, values);
  };

  const onSave = (values) => {
    props.onSubmit(props.record?.id, values);
  };

  const onReset = () => {
    Object.keys(initialValues).forEach((key) => {
      form.setValue(key, initialValues[key]);
    });
  };

  function a11yProps(index) {
    return {
      id: `planificacion-tab-${index}`,
      'aria-controls': `planificacion-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const doConfirm = () => {
    setConfirm(true);
  };

  const doCloseConfirm = () => {
    setConfirm(false);
  };

  const {saveLoading, modal} = props;

  const estado = useWatch({
    control: form.control,
    name: 'estado',
  });

  const isFolder = useWatch({
    control: form.control,
    name: 'isFolder',
  });

  const isTemplate = useWatch({
    control: form.control,
    name: 'isTemplate',
  });

  return (
    <FormWrapper>
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          style={
            {
              ...magicFlexBox,
            } as any
          }>
          <Box
            sx={{
              bgcolor: 'background.paper',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
              flexGrow: 1,
            }}>
            <Box display="flex" flexDirection={'row'} gap={2} alignItems={'center'}>
              <Typography variant="h3" style={{paddingLeft: 2}}>
                {form.watch('nombre')}
              </Typography>
              {!props.disabled && (
                <CheckboxFormItem
                  name="isFolder"
                  label={i18n('entities.planificacion.fields.isFolder')}
                  defaultValue={initialValues.isFolder}
                />
              )}
              {!props.disabled && (isTemplate || isPlanningTemplates) && (
                <CheckboxFormItem
                  disabled={isPlanningTemplates}
                  name="isTemplate"
                  label={i18n('entities.planificacion.fields.isTemplate')}
                  defaultValue={initialValues.isTemplate}
                />
              )}
              {estado && props.record?.id && (
                <Box>
                  <ClasificacionTagView value={estado} entity="planificacion" fieldName="estado" />
                </Box>
              )}

              {/* <div style={{marginBottom: 15}}>
                <QRCode
                  value={`${config.frontendUrl.protocol}://${config.frontendUrl.host}/planificacion/${props.record?.id}`}
                  size={140}
                />
              </div> */}
            </Box>
            {/* {isFolder && (
              <InputFormItem
                name="nombre"
                disabled={props.disabled}
                label={i18n('entities.planificacion.fields.nombre')}
                required={true}
              />
            )} */}
            <Box
              sx={
                {
                  ...magicFlexBox,
                } as any
              }>
              <Tabs
                orientation="horizontal"
                variant="scrollable"
                value={tabValue}
                onChange={handleChange}
                aria-label="planificacion-wizard"
                textColor="secondary"
                indicatorColor="secondary"
                sx={{
                  borderRight: 1,
                  borderColor: 'divider',
                }}>
                <Tab label="General" {...a11yProps(0)} />
                <Tab label="Ubicación Externa" {...a11yProps(1)} />
                {/* <Tab label="Recursos" {...a11yProps(2)} /> */}
                <Tab label="Adjuntos" {...a11yProps(2)} />
              </Tabs>
              <TabPanel value={tabValue} index={0}>
                <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
                  <InputFormItem
                    name="nombre"
                    disabled={props.disabled}
                    label={i18n('entities.planificacion.fields.nombre')}
                    required={true}
                  />
                </Grid>
                <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
                  <TerceroAutocompleteFormItem
                    name="tercero"
                    disabled={props.disabled}
                    label={i18n('entities.planificacion.fields.tercero')}
                    filter={{tipo: ['Cliente']}}
                    showCreate={!props.modal}
                  />
                </Grid>
                <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
                  <UserAutocompleteFormItem
                    name="responsables"
                    disabled={props.disabled}
                    label={i18n('entities.planificacion.fields.responsables')}
                    required={false}
                    showCreate={!props.modal}
                    mode="multiple"
                  />
                </Grid>
                <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
                  <RadioFormItem
                    name="estado"
                    disabled={props.disabled}
                    label={i18n('entities.planificacion.fields.estado')}
                    options={planificacionEnumerators.estado.map((value) => ({
                      value,
                      label: i18n(`entities.planificacion.enumerators.estado.${value}`),
                    }))}
                    required={true}
                  />
                </Grid>
                <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
                  <ImagesFormItem
                    name="fotosAdjuntas"
                    disabled={props.disabled}
                    label={i18n('entities.planificacion.fields.fotosAdjuntas')}
                    required={false}
                    storage={Storage.values.planificacionFotosAdjuntas}
                    max={undefined}
                  />
                </Grid>
                <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
                  <TextAreaFormItem
                    name="descripcion"
                    disabled={props.disabled}
                    label={i18n('entities.planificacion.fields.descripcion')}
                    required={false}
                  />
                </Grid>
                {!isFolder && (
                  <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
                    <PlanificacionDependencyView
                      name="tareas"
                      depName="dependency"
                      disabled={props.disabled}
                      label={i18n('entities.planificacion.fields.tareas')}
                      isForm={true}
                      defaultValue={initialValues.tareas}
                      defaultDep={initialValues.dependency}
                      showCreate={!props.modal}
                    />
                    <CheckboxFormItem
                      disabled={props.disabled}
                      name="sequentialExecutionRequired"
                      label={i18n('entities.planificacion.fields.sequentialExecutionRequired')}
                      defaultValue={initialValues.sequentialExecutionRequired}
                    />
                  </Grid>
                )}
                {!isFolder && (
                  <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
                    <SelectFormItem
                      name="clasificacion"
                      disabled={props.disabled}
                      label={i18n('entities.planificacion.fields.clasificacion')}
                      options={planificacionEnumerators.clasificacion.map((value) => ({
                        value,
                        label: i18n(`entities.planificacion.enumerators.clasificacion.${value}`),
                      }))}
                      required={false}
                    />
                  </Grid>
                )}
                <Grid item lg={props.drawer ? 12 : 6} md={8} sm={12} xs={12}>
                  <UbicacionAutocompleteFormItem
                    name="locations"
                    disabled={props.disabled}
                    label={i18n('common.locations')}
                    required={false}
                    showCreate={false}
                    mode="multiple"
                  />
                </Grid>
                {/* <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
                  <UbicacionAutocompleteFormItem
                    name="ubicacion"
                    entity="ubicacion"
                    disabled={props.disabled}
                    label={i18n('entities.planificacion.fields.ubicacion')}
                    required={false}
                    showCreate={!props.modal}
                  />
                </Grid> */}
                <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
                  <InputFormItem
                    name="ordenServicio"
                    disabled={props.disabled}
                    label={i18n('entities.planificacion.fields.ordenServicio')}
                    required={false}
                  />
                </Grid>
                <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
                  <InputFormItem
                    name="ordenMantenimiento"
                    disabled={props.disabled}
                    label={i18n('entities.planificacion.fields.ordenMantenimiento')}
                    required={false}
                  />
                </Grid>
                <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
                  <DatePickerFormItem
                    name="fechaEstimadaInicio"
                    disabled={props.disabled}
                    label={i18n('entities.planificacion.fields.fechaEstimadaInicio')}
                    required={false}
                  />
                </Grid>
                <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
                  <DatePickerFormItem
                    name="fechaEstimadaFin"
                    disabled={props.disabled}
                    minDate={moment(form.watch('fechaEstimadaInicio')).toISOString()}
                    label={i18n('entities.planificacion.fields.fechaEstimadaFin')}
                    required={false}
                  />
                </Grid>
                <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
                  <InputNumberFormItem
                    name="presupuestoEstimado"
                    disabled={props.disabled}
                    label={i18n('entities.planificacion.fields.presupuestoEstimado')}
                    required={false}
                  />
                </Grid>
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
                  <GoogleAutocompleteFormItem
                    name="ubicacionGoogle"
                    disabled={props.disabled}
                    label={i18n('entities.planificacion.fields.ubicacionGoogle')}
                    required={false}
                    defaultCenter={{
                      lat: -36.8269882,
                      lng: -73.0497665,
                    }}
                    center={{
                      lat: form.watch('latitud'),
                      lng: form.watch('longitud'),
                    }}
                    zoom={14}
                    enableMap={true}
                  />
                </Grid>
              </TabPanel>
              {/* <TabPanel value={tabValue} index={2}>
                <Grid
                  item
                  lg={props.drawer ? 12 : 7}
                  md={8}
                  sm={12}
                  xs={12}
                >
                  <ActivoAutocompleteFormItem
                    name="activo"
                    disabled={props.disabled}
                    label={i18n(
                      'entities.planificacion.fields.activo',
                    )}
                    required={false}
                    showCreate={!props.modal}
                    mode="multiple"
                  />
                </Grid>
              </TabPanel> */}
              <TabPanel value={tabValue} index={2}>
                <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
                  <GestorDocumentalAutocompleteFormItem
                    name="documentosAdjuntos"
                    label={i18n('entities.planificacion.fields.documentosAdjuntos')}
                    disabled={props.disabled}
                    required={false}
                    showCreate={!props.modal}
                    defaultprops={{tipo: 'Imagen'}}
                    showButton={true}
                    mode="multiple"
                    disableAutocomplete
                  />
                </Grid>
              </TabPanel>
            </Box>
          </Box>

          {!props.disabled && (
            <Box
              style={{
                marginTop: 20,
                marginBottom: 15,
                display: 'flex',
                flexShrink: 0,
              }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Button
                    style={{
                      width: '100%',
                      ...customButtonStyle,
                    }}
                    variant="contained"
                    color="secondary"
                    disabled={saveLoading}
                    type="button"
                    onClick={form.handleSubmit(onSave)}
                    startIcon={<SaveOutlined />}
                    size="large">
                    {i18n('common.save')}
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    style={{
                      width: '100%',
                      ...customButtonStyle,
                      backgroundColor: 'red',
                    }}
                    variant="contained"
                    color="secondary"
                    disabled={saveLoading}
                    type="button"
                    onClick={() => {
                      form.setValue('estado', 'Cerrada');
                      doConfirm();
                    }}
                    startIcon={<Close />}
                    size="large">
                    {i18n('common.closePlanning')}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          )}
        </form>
      </FormProvider>

      {confirm && (
        <ConfirmModal
          title={i18n('common.areYouSure')}
          onConfirm={form.handleSubmit(onSubmit)}
          onClose={() => doCloseConfirm()}
          okText={i18n('common.yes')}
          cancelText={i18n('common.no')}
        />
      )}
    </FormWrapper>
  );
}

export default PlanificacionForm;
