import {makeStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {useFormContext, useWatch} from 'react-hook-form';
import CreatableSelect from 'react-select/creatable';
import {i18n} from 'src/i18n';
import FormErrors from 'src/view/shared/form/formErrors';
import {
  components as materialUiComponents,
  styles as materialUiStyles,
} from 'src/view/shared/form/items/shared/reactSelectMaterialUi';

const useStyles = makeStyles(materialUiStyles as any);

function TagsFormItem(props) {
  const {
    label,
    name,
    hint,
    externalErrorMessage,
    required,
    placeholder,
    isClearable,
    notFoundContent,
    disabled,
    getOptionValue,
    createOption,
  } = props;

  const {
    register,
    formState: {touchedFields, isSubmitted, errors},
    setValue,
    control,
  } = useFormContext();

  const errorMessage = FormErrors.errorMessage(name, errors, touchedFields, isSubmitted, externalErrorMessage);

  useEffect(() => {
    register(name);
  }, []);

  const originalValue: any = useWatch({name, control});

  const handleChange = (data) => {
    if (!data || !data.length) {
      setValue(name, null, {shouldValidate: true});
      props.onChange && props.onChange(null);
      return;
    }

    const commaSplittedValues = createOption
      ? data
      : data
          .map((item) => item.value)
          .join(',')
          .split(',');

    setValue(name, commaSplittedValues, {shouldValidate: true});
    props.onChange && props.onChange(commaSplittedValues);
  };

  const handleCreate = (inputValue) => {
    const newOption = createOption(inputValue);
    const newData = (originalValue || []).concat([newOption]);
    handleChange(newData);
  };

  const value = () => {
    if (!originalValue || !originalValue.length) {
      return [];
    }

    if (getOptionValue) {
      return originalValue;
    }

    return originalValue?.map((item) => ({
      value: item,
      label: item,
    }));
  };

  const controlStyles = {
    container: (provided) => ({
      ...provided,
      width: '100%',
      marginTop: '0px',
      marginBottom: '0px',
    }),
    control: (provided) => ({
      ...provided,
      borderColor: Boolean(errorMessage) ? 'red' : undefined,
    }),
  };

  const classes = useStyles();

  return (
    <CreatableSelect
      value={value()}
      classes={classes}
      onChange={handleChange}
      onBlur={(event) => {
        props.onBlur && props.onBlur(event);
      }}
      inputId={name}
      TextFieldProps={{
        label,
        required,
        variant: 'outlined',
        fullWidth: true,
        error: Boolean(errorMessage),
        helperText: errorMessage || hint,
        InputLabelProps: {
          shrink: true,
        },
        size: 'small',
      }}
      components={materialUiComponents}
      placeholder={placeholder || ''}
      isClearable={isClearable}
      isDisabled={disabled}
      styles={controlStyles}
      isMulti
      getOptionValue={getOptionValue}
      onCreateOption={createOption ? handleCreate : undefined}
      formatCreateLabel={(inputValue) => inputValue}
      loadingMessage={() => i18n('autocomplete.loading')}
      noOptionsMessage={() => notFoundContent || ''}
    />
  );
}

TagsFormItem.defaultProps = {
  required: false,
  isClearable: true,
};

TagsFormItem.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  hint: PropTypes.string,
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
  mode: PropTypes.string,
  isClearable: PropTypes.bool,
  notFoundContent: PropTypes.string,
  disabled: PropTypes.bool,
  getOptionValue: PropTypes.func,
  createOption: PropTypes.func,
};

export default TagsFormItem;
