import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/gestorDocumental/importer/gestorDocumentalImporterSelectors';
import GestorDocumentalService from 'src/modules/gestorDocumental/gestorDocumentalService';
import fields from 'src/modules/gestorDocumental/importer/gestorDocumentalImporterFields';
import { i18n } from 'src/i18n';

const gestorDocumentalImporterActions = importerActions(
  'GESTORDOCUMENTAL_IMPORTER',
  selectors,
  GestorDocumentalService.import,
  fields,
  i18n('entities.gestorDocumental.importer.fileName'),
);

export default gestorDocumentalImporterActions;