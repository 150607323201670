import {ArrowDownward, ArrowUpward, Delete, FileCopy} from '@mui/icons-material';
import {Box, Button, IconButton, Tooltip, Typography, styled} from '@mui/material';
import moment from 'moment-timezone';
import React, {useEffect, useState} from 'react';
import {useFieldArray, useFormContext, useWatch} from 'react-hook-form';
import {i18n} from 'src/i18n';
import CustomAccordion from 'src/view/shared/CustomAccordion';
import CheckboxFormItem from 'src/view/shared/form/items/CheckboxFormItem';
import InputFormItem from 'src/view/shared/form/items/InputFormItem';
import ConfirmModal from 'src/view/shared/modals/ConfirmModal';
import SubseccionesPreguntasFormularioForm from './SubseccionesPreguntasFormularioForm';

const OnHoverBox = styled(Box)`
  display: flex;
  flexdirection: row;
  gap: 8px;
  opacity: 0.2;
  &:hover {
    opacity: 1;
  }
`;
export default function SubseccionesFormularioForm(props) {
  const {categoriaIndex, name, esRespuesta = false, editar = true, clasificacion = null, subseccion} = props;

  const {control, getValues} = useFormContext();

  const {fields, remove, append, insert, move} = useFieldArray({
    control,
    name: `${name}.${categoriaIndex}.subseccions`,
  });
  const fieldsWatch = useWatch({name: `${name}.${categoriaIndex}.subseccions`, control});

  const [recordToDelete, setRecordToDelete] = useState(null);

  const doCloseDeleteConfirmModal = () => {
    setRecordToDelete(null);
  };

  const doDeleteRecord = (subseccionIndex) => {
    remove(subseccionIndex);
    doCloseDeleteConfirmModal();
  };

  const doOpenDeleteConfirmModal = (subseccionIndex) => {
    setRecordToDelete(subseccionIndex);
  };

  const [recordToCopy, setRecordToCopy] = useState(null);

  const doCloseCopyConfirmModal = () => {
    setRecordToCopy(null);
  };

  const doCopyJob = async (original) => {
    const subseccion = {...original};
    subseccion.categoria = subseccion.categoria + ' Copia ' + moment().format('DD-MM-YYYY HH:mm:ss');
    delete subseccion.key;
    delete subseccion.id;
    // delete record._id;
    // delete record.tenant;
    // delete record.createdAt;
    // delete record.updatedAt;
    // delete record.createdBy;
    // delete record.updatedBy;
    //console.log('%c⧭ doCopyJob', 'color: #9c66cc', subseccion);
    append(subseccion);
    doCloseCopyConfirmModal();
  };

  const doOpenCopyConfirmModal = (subseccionIndex) => {
    const subseccion = getValues(`${name}.${categoriaIndex}.subseccions.${subseccionIndex}`);
    setRecordToCopy(subseccion);
  };

  useEffect(() => {
    if (esRespuesta) {
      // fields.map((item, subseccionIndex) => {
      //   register(
      //     `${name}[${categoriaIndex}].subseccions[${subseccionIndex}].categoria`,
      //   );
      //   setValue(
      //     `${name}[${categoriaIndex}].subseccions[${subseccionIndex}].categoria`,
      //     item.categoria,
      //   );
      // });
    }
  }, []);

  //console.log('%c⧭', 'color: #2a00e6', 'SubseccionesFormulario', name);

  const [expanded, setExpanded] = React.useState<string | false>(false); //fields?.[0]?.id);

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  const moveUp = (index) => {
    if (index === 0) {
      return;
    }
    move(index, index - 1);
  };

  const moveDown = (index) => {
    if (index === fields.length - 1) {
      return;
    }
    move(index, index + 1);
  };

  const renderSubsectionSummary = (item, subseccionIndex) => {
    return (
      <div style={{display: 'inline-flex', width: '100%', cursor: 'default'}}>
        <div
          style={{
            width: '81%',
            //backgroundColor: 'rgb(255,255,255,.725)',
            borderRadius: '5px',
            paddingLeft: '12px',
            paddingRight: '12px',
            paddingBottom: '4px',
            marginBottom: 10,
          }}>
          <div onClick={($e) => $e.stopPropagation()}>
            <InputFormItem
              name={`${name}.${categoriaIndex}.subseccions.${subseccionIndex}.categoria`}
              defaultValue={item.categoria}
              label={'Subsección ' + (subseccionIndex + 1)}
              variant="outlined"
              fontSize="1.2rem"
              labelFontSize="1.08rem"
              shrinkLabel={false}
            />
          </div>
        </div>
        <OnHoverBox style={{marginLeft: 'auto', alignSelf: 'center'}} onClick={($e) => $e.stopPropagation()}>
          <Tooltip
            enterDelay={700}
            leaveDelay={150}
            enterNextDelay={400}
            style={{marginLeft: 10}}
            followCursor
            placement={'top-start'}
            title={'Subsección dinámica'}>
            <div>
              <CheckboxFormItem name={`${name}.${categoriaIndex}.subseccions.${subseccionIndex}.isDynamic`} label={''} />
            </div>
          </Tooltip>
          <Tooltip
            enterDelay={700}
            leaveDelay={150}
            enterNextDelay={400}
            followCursor
            placement={'top-start'}
            title={i18n('common.copy')}>
            <IconButton size="small" color="primary" onClick={(_) => doOpenCopyConfirmModal(subseccionIndex)}>
              <FileCopy />
            </IconButton>
          </Tooltip>
          <Tooltip
            enterDelay={700}
            leaveDelay={150}
            enterNextDelay={400}
            followCursor
            placement={'top-start'}
            title={i18n('common.moveDown')}>
            <IconButton size="small" color="primary" onClick={() => moveDown(subseccionIndex)}>
              <ArrowDownward />
            </IconButton>
          </Tooltip>
          <Tooltip
            enterDelay={700}
            leaveDelay={150}
            enterNextDelay={400}
            followCursor
            placement={'top-start'}
            title={i18n('common.moveUp')}>
            <IconButton size="small" color="primary" onClick={() => moveUp(subseccionIndex)}>
              <ArrowUpward />
            </IconButton>
          </Tooltip>
          <Tooltip
            enterDelay={700}
            leaveDelay={150}
            enterNextDelay={400}
            followCursor
            placement={'top-start'}
            title={i18n('common.destroy')}>
            <IconButton
              size="small"
              color="secondary"
              onClick={() => {
                doOpenDeleteConfirmModal(subseccionIndex);
              }}>
              <Delete />
            </IconButton>
          </Tooltip>
        </OnHoverBox>
      </div>
    );
  };

  const renderSubsectionDetails = (item, subseccionIndex) => {
    return (
      <div>
        <SubseccionesPreguntasFormularioForm
          item={subseccion}
          removeSubseccion={remove}
          categoriaIndex={categoriaIndex}
          subseccionIndex={subseccionIndex}
          name={name}
          editar={editar}
          clasificacion={clasificacion}
        />
      </div>
    );
  };

  if (esRespuesta) {
    return (
      <Typography
        variant="h3"
        // sx={{ color: '#1E1F5A' }}
      >
        {subseccion.categoria}
      </Typography>
    );
  } else {
    return (
      <React.Fragment>
        {fieldsWatch?.length === 0 && (
          <Button onClick={() => append({categoria: '', preguntas: []})} variant="outlined" color="primary" type="button">
            + Añadir Subsección
          </Button>
          // <div style={{marginLeft: 'auto', alignSelf: 'center'}}>
          //   <IconButton
          //     color="primary"
          //     onClick={() => {
          //       append({categoria: '', preguntas: []});
          //     }}
          //     style={{backgroundColor: '#fff', border: '1px solid rgb(63, 81, 181,.6)'}}>
          //     <Add />
          //   </IconButton>
          // </div>
        )}
        {fields.map((subseccion: any, subseccionIndex) => (
          <CustomAccordion
            key={subseccion.id}
            item={subseccion}
            index={subseccionIndex}
            uniqueKey={subseccion.id}
            entityName="Subsección"
            renderSummary={renderSubsectionSummary}
            renderDetails={renderSubsectionDetails}
            detailTitle={'Preguntas'}
            expanded={expanded}
            handleChange={handleChange}
            topButtonClick={(item, index) => {
              insert(index, {
                categoria: '',
                isDynamic: false,
                preguntas: [
                  {
                    nPregunta: null,
                    pregunta: {
                      titulo: null,
                    },
                    link: '',
                    tipoRespuesta: null,
                    tieneComentario: false,
                    tienePuntaje: false,
                  },
                ],
              });
            }}
            bottomButtonClick={(item, index) => {
              insert(index + 1, {
                categoria: '',
                preguntas: [
                  {
                    nPregunta: null,
                    pregunta: {
                      titulo: null,
                    },
                    link: '',
                    tipoRespuesta: null,
                    tieneComentario: false,
                    tienePuntaje: false,
                  },
                ],
              });
            }}
          />
        ))}

        {recordToCopy && (
          <ConfirmModal
            title={i18n('common.areYouSureCopySubseccion')}
            onConfirm={() => doCopyJob(recordToCopy)}
            onClose={() => doCloseCopyConfirmModal()}
            okText={i18n('common.yes')}
            cancelText={i18n('common.no')}
          />
        )}
        {(recordToDelete || recordToDelete === 0) && (
          <ConfirmModal
            title={i18n('common.areYouSureDelete')}
            onConfirm={() => doDeleteRecord(recordToDelete)}
            onClose={() => doCloseDeleteConfirmModal()}
            okText={i18n('common.yes')}
            cancelText={i18n('common.no')}
          />
        )}
      </React.Fragment>
    );
  }
}
