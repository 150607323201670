import {yupResolver} from '@hookform/resolvers/yup';
import {Search as SearchIcon} from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import UndoIcon from '@mui/icons-material/Undo';
import {AccordionDetails, AccordionSummary, Button, Grid} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {i18n} from 'src/i18n';
import actions from 'src/modules/recurso/list/recursoListActions';
import selectors from 'src/modules/recurso/list/recursoListSelectors';
import recursoEnumerators from 'src/modules/recurso/recursoEnumerators';
import filterRenders from 'src/modules/shared/filter/filterRenders';
import yupFilterSchemas from 'src/modules/shared/yup/yupFilterSchemas';
import FilterAccordion from 'src/view/shared/filter/FilterAccordion';
import FilterPreview from 'src/view/shared/filter/FilterPreview';
import DatePickerRangeFormItem from 'src/view/shared/form/items/DatePickerRangeFormItem';
import InputFormItem from 'src/view/shared/form/items/InputFormItem';
import InputNumberRangeFormItem from 'src/view/shared/form/items/InputNumberRangeFormItem';
import SelectFormItem from 'src/view/shared/form/items/SelectFormItem';
import FilterWrapper, {FilterButtons} from 'src/view/shared/styles/FilterWrapper';
import TareaAutocompleteFormItem from 'src/view/tarea/autocomplete/TareaAutocompleteFormItem';
import * as yup from 'yup';

const schema = yup.object().shape({
  descripcion: yupFilterSchemas.string(i18n('entities.recurso.fields.descripcion')),
  tipo: yupFilterSchemas.enumerator(i18n('entities.recurso.fields.tipo')),
  fechaPagoRange: yupFilterSchemas.dateRange(i18n('entities.recurso.fields.fechaPagoRange')),
  cantidadRange: yupFilterSchemas.integerRange(i18n('entities.recurso.fields.cantidadRange')),
  tareasVinculadas: yupFilterSchemas.relationToMany(i18n('entities.activo.fields.tareasVinculadas')),
  planificaciones: yupFilterSchemas.relationToMany(i18n('entities.activo.fields.planificaciones')),
});

const emptyValues = {
  descripcion: null,
  tipo: null,
  planificaciones: [],
  tareasVinculadas: [],
  fechaPagoRange: [null, null],
  cantidadRange: [],
};

const previewRenders = {
  descripcion: {
    label: i18n('entities.recurso.fields.descripcion'),
    render: filterRenders.generic(),
  },
  tareasVinculadas: {
    label: i18n('entities.recurso.fields.tareasVinculadas'),
    render: filterRenders.relationToMany(),
  },
  planificaciones: {
    label: i18n('entities.recurso.fields.planificaciones'),
    render: filterRenders.relationToMany(),
  },
  tipo: {
    label: i18n('entities.recurso.fields.tipo'),
    render: filterRenders.enumerator('entities.recurso.enumerators.tipo'),
  },
  fechaPagoRange: {
    label: i18n('entities.recurso.fields.fechaPagoRange'),
    render: filterRenders.dateRange(),
  },
  cantidadRange: {
    label: i18n('entities.recurso.fields.cantidadRange'),
    render: filterRenders.range(),
  },
};

function RecursoListFilter(props) {
  const rawFilter = useSelector(selectors.selectRawFilter);
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(false);

  const [initialValues] = useState(() => {
    return {
      ...emptyValues,
      ...rawFilter,
      ...props.filter,
    };
  });

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialValues,
    mode: 'all',
  });

  useEffect(() => {
    dispatch(actions.doFetch(schema.cast(initialValues), rawFilter));
    // eslint-disable-next-line
  }, [dispatch]);

  const onSubmit = (values) => {
    const rawValues = form.getValues();
    dispatch(actions.doFetch(values, rawValues));
    setExpanded(false);
  };

  const onReset = () => {
    Object.keys(emptyValues).forEach((key) => {
      form.setValue(key, emptyValues[key]);
    });
    dispatch(actions.doReset());
    setExpanded(false);
  };

  const onRemove = (key) => {
    form.setValue(key, emptyValues[key]);
    return form.handleSubmit(onSubmit)();
  };

  return (
    <FilterWrapper>
      <FilterAccordion expanded={expanded} onChange={(event, isExpanded) => setExpanded(isExpanded)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <FilterPreview values={rawFilter} renders={previewRenders} expanded={expanded} onRemove={onRemove} />
        </AccordionSummary>
        <AccordionDetails>
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <Grid container columnSpacing={2}>
                <Grid item lg={6} xs={12}>
                  <TareaAutocompleteFormItem
                    name="tareasVinculadas"
                    disabled={props.disabled}
                    label={i18n('entities.recurso.fields.tareasVinculadas')}
                    required={false}
                    mode="multiple"
                  />
                </Grid>
                {/* <Grid item lg={6} xs={12}>
                  <PlanificacionAutocompleteFormItem
                    name="planificaciones"
                    disabled={props.disabled}
                    label={i18n(
                      'entities.recurso.fields.planificaciones',
                    )}
                    required={false}
                    mode="multiple"
                  />
                </Grid> */}
                <Grid item lg={6} xs={12}>
                  <InputFormItem name="descripcion" label={i18n('entities.recurso.fields.descripcion')} />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <SelectFormItem
                    name="tipo"
                    label={i18n('entities.recurso.fields.tipo')}
                    options={recursoEnumerators.tipo.map((value) => ({
                      value,
                      label: i18n(`entities.recurso.enumerators.tipo.${value}`),
                    }))}
                  />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <DatePickerRangeFormItem name="fechaPagoRange" label={i18n('entities.recurso.fields.fechaPagoRange')} />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <InputNumberRangeFormItem name="cantidadRange" label={i18n('entities.recurso.fields.cantidadRange')} />
                </Grid>
              </Grid>

              <FilterButtons>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={props.loading}
                  startIcon={<SearchIcon />}
                  size="small">
                  {i18n('common.search')}
                </Button>

                <Button type="button" onClick={onReset} disabled={props.loading} startIcon={<UndoIcon />} size="small">
                  {i18n('common.reset')}
                </Button>
              </FilterButtons>
            </form>
          </FormProvider>
        </AccordionDetails>
      </FilterAccordion>
    </FilterWrapper>
  );
}

export default RecursoListFilter;
