import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.solicitud.fields.id'),
  },
  {
    name: 'tipo',
    label: i18n('entities.solicitud.fields.tipo'),
  },
  {
    name: 'titulo',
    label: i18n('entities.solicitud.fields.titulo'),
  },
  {
    name: 'estado',
    label: i18n('entities.solicitud.fields.estado'),
  },
  {
    name: 'fechaVencimiento',
    label: i18n('entities.solicitud.fields.fechaVencimiento'),
  },
  {
    name: 'proveedor',
    label: i18n('entities.solicitud.fields.proveedor'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'descripcion',
    label: i18n('entities.solicitud.fields.descripcion'),
  },
  {
    name: 'solicitante',
    label: i18n('entities.solicitud.fields.solicitante'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'metodoEnvio',
    label: i18n('entities.solicitud.fields.metodoEnvio'),
  },
  {
    name: 'condicionesEnvio',
    label: i18n('entities.solicitud.fields.condicionesEnvio'),
  },
  {
    name: 'notas',
    label: i18n('entities.solicitud.fields.notas'),
  },
  {
    name: 'correoSolicitante',
    label: i18n('entities.solicitud.fields.correoSolicitante'),
  },
  {
    name: 'rutSolicitante',
    label: i18n('entities.solicitud.fields.rutSolicitante'),
  },
  {
    name: 'nombreEmpresaSolicitante',
    label: i18n('entities.solicitud.fields.nombreEmpresaSolicitante'),
  },
  {
    name: 'direccionSolicitante',
    label: i18n('entities.solicitud.fields.direccionSolicitante'),
  },
  {
    name: 'ciudadSolicitante',
    label: i18n('entities.solicitud.fields.ciudadSolicitante'),
  },
  {
    name: 'estadoSolicitante',
    label: i18n('entities.solicitud.fields.estadoSolicitante'),
  },
  {
    name: 'codigoPostalSolicitante',
    label: i18n('entities.solicitud.fields.codigoPostalSolicitante'),
  },
  {
    name: 'telefonoSolicitante',
    label: i18n('entities.solicitud.fields.telefonoSolicitante'),
  },
  {
    name: 'faxSolicitante',
    label: i18n('entities.solicitud.fields.faxSolicitante'),
  },
  {
    name: 'nombreEnvio',
    label: i18n('entities.solicitud.fields.nombreEnvio'),
  },
  {
    name: 'nombreEmpresaEnvio',
    label: i18n('entities.solicitud.fields.nombreEmpresaEnvio'),
  },
  {
    name: 'usarDireccionEmpresaEnvio',
    label: i18n('entities.solicitud.fields.usarDireccionEmpresaEnvio'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'direccionEnvio',
    label: i18n('entities.solicitud.fields.direccionEnvio'),
  },
  {
    name: 'ciudadEnvio',
    label: i18n('entities.solicitud.fields.ciudadEnvio'),
  },
  {
    name: 'estadoEnvio',
    label: i18n('entities.solicitud.fields.estadoEnvio'),
  },
  {
    name: 'codigoPostalEnvio',
    label: i18n('entities.solicitud.fields.codigoPostalEnvio'),
  },
  {
    name: 'telefonoEnvio',
    label: i18n('entities.solicitud.fields.telefonoEnvio'),
  },
  {
    name: 'faxEnvio',
    label: i18n('entities.solicitud.fields.faxEnvio'),
  },
  {
    name: 'solicitarActivos',
    label: i18n('entities.solicitud.fields.solicitarActivos'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'cuentasContables',
    label: i18n('entities.solicitud.fields.cuentasContables'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.solicitud.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.solicitud.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
