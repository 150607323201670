import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.modeloFormulario.fields.id'),
  },
  {
    name: 'nombre',
    label: i18n('entities.modeloFormulario.fields.nombre'),
  },
  {
    name: 'sucursales',
    label: i18n('entities.modeloFormulario.fields.sucursales'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'formato',
    label: i18n('entities.modeloFormulario.fields.formato'),
  },
  {
    name: 'categoriaGeneral',
    label: i18n('entities.modeloFormulario.fields.categoriaGeneral'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'categoriasFormulario',
    label: i18n('entities.modeloFormulario.fields.categoriasFormulario'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.modeloFormulario.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.modeloFormulario.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
