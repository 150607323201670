import {FormControl, FormHelperText, FormLabel} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {useFormContext, useWatch} from 'react-hook-form';
import FormErrors from 'src/view/shared/form/formErrors';
import ImagesUploader from 'src/view/shared/uploaders/ImagesUploader';
import ImagesViewItem from 'src/view/shared/view/ImagesViewItem';
import {DisabledItem} from './shared/formItemWrappers';
function ImagesFormItem(props) {
  const {label, name, hint, storage, max, required, externalErrorMessage, disabled} = props;

  const {
    formState: {touchedFields, isSubmitted, errors},

    setValue,
    control,
    register,
  } = useFormContext();

  useEffect(() => {
    register(name);
  }, []);

  const errorMessage = FormErrors.errorMessage(name, errors, touchedFields, isSubmitted, externalErrorMessage);

  const formHelperText = errorMessage || hint;

  const watchName: any = useWatch({name, control});
  if (disabled) {
    return (
      <DisabledItem
        label={label}
        show={watchName?.length > 0}
        noAnswerText="Sin fotos"
        item={<ImagesViewItem value={watchName} />}
      />
    );
  }

  return (
    <FormControl fullWidth required={required} error={Boolean(errorMessage)} component="fieldset" size="small">
      <FormLabel component="legend">{label}</FormLabel>

      <ImagesUploader
        //readonly={disabled}
        AIContent={props.AIContent}
        onReadImage={props.onReadImage}
        storage={storage}
        value={watchName}
        onChange={(value) => {
          setValue(name, value, {
            shouldValidate: true,
          });
          props.onChange && props.onChange(value);
        }}
        max={max}
        onImageUpload={props.onImageUpload}
      />

      {formHelperText && <FormHelperText style={{marginTop: 0}}>{formHelperText}</FormHelperText>}
    </FormControl>
  );
}

ImagesFormItem.defaultProps = {
  max: undefined,
  required: false,
};

ImagesFormItem.propTypes = {
  storage: PropTypes.object.isRequired,
  max: PropTypes.number,
  required: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  hint: PropTypes.string,
  formItemProps: PropTypes.object,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onReadImage: PropTypes.func,
  AIContent: PropTypes.bool,
  onImageUpload: PropTypes.func,
};

export default ImagesFormItem;
