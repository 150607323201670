import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/nivelPrioridad/importer/nivelPrioridadImporterSelectors';
import NivelPrioridadService from 'src/modules/nivelPrioridad/nivelPrioridadService';
import fields from 'src/modules/nivelPrioridad/importer/nivelPrioridadImporterFields';
import { i18n } from 'src/i18n';

const nivelPrioridadImporterActions = importerActions(
  'NIVELPRIORIDAD_IMPORTER',
  selectors,
  NivelPrioridadService.import,
  fields,
  i18n('entities.nivelPrioridad.importer.fileName'),
);

export default nivelPrioridadImporterActions;