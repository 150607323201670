import {createSelector} from 'reselect';

const selectRaw = state => state.activo.form;

const selectRecord = createSelector([selectRaw], raw => raw.record);

const selectInitLoading = createSelector([selectRaw], raw => Boolean(raw.initLoading));

const selectSaveLoading = createSelector([selectRaw], raw => Boolean(raw.saveLoading));

const selectFieldConfig = createSelector([selectRaw], raw => {
  return raw.fieldConfig;
});

const activoFormSelectors = {
  selectInitLoading,
  selectSaveLoading,
  selectRecord,
  selectRaw,
  selectFieldConfig,
};

export default activoFormSelectors;
