import React, {useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {useSelector} from 'react-redux';
import selectors from 'src/modules/modeloTarea/modeloTareaSelectors';
import ModeloTareaService from 'src/modules/modeloTarea/modeloTareaService';
import ModeloTareaFormModal from 'src/view/modeloTarea/form/ModeloTareaFormModal';
import AutocompleteInMemoryFormItem from 'src/view/shared/form/items/AutocompleteInMemoryFormItem';

function ModeloTareaAutocompleteFormItem(props) {
  const {setValue, getValues} = useFormContext();

  const [modalVisible, setModalVisible] = useState(false);

  const hasPermissionToCreate = useSelector(selectors.selectPermissionToCreate);

  const doCloseModal = () => {
    setModalVisible(false);
  };

  const doOpenModal = () => {
    setModalVisible(true);
  };

  const doCreateSuccess = (record) => {
    const {name, mode} = props;

    if (mode && mode === 'multiple') {
      setValue(name, [...(getValues()[name] || []), record]);
    } else {
      setValue(name, record);
    }

    doCloseModal();
  };

  const fetchFn = (value, limit) => {
    return ModeloTareaService.listAutocomplete(
      {
        filter: props.filter,
        value,
      },
      limit,
    );
  };

  const mapper = {
    toAutocomplete(originalValue) {
      if (!originalValue) {
        return null;
      }

      const value = originalValue.id;
      let label = originalValue.label;

      if (originalValue.titulo) {
        label = originalValue.titulo;
      }

      return {
        key: value,
        value,
        label,
        record: originalValue.record,
      };
    },

    toValue(originalValue) {
      if (!originalValue) {
        return null;
      }

      return {
        id: originalValue.value,
        label: originalValue.label,
        record: originalValue.record,
      };
    },
  };

  return (
    <>
      <AutocompleteInMemoryFormItem
        {...props}
        customFetchSize={20}
        fetchFn={fetchFn}
        entity="modelo-tarea"
        mapper={mapper}
        onOpenModal={doOpenModal}
        hasPermissionToCreate={hasPermissionToCreate}
      />

      {modalVisible && <ModeloTareaFormModal onClose={doCloseModal} onSuccess={doCreateSuccess} />}
    </>
  );
}

export default ModeloTareaAutocompleteFormItem;
