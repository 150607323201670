import list from 'src/modules/producto/list/productoListReducers';
import form from 'src/modules/producto/form/productoFormReducers';
import view from 'src/modules/producto/view/productoViewReducers';
import destroy from 'src/modules/producto/destroy/productoDestroyReducers';
import importerReducer from 'src/modules/producto/importer/productoImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
