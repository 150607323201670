import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';
import pendienteEnumerators from 'src/modules/pendiente/pendienteEnumerators';

export default [
  {
    name: 'titulo',
    label: i18n('entities.pendiente.fields.titulo'),
    schema: schemas.string(
      i18n('entities.pendiente.fields.titulo'),
      {},
    ),
  },
  {
    name: 'tipoPendiente',
    label: i18n('entities.pendiente.fields.tipoPendiente'),
    schema: schemas.enumerator(
      i18n('entities.pendiente.fields.tipoPendiente'),
      {
        "options": pendienteEnumerators.tipoPendiente
      },
    ),
  },
  {
    name: 'responsable',
    label: i18n('entities.pendiente.fields.responsable'),
    schema: schemas.relationToOne(
      i18n('entities.pendiente.fields.responsable'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'sucursal',
    label: i18n('entities.pendiente.fields.sucursal'),
    schema: schemas.relationToOne(
      i18n('entities.pendiente.fields.sucursal'),
      {},
    ),
  },
  {
    name: 'formulario',
    label: i18n('entities.pendiente.fields.formulario'),
    schema: schemas.relationToOne(
      i18n('entities.pendiente.fields.formulario'),
      {},
    ),
  },
  {
    name: 'tarea',
    label: i18n('entities.pendiente.fields.tarea'),
    schema: schemas.relationToOne(
      i18n('entities.pendiente.fields.tarea'),
      {},
    ),
  },
  {
    name: 'estado',
    label: i18n('entities.pendiente.fields.estado'),
    schema: schemas.enumerator(
      i18n('entities.pendiente.fields.estado'),
      {
        "options": pendienteEnumerators.estado
      },
    ),
  },
  {
    name: 'categoria',
    label: i18n('entities.pendiente.fields.categoria'),
    schema: schemas.relationToOne(
      i18n('entities.pendiente.fields.categoria'),
      {},
    ),
  },
  {
    name: 'enviado',
    label: i18n('entities.pendiente.fields.enviado'),
    schema: schemas.boolean(
      i18n('entities.pendiente.fields.enviado'),
      {},
    ),
  },
  {
    name: 'calificacionEvaluador',
    label: i18n('entities.pendiente.fields.calificacionEvaluador'),
    schema: schemas.integer(
      i18n('entities.pendiente.fields.calificacionEvaluador'),
      {},
    ),
  },
  {
    name: 'comentarioEvaluador',
    label: i18n('entities.pendiente.fields.comentarioEvaluador'),
    schema: schemas.string(
      i18n('entities.pendiente.fields.comentarioEvaluador'),
      {},
    ),
  },
  {
    name: 'esEvaluado',
    label: i18n('entities.pendiente.fields.esEvaluado'),
    schema: schemas.boolean(
      i18n('entities.pendiente.fields.esEvaluado'),
      {},
    ),
  },
  {
    name: 'evaluadores',
    label: i18n('entities.pendiente.fields.evaluadores'),
    schema: schemas.relationToMany(
      i18n('entities.pendiente.fields.evaluadores'),
      {},
    ),
  },
  {
    name: 'actividad',
    label: i18n('entities.pendiente.fields.actividad'),
    schema: schemas.relationToOne(
      i18n('entities.pendiente.fields.actividad'),
      {},
    ),
  },
];