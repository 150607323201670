import {Box, Button} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import moment from 'moment-timezone';
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {i18n} from 'src/i18n';
import destroyActions from 'src/modules/gestorDocumental/destroy/gestorDocumentalDestroyActions';
import destroySelectors from 'src/modules/gestorDocumental/destroy/gestorDocumentalDestroySelectors';
import gestorDocumentalSelectors from 'src/modules/gestorDocumental/gestorDocumentalSelectors';
import actions from 'src/modules/gestorDocumental/list/gestorDocumentalListActions';
import selectors from 'src/modules/gestorDocumental/list/gestorDocumentalListSelectors';
import Spinner from 'src/view/shared/Spinner';
import ConfirmModal from 'src/view/shared/modals/ConfirmModal';
import Pagination from 'src/view/shared/table/Pagination';
import TableCellCustom from 'src/view/shared/table/TableCellCustom';
import FilesViewItem from 'src/view/shared/view/FilesViewItem';
import ImagesViewItem from 'src/view/shared/view/ImagesViewItem';

function GestorDocumentalListTableDrawer(props) {
  const [recordIdToDestroy, setRecordIdToDestroy] = useState(null);
  const dispatch = useDispatch();
  const [confirm, setConfirm] = useState(false);
  const findLoading = useSelector(selectors.selectLoading);
  const [selectRow, setSelectRow] = useState(null);

  const destroyLoading = useSelector(destroySelectors.selectLoading);

  const loading = findLoading || destroyLoading;

  const rows = useSelector(selectors.selectRows);
  const pagination = useSelector(selectors.selectPagination);
  const selectedKeys = useSelector(selectors.selectSelectedKeys);
  const hasRows = useSelector(selectors.selectHasRows);
  const sorter = useSelector(selectors.selectSorter);
  const isAllSelected = useSelector(selectors.selectIsAllSelected);
  const hasPermissionToEdit = useSelector(gestorDocumentalSelectors.selectPermissionToEdit);
  const hasPermissionToDestroy = useSelector(gestorDocumentalSelectors.selectPermissionToDestroy);

  const doOpenDestroyConfirmModal = (id) => {
    setRecordIdToDestroy(id);
  };

  const doCloseDestroyConfirmModal = () => {
    setRecordIdToDestroy(null);
  };

  const doChangeSort = (field) => {
    const order = sorter.field === field && sorter.order === 'asc' ? 'desc' : 'asc';

    dispatch(
      actions.doChangeSort({
        field,
        order,
      }),
    );
  };

  const doChangePagination = (pagination) => {
    dispatch(actions.doChangePagination(pagination));
  };

  const doDestroy = (id) => {
    doCloseDestroyConfirmModal();

    dispatch(destroyActions.doDestroy(id));
  };

  const doToggleAllSelected = () => {
    dispatch(actions.doToggleAllSelected());
  };

  const doToggleOneSelected = (id) => {
    dispatch(actions.doToggleOneSelected(id));
  };

  const doConfirm = () => {
    setConfirm(true);
  };

  const doCloseConfirm = () => {
    setConfirm(false);
  };
  return (
    <>
      <Box
        style={{
          display: 'flex',
          flexGrow: 1,
          width: '100%',
          overflowX: 'auto',
        }}>
        <Table
          style={{
            borderRadius: '5px',
            border: '1px solid rgb(224, 224, 224)',
            borderCollapse: 'initial',
          }}>
          <TableHead>
            <TableRow>
              <TableCellCustom padding="checkbox">
                {hasRows && <Checkbox checked={Boolean(isAllSelected)} onChange={() => doToggleAllSelected()} size="small" />}
              </TableCellCustom>
              <TableCellCustom size="md" />
              <TableCellCustom label={i18n('entities.gestorDocumental.fields.fotosAdjuntas')} />
              <TableCellCustom label={i18n('entities.gestorDocumental.fields.documentosAdjuntos')} />
              <TableCellCustom
                onSort={doChangeSort}
                hasRows={hasRows}
                sorter={sorter}
                name={'nombre'}
                label={i18n('entities.gestorDocumental.fields.nombre')}
              />
              <TableCellCustom
                onSort={doChangeSort}
                hasRows={hasRows}
                sorter={sorter}
                name={'validoHasta'}
                label={i18n('entities.gestorDocumental.fields.validoHasta')}
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {loading && (
              <TableRow>
                <TableCell
                  colSpan={100}
                  sx={{
                    borderBottom: '0px solid white',
                  }}>
                  <Spinner />
                </TableCell>
              </TableRow>
            )}
            {!loading && !hasRows && (
              <TableRow>
                <TableCell
                  colSpan={100}
                  sx={{
                    borderBottom: '0px solid white',
                  }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}>
                    {i18n('table.noData')}
                  </div>
                </TableCell>
              </TableRow>
            )}
            {!loading &&
              rows.map((row) => (
                <TableRow key={row.id}>
                  <TableCell padding="checkbox">
                    <Checkbox checked={selectedKeys.includes(row.id)} onChange={() => doToggleOneSelected(row.id)} size="small" />
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={() => {
                        setSelectRow(row);
                        setConfirm(true);
                      }}
                      type="button"
                      color="primary">
                      {i18n('common.select')}
                    </Button>
                  </TableCell>
                  <TableCell>{row.fotosAdjuntas?.length > 0 && <ImagesViewItem value={row.fotosAdjuntas} />}</TableCell>
                  <TableCell>{row.documentosAdjuntos?.length > 0 && <FilesViewItem value={row.documentosAdjuntos} />}</TableCell>
                  <TableCell>{row.nombre}</TableCell>
                  <TableCell>{row.validoHasta ? moment(row.validoHasta).format('DD/MM/YYYY') : 'No definido'}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Box>

      <Pagination onChange={doChangePagination} pagination={pagination} />

      {confirm && (
        <ConfirmModal
          title={i18n('common.areYouSure')}
          onConfirm={() => props.onSuccess(selectRow)}
          onClose={() => doCloseConfirm()}
          okText={i18n('common.yes')}
          cancelText={i18n('common.no')}
        />
      )}

      {recordIdToDestroy && (
        <ConfirmModal
          title={i18n('common.areYouSure')}
          onConfirm={() => doDestroy(recordIdToDestroy)}
          onClose={() => doCloseDestroyConfirmModal()}
          okText={i18n('common.yes')}
          cancelText={i18n('common.no')}
        />
      )}
    </>
  );
}

export default GestorDocumentalListTableDrawer;
