import list from 'src/modules/ubicacion/list/ubicacionListReducers';
import form from 'src/modules/ubicacion/form/ubicacionFormReducers';
import view from 'src/modules/ubicacion/view/ubicacionViewReducers';
import destroy from 'src/modules/ubicacion/destroy/ubicacionDestroyReducers';
import importerReducer from 'src/modules/ubicacion/importer/ubicacionImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
