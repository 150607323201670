import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.tercero.fields.id'),
  },
  {
    name: 'nombre',
    label: i18n('entities.tercero.fields.nombre'),
  },
  {
    name: 'codigo',
    label: i18n('entities.tercero.fields.codigo'),
  },
  {
    name: 'tipo',
    label: i18n('entities.tercero.fields.tipo'),
  },
  {
    name: 'paginaWeb',
    label: i18n('entities.tercero.fields.paginaWeb'),
  },
  {
    name: 'clasificacion',
    label: i18n('entities.tercero.fields.clasificacion'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'direccion',
    label: i18n('entities.tercero.fields.direccion'),
  },
  {
    name: 'ciudad',
    label: i18n('entities.tercero.fields.ciudad'),
  },
  {
    name: 'region',
    label: i18n('entities.tercero.fields.region'),
  },
  {
    name: 'codigoArea',
    label: i18n('entities.tercero.fields.codigoArea'),
  },
  {
    name: 'pais',
    label: i18n('entities.tercero.fields.pais'),
  },
  {
    name: 'ubicacionGoogle',
    label: i18n('entities.tercero.fields.ubicacionGoogle'),
  },
  {
    name: 'latitud',
    label: i18n('entities.tercero.fields.latitud'),
    render: exporterRenders.decimal(),
  },
  {
    name: 'longitud',
    label: i18n('entities.tercero.fields.longitud'),
    render: exporterRenders.decimal(),
  },
  {
    name: 'telefonoPrincipal',
    label: i18n('entities.tercero.fields.telefonoPrincipal'),
  },
  {
    name: 'telefonoSecundario',
    label: i18n('entities.tercero.fields.telefonoSecundario'),
  },
  {
    name: 'email',
    label: i18n('entities.tercero.fields.email'),
  },
  {
    name: 'fax',
    label: i18n('entities.tercero.fields.fax'),
  },
  {
    name: 'telefonoSMS',
    label: i18n('entities.tercero.fields.telefonoSMS'),
  },
  {
    name: 'contactos',
    label: i18n('entities.tercero.fields.contactos'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'servicios',
    label: i18n('entities.tercero.fields.servicios'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'activosVinculados',
    label: i18n('entities.tercero.fields.activosVinculados'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.tercero.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.tercero.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
