import CloseIcon from '@mui/icons-material/Close';
import {Dialog, DialogContent, DialogTitle, IconButton} from '@mui/material';
import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import {i18n} from 'src/i18n';
import Errors from 'src/modules/shared/error/errors';
import UserService from 'src/modules/user/userService';
import UserNewForm from 'src/view/user/new/UserNewForm';

function UserNewFormModal(props) {
  const [saveLoading, setSaveLoading] = useState(false);

  const doSubmit = async (_, data) => {
    try {
      setSaveLoading(true);
      await UserService.create(data);

      const {rows} = await UserService.fetchUsers(
        {
          email: data.emails[0],
        },
        null,
        1,
        0,
      );

      props.onSuccess(rows[0]);
    } catch (error) {
      Errors.handle(error);
    } finally {
      setSaveLoading(false);
    }
  };

  const doClose = () => {
    return props.onClose();
  };

  return ReactDOM.createPortal(
    <Dialog open={true} onClose={doClose} maxWidth="md" fullWidth={true}>
      <DialogTitle
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <h2 style={{margin: 0}}>{i18n('user.new.titleModal')}</h2>
        <IconButton onClick={doClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <UserNewForm saveLoading={saveLoading} onSubmit={doSubmit} onCancel={doClose} modal single />
      </DialogContent>
    </Dialog>,
    (document as any).getElementById('modal-root'),
  );
}

export default UserNewFormModal;
