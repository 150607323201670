import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.historialInventario.fields.id'),
  },
  {
    name: 'nota',
    label: i18n('entities.historialInventario.fields.nota'),
  },
  {
    name: 'movimiento',
    label: i18n('entities.historialInventario.fields.movimiento'),
  },
  {
    name: 'stockInicial',
    label: i18n('entities.historialInventario.fields.stockInicial'),
  },
  {
    name: 'stockFinal',
    label: i18n('entities.historialInventario.fields.stockFinal'),
  },
  {
    name: 'ubicacion',
    label: i18n('entities.historialInventario.fields.ubicacion'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'fotos',
    label: i18n('entities.historialInventario.fields.fotos'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'documentos',
    label: i18n('entities.historialInventario.fields.documentos'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'inventarios',
    label: i18n('entities.historialInventario.fields.inventarios'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'firmas',
    label: i18n('entities.historialInventario.fields.firmas'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.historialInventario.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.historialInventario.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
