/**
 * Storage permissions.
 *
 * @id - Used to identify the rule on permissions and upload.
 * @folder - Folder where the files will be saved
 * @maxSizeInBytes - Max allowed size in bytes
 * @bypassWritingPermissions - Does not validate if the user has permission to write
 * @publicRead - The file can be publicly accessed via the URL without the need for a signed token
 */
export default class Storage {
  static get values() {
    return {
      productoFoto: {
        id: 'productoFoto',
        folder: 'tenant/:tenantId/producto/foto',
        maxSizeInBytes: 100 * 1024 * 1024,
        publicRead: true,
      },

      formularioFoto: {
        id: 'formularioFoto',
        folder: 'tenant/:tenantId/formulario/foto',
        maxSizeInBytes: 100 * 1024 * 1024,
        publicRead: true,
      },
      formularioDocumento: {
        id: 'formularioDocumento',
        folder: 'tenant/:tenantId/formulario/documento',
        maxSizeInBytes: 100 * 1024 * 1024,
        publicRead: true,
      },

      preguntaFoto: {
        id: 'preguntaFoto',
        folder: 'tenant/:tenantId/pregunta/foto',
        maxSizeInBytes: 100 * 1024 * 1024,
        publicRead: true,
      },

      facturaFactura: {
        id: 'facturaFactura',
        folder: 'tenant/:tenantId/factura/factura',
        maxSizeInBytes: 100 * 1024 * 1024,
        publicRead: true,
      },
      facturaComprobanteTransferencia: {
        id: 'facturaComprobanteTransferencia',
        folder: 'tenant/:tenantId/factura/comprobanteTransferencia',
        maxSizeInBytes: 100 * 1024 * 1024,
        publicRead: true,
      },

      paisFoto: {
        id: 'paisFoto',
        folder: 'tenant/:tenantId/pais/foto',
        maxSizeInBytes: 100 * 1024 * 1024,
        publicRead: true,
      },

      modeloTareaDocumentosAdjuntos: {
        id: 'modeloTareaDocumentosAdjuntos',
        folder: 'tenant/:tenantId/modeloTarea/documentosAdjuntos',
        maxSizeInBytes: 100 * 1024 * 1024,
        publicRead: true,
      },
      modeloTareaFotosAdjuntas: {
        id: 'modeloTareaFotosAdjuntas',
        folder: 'tenant/:tenantId/modeloTarea/fotosAdjuntas',
        maxSizeInBytes: 100 * 1024 * 1024,
        publicRead: true,
      },

      categoriaAvatar: {
        id: 'categoriaAvatar',
        folder: 'tenant/:tenantId/categoria/avatar',
        maxSizeInBytes: 100 * 1024 * 1024,
        publicRead: true,
      },
      categoriaPortada: {
        id: 'categoriaPortada',
        folder: 'tenant/:tenantId/categoria/portada',
        maxSizeInBytes: 100 * 1024 * 1024,
        publicRead: true,
      },

      tareaFotosEnviadas: {
        id: 'tareaFotosEnviadas',
        folder: 'tenant/:tenantId/tarea/fotosEnviadas',
        maxSizeInBytes: 100 * 1024 * 1024,
        publicRead: true,
      },
      gestorDocumentalDocumentosAdjuntos: {
        id: 'gestorDocumentalDocumentosAdjuntos',
        folder: 'tenant/:tenantId/gestorDocumental/documentosAdjuntos',
        maxSizeInBytes: 100 * 1024 * 1024,
        publicRead: true,
      },
      gestorDocumentalFotosAdjuntas: {
        id: 'gestorDocumentalFotosAdjuntas',
        folder: 'tenant/:tenantId/gestorDocumental/fotosAdjuntas',
        maxSizeInBytes: 100 * 1024 * 1024,
        publicRead: true,
      },
      tareaDocumentosEnviados: {
        id: 'tareaDocumentosEnviados',
        folder: 'tenant/:tenantId/tarea/documentosEnviados',
        maxSizeInBytes: 100 * 1024 * 1024,
        publicRead: true,
      },
      tareaFotosAdjuntas: {
        id: 'tareaFotosAdjuntas',
        folder: 'tenant/:tenantId/tarea/fotosAdjuntas',
        maxSizeInBytes: 100 * 1024 * 1024,
        publicRead: true,
      },
      tareaDocumentosAdjuntos: {
        id: 'tareaDocumentosAdjuntos',
        folder: 'tenant/:tenantId/tarea/documentosAdjuntos',
        maxSizeInBytes: 100 * 1024 * 1024,
        publicRead: true,
      },

      absenceAttachedFiles: {
        id: 'absenceAttachedFiles',
        folder: 'tenant/:tenantId/absence/attachedFiles',
        maxSizeInBytes: 100 * 1024 * 1024,
        publicRead: true,
      },
      incidentAttachedFiles: {
        id: 'incidentAttachedFiles',
        folder: 'tenant/:tenantId/incident/attachedFiles',
        maxSizeInBytes: 100 * 1024 * 1024,
        publicRead: true,
      },

      mensajeInternoFotosAdjunta: {
        id: 'mensajeInternoFotosAdjunta',
        folder: 'tenant/:tenantId/mensajeInterno/fotosAdjunta',
        maxSizeInBytes: 100 * 1024 * 1024,
        publicRead: true,
      },
      mensajeInternoDocumentosAdjuntos: {
        id: 'mensajeInternoDocumentosAdjuntos',
        folder: 'tenant/:tenantId/mensajeInterno/documentosAdjuntos',
        maxSizeInBytes: 100 * 1024 * 1024,
        publicRead: true,
      },

      comunicacionInternaFotosAdjunta: {
        id: 'comunicacionInternaFotosAdjunta',
        folder: 'tenant/:tenantId/comunicacionInterna/fotosAdjunta',
        maxSizeInBytes: 100 * 1024 * 1024,
        publicRead: true,
      },
      comunicacionInternaDocumentosAdjuntos: {
        id: 'comunicacionInternaDocumentosAdjuntos',
        folder: 'tenant/:tenantId/comunicacionInterna/documentosAdjuntos',
        maxSizeInBytes: 100 * 1024 * 1024,
        publicRead: true,
      },

      eventoFotos: {
        id: 'eventoFotos',
        folder: 'tenant/:tenantId/evento/fotos',
        maxSizeInBytes: 100 * 1024 * 1024,
        publicRead: true,
      },

      notificacionFoto: {
        id: 'notificacionFoto',
        folder: 'tenant/:tenantId/notificacion/foto',
        maxSizeInBytes: 100 * 1024 * 1024,
        publicRead: true,
      },

      settingsLogos: {
        id: 'settingsLogos',
        folder: 'tenant/:tenantId/settings/logos',
        maxSizeInBytes: 10 * 1024 * 1024,
        publicRead: true,
      },
      settingsLogosPdf: {
        id: 'settingsLogosPdf',
        folder: 'tenant/:tenantId/settings/logosPdf',
        maxSizeInBytes: 10 * 1024 * 1024,
        publicRead: true,
      },
      settingsBackgroundImages: {
        id: 'settingsBackgroundImages',
        folder: 'tenant/:tenantId/settings/backgroundImages',
        maxSizeInBytes: 10 * 1024 * 1024,
        publicRead: true,
      },
      userAvatarsProfiles: {
        id: 'userAvatarsProfiles',
        folder: 'user/avatars/profile/:userId',
        maxSizeInBytes: 10 * 1024 * 1024,
        bypassWritingPermissions: true,
        publicRead: true,
      },

      //Stem
      empresaFoto: {
        id: 'empresaFoto',
        folder: 'tenant/:tenantId/empresa/foto',
        maxSizeInBytes: 100 * 1024 * 1024,
        publicRead: true,
      },
      empresaDocumentos: {
        id: 'empresaDocumentos',
        folder: 'tenant/:tenantId/empresa/documentos',
        maxSizeInBytes: 100 * 1024 * 1024,
        publicRead: true,
      },

      ubicacionFoto: {
        id: 'ubicacionFoto',
        folder: 'tenant/:tenantId/ubicacion/foto',
        maxSizeInBytes: 100 * 1024 * 1024,
        publicRead: true,
      },
      ubicacionDocumentos: {
        id: 'ubicacionDocumentos',
        folder: 'tenant/:tenantId/ubicacion/documentos',
        maxSizeInBytes: 100 * 1024 * 1024,
        publicRead: true,
      },

      activoFotos: {
        id: 'activoFotos',
        folder: 'tenant/:tenantId/activo/fotos',
        maxSizeInBytes: 100 * 1024 * 1024,
        publicRead: true,
      },
      activoDocumentos: {
        id: 'activoDocumentos',
        folder: 'tenant/:tenantId/activo/documentos',
        maxSizeInBytes: 100 * 1024 * 1024,
        publicRead: true,
      },

      rubroFoto: {
        id: 'rubroFoto',
        folder: 'tenant/:tenantId/rubro/foto',
        maxSizeInBytes: 100 * 1024 * 1024,
        publicRead: true,
      },

      trazabilidadRespaldoEnvia: {
        id: 'trazabilidadRespaldoEnvia',
        folder: 'tenant/:tenantId/trazabilidad/respaldoEnvia',
        maxSizeInBytes: 100 * 1024 * 1024,
        publicRead: true,
      },
      trazabilidadRespaldoRecibe: {
        id: 'trazabilidadRespaldoRecibe',
        folder: 'tenant/:tenantId/trazabilidad/respaldoRecibe',
        maxSizeInBytes: 100 * 1024 * 1024,
        publicRead: true,
      },

      trazabilidadFotosAdjuntas: {
        id: 'trazabilidadFotosAdjuntas',
        folder: 'tenant/:tenantId/trazabilidad/fotosAdjuntas',
        maxSizeInBytes: 100 * 1024 * 1024,
        publicRead: true,
      },
      trazabilidadDocumentosAdjuntos: {
        id: 'trazabilidadDocumentosAdjuntos',
        folder: 'tenant/:tenantId/trazabilidad/documentosAdjuntos',
        maxSizeInBytes: 100 * 1024 * 1024,
        publicRead: true,
      },

      planificacionDocumentosAdjuntos: {
        id: 'planificacionDocumentosAdjuntos',
        folder: 'tenant/:tenantId/planificacion/documentosAdjuntos',
        maxSizeInBytes: 100 * 1024 * 1024,
        publicRead: true,
      },
      planificacionFotosAdjuntas: {
        id: 'planificacionFotosAdjuntas',
        folder: 'tenant/:tenantId/planificacion/fotosAdjuntas',
        maxSizeInBytes: 100 * 1024 * 1024,
        publicRead: true,
      },

      materialFoto: {
        id: 'materialFoto',
        folder: 'tenant/:tenantId/material/foto',
        maxSizeInBytes: 100 * 1024 * 1024,
        publicRead: true,
      },

      solicitudReciclajeComprobantes: {
        id: 'solicitudReciclajeComprobantes',
        folder: 'tenant/:tenantId/solicitudReciclaje/comprobantes',
        maxSizeInBytes: 100 * 1024 * 1024,
        publicRead: true,
      },

      notificacionDocumentos: {
        id: 'notificacionDocumentos',
        folder: 'tenant/:tenantId/notificacion/documentos',
        maxSizeInBytes: 100 * 1024 * 1024,
        publicRead: true,
      },

      perfilEmpleadoFirma: {
        id: 'perfilEmpleadoFirma',
        folder: 'tenant/:tenantId/perfilEmpleado/firma',
        maxSizeInBytes: 100 * 1024 * 1024,
        publicRead: true,
      },

      contactoFoto: {
        id: 'contactoFoto',
        folder: 'tenant/:tenantId/contacto/foto',
        maxSizeInBytes: 100 * 1024 * 1024,
        publicRead: true,
      },

      historialInventarioFotos: {
        id: 'historialInventarioFotos',
        folder: 'tenant/:tenantId/historialInventario/fotos',
        maxSizeInBytes: 100 * 1024 * 1024,
        publicRead: true,
      },
      historialInventarioDocumentos: {
        id: 'historialInventarioDocumentos',
        folder: 'tenant/:tenantId/historialInventario/documentos',
        maxSizeInBytes: 100 * 1024 * 1024,
        publicRead: true,
      },

      recursoFotos: {
        id: 'recursoFotos',
        folder: 'tenant/:tenantId/recurso/fotos',
        maxSizeInBytes: 100 * 1024 * 1024,
        publicRead: true,
      },
      recursoDocumentos: {
        id: 'recursoDocumentos',
        folder: 'tenant/:tenantId/recurso/documentos',
        maxSizeInBytes: 100 * 1024 * 1024,
        publicRead: true,
      },
    };
  }
}
