import {yupResolver} from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import {Button, Grid} from '@mui/material';
import React, {useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {i18n} from 'src/i18n';
import materialEnumerators from 'src/modules/material/materialEnumerators';
import yupFormSchemas from 'src/modules/shared/yup/yupFormSchemas';
import Storage from 'src/security/storage';
import ImagesFormItem from 'src/view/shared/form/items/ImagesFormItem';
import InputFormItem from 'src/view/shared/form/items/InputFormItem';
import SelectFormItem from 'src/view/shared/form/items/SelectFormItem';
import FormWrapper, {FormButtons} from 'src/view/shared/styles/FormWrapper';
import * as yup from 'yup';

const schema = yup.object().shape({
  foto: yupFormSchemas.images(i18n('entities.material.fields.foto'), {
    max: 1,
    required: false,
  }),
  nombre: yupFormSchemas.string(i18n('entities.material.fields.nombre'), {
    required: true,
  }),
  unidadMedida: yupFormSchemas.enumerator(i18n('entities.material.fields.unidadMedida'), {
    options: materialEnumerators.unidadMedida,
  }),
  clasificacion: yupFormSchemas.enumerator(i18n('entities.material.fields.clasificacion'), {
    options: materialEnumerators.clasificacion,
  }),
});

function MaterialForm(props) {
  const [initialValues] = useState(() => {
    const record = props.record || {};

    return {
      foto: record.foto || [],
      nombre: record.nombre,
      unidadMedida: record.unidadMedida,
      clasificacion: record.clasificacion,
    };
  });

  const form = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: initialValues as any,
  });

  const onSubmit = (values) => {
    props.onSubmit(props.record?.id, values);
  };

  const onReset = () => {
    Object.keys(initialValues).forEach((key) => {
      form.setValue(key, initialValues[key]);
    });
  };

  const {saveLoading, modal} = props;

  return (
    <FormWrapper>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Grid spacing={2} container>
            <Grid item lg={7} md={8} sm={12} xs={12}>
              <ImagesFormItem
                name="foto"
                label={i18n('entities.material.fields.foto')}
                required={false}
                storage={Storage.values.materialFoto}
                max={1}
              />
            </Grid>
            <Grid item lg={7} md={8} sm={12} xs={12}>
              <InputFormItem name="nombre" label={i18n('entities.material.fields.nombre')} required={true} />
            </Grid>
            <Grid item lg={7} md={8} sm={12} xs={12}>
              <SelectFormItem
                name="unidadMedida"
                label={i18n('entities.material.fields.unidadMedida')}
                options={materialEnumerators.unidadMedida.map((value) => ({
                  value,
                  label: i18n(`entities.material.enumerators.unidadMedida.${value}`),
                }))}
                required={false}
              />
            </Grid>
            <Grid item lg={7} md={8} sm={12} xs={12}>
              <SelectFormItem
                name="clasificacion"
                label={i18n('entities.material.fields.clasificacion')}
                hint={i18n('entities.material.hints.clasificacion')}
                options={materialEnumerators.clasificacion.map((value) => ({
                  value,
                  label: i18n(`entities.material.enumerators.clasificacion.${value}`),
                }))}
                required={false}
              />
            </Grid>
          </Grid>
          <FormButtons
            style={{
              flexDirection: modal ? 'row-reverse' : undefined,
            }}>
            <Button
              variant="contained"
              color="primary"
              disabled={saveLoading}
              type="button"
              onClick={form.handleSubmit(onSubmit)}
              startIcon={<SaveIcon />}
              size="small">
              {i18n('common.save')}
            </Button>

            <Button disabled={saveLoading} onClick={onReset} type="button" startIcon={<UndoIcon />} size="small">
              {i18n('common.reset')}
            </Button>

            {props.onCancel ? (
              <Button
                disabled={saveLoading}
                onClick={() => props.onCancel()}
                type="button"
                startIcon={<CloseIcon />}
                size="small">
                {i18n('common.cancel')}
              </Button>
            ) : null}
          </FormButtons>
        </form>
      </FormProvider>
    </FormWrapper>
  );
}

export default MaterialForm;
