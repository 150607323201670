import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'titulo',
    label: i18n('entities.pregunta.fields.titulo'),
    schema: schemas.string(
      i18n('entities.pregunta.fields.titulo'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'descripcion',
    label: i18n('entities.pregunta.fields.descripcion'),
    schema: schemas.string(
      i18n('entities.pregunta.fields.descripcion'),
      {},
    ),
  },
  {
    name: 'foto',
    label: i18n('entities.pregunta.fields.foto'),
    schema: schemas.images(
      i18n('entities.pregunta.fields.foto'),
      {},
    ),
  },
  {
    name: 'keyTraductor',
    label: i18n('entities.pregunta.fields.keyTraductor'),
    schema: schemas.string(
      i18n('entities.pregunta.fields.keyTraductor'),
      {},
    ),
  },
  {
    name: 'modificarRespuesta',
    label: i18n('entities.pregunta.fields.modificarRespuesta'),
    schema: schemas.boolean(
      i18n('entities.pregunta.fields.modificarRespuesta'),
      {},
    ),
  },
  {
    name: 'respuestas',
    label: i18n('entities.pregunta.fields.respuestas'),
    schema: schemas.relationToMany(
      i18n('entities.pregunta.fields.respuestas'),
      {},
    ),
  },
];