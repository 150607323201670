import {combineReducers} from 'redux';
import destroy from 'src/modules/pendiente/destroy/pendienteDestroyReducers';
import form from 'src/modules/pendiente/form/pendienteFormReducers';
import importerReducer from 'src/modules/pendiente/importer/pendienteImporterReducers';
import list from 'src/modules/pendiente/list/pendienteListReducers';
import view from 'src/modules/pendiente/view/pendienteViewReducers';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
