import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/temaComunicacion/importer/temaComunicacionImporterSelectors';
import TemaComunicacionService from 'src/modules/temaComunicacion/temaComunicacionService';
import fields from 'src/modules/temaComunicacion/importer/temaComunicacionImporterFields';
import { i18n } from 'src/i18n';

const temaComunicacionImporterActions = importerActions(
  'TEMACOMUNICACION_IMPORTER',
  selectors,
  TemaComunicacionService.import,
  fields,
  i18n('entities.temaComunicacion.importer.fileName'),
);

export default temaComunicacionImporterActions;