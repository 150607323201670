import moment from 'moment-timezone';
import {i18n} from 'src/i18n';

const filterRenders = {
  enumerator: (i18nStartPath) => (value) => value ? i18n(`${i18nStartPath}.${value}`) : null,
  enumeratorMultiple: (i18nStartPath) => (values) =>
    values ? values.map((value) => i18n(`${i18nStartPath}.${value}`)).join(', ') : null,
  generic: () => (value) => value,
  stringArray: () => (value) => (value || []).join(', '),
  json: () => (value) => value ? JSON.stringify(value, null, 2) : null,
  decimal: (fractionDigits?) => (value) => formatDecimal(value, fractionDigits),
  boolean: (trueLabel?, falseLabel?) => (value) =>
    value == null
      ? null
      : Boolean(value) && value !== 'false'
      ? trueLabel || i18n('common.yes')
      : falseLabel || i18n('common.no'),
  relationToOne: () => (value) => (value && value.label) || null,
  relationToMany: () => (value) => ((Array.isArray(value) && value) || []).map((item) => item.label).join(', '),
  filesOrImages: () => (value) => (value || []).map((item) => item.downloadUrl).join(' '),
  date:
    (dateFormat: any = undefined) =>
    (value) =>
      formatDate(value, dateFormat),
  dateRange:
    (dateFormat: any = undefined) =>
    (value) => {
      if (!value || !value.length) {
        return null;
      }

      const start = value[0];
      const end = value.length === 2 && value[1];

      if (!start && !end) {
        return null;
      }

      if (start && !end) {
        return `> ${formatDate(start, dateFormat)}`;
      }

      if (!start && end) {
        return `< ${formatDate(end, dateFormat)}`;
      }

      return `${formatDate(start, dateFormat)} ~ ${formatDate(end, dateFormat)}`;
    },
  datetime:
    (dateFormat: any = undefined) =>
    (value) =>
      formatDatetime(value, dateFormat),
  datetimeRange:
    (dateFormat: any = undefined) =>
    (value) => {
      if (!value || !value.length) {
        return null;
      }

      const start = value[0];
      const end = value.length === 2 && value[1];

      if (!start && !end) {
        return null;
      }

      if (start && !end) {
        return `> ${formatDate(start, dateFormat)}`;
      }

      if (!start && end) {
        return `< ${formatDate(end, dateFormat)}`;
      }

      return `${formatDate(start, dateFormat)} ~ ${formatDate(end, dateFormat)}`;
    },
  decimalRange: (fractionDigits?) => (value) => {
    if (!value || !value.length) {
      return null;
    }

    const start = value[0];
    const end = value.length === 2 && value[1];

    if (start == null && end == null) {
      return null;
    }

    if (start != null && end == null) {
      return `> ${formatDecimal(start, fractionDigits)}`;
    }

    if (start == null && end != null) {
      return `< ${formatDecimal(end, fractionDigits)}`;
    }

    return `${formatDecimal(start, fractionDigits)} - ${formatDecimal(end, fractionDigits)}`;
  },
  range: () => (value) => {
    if (!value || !value.length) {
      return null;
    }

    const start = value[0];
    const end = value.length === 2 && value[1];

    if ((start == null || start === '') && (end == null || end === '')) {
      return null;
    }

    if (start != null && (end == null || end === '')) {
      return `> ${start}`;
    }

    if ((start == null || start === '') && end != null) {
      return `< ${end}`;
    }

    return `${start} - ${end}`;
  },
};

function formatDecimal(value, fractionDigits) {
  return value ? (fractionDigits ? Number(value).toFixed(fractionDigits) : Number(value)) : null;
}

function formatDate(value, dateFormat = 'DD/MM/YYYY') {
  return value ? moment(value).format(dateFormat) : null;
}

function formatDatetime(value, dateFormat = 'DD/MM/YYYY HH:mm') {
  return value ? moment(value).format(dateFormat) : null;
}

export default filterRenders;
