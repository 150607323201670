import PropTypes from 'prop-types';
import React from 'react';

import {useSelector} from 'react-redux';
import {i18n} from 'src/i18n';
import selectors from 'src/modules/firma/firmaSelectors';
import DrawerButton from 'src/view/shared/view/DrawerButton';
import FirmaView from '../view/FirmaView';

function FirmaListItem(props) {
  const hasPermissionToRead = useSelector(selectors.selectPermissionToRead);

  const valueAsArray = () => {
    const {value} = props;

    if (!value) {
      return [];
    }

    if (Array.isArray(value)) {
      return value;
    }

    return [value];
  };

  const displayableRecord = (record) => {
    if (hasPermissionToRead) {
      return (
        <div key={record.id}>
          <DrawerButton
            drawerId={'DrawerFirmaViewPage'}
            type={'chip'}
            entity={'firma'}
            tooltipTitle={i18n(record.descripcion)}
            buttonTitle={i18n(record.descripcion)}
            width={window.innerWidth * 0.55}
            record={record}
            component={<FirmaView record={record} drawer={true} />}
          />
        </div>
      );
    }

    return <div key={record.id}>{record.descripcion}</div>;
  };

  if (!valueAsArray().length) {
    return null;
  }

  return <>{valueAsArray().map((value) => displayableRecord(value))}</>;
}

FirmaListItem.propTypes = {
  value: PropTypes.any,
};

export default FirmaListItem;
