import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/planificadorFormulario/importer/planificadorFormularioImporterSelectors';
import PlanificadorFormularioService from 'src/modules/planificadorFormulario/planificadorFormularioService';
import fields from 'src/modules/planificadorFormulario/importer/planificadorFormularioImporterFields';
import { i18n } from 'src/i18n';

const planificadorFormularioImporterActions = importerActions(
  'PLANIFICADORFORMULARIO_IMPORTER',
  selectors,
  PlanificadorFormularioService.import,
  fields,
  i18n('entities.planificadorFormulario.importer.fileName'),
);

export default planificadorFormularioImporterActions;