import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';
import notificacionEnumerators from 'src/modules/notificacion/notificacionEnumerators';

export default [
  {
    name: 'tipoDestino',
    label: i18n('entities.notificacion.fields.tipoDestino'),
    schema: schemas.enumerator(
      i18n('entities.notificacion.fields.tipoDestino'),
      {
        options: notificacionEnumerators.tipoDestino,
      },
    ),
  },
  {
    name: 'foto',
    label: i18n('entities.notificacion.fields.foto'),
    schema: schemas.images(
      i18n('entities.notificacion.fields.foto'),
      {},
    ),
  },
  {
    name: 'relacionadoA',
    label: i18n(
      'entities.notificacion.fields.relacionadoA',
    ),
    schema: schemas.enumerator(
      i18n('entities.notificacion.fields.relacionadoA'),
      {
        required: true,
        options: notificacionEnumerators.relacionadoA,
      },
    ),
  },
  {
    name: 'pendientesRelacionados',
    label: i18n(
      'entities.notificacion.fields.pendientesRelacionados',
    ),
    schema: schemas.relationToMany(
      i18n(
        'entities.notificacion.fields.pendientesRelacionados',
      ),
      {},
    ),
  },
  {
    name: 'eventosRelacionados',
    label: i18n(
      'entities.notificacion.fields.eventosRelacionados',
    ),
    schema: schemas.relationToMany(
      i18n(
        'entities.notificacion.fields.eventosRelacionados',
      ),
      {},
    ),
  },
  {
    name: 'mensajesRelacionados',
    label: i18n(
      'entities.notificacion.fields.mensajesRelacionados',
    ),
    schema: schemas.relationToMany(
      i18n(
        'entities.notificacion.fields.mensajesRelacionados',
      ),
      {},
    ),
  },
  {
    name: 'empleadosRelacionados',
    label: i18n(
      'entities.notificacion.fields.empleadosRelacionados',
    ),
    schema: schemas.relationToMany(
      i18n(
        'entities.notificacion.fields.empleadosRelacionados',
      ),
      {},
    ),
  },
  {
    name: 'titulo',
    label: i18n('entities.notificacion.fields.titulo'),
    schema: schemas.string(
      i18n('entities.notificacion.fields.titulo'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'asunto',
    label: i18n('entities.notificacion.fields.asunto'),
    schema: schemas.string(
      i18n('entities.notificacion.fields.asunto'),
      {},
    ),
  },
  {
    name: 'estado',
    label: i18n('entities.notificacion.fields.estado'),
    schema: schemas.enumerator(
      i18n('entities.notificacion.fields.estado'),
      {
        options: notificacionEnumerators.estado,
      },
    ),
  },
  {
    name: 'sucursales',
    label: i18n('entities.notificacion.fields.sucursales'),
    schema: schemas.relationToMany(
      i18n('entities.notificacion.fields.sucursales'),
      {},
    ),
  },
  {
    name: 'puestos',
    label: i18n('entities.notificacion.fields.puestos'),
    schema: schemas.relationToMany(
      i18n('entities.notificacion.fields.puestos'),
      {},
    ),
  },
  {
    name: 'usuariosDestino',
    label: i18n(
      'entities.notificacion.fields.usuariosDestino',
    ),
    schema: schemas.relationToMany(
      i18n('entities.notificacion.fields.usuariosDestino'),
      {},
    ),
  },
  {
    name: 'comunicacionesInternas',
    label: i18n(
      'entities.notificacion.fields.comunicacionesInternas',
    ),
    schema: schemas.relationToMany(
      i18n(
        'entities.notificacion.fields.comunicacionesInternas',
      ),
      {},
    ),
  },
  {
    name: 'tarea',
    label: i18n('entities.notificacion.fields.tarea'),
    schema: schemas.relationToMany(
      i18n('entities.notificacion.fields.tarea'),
      {},
    ),
  },
  {
    name: 'formulariosRelacionados',
    label: i18n(
      'entities.notificacion.fields.formulariosRelacionados',
    ),
    schema: schemas.relationToMany(
      i18n(
        'entities.notificacion.fields.formulariosRelacionados',
      ),
      {},
    ),
  },
];
