import NotificacionService from 'src/modules/notificacion/notificacionService';
import Errors from 'src/modules/shared/error/errors';
import Message from 'src/view/shared/message';
import { getHistory } from 'src/modules/store';
import { i18n } from 'src/i18n';

import NotificacionListActions from 'src/modules/notificacion/list/notificacionListActions';

const prefix = 'NOTIFICACION_FORM';

const notificacionFormActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  CREATE_STARTED: `${prefix}_CREATE_STARTED`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  doInit: (id) => async (dispatch) => {
    try {
      dispatch({
        type: notificacionFormActions.INIT_STARTED,
      });

      let record = {};

      const isEdit = Boolean(id);

      if (isEdit) {
        record = await NotificacionService.find(id);
      }

      dispatch({
        type: notificacionFormActions.INIT_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: notificacionFormActions.INIT_ERROR,
      });

      getHistory().push('/notificacion');
    }
  },

  doCreate: (values) => async (dispatch) => {
    try {
      dispatch({
        type: notificacionFormActions.CREATE_STARTED,
      });

      await NotificacionService.create(values);

      dispatch({
        type: notificacionFormActions.CREATE_SUCCESS,
      });

      Message.success(
        i18n('entities.notificacion.create.success'),
      );

      getHistory().push('/notificacion');
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: notificacionFormActions.CREATE_ERROR,
      });
    }
  },

  doUpdate: (id, values) => async (dispatch, getState) => {
    try {
      dispatch({
        type: notificacionFormActions.UPDATE_STARTED,
      });

      await NotificacionService.update(id, values);

      dispatch({
        type: notificacionFormActions.UPDATE_SUCCESS,
      });

      // getHistory().push('/notificacion');

      if (!values.silent) {
        dispatch(
          NotificacionListActions.doFetchCurrentFilter(),
        );
      }
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: notificacionFormActions.UPDATE_ERROR,
      });
    }
  },
};

export default notificacionFormActions;
