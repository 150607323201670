import moment from 'moment-timezone';
import {i18n} from 'src/i18n';
import * as yup from 'yup';

const stringToDate = (date) => {
  if (!date) return null;
  let formattedDate: any;
  const dateFormats = [
    {
      regex: /^\d{4}\/\d{2}\/\d{2}$/, // yyyy/MM/dd
      format: 'YYYY/MM/DD',
    },
    {
      regex: /^\d{4}-\d{2}-\d{2}$/, // yyyy-MM-dd
      format: 'YYYY-MM-DD',
    },
    {
      regex: /^\d{2}\/\d{2}\/\d{4}$/, // dd/MM/yyyy
      format: 'DD/MM/YYYY',
    },
    {
      regex: /^\d{2}-\d{2}-\d{4}$/, // dd-MM-yyyy
      format: 'DD-MM-YYYY',
    },
  ];
  if (
    !dateFormats.some((format) => {
      if (format.regex.test(date)) {
        formattedDate = moment(date, format.format).toDate();
        return true;
      }
      return false;
    })
  ) {
    formattedDate = new Date(date);
    if (isNaN(formattedDate.getTime())) {
      console.error(`date ${date} is not valid. valid formats: yyyy/MM/dd, yyyy-MM-dd, dd/MM/yyyy or dd-MM-yyyy`);
      return 'invalid date';
    }
  }
  return formattedDate;
};
const calcularDigitoVerificador = (cuerpoRut) => {
  // Paso 1: Obtener los números del RUT
  const numerosRut = cuerpoRut.replace(/\D/g, '').split('').map(Number);

  // Paso 2: Reordenar los números
  const numerosReordenados = numerosRut.reverse();

  // Paso 3: Multiplicar cada número por la serie 2, 3, 4, 5, 6, 7
  let suma = 0;
  let serie = [2, 3, 4, 5, 6, 7];
  for (let i = 0; i < numerosReordenados.length; i++) {
    suma += numerosReordenados[i] * serie[i % serie.length];
  }

  // Paso 4: Sumar todos los resultados
  const resultado = suma;

  // Paso 5: Dividir por 11 y obtener el resto
  const resto = resultado % 11;

  // Paso 6: Restar al resultado anterior
  let digitoVerificador: any = 11 - resto;

  // Paso 7: Manejar casos especiales (11 => 0, 10 => K)
  if (digitoVerificador === 11) {
    digitoVerificador = 0;
  } else if (digitoVerificador === 10) {
    digitoVerificador = 'K';
  }
  return digitoVerificador;
};

const isValidRut = (rut) => {
  if (!rut || typeof rut !== 'string') {
    return false;
  }
  const [rutNumber, rutDigit] = rut.split('-');
  const calculatedDigit = calcularDigitoVerificador(rutNumber);

  // console.log('%c⧭ isValidRut', 'color: #d0bfff', {
  //   calculatedDigit,
  //   rutDigit,
  //   'calculatedDigit == rutDigit': rutDigit.toString().toUpperCase() === calculatedDigit,
  // });
  return rutDigit.toString().toUpperCase() === calculatedDigit.toString().toUpperCase();
};

const yupImporterSchemas = {
  generic(label) {
    return yup.mixed().label(label);
  },
  string(label, config?) {
    config = config || {};

    let yupChain = yup
      .string()
      .transform((cv, ov) => {
        return ov === '' ? null : cv;
      })
      .nullable(true)
      .trim()
      .label(label);

    if (config.required) {
      yupChain = yupChain.required();
    }

    if (config.min || config.min === 0) {
      yupChain = yupChain.min(config.min);
    }

    if (config.max) {
      yupChain = yupChain.max(config.max);
    }

    if (config.matches) {
      yupChain = yupChain.matches(config.matches);
    }

    return yupChain;
  },
  boolean(label, config?) {
    // sometimes value equivalences are sent in config
    // for example {trueValues: ['yes', 'y']}
    // this is because excel files can't have boolean values
    config = config || {};
    if (config.trueValues || config.falseValues) {
      return yup
        .mixed()
        .nullable(true)
        .label(label)
        .transform((value) => {
          // value is the value in the excel file
          if (!value) {
            // if value is falsy, it's false
            return false;
          }
          if (config.trueValues && config.trueValues.includes(value.toString().toLowerCase())) {
            // if value is in trueValues, it's true
            return true;
          }
          return false;
        });
    }
    return yup.bool().default(false).label(label);
  },
  relationToOne(label, config?) {
    config = config || {};

    let yupChain = yup.mixed().nullable(true).label(label);

    if (config.required) {
      yupChain = yupChain.required();
    }

    return yupChain;
  },
  stringArray(label, config?) {
    config = config || {};

    let yupChain = yup
      .mixed()
      .label(label)
      .transform((value) =>
        Array.isArray(value)
          ? value
          : (value || '')
              .trim()
              .split(' ')
              .filter((item) => Boolean(item))
              .map((item) => item.trim()),
      );

    if (config.required) {
      yupChain = yupChain.required();
    }

    return yupChain;
  },
  relationToMany(label, config?) {
    config = config || {};

    let yupChain = yup
      .array()
      .nullable(true)
      .label(label)
      .transform((value, originalValue) => {
        if (!originalValue) {
          return null;
        }

        if (Array.isArray(originalValue)) {
          return originalValue;
        }

        return originalValue
          .trim()
          .split(' ')
          .map((value) => {
            return value;
          });
      });

    if (config.required) {
      yupChain = yupChain.required();
    }

    if (config.min || config.min === 0) {
      yupChain = yupChain.min(config.min);
    }

    if (config.max) {
      yupChain = yupChain.max(config.max);
    }

    return yupChain;
  },
  json(label) {
    return yup.mixed().label(label);
  },
  integer(label, config?) {
    config = config || {};

    let yupChain = yup
      .number()
      .transform((cv, ov) => {
        return ov === '' ? null : cv;
      })
      .integer()
      .nullable(true)
      .label(label);

    if (config.required) {
      yupChain = yupChain.required();
    }

    if (config.min || config.min === 0) {
      yupChain = yupChain.min(config.min);
    }

    if (config.max) {
      yupChain = yupChain.max(config.max);
    }

    return yupChain;
  },
  images(label, config?) {
    config = config || {};

    let yupChain = yup
      .array()
      .nullable(true)
      .label(label)
      .transform((value, originalValue) => {
        if (!originalValue) {
          return null;
        }

        if (Array.isArray(originalValue)) {
          return originalValue;
        }

        return originalValue
          .trim()
          .split(' ')
          .map((value) => {
            return {
              name: value.trim(),
              publicUrl: value.trim(),
              new: true,
            };
          });
      });

    if (config.required || config.min) {
      yupChain = yupChain.required();
    }

    if (config.min || config.min === 0) {
      yupChain = yupChain.min(config.min);
    }

    if (config.max) {
      yupChain = yupChain.max(config.max);
    }

    return yupChain;
  },
  files(label, config?) {
    config = config || {};

    let yupChain = yup
      .array()
      .nullable(true)
      .label(label)
      .transform((value, originalValue) => {
        if (!originalValue) {
          return null;
        }

        if (Array.isArray(originalValue)) {
          return originalValue;
        }

        return originalValue
          .trim()
          .split(' ')
          .map((value) => {
            return {
              name: value.trim(),
              publicUrl: value.trim(),
              new: true,
            };
          });
      });

    if (config.required || config.min) {
      yupChain = yupChain.required();
    }

    if (config.min || config.min === 0) {
      yupChain = yupChain.min(config.min);
    }

    if (config.max) {
      yupChain = yupChain.max(config.max);
    }

    return yupChain;
  },
  enumerator(label, config?) {
    config = config || {};

    let yupChain = yup
      .string()
      .transform((cv, ov) => {
        // sometimes value equivalences are sent in config
        // in this case we need to transform the value to the equivalence
        // 'WorkAccident', 'CommutingAccident', 'OccupationalDisease', 'NotCoveredByLaw', 'NotApplicable'
        // example : { equivalences: {
        //   'trabajo': 'WorkAccident',
        //   'trayecto': 'CommutingAccident',
        //   'enf. prof.': 'OccupationalDisease',
        //   'no ley': 'NotCoveredByLaw',
        //   'n/a': 'NotApplicable',
        // }}
        // if value is a key in equivalences, return the equivalence
        let lowerCaseValue = cv?.toString().toLowerCase();
        if (config.equivalences && config.equivalences[lowerCaseValue]) {
          return config.equivalences[lowerCaseValue];
        }
        return ov === '' ? null : cv;
      })
      .label(label)
      .nullable(true)
      .oneOf([null, ...(config.options || [])]);

    if (config.required) {
      yupChain = yupChain.required(i18n('validation.string.selected'));
    }

    return yupChain;
  },
  rut(label, config?) {
    config = config || {};

    let yupChain = yup
      .string()
      .transform((cv, ov) => {
        if (cv) {
          cv = cv.replace('k', 'K');
          // delete all non digits except K and -
          cv = cv.replace(/[^\dK-]/gi, '');
          //console.log('%c⧭ rut transform', 'color: #00736b', cv);
        }
        return ov === '' ? null : cv;
      })
      .nullable(true)
      .trim()
      .label(label)
      .test('rut', i18n('validation.string.rut'), (value) => {
        if (!value) {
          return true;
        }
        return isValidRut(value);
      });

    if (config.required) {
      yupChain = yupChain.required();
    }

    return yupChain;
  },
  email(label, config?) {
    config = config || {};

    let yupChain = yup
      .string()
      .transform((cv, ov) => {
        return ov === '' ? null : cv;
      })
      .nullable(true)
      .trim()
      .label(label)
      .email();

    if (config.required) {
      yupChain = yupChain.required();
    }

    if (config.min || config.min === 0) {
      yupChain = yupChain.min(config.min);
    }

    if (config.max) {
      yupChain = yupChain.max(config.max);
    }

    if (config.matches) {
      yupChain = yupChain.matches(config.matches);
    }

    return yupChain;
  },
  decimal(label, config?) {
    config = config || {};
    let yupChain = yup
      .number()
      .transform((cv, ov) => {
        return ov === '' ? null : cv;
      })
      .nullable(true)
      .label(label);

    if (config.required) {
      yupChain = yupChain.required();
    }

    if (config.min || config.min === 0) {
      yupChain = yupChain.min(config.min);
    }

    if (config.max) {
      yupChain = yupChain.max(config.max);
    }

    return yupChain;
  },
  datetime(label, config?) {
    config = config || {};
    let yupChain = yup
      .mixed()
      .nullable(true)
      .label(label)
      .transform((value, originalValue) =>
        // no usar config.fromFormat, hay un bug al hacer el cast dos veces al importar
        originalValue ? moment(originalValue).toISOString() : null,
      );

    if (config.required) {
      yupChain = yupChain.required();
    }

    return yupChain;
  },
  date(label, config?) {
    config = config || {};
    let yupChain = yup
      .mixed()
      .transform((value, originalValue) => {
        if (!originalValue) return null;
        // no usar config.fromFormat, hay un bug al hacer el cast dos veces al importar
        const isDate = originalValue instanceof Date && !isNaN(originalValue as any);
        if (!isDate) {
          originalValue = stringToDate(originalValue);
        }
        return moment(originalValue).format('YYYY-MM-DD');
      })
      .nullable(true)
      .label(label);

    if (config.required) {
      yupChain = yupChain.required();
    }

    return yupChain;
  },
};

export default yupImporterSchemas;
