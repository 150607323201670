import {Button} from '@mui/material';
import React, {useState} from 'react';
import {useFieldArray, useFormContext} from 'react-hook-form';
import PreguntaFormularioForm from 'src/view/modeloFormulario/form/PreguntaFormularioForm';
import RespuestaFormularioForm from 'src/view/modeloFormulario/form/RespuestaFormularioForm';

export default function SubseccionesPreguntasFormularioForm(props) {
  const {item, pageIndex, indexPregunta, categoriaIndex, subseccionIndex, name, esRespuesta = false, editar = true} = props;

  const [renderCount, setRenderCount] = useState(0);

  const {control, getValues, register, setValue} = useFormContext();

  // subseccionName se ocupa para las preguntas de la edición del procedimiento
  const subseccionName = `${name}.${categoriaIndex}.subseccions.${subseccionIndex}`;
  const {fields, remove, prepend, append, insert, move} = useFieldArray({
    control,
    name: `${subseccionName}.preguntas`,
  });

  const [expanded, setExpanded] = React.useState<string | false>(fields?.[0]?.id);

  const handleChangeAccordion = (index) => (event, expanded) => setExpanded(expanded ? index : -1);
  const handleExitedAccordion = (index) => (event) => {
    // if (expanded === index) window.scrollTo(0, event.offsetTop - 100);
  };

  const secciones: any = getValues(name);
  // seccionKey se ocupa para las respuestas de listaProcedimiento
  const seccionKey = `${name}[${indexPregunta}]`;

  let preguntasValues = [];
  if (!esRespuesta) {
    preguntasValues = getValues(`${subseccionName}.preguntas`);
  }

  const preguntaEmpty = (index: any = null) => {
    if (index === null) index = preguntasValues?.length || 0;
    setExpanded(index);
    setTimeout(() => {
      const scrollIndex = index ? index - 1 : 0;
      const pregName = `${subseccionName}.preguntas.${scrollIndex}`;
      const expandedItem = document.getElementById(pregName);
      if (expandedItem) window.scrollTo({top: expandedItem.offsetTop - (150 + Math.trunc(10 * index)), behavior: 'smooth'});
    }, 0);
    return {
      nPregunta: null,
      pregunta: {
        titulo: null,
      },
      link: '',
      tipoRespuesta: null,
      tieneComentario: false,
      tienePuntaje: false,
    };
  };

  const controlledFields = fields.map((field, index) => {
    return {...field};
  });

  const reRender = () => {
    console.log('%c⧭ call on reRender', 'color: #aa00ff');
    setRenderCount(renderCount + 1);
  };

  const reOrderPreguntas = () => {
    // Timeout in order to wait for the move to be done
    setTimeout(() => {
      const preguntas = getValues(`${subseccionName}.preguntas`);
      preguntas.forEach((item, indexPregunta) => {
        if (item.nPregunta !== indexPregunta + 1) {
          //console.log('%c⧭ item.nPregunta !== indexPregunta + 1', 'color: #0088cc', item.nPregunta, '!==', indexPregunta + 1);
          setValue(`${subseccionName}.preguntas.${indexPregunta}.nPregunta`, indexPregunta + 1);
        }
      });
    }, 0);
  };

  const moveHandle = (fromIndex, toIndex) => {
    move(fromIndex, toIndex);
    reOrderPreguntas();
  };

  const insertHandle = (index) => {
    insert(index, preguntaEmpty(index));
    reOrderPreguntas();
  };

  const removeHandle = (index) => {
    remove(index);
    reOrderPreguntas();
  };

  if (esRespuesta) {
    return <RespuestaFormularioForm secciones={secciones} preguntaKey={seccionKey} item={item} editar={editar} />;
  } else {
    return (
      <React.Fragment>
        {controlledFields.map((pregunta, indexPregunta) => {
          const preguntaValue = getValues(`${subseccionName}.preguntas.${indexPregunta}`);
          pregunta = {...pregunta, ...preguntaValue};
          return (
            <PreguntaFormularioForm
              fieldsLength={controlledFields.length}
              reRender={reRender}
              key={pregunta.id}
              daKey={pregunta.id}
              name={`${subseccionName}.preguntas.${indexPregunta}`}
              item={pregunta}
              indexPregunta={indexPregunta}
              remove={removeHandle}
              register={register}
              move={moveHandle}
              control={control}
              insert={insertHandle}
              append={append}
              expanded={expanded}
              handleChangeAccordion={handleChangeAccordion}
              handleExitedAccordion={handleExitedAccordion}
            />
          );
        })}
        {!controlledFields?.length && (
          <Button onClick={() => append(preguntaEmpty())} variant="outlined" color="primary" type="button">
            + Añadir Pregunta
          </Button>
        )}
      </React.Fragment>
    );
  }
}
