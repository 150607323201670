import ComunicacionInternaService from 'src/modules/comunicacionInterna/comunicacionInternaService';
import Errors from 'src/modules/shared/error/errors';
import Message from 'src/view/shared/message';
import {getHistory} from 'src/modules/store';
import {i18n} from 'src/i18n';
import comunicacionInternaListActions from '../list/comunicacionInternaListActions';
import TareaListActions from '../../tarea/list/tareaListActions';

import layoutActions from 'src/modules/layout/layoutActions';
import moment from 'moment-timezone';

const prefix = 'COMUNICACIONINTERNA_FORM';

const comunicacionInternaFormActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  CREATE_STARTED: `${prefix}_CREATE_STARTED`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  doInit: id => async dispatch => {
    try {
      dispatch({
        type: comunicacionInternaFormActions.INIT_STARTED,
      });

      let record = {};

      const isEdit = Boolean(id);

      if (isEdit) {
        record = await ComunicacionInternaService.find(id);
      }

      dispatch({
        type: comunicacionInternaFormActions.INIT_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: comunicacionInternaFormActions.INIT_ERROR,
      });

      getHistory().push('/comunicacion-interna');
    }
  },

  doCreate: values => async dispatch => {
    try {
      dispatch({
        type: comunicacionInternaFormActions.CREATE_STARTED,
      });

      const newRecord = await ComunicacionInternaService.create(values);

      dispatch({
        type: comunicacionInternaFormActions.CREATE_SUCCESS,
      });

      Message.success(i18n('entities.comunicacionInterna.create.success'));

      dispatch(comunicacionInternaListActions.doFetchCurrentFilter());

      if (!values.passProps) {
        if (window.innerWidth > 768) {
          getHistory().push('/comunicacion-interna/vista-360-desktop/' + newRecord.id);
        } else {
          getHistory().push('/comunicacion-interna/vista-360-mobile/' + newRecord.id);
        }
      } else {
        dispatch(TareaListActions.doFetchCurrentFilter());

        dispatch(
          layoutActions.doVisibleDrawer({
            drawerId: 'DrawerComunicacionInternaFormPage',
            drawerVisible: false,
            drawerChanged: moment().unix(),
            drawerUpdateComponent: 'PendienteListPage',
          }),
        );
      }
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: comunicacionInternaFormActions.CREATE_ERROR,
      });
    }
  },

  doUpdate: (id, values) => async (dispatch, getState) => {
    try {
      dispatch({
        type: comunicacionInternaFormActions.UPDATE_STARTED,
      });

      const updatedRecord = await ComunicacionInternaService.update(id, values);

      dispatch({
        type: comunicacionInternaFormActions.UPDATE_SUCCESS,
      });

      Message.success(i18n('entities.comunicacionInterna.update.success'));

      if (!values.passProps) {
        if (window.innerWidth > 768) {
          getHistory().push('/comunicacion-interna/vista-360-desktop/' + updatedRecord.id);
        } else {
          getHistory().push('/comunicacion-interna/vista-360-mobile/' + updatedRecord.id);
        }
      } else {
        dispatch(TareaListActions.doFetchCurrentFilter());

        dispatch(
          layoutActions.doVisibleDrawer({
            drawerId: 'DrawerComunicacionInternaFormPage',
            drawerVisible: false,
            drawerChanged: moment().unix(),
            drawerUpdateComponent: 'PendienteListPage',
          }),
        );
      }
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: comunicacionInternaFormActions.UPDATE_ERROR,
      });
    }
  },
};

export default comunicacionInternaFormActions;
