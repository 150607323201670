export default class ExporterSchema {
  fields: Array<any>;
  options: any;

  constructor(fields, options: any = null) {
    this.fields = fields;
    this.options = options;
  }

  get labels() {
    if (this.options?.customLabels) return this.options.customLabels(this.fields);
    return this.fields.map((field) => field.label);
  }

  labelOf(name) {
    const field = this.fields.find((field) => field.name === name);

    if (field) {
      return field.label;
    }

    return name;
  }

  cast(row) {
    if (!row) {
      return row;
    }

    const casted = {};

    const castFn =
      this.options?.customCast ||
      (({fields, casted, row}) =>
        (name) => {
          const field = fields.find((field) => field.name === name);
          if (field) {
            casted[name] = field.render ? field.render(row[name]) : row[name] !== undefined ? String(row[name]) : '';
          }
        });
    Object.keys(row).forEach(castFn({fields: this.fields, casted, row}));
    return casted;
  }
}
