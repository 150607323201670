import {Add, Remove} from '@mui/icons-material';
import {Box, Button, Divider, Grid, Typography} from '@mui/material';
import React, {useState} from 'react';
import {useFormContext} from 'react-hook-form';

// import { List, AutoSizer } from 'react-virtualized';
import Pagination from 'src/view/shared/table/Pagination';
import RespuestaFormularioForm from './RespuestaFormularioForm';

export default function PrincipalFormularioForm(props) {
  const {name, esRespuesta, editar, onSave, customButton, blankFormList, record} = props;
  //console.log('%c⧭ PrincipalFormularioForm', 'color: #00a3cc', {editar});

  const {control, getValues, setValue, watch} = useFormContext();

  const listaProcedimiento: any = watch(name);
  const dynamicList: any = [];
  let lastSection: any = null;
  let lastSubsection: any = null;
  const counters: any = {};
  listaProcedimiento?.forEach((xitem: any, index) => {
    const item = {...xitem};
    item.index = index;
    if (item.tipo === 'seccion') {
      counters[item.listIndex] = counters[item.listIndex] ? counters[item.listIndex] + 1 : 1;
      item.counter = counters[item.listIndex];
      const nextQuestionIndex = index + 2;
      const nextQuestion: any = listaProcedimiento[nextQuestionIndex];
      nextQuestion.seccion = item;
      if (lastSection?.isDynamic) {
        nextQuestion.previousSection = lastSection;
      }
      if (lastSubsection?.isDynamic) {
        nextQuestion.previousSubsection = lastSubsection;
      }
      lastSection = item;
    }
    if (item.tipo === 'subseccion') {
      const subIndex = lastSection.index + '_' + item.listIndex;
      counters[subIndex] = counters[subIndex] ? counters[subIndex] + 1 : 1;
      item.counter = counters[subIndex];
      item.subIndex = subIndex;
      const nextQuestionIndex = index + 1;
      const nextQuestion: any = listaProcedimiento[nextQuestionIndex];
      nextQuestion.subseccion = item;
      if (lastSubsection?.isDynamic) {
        nextQuestion.previousSubsection = lastSubsection;
      }
      lastSubsection = item;
    }
    if (item.tipo === 'pregunta') {
      const isLastItem = index === listaProcedimiento.length - 1;
      item.isLastItem = isLastItem;
      if (isLastItem) {
        if (lastSection.isDynamic) {
          item.previousSection = lastSection;
        }
        if (lastSubsection.isDynamic) {
          item.previousSubsection = lastSubsection;
        }
      }
      dynamicList.push(item);
    }
  });
  //const notQuestions = fields.filter((item: any) => item.tipo !== 'pregunta');

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: dynamicList.length,
  });

  const selectOffset = () => {
    if (!pagination || !pagination.pageSize) {
      return 0;
    }

    const current = pagination.current || 1;

    return (current - 1) * pagination.pageSize;
  };

  const doChangePagination = (pagination) => {
    if (esRespuesta && editar) {
      onSave({...getValues(), updating: true});
    }

    setPagination({
      current: pagination.current,
      pageSize: pagination.pageSize,
      total: dynamicList.length,
    });
  };

  const tipoFormulario: any = record.tipoFormulario;
  // const watchCategoria: any = useWatch({ name: 'categoria', control }); // lentitud
  const fechaLimite: any = record.fechaLimite;
  //console.log('%c⧭', 'color: #917399', {dynamicList});

  const modeloTarea: any = record.modeloTarea;
  const tituloTarea: any = record.tituloTarea;
  const tituloOT: any = record.titulo;

  const categoria: any = record.categoria;

  const initialTitle = tipoFormulario
    ? tipoFormulario + ' ' + categoria?.nombre
    : tituloOT
    ? `${tituloOT} ${tituloTarea || ''}`
    : modeloTarea?.titulo;

  // console.log('%c⧭', 'color: #364cd9', initialTitle, {
  //   tipoFormulario,
  //   categoria,
  //   tituloOT,
  //   tituloTarea,
  //   modeloTarea,
  // });

  if (esRespuesta) {
    // console.log('%c⧭', 'color: #7f2200', {dynamicList});
    return (
      <React.Fragment>
        <Grid
          style={{
            marginBottom: 20,
          }}
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}>
          <Grid item id="container-form" xs={12} lg={12}>
            <Box>
              <Box
                display="flex"
                justifyContent="center"
                width="100%"
                style={{maxWidth: Boolean(customButton) ? '80%' : undefined}}>
                <Typography variant="h2">{initialTitle}</Typography>
              </Box>
              <Box style={{marginLeft: 'auto', alignSelf: 'center'}}>{customButton}</Box>
            </Box>
            <Divider sx={{mt: 1}} />

            <Pagination onChange={doChangePagination} pagination={pagination} limitsPerPageOptions={[10]} />

            {dynamicList &&
              dynamicList
                .slice(selectOffset(), selectOffset() + pagination.pageSize)
                .map((item, pageIndex) => renderProcedure(item, item.index, pageIndex))}

            <Box px={3}>
              <Pagination onChange={doChangePagination} pagination={pagination} limitsPerPageOptions={[10]} />
            </Box>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
  return null;

  function addSection(item, index) {
    const listIndex = item.listIndex;
    // from current index search next item with tipo seccion or end of array, that range must be added without answers
    const nextSectionIndex = blankFormList.findIndex((item: any, i) => i > listIndex && item.tipo === 'seccion');
    const nextSection = nextSectionIndex !== -1 ? nextSectionIndex : blankFormList.length;
    const newQuestions = blankFormList.slice(listIndex, nextSection);

    // add new questions to listaProcedimiento
    const newListaProcedimiento = listaProcedimiento.toSpliced(index + 1, 0, ...newQuestions);

    // update dynamicList
    setValue(name, newListaProcedimiento);
    setPagination({
      current: pagination.current,
      pageSize: pagination.pageSize,
      total: newListaProcedimiento.filter((item: any) => item.tipo === 'pregunta').length,
    });
  }
  function addSubsection(item, index) {
    const listIndex = item.listIndex;

    // from current index search next item with tipo subseccion or end of array, that range must be added
    const nextSectionIndex = blankFormList.findIndex((item: any, i) => i > listIndex && item.tipo === 'seccion');
    const nextSection = nextSectionIndex !== -1 ? nextSectionIndex : blankFormList.length;
    const nextSubsectionIndex = blankFormList.findIndex((item: any, i) => i > listIndex && item.tipo === 'subseccion');
    const nextSubsection = nextSubsectionIndex !== -1 ? nextSubsectionIndex : nextSection;
    const newQuestions = blankFormList.slice(listIndex, nextSubsection);

    // add new questions to listaProcedimiento
    const newListaProcedimiento = listaProcedimiento.toSpliced(index + 1, 0, ...newQuestions);

    // update dynamicList
    setValue(name, newListaProcedimiento);
    setPagination({
      current: pagination.current,
      pageSize: pagination.pageSize,
      total: newListaProcedimiento.filter((item: any) => item.tipo === 'pregunta').length,
    });
  }
  function removeSection(item) {
    const index = item.index;
    const listIndex = item.listIndex;
    // from current index search next item with tipo seccion or end of array, that range must be added without answers
    const nextSectionIndex = blankFormList.findIndex((item: any, i) => i > listIndex && item.tipo === 'seccion');
    const nextSection = nextSectionIndex !== -1 ? nextSectionIndex : blankFormList.length;
    const sectionItems = blankFormList.slice(listIndex, nextSection);

    // add new questions to listaProcedimiento
    const newListaProcedimiento = listaProcedimiento.toSpliced(index, sectionItems.length);

    // update dynamicList
    setValue(name, newListaProcedimiento);
    setPagination({
      current: pagination.current,
      pageSize: pagination.pageSize,
      total: newListaProcedimiento.filter((item: any) => item.tipo === 'pregunta').length,
    });
  }
  function removeSubsection(item) {
    const index = item.index;
    const listIndex = item.listIndex;
    // from current index search next item with tipo subseccion or end of array, that range must be added
    const nextSectionIndex = blankFormList.findIndex((item: any, i) => i > listIndex && item.tipo === 'seccion');
    const nextSection = nextSectionIndex !== -1 ? nextSectionIndex : blankFormList.length;
    const nextSubsectionIndex = blankFormList.findIndex((item: any, i) => i > listIndex && item.tipo === 'subseccion');
    const nextSubsection = nextSubsectionIndex !== -1 ? nextSubsectionIndex : nextSection;
    const subsectionItems = blankFormList.slice(listIndex, nextSubsection);

    const section = item.seccion;
    if (section && listaProcedimiento[subsectionItems.length + 1]) {
      listaProcedimiento[subsectionItems.length + 1].seccion = section;
    }
    // add new questions to listaProcedimiento
    const newListaProcedimiento = listaProcedimiento.toSpliced(index, subsectionItems.length);

    // update dynamicList
    setValue(name, newListaProcedimiento);
    setPagination({
      current: pagination.current,
      pageSize: pagination.pageSize,
      total: newListaProcedimiento.filter((item: any) => item.tipo === 'pregunta').length,
    });
  }

  function renderProcedure(item, index, pageIndex) {
    const isLastItem = item.isLastItem;
    let subsectionIsLastOfCounter;
    if (item.previousSubsection) {
      let subsectionTotalCounter = counters[item.previousSubsection.subIndex];
      subsectionIsLastOfCounter = subsectionTotalCounter === item.previousSubsection.counter;
    }
    let sectionIsLastOfCounter;
    if (item.previousSection) {
      let sectionTotalCounter = counters[item.previousSection.listIndex];
      sectionIsLastOfCounter = sectionTotalCounter === item.previousSection.counter;
    }
    return (
      <Box key={index}>
        {(subsectionIsLastOfCounter || sectionIsLastOfCounter) && editar && !isLastItem && (
          <Grid container direction="column" gap={3} alignItems="left" mb={2}>
            {subsectionIsLastOfCounter && (
              <Box>
                <Button
                  color="primary"
                  variant="contained"
                  sx={{ml: 4, borderRadius: '50px'}}
                  onClick={() => addSubsection(item.previousSubsection, index)}>
                  <Add /> Añadir Subsección "{item.previousSubsection.categoria}"
                </Button>
              </Box>
            )}
            {sectionIsLastOfCounter && (
              <Box>
                <Button
                  color="secondary"
                  variant="contained"
                  sx={{ml: 2, borderRadius: '50px', mt: -1}}
                  onClick={() => addSection(item.previousSection, index)}>
                  <Add /> Añadir Sección "{item.previousSection.categoria}"
                </Button>
              </Box>
            )}
          </Grid>
        )}
        {item.seccion && item.seccion.categoria && (
          <Grid item lg={12} sx={{borderBottom: '2px solid gray', mt: 1, mb: 1.5, py: 1}}>
            <Grid item lg={12} sx={{px: 4, backgroundColor: 'transparent', borderRadius: '5px'}}>
              <Grid container justifyContent="center">
                <Typography variant="h3" fontSize="22px">
                  {item.seccion.categoria} {item.seccion.isDynamic && item.seccion.counter ? `(${item.seccion.counter})` : ''}
                  {item.seccion.isDynamic && editar && counters[item.seccion.listIndex] > 1 && (
                    <Button
                      variant="contained"
                      sx={{
                        opacity: 0.3,
                        backgroundColor: 'gray',
                        ml: 2,
                        borderRadius: '50px',
                        '&:hover': {backgroundColor: 'red', opacity: 0.8},
                      }}
                      startIcon={<Remove />}
                      onClick={() => removeSection(item.seccion)}>
                      Eliminar
                    </Button>
                  )}
                  {/* {fechaLimite ? (
                    <React.Fragment>
                      <Schedule sx={{ml: 4, mb: '-5px'}} /> Finaliza {moment(fechaLimite).fromNow()}{' '}
                    </React.Fragment>
                  ) : null} */}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}

        {item.subseccion && (
          <Box px={4}>
            <Box sx={{pt: 1, pb: 0}} display="flex" justifyContent="center">
              <Typography variant="h3">
                {item.subseccion.categoria}{' '}
                {item.subseccion.isDynamic && item.subseccion.counter ? `(${item.subseccion.counter})` : ''}
                {item.subseccion.isDynamic && editar && counters[item.subseccion.subIndex] > 1 && (
                  <Button
                    variant="contained"
                    sx={{
                      opacity: 0.3,
                      backgroundColor: 'gray',
                      ml: 2,
                      borderRadius: '50px',
                      '&:hover': {backgroundColor: 'red', opacity: 0.8},
                    }}
                    startIcon={<Remove />}
                    onClick={() => removeSubsection(item.subseccion)}>
                    Eliminar
                  </Button>
                )}
              </Typography>
            </Box>
            <Divider sx={{mt: 1.5, mb: 3}} />
          </Box>
        )}

        {item.tipo === 'pregunta' && (
          <Box px={4} key={item.index + '_' + listaProcedimiento.length}>
            <RespuestaFormularioForm secciones={true} preguntaKey={`${name}[${item.index}]`} item={item} editar={editar} />
            <Divider sx={{mt: 2, mb: 3}} />
          </Box>
        )}

        {(item.previousSubsection || item.previousSection) && editar && isLastItem && (
          <Grid container direction="column" gap={3} alignItems="left" mb={2}>
            {item.previousSubsection && (
              <Box>
                <Button
                  color="primary"
                  variant="contained"
                  sx={{ml: 4, borderRadius: '50px'}}
                  onClick={() => addSubsection(item.previousSubsection, index)}>
                  <Add /> Añadir Subsección "{item.previousSubsection.categoria}"
                </Button>
              </Box>
            )}
            {item.previousSection && (
              <Box>
                <Button
                  color="secondary"
                  variant="contained"
                  sx={{borderRadius: '50px'}}
                  onClick={() => addSection(item.previousSection, index)}>
                  <Add /> Añadir Sección "{item.previousSection.categoria}"
                </Button>
              </Box>
            )}
          </Grid>
        )}
      </Box>
    );
  }
}
