import ActivoService from 'src/modules/activo/activoService';
import Errors from 'src/modules/shared/error/errors';
import Message from 'src/view/shared/message';
import {getHistory} from 'src/modules/store';
import {i18n} from 'src/i18n';
import ActivoListActions from 'src/modules/activo/list/activoListActions';
import layoutActions from 'src/modules/layout/layoutActions';
import moment from 'moment-timezone';

const prefix = 'ACTIVO_FORM';

const activoFormActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  CREATE_STARTED: `${prefix}_CREATE_STARTED`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  doInit: (id, fieldConfig:any = {}) => async dispatch => {
    try {
      dispatch({
        type: activoFormActions.INIT_STARTED,
      });

      let record = {};

      const isEdit = Boolean(id);

      if (isEdit) {
        record = await ActivoService.find(id);
      }
      if(!fieldConfig?.fields) fieldConfig = await ActivoService.getFieldConfig();

      dispatch({
        type: activoFormActions.INIT_SUCCESS,
        payload: {
          ...record,
          fieldConfig
        },
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: activoFormActions.INIT_ERROR,
      });

      getHistory().push('/activo');
    }
  },

  doCreate: values => async dispatch => {
    try {
      dispatch({
        type: activoFormActions.CREATE_STARTED,
      });

      await ActivoService.create(values);

      dispatch({
        type: activoFormActions.CREATE_SUCCESS,
      });

      Message.success(i18n('entities.activo.create.success'));

      dispatch(ActivoListActions.doFetchCurrentFilter());

      dispatch(
        layoutActions.doVisibleDrawer({
          drawerId: 'DrawerActivoFormPage',
          drawerVisible: false,
          drawerChanged: moment().unix(),
          drawerUpdateComponent: null,
        }),
      );
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: activoFormActions.CREATE_ERROR,
      });
    }
  },

  doUpdate: (id, values) => async (dispatch, getState) => {
    try {
      dispatch({
        type: activoFormActions.UPDATE_STARTED,
      });

      await ActivoService.update(id, values);

      dispatch({
        type: activoFormActions.UPDATE_SUCCESS,
      });

      Message.success(i18n('entities.activo.update.success'));

      dispatch(ActivoListActions.doFetchCurrentFilter());

      dispatch(
        layoutActions.doVisibleDrawer({
          drawerId: 'DrawerActivoFormPage',
          drawerVisible: false,
          drawerChanged: moment().unix(),
          drawerUpdateComponent: null,
        }),
      );
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: activoFormActions.UPDATE_ERROR,
      });
    }
  },

  doSaveFieldConfig: values => async (dispatch, getState) => {
    try {
      dispatch({
        type: activoFormActions.UPDATE_STARTED,
      });

      await ActivoService.saveFieldConfig(values);

      dispatch({
        type: activoFormActions.UPDATE_SUCCESS,
      });

      Message.success(i18n('entities.activo.update.success'));

      dispatch(ActivoListActions.doFetchCurrentFilter());

      dispatch(
        layoutActions.doVisibleDrawer({
          drawerId: 'DrawerActivoFormPage',
          drawerVisible: false,
          drawerChanged: moment().unix(),
          drawerUpdateComponent: null,
        }),
      );
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: activoFormActions.UPDATE_ERROR,
      });
    }
  },
};

export default activoFormActions;
