import React from 'react';
import Spinner from 'src/view/shared/Spinner';
import UbicacionFormPage from '../form/UbicacionFormPage';

function UbicacionView(props) {
  const renderView = () => {
    const {record, drawer} = props;
    return <UbicacionFormPage record={record} disabled={true} drawer={drawer} />;
  };

  const {record, loading} = props;

  if (loading || !record) {
    return <Spinner />;
  }

  return renderView();
}

export default UbicacionView;
