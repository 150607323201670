import {Close, Visibility, VisibilityOff} from '@mui/icons-material';
import {IconButton, InputAdornment, TextField} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useFormContext, useWatch} from 'react-hook-form';
import {i18n} from 'src/i18n';
import FormErrors from 'src/view/shared/form/formErrors';
import {DisabledText} from './shared/formItemWrappers';

export function InputFormItem(props) {
  const {
    readonly,
    label,
    name,
    hint,
    type,
    min,
    max,
    maxLength,
    placeholder,
    autoFocus,
    autoComplete,
    required,
    externalErrorMessage,
    disabled,
    endAdornment,
    defaultValue,
    onChange,
    onBlur,
    onClick,
    onFocus,
    disabledInput,
    fontSize,
    labelFontSize,
    lang = 'es-CL',
    variant = 'outlined',
    size = 'small',
    shrinkLabel = true,
  } = props;

  const {
    register,
    setValue,
    formState: {touchedFields, isSubmitted, errors},
    control,
  } = useFormContext();

  const [isInputFocused, setInputFocus] = useState(false);
  const handleInputFocus = ($event) => {
    setTimeout(() => {
      setInputFocus(true);
    }, 80);
    if (onFocus) onFocus($event);
  };
  const handleInputBlur = ($event) => {
    setInputFocus(false);
    if (onBlur) onBlur($event);
  };
  const handleEvent = ($event) => {
    if (isInputFocused) {
      //$event.stopPropagation();
    }
    if (onClick) onClick($event);
  };

  const inputRef = register(name, {
    required,
    min,
    max,
    maxLength,
    onChange: (event) => onChange && onChange(event.target.value),
    onBlur: handleInputBlur,
  } as any);

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const errorMessage = FormErrors.errorMessage(name, errors, touchedFields, isSubmitted, externalErrorMessage);

  const watchName: any = useWatch({name, control});
  //console.log('%c⧭', 'color: #7f7700', {watchName});
  if (disabled && !disabledInput) {
    return <DisabledText label={label} value={watchName} />;
  }

  //return <TextField {...inputRef} ref={inputRef.ref}/>;
  let inputType;
  if (type === 'password') {
    inputType = showPassword ? 'text' : 'password';
  } else {
    inputType = type;
  }

  const handleChange = (value) => {
    setValue(name, value, {shouldValidate: true});
    props.onChange && props.onChange(value);
  };

  // if (name === 'accidentabilidad') {
  //   console.log({
  //     name,
  //     value: watchName,
  //     type: type,
  //     inputType: inputType,
  //     defaultValue: defaultValue,
  //   });
  // }

  return (
    <TextField
      {...inputRef}
      style={props.style}
      ref={inputRef.ref}
      defaultValue={defaultValue}
      id={name}
      name={name}
      type={inputType}
      label={label}
      required={required}
      margin={props.margin || 'normal'}
      fullWidth
      variant={variant}
      size={size}
      placeholder={placeholder || undefined}
      autoFocus={autoFocus || undefined}
      autoComplete={autoComplete || undefined}
      InputLabelProps={{
        shrink: !!watchName || shrinkLabel,
        style: {fontSize: labelFontSize},
      }}
      error={Boolean(errorMessage)}
      helperText={errorMessage || hint}
      InputProps={{
        style: {fontSize: fontSize},
        readOnly: readonly,
        endAdornment: endAdornment ? (
          endAdornment
        ) : props.clearable ? (
          <InputAdornment position="end">
            <IconButton
              style={{borderRadius: 50}}
              onClick={($event) => {
                $event.stopPropagation();
                handleChange(null);
              }}
              onMouseDown={() => handleChange(null)}
              size="small">
              <Close fontSize="small" style={{color: 'gray'}} />
            </IconButton>
          </InputAdornment>
        ) : type === 'password' ? (
          <InputAdornment position="end">
            <IconButton
              aria-label={i18n('customValidation.password.toggleVisibility')}
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}>
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ) : undefined,
      }}
      inputProps={{
        name,
        maxLength,
        min,
        max,
        lang,
        onClick: handleEvent,
        onFocus: handleInputFocus,
      }}
      disabled={disabled}
    />
  );
}

InputFormItem.defaultProps = {
  type: 'text',
  required: false,
  clearable: false,
};

InputFormItem.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  type: PropTypes.string,
  maxLength: PropTypes.number,
  label: PropTypes.string,
  hint: PropTypes.string,
  autoFocus: PropTypes.bool,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  prefix: PropTypes.string,
  placeholder: PropTypes.string,
  autoComplete: PropTypes.string,
  externalErrorMessage: PropTypes.string,
  onChange: PropTypes.func,
  endAdornment: PropTypes.any,
  defaultValue: PropTypes.any,
  style: PropTypes.any,
  readonly: PropTypes.bool,
  clearable: PropTypes.bool,
  disabledInput: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  lang: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  fontSize: PropTypes.string,
  labelFontSize: PropTypes.string,
  shrinkLabel: PropTypes.bool,
};

export default InputFormItem;
