import ShowChartIcon from '@mui/icons-material/ShowChart';
import clsx from 'clsx';
import React, {useEffect, useLayoutEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import authSelectors from 'src/modules/auth/authSelectors';
import PermissionChecker from 'src/modules/auth/permissionChecker';
import actions from 'src/modules/layout/layoutActions';
import layoutSelectors from 'src/modules/layout/layoutSelectors';
import menus from 'src/view/menus';
//import Mail from '@mui/icons-material/Mail';

//import {StarBorder} from '@mui/icons-material';
import {ExpandLess, ExpandMore} from '@mui/icons-material';
import {Badge, Collapse, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText} from '@mui/material';
import {makeStyles} from '@mui/styles';
import FirmaService from 'src/modules/firma/firmaService';
import NotificacionService from 'src/modules/notificacion/notificacionService';
import TareaService from 'src/modules/tarea/tareaService';
import Permissions from 'src/security/permissions';
//import moment from 'moment-timezone';

const permissions = Permissions.values;
const drawerWidth = 226;

const useStyles = makeStyles((theme: any) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    overflowX: 'hidden',
    overflowY: 'hidden',
  },
  active: {
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.fontSize,
  },
  nonActive: {
    color: theme.palette.default.main,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.fontSize,
  },
  toolbarOffset: theme.mixins.toolbar,
  listItemIcon: {
    minWidth: '24px',
  },
  listItemIconActive: {
    minWidth: '24px',
    color: theme.palette.secondary.main,
  },
  listItemDisabled: {
    opacity: 0.5,
  },
}));

function Menu(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [subMenu, setSubMenu] = useState<any[]>([]);
  const [notificaciones, setNotificaciones] = useState(0);
  const [firmasPendientes, setFirmasPendientes] = useState(0);
  const [tareasPendientes, setTareasPendientes] = useState(0);

  const currentTenant = useSelector(authSelectors.selectCurrentTenant);
  const currentUser = useSelector(authSelectors.selectCurrentUser);
  const menuVisible = useSelector(layoutSelectors.selectMenuVisible);
  const settings = useSelector(authSelectors.selectCurrentSettings);
  const tenantUser = useSelector(authSelectors.selectTenantUser);

  const permissionChecker = new PermissionChecker(currentTenant, currentUser);

  let menusList = menus;

  const isLuxottica = currentTenant?.id === '66900c307b9c0b7b3e460a64';
  if (isLuxottica) {
    menusList = menusList.filter((menu) => menu.path !== '/business-intelligence/dashboard');

    const dashboardLinks = tenantUser?.dashboardLinks;
    if (dashboardLinks?.length > 0) {
      const dashboardMenus = dashboardLinks.map((dashboardLink) => ({
        path: '/dashboard/' + dashboardLink.slug,
        permissionRequired: permissions.dashboardRead,
        icon: ShowChartIcon,
        label: dashboardLink.name,
      }));
      menusList.splice(1, 0, ...dashboardMenus);
    }
  }
  //console.log('%c⧭ menusList', 'color: #731d6d', menusList);

  useEffect(() => {
    // eslint-disable-next-line

    async function init() {
      const notificaciones = await NotificacionService.list({leida: false}, 'createdAt_DESC', 0, 0);

      setNotificaciones(notificaciones.count);

      const firmasPendientes = await FirmaService.list(
        {estado: 'Pendiente', responsable: currentUser?.id},
        'createdAt_DESC',
        0,
        0,
      );
      setFirmasPendientes(firmasPendientes.count);

      const tareasPendientes = await TareaService.list(
        {estadoNin: ['FueraPlazo', 'Cerrada'], pendientes: true, ejecutor: currentUser?.id},
        'fechaLimite_ASC',
        1,
        0,
      );

      setTareasPendientes(tareasPendientes.count);
    }

    init();
  }, []);

  useLayoutEffect(() => {
    const toggleMenuOnResize = () =>
      (window as any).innerWidth < 576 ? dispatch(actions.doHideMenu()) : dispatch(actions.doShowMenu());

    const match = menusList.find((option) => {
      if (option.subMenu) {
        return option.subMenu.find((item) => {
          return props.url === item.path || props.url.startsWith(item.path + '/');
        });
      }
    });

    if (match && handleMenuOpen) handleMenuOpen(match);

    toggleMenuOnResize();

    (window as any).addEventListener('resize', toggleMenuOnResize);

    return () => (window as any).removeEventListener('resize', toggleMenuOnResize);
  }, [dispatch]);

  const selectedKeysSubMenu = (path) => {
    const url = props.url;

    const match = menusList.find((option) => {
      if (option.subMenu) {
        return option.subMenu.find((item) => {
          return (url === item.path && path === url) || url.startsWith(path + '/');
        });
      }
    });

    if (match) {
      return true;
    }

    return false;
  };

  const selectedKeys = () => {
    const url = props.url;

    const match = menusList.find((option) => {
      if (option.exact) {
        return url === option.path;
      }

      return url === option.path || url.startsWith(option.path + '/') || url.endsWith(option.path + '-0');
    });

    if (match) {
      return [match.path];
    }

    return [];
  };

  const match = (permission) => permissionChecker.match(permission);

  const lockedForCurrentPlan = (permission) => permissionChecker.lockedForCurrentPlan(permission);

  const CustomRouterLink = (props) => (
    <div style={{flexGrow: 1}}>
      <Link style={{textDecoration: 'none', color: 'inherit'}} {...props} />
    </div>
  );

  if (!menuVisible) {
    return null;
  }

  const handleMenuOpen = (menu) => {
    if (menu.subMenu) {
      if (menu.subMenu === subMenu) {
        setSubMenu([]);
      }

      if (menu.subMenu !== subMenu) {
        setSubMenu(menu.subMenu);
      }
    }
  };

  return (
    <Drawer className={classes.drawer} variant="permanent" anchor="left" open={true} classes={{paper: classes.drawerPaper}}>
      <div className={classes.toolbarOffset}></div>
      <div
        style={{
          height: '100%',
          overflowY: 'scroll',
        }}>
        <List
          style={{
            paddingTop: 14,
          }}>
          {/* <Grid item lg={12} xs={12}>
            <img
              style={{width: drawerWidth - 30, cursor: 'pointer', paddingLeft: 10, marginBottom: 10, marginTop: 10}}
              alt="complex"
              src="/images/logo.png"
            />
          </Grid> */}
          {/* <div style={{marginTop: 20, marginBottom: 20}}></div> */}
          {menusList
            .filter((menu) => match(menu.permissionRequired))
            .map((menu, index) =>
              menu.subMenu ? (
                <div key={index}>
                  {menu.subMenu.filter((item) => match(item.permissionRequired)).length > 0 && (
                    <ListItemButton onClick={() => handleMenuOpen(menu)}>
                      <ListItemText className={clsx(selectedKeys().includes(menu.path) ? [classes.active] : [classes.nonActive])}>
                        {menu.label}
                      </ListItemText>
                      {menu.subMenu ? (
                        subMenu === menu.subMenu ? (
                          <ExpandLess
                            className={clsx(selectedKeys().includes(menu.path) ? [classes.active] : [classes.nonActive])}
                          />
                        ) : (
                          <ExpandMore
                            className={clsx(selectedKeys().includes(menu.path) ? [classes.active] : [classes.nonActive])}
                          />
                        )
                      ) : null}
                    </ListItemButton>
                  )}
                  {menu.subMenu.filter((item) => match(item.permissionRequired)).length > 0 && (
                    <Collapse in={subMenu === menu.subMenu} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {subMenu.map(
                          (item) =>
                            match(item.permissionRequired) && (
                              <CustomRouterLink key={item.path} to={item.path}>
                                <ListItemButton
                                  className={clsx(selectedKeys().includes(item.path) ? [classes.active] : [classes.nonActive])}
                                  key={item.path}>
                                  <ListItemIcon>
                                    <item.icon
                                      className={clsx(
                                        selectedKeysSubMenu(item.path) ? [classes.listItemIconActive] : [classes.listItemIcon],
                                      )}
                                    />
                                  </ListItemIcon>
                                  <ListItemText
                                    className={clsx(
                                      selectedKeysSubMenu(item.path) ? [classes.listItemIconActive] : [classes.listItemIcon],
                                    )}>
                                    {item.path === '/tarea' && (
                                      <React.Fragment>
                                        {item.label}
                                        <Badge
                                          style={{marginLeft: 10, backgroundColor: 'red !important'}}
                                          badgeContent={tareasPendientes}
                                          color="error"></Badge>
                                      </React.Fragment>
                                    )}
                                    {item.path !== '/tarea' && item.label}
                                  </ListItemText>
                                </ListItemButton>
                              </CustomRouterLink>
                            ),
                        )}
                      </List>
                    </Collapse>
                  )}
                </div>
              ) : (
                <CustomRouterLink key={menu.path} to={menu.path}>
                  <ListItemButton key={index}>
                    <ListItemIcon>
                      <menu.icon
                        className={clsx(
                          selectedKeys().includes(menu.path)
                            ? [classes.listItemIcon, classes.active]
                            : [classes.listItemIcon, classes.nonActive],
                        )}
                      />
                    </ListItemIcon>
                    <ListItemText className={clsx(selectedKeys().includes(menu.path) ? [classes.active] : [classes.nonActive])}>
                      {menu.path === '/notificacion' && (
                        <React.Fragment>
                          {menu.label}{' '}
                          <Badge
                            style={{marginLeft: 10, backgroundColor: 'red !important'}}
                            badgeContent={notificaciones}
                            color="error"></Badge>
                        </React.Fragment>
                      )}
                      {menu.path === '/firma' && (
                        <React.Fragment>
                          {menu.label}{' '}
                          <Badge
                            style={{marginLeft: 10, backgroundColor: 'red !important'}}
                            badgeContent={firmasPendientes}
                            color="error"></Badge>
                        </React.Fragment>
                      )}
                      {menu.path === '/tarea' && (
                        <React.Fragment>
                          {menu.label}{' '}
                          <Badge
                            style={{marginLeft: 10, backgroundColor: 'red !important'}}
                            badgeContent={tareasPendientes}
                            color="error"></Badge>
                        </React.Fragment>
                      )}
                      {menu.path === '/pendiente' && (
                        <React.Fragment>
                          {menu.label}{' '}
                          <Badge
                            style={{marginLeft: 10, backgroundColor: 'red !important'}}
                            badgeContent={tareasPendientes}
                            color="error"></Badge>
                        </React.Fragment>
                      )}
                      {menu.path !== '/firma' &&
                        menu.path !== '/notificacion' &&
                        menu.path !== '/tarea' &&
                        menu.path !== '/pendiente' &&
                        menu.label}
                    </ListItemText>
                  </ListItemButton>
                </CustomRouterLink>
              ),
            )}
          {menusList
            .filter((menu) => lockedForCurrentPlan(menu.permissionRequired))
            .map((menu) => (
              <ListItem key={menu.path} className={classes.listItemDisabled}>
                <ListItemIcon>
                  <menu.icon className={classes.listItemIcon} />
                </ListItemIcon>{' '}
                <ListItemText>{menu.label}</ListItemText>
              </ListItem>
            ))}
        </List>
      </div>
    </Drawer>
  );
}

export default Menu;
