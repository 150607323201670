import {i18n} from 'src/i18n';
import ActivoService from 'src/modules/activo/activoService';
import exporterFields from 'src/modules/activo/list/activoListExporterFields';
import selectors from 'src/modules/activo/list/activoListSelectors';
import Errors from 'src/modules/shared/error/errors';
import Exporter from 'src/modules/shared/exporter/exporter';
import {getHistory} from 'src/modules/store';
import Message from 'src/view/shared/message';
import activoImporterActions from '../importer/activoImporterActions';

const prefix = 'ACTIVO_LIST';

const activoListActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  RESETED: `${prefix}_RESETED`,
  TOGGLE_ONE_SELECTED: `${prefix}_TOGGLE_ONE_SELECTED`,
  TOGGLE_ALL_SELECTED: `${prefix}_TOGGLE_ALL_SELECTED`,
  CLEAR_ALL_SELECTED: `${prefix}_CLEAR_ALL_SELECTED`,

  PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,
  SORTER_CHANGED: `${prefix}_SORTER_CHANGED`,

  EXPORT_STARTED: `${prefix}_EXPORT_STARTED`,
  EXPORT_SUCCESS: `${prefix}_EXPORT_SUCCESS`,
  EXPORT_ERROR: `${prefix}_EXPORT_ERROR`,

  CHANGE_ALL_STARTED: `${prefix}_CHANGE_ALL_STARTED`,
  CHANGE_ALL_SUCCESS: `${prefix}_CHANGE_ALL_SUCCESS`,
  CHANGE_ALL_ERROR: `${prefix}_CHANGE_ALL_ERROR`,

  doUpdateAllSelected: (ids, data) => async (dispatch) => {
    try {
      dispatch({
        type: activoListActions.CHANGE_ALL_STARTED,
      });

      await ActivoService.updateAll(ids, data);

      dispatch({
        type: activoListActions.CHANGE_ALL_SUCCESS,
      });

      if (activoListActions) {
        dispatch(activoListActions.doClearAllSelected());
        dispatch(activoListActions.doFetchCurrentFilter());
      }

      Message.success(i18n('entities.activo.updateAll.success'));

      getHistory().push('/activo');
    } catch (error) {
      Errors.handle(error);

      dispatch(activoListActions.doFetchCurrentFilter());

      dispatch({
        type: activoListActions.CHANGE_ALL_ERROR,
      });
    }
  },
  doClearAllSelected() {
    return {
      type: activoListActions.CLEAR_ALL_SELECTED,
    };
  },

  doToggleAllSelected() {
    return {
      type: activoListActions.TOGGLE_ALL_SELECTED,
    };
  },

  doToggleOneSelected(id) {
    return {
      type: activoListActions.TOGGLE_ONE_SELECTED,
      payload: id,
    };
  },

  doReset: () => async (dispatch) => {
    dispatch({
      type: activoListActions.RESETED,
    });

    dispatch(activoListActions.doFetch());
  },

  doExport: () => async (dispatch, getState) => {
    try {
      if (!exporterFields || !exporterFields.length) {
        throw new Error('exporterFields is required');
      }

      dispatch({
        type: activoListActions.EXPORT_STARTED,
      });

      const filter = selectors.selectFilter(getState());
      const response = await ActivoService.list(filter, selectors.selectOrderBy(getState()), null, null);

      const alwaysInclude = ['id', 'createdAt', 'updatedAt'];
      const fieldConfig = selectors.selectFieldConfig(getState())?.fields;
      const filteredFields = fieldConfig
        ? exporterFields.filter((field) => alwaysInclude.includes(field.name) || fieldConfig[field.name])
        : exporterFields;
      new Exporter(filteredFields, i18n('entities.activo.exporterFileName')).transformAndExportAsExcelFile(response.rows);

      dispatch({
        type: activoListActions.EXPORT_SUCCESS,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: activoListActions.EXPORT_ERROR,
      });
    }
  },

  doChangePagination: (pagination) => async (dispatch, getState) => {
    dispatch({
      type: activoListActions.PAGINATION_CHANGED,
      payload: pagination,
    });

    dispatch(activoListActions.doFetchCurrentFilter());
  },

  doChangeSort: (sorter) => async (dispatch, getState) => {
    dispatch({
      type: activoListActions.SORTER_CHANGED,
      payload: sorter,
    });

    dispatch(activoListActions.doFetchCurrentFilter());
  },

  doFetchCurrentFilter: () => async (dispatch, getState) => {
    const filter = selectors.selectFilter(getState());
    const rawFilter = selectors.selectRawFilter(getState());
    dispatch(activoListActions.doFetch(filter, rawFilter, true));
  },

  doFetch:
    (filter?, rawFilter?, keepPagination = false) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: activoListActions.FETCH_STARTED,
          payload: {filter, rawFilter, keepPagination},
        });

        const response = await ActivoService.list(
          filter,
          selectors.selectOrderBy(getState()),
          selectors.selectLimit(getState()),
          selectors.selectOffset(getState()),
        );
        const fieldConfig = await ActivoService.getFieldConfig();
        // const test = await ActivoService.test(); // test de disponibilidad
        // console.log('%c⧭', 'color: #eeff00', {test});
        //console.log('%c⧭', 'color: #00258c', {fieldConfig});

        dispatch(
          activoImporterActions.doPatchState({
            fieldConfig: fieldConfig,
          }),
        );
        dispatch({
          type: activoListActions.FETCH_SUCCESS,
          payload: {
            rows: response.rows,
            count: response.count,
            fieldConfig,
          },
        });
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: activoListActions.FETCH_ERROR,
        });
      }
    },
};

export default activoListActions;
