import PropTypes from 'prop-types';
import React from 'react';
import {useSelector} from 'react-redux';
import {i18n} from 'src/i18n';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';
import authSelectors from 'src/modules/auth/authSelectors';
import userFormSelectors from 'src/modules/user/form/userFormSelectors';
import selectors from 'src/modules/user/userSelectors';
import DrawerButton from 'src/view/shared/view/DrawerButton';
import UserViewPage from 'src/view/user/view/UserViewPage';
import UserEditForm from '../edit/UserEditForm';
import ChipUserView from '../view/ChipUserView';

function UserListItem(props) {
  const hasPermissionToRead = useSelector(selectors.selectPermissionToRead);

  const saveLoading = useSelector(userFormSelectors.selectSaveLoading);

  const roles = useSelector(authSelectors.selectRoles);
  const isAdmin = roles.includes('admin');

  const valueAsArray = () => {
    const {value} = props;

    if (!value) {
      return [];
    }

    if (Array.isArray(value)) {
      return value;
    }

    return [value];
  };

  const label = (record) => {
    if (!record) {
      return null;
    }

    if (!record.fullName) {
      return record.email;
    }

    return `${record.fullName} <${record.email}>`;
  };

  const tenantId = AuthCurrentTenant.get();

  const userWithRoles = (user) => {
    const roles = user.roles || user?.tenants?.find((userTenant) => userTenant.tenant === tenantId)?.roles;
    return {
      ...user,
      roles: roles,
    };
  };

  const onUserRolesUpdated = (user) => {
    if (props.onUserRolesUpdated) props.onUserRolesUpdated(user);
  };

  const userViewComponent = (user) =>
    isAdmin ? (
      <UserEditForm onSubmit={onUserRolesUpdated} saveLoading={saveLoading} user={userWithRoles(user)} drawer={true} />
    ) : (
      <UserViewPage record={user} drawer={true} />
    );

  const displayableRecord = (record) => {
    if (hasPermissionToRead && record) {
      return (
        <div key={record.id}>
          <DrawerButton
            drawerId={'DrawerUserViewPage'}
            type={props.type || 'chip'}
            size={props.size}
            entity={'user'}
            tooltipTitle={i18n(record.fullName)}
            buttonTitle={i18n(record.fullName)}
            width={window.innerWidth * 0.55}
            record={record}
            component={userViewComponent(record)}
          />
        </div>
      );
    }

    return <ChipUserView user={record} type={'chip'} size={'large'} />;
  };

  if (!valueAsArray().length) {
    return null;
  }

  return <>{valueAsArray().map((value) => displayableRecord(value))}</>;
}

UserListItem.propTypes = {
  value: PropTypes.any,
  type: PropTypes.string,
  size: PropTypes.number,
  onUserRolesUpdated: PropTypes.func,
};

export default UserListItem;
