import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/inventario/importer/inventarioImporterSelectors';
import InventarioService from 'src/modules/inventario/inventarioService';
import fields from 'src/modules/inventario/importer/inventarioImporterFields';
import { i18n } from 'src/i18n';

const inventarioImporterActions = importerActions(
  'INVENTARIO_IMPORTER',
  selectors,
  InventarioService.import,
  fields,
  i18n('entities.inventario.importer.fileName'),
);

export default inventarioImporterActions;