import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/tipoCentroCosto/importer/tipoCentroCostoImporterSelectors';
import TipoCentroCostoService from 'src/modules/tipoCentroCosto/tipoCentroCostoService';
import fields from 'src/modules/tipoCentroCosto/importer/tipoCentroCostoImporterFields';
import { i18n } from 'src/i18n';

const tipoCentroCostoImporterActions = importerActions(
  'TIPOCENTROCOSTO_IMPORTER',
  selectors,
  TipoCentroCostoService.import,
  fields,
  i18n('entities.tipoCentroCosto.importer.fileName'),
);

export default tipoCentroCostoImporterActions;