import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/funcion/importer/funcionImporterSelectors';
import FuncionService from 'src/modules/funcion/funcionService';
import fields from 'src/modules/funcion/importer/funcionImporterFields';
import { i18n } from 'src/i18n';

const funcionImporterActions = importerActions(
  'FUNCION_IMPORTER',
  selectors,
  FuncionService.import,
  fields,
  i18n('entities.funcion.importer.fileName'),
);

export default funcionImporterActions;
