import Chip from '@mui/material/Chip';
import {makeStyles} from '@mui/styles';
import React from 'react';
import {i18n} from 'src/i18n';

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  values: {
    marginLeft: 8,
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

export default function FilterPreview(props) {
  const classes = useStyles();

  const {values, renders} = props;

  const itemsNotEmpty = Object.keys(values || {})
    .map((key) => {
      return {
        label: renders[key]?.label,
        value: renders[key]?.render(values[key]),
      };
    })
    .filter((item) => item.value || item.value === 0 || item.value === false);

  if (!itemsNotEmpty.length || props.expanded) {
    return <div className={classes.root}>{props.label || i18n('common.filters')}</div>;
  }

  return (
    <div className={classes.root}>
      {props.label || i18n('common.filters')}:
      <span className={classes.values}>
        {itemsNotEmpty.map((item) => (
          <Chip key={item.label} size="small" label={`${item.label ? item.label + ':' : ''} ${item.value}`} />
        ))}
      </span>
    </div>
  );
}
