import {yupResolver} from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import {Button, Grid} from '@mui/material';
import React, {useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {i18n} from 'src/i18n';
import registroEnumerators from 'src/modules/registro/registroEnumerators';
import yupFormSchemas from 'src/modules/shared/yup/yupFormSchemas';
import CheckboxFormItem from 'src/view/shared/form/items/CheckboxFormItem';
import InputFormItem from 'src/view/shared/form/items/InputFormItem';
import SelectFormItem from 'src/view/shared/form/items/SelectFormItem';
import FormWrapper, {FormButtons} from 'src/view/shared/styles/FormWrapper';
import * as yup from 'yup';

const schema = yup.object().shape({
  descripcion: yupFormSchemas.string(i18n('entities.registro.fields.descripcion'), {
    required: true,
  }),
  categoria: yupFormSchemas.enumerator(i18n('entities.registro.fields.categoria'), {
    options: registroEnumerators.categoria,
  }),
  tipo: yupFormSchemas.enumerator(i18n('entities.registro.fields.tipo'), {
    options: registroEnumerators.tipo,
  }),
  prioridad: yupFormSchemas.enumerator(i18n('entities.registro.fields.prioridad'), {
    options: registroEnumerators.prioridad,
  }),
  afectaDisponibilidad: yupFormSchemas.boolean(i18n('entities.registro.fields.afectaDisponibilidad'), {}),
});

function DescRegistroForm(props) {
  const categoriaSeleccionada = props.categoriaSeleccionada;

  const [initialValues] = useState(() => {
    const record = props.record || {};

    return {
      descripcion: record.descripcion,
      categoria: categoriaSeleccionada || record.categoria,
      tipo: record.tipo,
      prioridad: record.prioridad,
      afectaDisponibilidad: record.afectaDisponibilidad || false,
    };
  });

  const form = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: initialValues as any,
    shouldUnregister: false,
  });

  const onSubmit = (values) => {
    props.onSubmit(props.record?.id, values);
  };

  const onReset = () => {
    Object.keys(initialValues).forEach((key) => {
      form.setValue(key, initialValues[key]);
    });
  };

  const {saveLoading, modal} = props;

  const fcategoria = form.getValues('categoria');
  console.log('%c⧭ desc form', 'color: #5200cc', {categoriaSeleccionada, fcategoria});
  return (
    <FormWrapper>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Grid spacing={2} container>
            <Grid item sm={7} xs={12}>
              <SelectFormItem
                name="categoria"
                label={i18n('entities.registro.fields.categoria')}
                options={registroEnumerators.categoria.map((value) => ({
                  value,
                  label: i18n(`entities.registro.enumerators.categoria.${value}`),
                }))}
                required={false}
                disabled={!!categoriaSeleccionada}
              />
            </Grid>
            <Grid item sm={7} xs={12}>
              <InputFormItem name="descripcion" label={i18n('entities.registro.fields.descripcion')} required={true} autoFocus />
            </Grid>
            <Grid item sm={7} xs={12}>
              <SelectFormItem
                name="tipo"
                label={i18n('entities.registro.fields.tipo')}
                options={registroEnumerators.tipo.map((value) => ({
                  value,
                  label: i18n(`entities.registro.enumerators.tipo.${value}`),
                }))}
                required={false}
                onChange={(value) => {
                  //console.log('%c⧭', 'color: #807160', {value});
                  form.setValue('afectaDisponibilidad', value === 'Detención');
                }}
              />
            </Grid>
            {form.getValues('tipo') === 'Detención' && (
              <Grid item sm={5} xs={12}>
                {/* afecta disponibilidad? */}
                <CheckboxFormItem
                  name="afectaDisponibilidad"
                  label={i18n('entities.registro.fields.afectaDisponibilidad')}
                  disabled={form.getValues('tipo') !== 'Detención'}
                />
              </Grid>
            )}
            <Grid item sm={7} xs={12}>
              <SelectFormItem
                name="prioridad"
                label={i18n('entities.registro.fields.prioridad')}
                options={registroEnumerators.prioridad.map((value) => ({
                  value,
                  label: i18n(`entities.registro.enumerators.prioridad.${value}`),
                }))}
                required={false}
              />
            </Grid>
          </Grid>
          <FormButtons
            style={{
              flexDirection: modal ? 'row-reverse' : undefined,
            }}>
            <Button
              variant="contained"
              color="primary"
              disabled={saveLoading}
              type="button"
              onClick={form.handleSubmit(onSubmit)}
              startIcon={<SaveIcon />}
              size="small">
              {i18n('common.save')}
            </Button>

            <Button disabled={saveLoading} onClick={onReset} type="button" startIcon={<UndoIcon />} size="small">
              {i18n('common.reset')}
            </Button>

            {props.onCancel ? (
              <Button
                disabled={saveLoading}
                onClick={() => props.onCancel()}
                type="button"
                startIcon={<CloseIcon />}
                size="small">
                {i18n('common.cancel')}
              </Button>
            ) : null}
          </FormButtons>
        </form>
      </FormProvider>
    </FormWrapper>
  );
}

export default DescRegistroForm;
