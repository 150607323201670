import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import {i18n} from 'src/i18n';
import moment from 'moment-timezone';

export default [
  {
    name: 'descripcion',
    label: i18n('entities.perfilEmpleado.fields.descripcion'),
    schema: schemas.string(i18n('entities.perfilEmpleado.fields.descripcion'), {
      required: true,
    }),
  },
  {
    name: 'nombre',
    label: i18n('entities.perfilEmpleado.fields.nombre'),
    schema: schemas.string(i18n('entities.perfilEmpleado.fields.nombre'), {}),
  },
  {
    name: 'apellidos',
    label: i18n('entities.perfilEmpleado.fields.apellidos'),
    schema: schemas.string(i18n('entities.perfilEmpleado.fields.apellidos'), {}),
  },
  {
    name: 'fechaNacimiento',
    label: i18n('entities.perfilEmpleado.fields.fechaNacimiento'),
    schema: schemas.date(i18n('entities.perfilEmpleado.fields.fechaNacimiento'), {}),
    render: value => (value && value instanceof Date ? moment(value).format('YYYY-MM-DD') : value),
  },
  {
    name: 'codigo',
    label: i18n('entities.perfilEmpleado.fields.codigo'),
    schema: schemas.string(i18n('entities.perfilEmpleado.fields.codigo'), {}),
  },
  {
    name: 'usuario',
    label: i18n('entities.perfilEmpleado.fields.usuario'),
    schema: schemas.relationToOne(i18n('entities.perfilEmpleado.fields.usuario'), {
      required: true,
    }),
  },
  {
    name: 'direccion',
    label: i18n('entities.perfilEmpleado.fields.direccion'),
    schema: schemas.string(i18n('entities.perfilEmpleado.fields.direccion'), {}),
  },
  {
    name: 'ciudad',
    label: i18n('entities.perfilEmpleado.fields.ciudad'),
    schema: schemas.string(i18n('entities.perfilEmpleado.fields.ciudad'), {}),
  },
  {
    name: 'region',
    label: i18n('entities.perfilEmpleado.fields.region'),
    schema: schemas.string(i18n('entities.perfilEmpleado.fields.region'), {}),
  },
  {
    name: 'pais',
    label: i18n('entities.perfilEmpleado.fields.pais'),
    schema: schemas.string(i18n('entities.perfilEmpleado.fields.pais'), {}),
  },
  {
    name: 'codigoArea',
    label: i18n('entities.perfilEmpleado.fields.codigoArea'),
    schema: schemas.string(i18n('entities.perfilEmpleado.fields.codigoArea'), {}),
  },
  {
    name: 'ubicacionGoogle',
    label: i18n('entities.perfilEmpleado.fields.ubicacionGoogle'),
    schema: schemas.string(i18n('entities.perfilEmpleado.fields.ubicacionGoogle'), {}),
  },
  {
    name: 'latitud',
    label: i18n('entities.perfilEmpleado.fields.latitud'),
    schema: schemas.decimal(i18n('entities.perfilEmpleado.fields.latitud'), {}),
  },
  {
    name: 'longitud',
    label: i18n('entities.perfilEmpleado.fields.longitud'),
    schema: schemas.decimal(i18n('entities.perfilEmpleado.fields.longitud'), {}),
  },
  {
    name: 'telefonoPrincipal',
    label: i18n('entities.perfilEmpleado.fields.telefonoPrincipal'),
    schema: schemas.integer(i18n('entities.perfilEmpleado.fields.telefonoPrincipal'), {}),
  },
  {
    name: 'telefonoSecundario',
    label: i18n('entities.perfilEmpleado.fields.telefonoSecundario'),
    schema: schemas.integer(i18n('entities.perfilEmpleado.fields.telefonoSecundario'), {}),
  },
  {
    name: 'telefonoSMS',
    label: i18n('entities.perfilEmpleado.fields.telefonoSMS'),
    schema: schemas.integer(i18n('entities.perfilEmpleado.fields.telefonoSMS'), {}),
  },
  {
    name: 'centrosCostos',
    label: i18n('entities.perfilEmpleado.fields.centrosCostos'),
    schema: schemas.relationToMany(i18n('entities.perfilEmpleado.fields.centrosCostos'), {}),
  },
  {
    name: 'firma',
    label: i18n('entities.perfilEmpleado.fields.firma'),
    schema: schemas.images(i18n('entities.perfilEmpleado.fields.firma'), {
      max: 1,
    }),
  },
  {
    name: 'idiomas',
    label: i18n('entities.perfilEmpleado.fields.idiomas'),
    schema: schemas.stringArray(i18n('entities.perfilEmpleado.fields.idiomas'), {}),
  },
  {
    name: 'documentosAdjuntos',
    label: i18n('entities.perfilEmpleado.fields.documentosAdjuntos'),
    schema: schemas.relationToMany(i18n('entities.perfilEmpleado.fields.documentosAdjuntos'), {}),
  },
  {
    name: 'habilidadesMedicion',
    label: i18n('entities.perfilEmpleado.fields.habilidadesMedicion'),
    schema: schemas.relationToMany(i18n('entities.perfilEmpleado.fields.habilidadesMedicion'), {}),
  },
];
