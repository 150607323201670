import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/presupuesto/importer/presupuestoImporterSelectors';
import PresupuestoService from 'src/modules/presupuesto/presupuestoService';
import fields from 'src/modules/presupuesto/importer/presupuestoImporterFields';
import { i18n } from 'src/i18n';

const presupuestoImporterActions = importerActions(
  'PRESUPUESTO_IMPORTER',
  selectors,
  PresupuestoService.import,
  fields,
  i18n('entities.presupuesto.importer.fileName'),
);

export default presupuestoImporterActions;