import {Button, Dialog, DialogActions, DialogTitle, Grid} from '@mui/material';
import React from 'react';
import ReactDOM from 'react-dom';

function ConfirmModal(props) {
  return ReactDOM.createPortal(
    <Dialog open={true} onClose={props.onClose} maxWidth="xs" fullWidth={true}>
      <DialogTitle>
        <span
          style={{
            fontSize: '1.3rem',
          }}>
          {props.title}
        </span>
      </DialogTitle>

      {props.heightCustomBody && props.renderCustomBody && (
        <Grid style={{height: props.heightCustomBody}}>{props.renderCustomBody}</Grid>
      )}

      <DialogActions>
        <Button
          onClick={props.onClose}
          color="primary"
          style={{
            fontSize: '0.9rem',
          }}>
          {props.cancelText}
        </Button>
        <Button
          onClick={props.onConfirm}
          color="primary"
          style={{
            fontSize: '0.9rem',
          }}
          autoFocus>
          {props.okText}
        </Button>
      </DialogActions>
    </Dialog>,
    (document as any).getElementById('modal-root'),
  );
}

export default ConfirmModal;
