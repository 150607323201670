import PlanificadorTareaService from 'src/modules/planificadorTarea/planificadorTareaService';
import PlanificadorTareaListActions from 'src/modules/planificadorTarea/list/planificadorTareaListActions';
import Errors from 'src/modules/shared/error/errors';
import Message from 'src/view/shared/message';
import {getHistory} from 'src/modules/store';
import {i18n} from 'src/i18n';
import layoutActions from 'src/modules/layout/layoutActions';
import moment from 'moment-timezone';

const prefix = 'PLANIFICADORTAREA_FORM';

const planificadorTareaFormActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  CREATE_STARTED: `${prefix}_CREATE_STARTED`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  doInit: id => async dispatch => {
    try {
      dispatch({
        type: planificadorTareaFormActions.INIT_STARTED,
      });

      let record = {};

      const isEdit = Boolean(id);

      if (isEdit) {
        record = await PlanificadorTareaService.find(id);
      }

      dispatch({
        type: planificadorTareaFormActions.INIT_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: planificadorTareaFormActions.INIT_ERROR,
      });

      getHistory().push('/planificador-tarea');
    }
  },

  doCreate: values => async dispatch => {
    try {
      dispatch({
        type: planificadorTareaFormActions.CREATE_STARTED,
      });

      await PlanificadorTareaService.create(values);

      dispatch({
        type: planificadorTareaFormActions.CREATE_SUCCESS,
      });

      Message.success(i18n('entities.planificadorTarea.create.success'));

      // dispatch(listActions.doFetchCurrentFilter());

      // getHistory().push('/planificador-tarea');

      dispatch(
        layoutActions.doVisibleDrawer({
          drawerId: 'DrawerPlanificadorTareaFormPage',
          drawerVisible: false,
          drawerChanged: moment().unix(),
          drawerUpdateComponent: 'PlanificadorTareaListFilter',
        }),
      );

      if (values.estado === 'Enviada') {
        dispatch(
          layoutActions.doVisibleDrawer({
            drawerId: 'DrawerModeloTareaListPage',
            drawerVisible: false,
            drawerChanged: moment().unix(),
            drawerUpdateComponent: null,
          }),
        );
      }
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: planificadorTareaFormActions.CREATE_ERROR,
      });
    }
  },

  doUpdate: (id, values) => async (dispatch, getState) => {
    try {
      dispatch({
        type: planificadorTareaFormActions.UPDATE_STARTED,
      });

      await PlanificadorTareaService.update(id, values);

      dispatch({
        type: planificadorTareaFormActions.UPDATE_SUCCESS,
      });

      Message.success(i18n('entities.planificadorTarea.update.success'));

      // dispatch(listActions.doFetchCurrentFilter());

      // getHistory().push('/planificador-tarea');

      dispatch(
        layoutActions.doVisibleDrawer({
          drawerId: 'DrawerPlanificadorTareaFormPage',
          drawerVisible: false,
          drawerChanged: moment().unix(),
          drawerUpdateComponent: 'PlanificadorTareaListFilter',
        }),
      );
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: planificadorTareaFormActions.UPDATE_ERROR,
      });
    }
  },
};

export default planificadorTareaFormActions;
