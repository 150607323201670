import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/pregunta/importer/preguntaImporterSelectors';
import PreguntaService from 'src/modules/pregunta/preguntaService';
import fields from 'src/modules/pregunta/importer/preguntaImporterFields';
import { i18n } from 'src/i18n';

const preguntaImporterActions = importerActions(
  'PREGUNTA_IMPORTER',
  selectors,
  PreguntaService.import,
  fields,
  i18n('entities.pregunta.importer.fileName'),
);

export default preguntaImporterActions;