import list from 'src/modules/modeloTarea/list/modeloTareaListReducers';
import form from 'src/modules/modeloTarea/form/modeloTareaFormReducers';
import view from 'src/modules/modeloTarea/view/modeloTareaViewReducers';
import destroy from 'src/modules/modeloTarea/destroy/modeloTareaDestroyReducers';
import importerReducer from 'src/modules/modeloTarea/importer/modeloTareaImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
