const terceroEnumerators = {
  tipo: [
    'Proveedor',
    'Fabricante',
    'Cliente',
    'Gestor de Reciclaje',
    'Representante de Marca',
  ],
};

export default terceroEnumerators;
