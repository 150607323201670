import list from 'src/modules/activo/list/activoListReducers';
import form from 'src/modules/activo/form/activoFormReducers';
import view from 'src/modules/activo/view/activoViewReducers';
import destroy from 'src/modules/activo/destroy/activoDestroyReducers';
import importerReducer from 'src/modules/activo/importer/activoImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
