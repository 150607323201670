import list from 'src/modules/categoria/list/categoriaListReducers';
import form from 'src/modules/categoria/form/categoriaFormReducers';
import view from 'src/modules/categoria/view/categoriaViewReducers';
import destroy from 'src/modules/categoria/destroy/categoriaDestroyReducers';
import importerReducer from 'src/modules/categoria/importer/categoriaImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
