import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/modeloTarea/importer/modeloTareaImporterSelectors';
import ModeloTareaService from 'src/modules/modeloTarea/modeloTareaService';
import fields from 'src/modules/modeloTarea/importer/modeloTareaImporterFields';
import { i18n } from 'src/i18n';

const modeloTareaImporterActions = importerActions(
  'MODELOTAREA_IMPORTER',
  selectors,
  ModeloTareaService.import,
  fields,
  i18n('entities.modeloTarea.importer.fileName'),
);

export default modeloTareaImporterActions;