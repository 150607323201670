import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/servicio/importer/servicioImporterSelectors';
import ServicioService from 'src/modules/servicio/servicioService';
import fields from 'src/modules/servicio/importer/servicioImporterFields';
import { i18n } from 'src/i18n';

const servicioImporterActions = importerActions(
  'SERVICIO_IMPORTER',
  selectors,
  ServicioService.import,
  fields,
  i18n('entities.servicio.importer.fileName'),
);

export default servicioImporterActions;