import React from 'react';
import TaskTableView from 'src/view/pendiente/view/TaskTableView';
import Spinner from 'src/view/shared/Spinner';
import DrawerButton from 'src/view/shared/view/DrawerButton';
import TareaFormPage from '../../tarea/form/TareaFormPage';

function PendienteView(props) {
  //const [open, setOpen]: any = useState(false);

  const valueAsArray = () => {
    const {record} = props;

    if (!record) {
      return [];
    }

    if (Array.isArray(record)) {
      return record;
    }

    return [record];
  };

  const {record, loading} = props;

  if (loading || !record) {
    return <Spinner />;
  }

  if (!valueAsArray().length) {
    return null;
  }

  return (
    <>
      {valueAsArray().map((value, index) => (
        <DrawerButton
          key={index}
          drawerId={'DrawerTareaFormPage'}
          width={window.innerWidth * 0.62}
          type={'table'}
          //open={!props.dontOpenRecord || value.isParentTask ? open : false}
          //onClose={() => setOpen(false)}
          component={<TareaFormPage tarea={{...value}} drawer={true} />}
          element={(drawerProps) => (
            <TaskTableView
              onClick={() => {
                if (!props.dontOpenRecord || value.isParentTask) {
                  if (drawerProps.onClick) {
                    drawerProps.onClick();
                  }
                }
              }}
              value={value}
              {...props}
            />
          )}
        />
      ))}
    </>
  );
}

export default PendienteView;
