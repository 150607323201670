import Plans from 'src/security/plans';
import Roles from 'src/security/roles';
import Storage from 'src/security/storage';

const storage = Storage.values;
const roles = Roles.values;
const plans = Plans.values;

class Permissions {
  static get values() {
    return {
      superadmin: {
        id: 'superadmin',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      dataImport: {
        id: 'dataLakeImport',
        allowedRoles: [roles.admin, roles.dataLakeAdmin, roles.dataAdmin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      dataCreate: {
        id: 'dataCreate',
        allowedRoles: [roles.admin, roles.dataLakeAdmin, roles.dataAdmin, roles.dataUser],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      dataEdit: {
        id: 'dataEdit',
        allowedRoles: [roles.admin, roles.dataLakeAdmin, roles.dataAdmin, roles.dataUser],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      dataDestroy: {
        id: 'dataDestroy',
        allowedRoles: [roles.admin, roles.dataLakeAdmin, roles.dataAdmin, roles.dataUser],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      dataRead: {
        id: 'dataRead',
        allowedRoles: [roles.admin, roles.dataLakeAdmin, roles.dataAdmin, roles.dataUser],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      dataAutocomplete: {
        id: 'dataAutocomplete',
        allowedRoles: [roles.admin, roles.dataLakeAdmin, roles.dataAdmin, roles.dataUser],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      dataLakeImport: {
        id: 'dataLakeImport',
        allowedRoles: [roles.admin, roles.dataLakeAdmin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      dataLakeCreate: {
        id: 'dataLakeCreate',
        allowedRoles: [roles.admin, roles.dataLakeAdmin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      dataLakeEdit: {
        id: 'dataLakeEdit',
        allowedRoles: [roles.admin, roles.dataLakeAdmin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      dataLakeDestroy: {
        id: 'dataLakeDestroy',
        allowedRoles: [roles.admin, roles.dataLakeAdmin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      dataLakeRead: {
        id: 'dataLakeRead',
        allowedRoles: [roles.admin, roles.dataLakeAdmin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      dataLakeAutocomplete: {
        id: 'dataLakeAutocomplete',
        allowedRoles: [roles.admin, roles.dataLakeAdmin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      dashboardRead: {
        id: 'dashboardRead',
        allowedRoles: [
          roles.admin,
          roles.administradorOperaciones,
          roles.administrador,
          roles.dataLakeAdmin,
          roles.dataAdmin,
          roles.dataUser,
          roles.dashboard,
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      kanbanRead: {
        id: 'kanbanRead',
        allowedRoles: [roles.admin, roles.administradorOperaciones, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      schedulerRead: {
        id: 'schedulerRead',
        allowedRoles: [roles.admin, roles.administradorOperaciones, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      ganttRead: {
        id: 'ganttRead',
        allowedRoles: [roles.admin, roles.administradorOperaciones, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      calendarioRead: {
        id: 'calendarioRead',
        allowedRoles: [roles.admin, roles.administradorOperaciones, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      tenantCreate: {
        id: 'tenantCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      tenantRead: {
        id: 'tenantRead',
        allowedRoles: [roles.admin, roles.cambiarEmpresa],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      tenantEdit: {
        id: 'tenantEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      tenantDestroy: {
        id: 'tenantDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      planEdit: {
        id: 'planEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      planRead: {
        id: 'planRead',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      userEdit: {
        id: 'userEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      userDestroy: {
        id: 'userDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      userCreate: {
        id: 'userCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      userImport: {
        id: 'userImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      userRead: {
        id: 'userRead',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      userAutocomplete: {
        id: 'userAutocomplete',
        allowedRoles: [
          roles.admin,
          roles.administrador,
          roles.administradorOperaciones,
          roles.administradorRecursosHumanos,
          roles.administradorInventario,
          roles.administradorCatalogo,
          roles.administradorSolicitudes,
          roles.planificadorOperaciones,
          roles.planificadorProcedimientos,
          roles.aceptadorPlanificaciones,
          roles.validadorPlanificaciones,
          roles.ejecutor,
          roles.signingUser,
          roles.controlInicioActividad,
          roles.editorProgreso,
          roles.ejecutorProgreso,
          roles.reasignadorDeTareas,
          roles.kanbanAdmin,
          roles.visualizadorDeActividades,
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      auditLogRead: {
        id: 'auditLogRead',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      settingsEdit: {
        id: 'settingsEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.settingsBackgroundImages, storage.settingsLogos],
      },
      productoImport: {
        id: 'productoImport',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      productoCreate: {
        id: 'productoCreate',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.productoFoto],
      },
      productoEdit: {
        id: 'productoEdit',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.productoFoto],
      },
      productoDestroy: {
        id: 'productoDestroy',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.productoFoto],
      },
      productoRead: {
        id: 'productoRead',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      productoAutocomplete: {
        id: 'productoAutocomplete',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      sucursalImport: {
        id: 'sucursalImport',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones, roles.administradorRecursosHumanos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      sucursalCreate: {
        id: 'sucursalCreate',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones, roles.administradorRecursosHumanos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      sucursalEdit: {
        id: 'sucursalEdit',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones, roles.administradorRecursosHumanos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      sucursalDestroy: {
        id: 'sucursalDestroy',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones, roles.administradorRecursosHumanos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      sucursalRead: {
        id: 'sucursalRead',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones, roles.administradorRecursosHumanos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      sucursalAutocomplete: {
        id: 'sucursalAutocomplete',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones, roles.administradorRecursosHumanos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      formularioImport: {
        id: 'formularioImport',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      formularioCreate: {
        id: 'formularioCreate',
        allowedRoles: [],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.formularioFoto, storage.formularioDocumento],
      },
      formularioEdit: {
        id: 'formularioEdit',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.formularioFoto, storage.formularioDocumento],
      },
      formularioDestroy: {
        id: 'formularioDestroy',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.formularioFoto, storage.formularioDocumento],
      },
      formularioRead: {
        id: 'formularioRead',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      formularioAutocomplete: {
        id: 'formularioAutocomplete',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      preguntaImport: {
        id: 'preguntaImport',
        allowedRoles: [roles.admin, roles.administrador, roles.planificadorProcedimientos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      preguntaCreate: {
        id: 'preguntaCreate',
        allowedRoles: [roles.admin, roles.administrador, roles.planificadorProcedimientos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.preguntaFoto],
      },
      preguntaEdit: {
        id: 'preguntaEdit',
        allowedRoles: [roles.admin, roles.administrador, roles.planificadorProcedimientos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.preguntaFoto],
      },
      preguntaDestroy: {
        id: 'preguntaDestroy',
        allowedRoles: [roles.admin, roles.administrador, roles.planificadorProcedimientos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.preguntaFoto],
      },
      preguntaRead: {
        id: 'preguntaRead',
        allowedRoles: [roles.admin, roles.administrador, roles.planificadorProcedimientos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      preguntaAutocomplete: {
        id: 'preguntaAutocomplete',
        allowedRoles: [roles.admin, roles.administrador, roles.planificadorProcedimientos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      respuestaImport: {
        id: 'respuestaImport',
        allowedRoles: [roles.admin, roles.administrador, roles.planificadorProcedimientos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      respuestaCreate: {
        id: 'respuestaCreate',
        allowedRoles: [roles.admin, roles.administrador, roles.planificadorProcedimientos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      respuestaEdit: {
        id: 'respuestaEdit',
        allowedRoles: [roles.admin, roles.administrador, roles.planificadorProcedimientos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      respuestaDestroy: {
        id: 'respuestaDestroy',
        allowedRoles: [roles.admin, roles.administrador, roles.planificadorProcedimientos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      respuestaRead: {
        id: 'respuestaRead',
        allowedRoles: [roles.admin, roles.administrador, roles.planificadorProcedimientos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      respuestaAutocomplete: {
        id: 'respuestaAutocomplete',
        allowedRoles: [roles.admin, roles.administrador, roles.planificadorProcedimientos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      modeloFormularioImport: {
        id: 'modeloFormularioImport',
        allowedRoles: [roles.admin, roles.administrador, roles.planificadorProcedimientos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      modeloFormularioCreate: {
        id: 'modeloFormularioCreate',
        allowedRoles: [roles.admin, roles.administrador, roles.planificadorProcedimientos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      modeloFormularioEdit: {
        id: 'modeloFormularioEdit',
        allowedRoles: [roles.admin, roles.administrador, roles.planificadorProcedimientos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      modeloFormularioDestroy: {
        id: 'modeloFormularioDestroy',
        allowedRoles: [roles.admin, roles.administrador, roles.planificadorProcedimientos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      modeloFormularioRead: {
        id: 'modeloFormularioRead',
        allowedRoles: [roles.admin, roles.administrador, roles.planificadorProcedimientos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      modeloFormularioAutocomplete: {
        id: 'modeloFormularioAutocomplete',
        allowedRoles: [
          roles.admin,
          roles.administrador,
          roles.administradorOperaciones,
          roles.administradorRecursosHumanos,
          roles.planificadorOperaciones,
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      rubroImport: {
        id: 'rubroImport',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      rubroCreate: {
        id: 'rubroCreate',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      rubroEdit: {
        id: 'rubroEdit',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      rubroDestroy: {
        id: 'rubroDestroy',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      rubroRead: {
        id: 'rubroRead',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      rubroAutocomplete: {
        id: 'rubroAutocomplete',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      facturaImport: {
        id: 'facturaImport',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      facturaCreate: {
        id: 'facturaCreate',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.facturaFactura, storage.facturaComprobanteTransferencia],
      },
      facturaEdit: {
        id: 'facturaEdit',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.facturaFactura, storage.facturaComprobanteTransferencia],
      },
      facturaDestroy: {
        id: 'facturaDestroy',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.facturaFactura, storage.facturaComprobanteTransferencia],
      },
      facturaRead: {
        id: 'facturaRead',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      facturaAutocomplete: {
        id: 'facturaAutocomplete',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      segmentacionImport: {
        id: 'segmentacionImport',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      segmentacionCreate: {
        id: 'segmentacionCreate',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      segmentacionEdit: {
        id: 'segmentacionEdit',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      segmentacionDestroy: {
        id: 'segmentacionDestroy',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      segmentacionRead: {
        id: 'segmentacionRead',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      segmentacionAutocomplete: {
        id: 'segmentacionAutocomplete',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      divisionImport: {
        id: 'divisionImport',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      divisionCreate: {
        id: 'divisionCreate',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      divisionEdit: {
        id: 'divisionEdit',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      divisionDestroy: {
        id: 'divisionDestroy',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      divisionRead: {
        id: 'divisionRead',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      divisionAutocomplete: {
        id: 'divisionAutocomplete',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      paisImport: {
        id: 'paisImport',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      paisCreate: {
        id: 'paisCreate',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.paisFoto],
      },
      paisEdit: {
        id: 'paisEdit',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.paisFoto],
      },
      paisDestroy: {
        id: 'paisDestroy',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.paisFoto],
      },
      paisRead: {
        id: 'paisRead',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      paisAutocomplete: {
        id: 'paisAutocomplete',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo, roles.planificadorProcedimientos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      puestoImport: {
        id: 'puestoImport',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorRecursosHumanos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      puestoCreate: {
        id: 'puestoCreate',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorRecursosHumanos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      puestoEdit: {
        id: 'puestoEdit',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorRecursosHumanos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      puestoDestroy: {
        id: 'puestoDestroy',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorRecursosHumanos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      puestoRead: {
        id: 'puestoRead',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorRecursosHumanos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      puestoAutocomplete: {
        id: 'puestoAutocomplete',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorRecursosHumanos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      departamentoImport: {
        id: 'departamentoImport',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorRecursosHumanos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      departamentoCreate: {
        id: 'departamentoCreate',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorRecursosHumanos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      departamentoEdit: {
        id: 'departamentoEdit',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorRecursosHumanos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      departamentoDestroy: {
        id: 'departamentoDestroy',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorRecursosHumanos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      departamentoRead: {
        id: 'departamentoRead',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorRecursosHumanos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      departamentoAutocomplete: {
        id: 'departamentoAutocomplete',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorRecursosHumanos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      nivelPuestoImport: {
        id: 'nivelPuestoImport',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorRecursosHumanos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      nivelPuestoCreate: {
        id: 'nivelPuestoCreate',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorRecursosHumanos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      nivelPuestoEdit: {
        id: 'nivelPuestoEdit',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorRecursosHumanos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      nivelPuestoDestroy: {
        id: 'nivelPuestoDestroy',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorRecursosHumanos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      nivelPuestoRead: {
        id: 'nivelPuestoRead',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorRecursosHumanos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      nivelPuestoAutocomplete: {
        id: 'nivelPuestoAutocomplete',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorRecursosHumanos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      planificadorFormularioImport: {
        id: 'planificadorFormularioImport',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      planificadorFormularioCreate: {
        id: 'planificadorFormularioCreate',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      planificadorFormularioEdit: {
        id: 'planificadorFormularioEdit',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      planificadorFormularioDestroy: {
        id: 'planificadorFormularioDestroy',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      planificadorFormularioRead: {
        id: 'planificadorFormularioRead',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      planificadorFormularioAutocomplete: {
        id: 'planificadorFormularioAutocomplete',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      configuracionNotificacionImport: {
        id: 'configuracionNotificacionImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      configuracionNotificacionCreate: {
        id: 'configuracionNotificacionCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      configuracionNotificacionEdit: {
        id: 'configuracionNotificacionEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      configuracionNotificacionDestroy: {
        id: 'configuracionNotificacionDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      configuracionNotificacionRead: {
        id: 'configuracionNotificacionRead',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      configuracionNotificacionAutocomplete: {
        id: 'configuracionNotificacionAutocomplete',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      objetivoImport: {
        id: 'objetivoImport',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones, roles.planificadorOperaciones],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      objetivoCreate: {
        id: 'objetivoCreate',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones, roles.planificadorOperaciones],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      objetivoEdit: {
        id: 'objetivoEdit',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones, roles.planificadorOperaciones],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      objetivoDestroy: {
        id: 'objetivoDestroy',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones, roles.planificadorOperaciones],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      objetivoRead: {
        id: 'objetivoRead',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones, roles.planificadorOperaciones],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      objetivoAutocomplete: {
        id: 'objetivoAutocomplete',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones, roles.planificadorOperaciones],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      modeloTareaImport: {
        id: 'modeloTareaImport',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones, roles.planificadorOperaciones],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      modeloTareaCreate: {
        id: 'modeloTareaCreate',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones, roles.planificadorOperaciones],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.modeloTareaDocumentosAdjuntos, storage.modeloTareaFotosAdjuntas],
      },
      modeloTareaEdit: {
        id: 'modeloTareaEdit',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones, roles.planificadorOperaciones],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.modeloTareaDocumentosAdjuntos, storage.modeloTareaFotosAdjuntas],
      },
      modeloTareaDestroy: {
        id: 'modeloTareaDestroy',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones, roles.planificadorOperaciones],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.modeloTareaDocumentosAdjuntos, storage.modeloTareaFotosAdjuntas],
      },
      modeloTareaRead: {
        id: 'modeloTareaRead',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones, roles.planificadorOperaciones],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      modeloTareaAutocomplete: {
        id: 'modeloTareaAutocomplete',
        allowedRoles: [
          roles.admin,
          roles.administrador,
          roles.administradorOperaciones,
          roles.planificadorOperaciones,
          roles.visualizadorDeActividades,
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      subTareaImport: {
        id: 'subTareaImport',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones, roles.planificadorOperaciones],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      subTareaCreate: {
        id: 'subTareaCreate',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones, roles.planificadorOperaciones],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      subTareaEdit: {
        id: 'subTareaEdit',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones, roles.planificadorOperaciones],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      subTareaDestroy: {
        id: 'subTareaDestroy',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones, roles.planificadorOperaciones],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      subTareaRead: {
        id: 'subTareaRead',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones, roles.planificadorOperaciones],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      subTareaAutocomplete: {
        id: 'subTareaAutocomplete',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones, roles.planificadorOperaciones],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      planificadorTareaImport: {
        id: 'planificadorTareaImport',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones, roles.planificadorOperaciones],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      planificadorTareaCreate: {
        id: 'planificadorTareaCreate',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones, roles.planificadorOperaciones],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      planificadorTareaEdit: {
        id: 'planificadorTareaEdit',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones, roles.planificadorOperaciones],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      planificadorTareaDestroy: {
        id: 'planificadorTareaDestroy',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      planificadorTareaRead: {
        id: 'planificadorTareaRead',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones, roles.planificadorOperaciones],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      planificadorTareaAutocomplete: {
        id: 'planificadorTareaAutocomplete',
        allowedRoles: [
          roles.admin,
          roles.administrador,
          roles.administradorOperaciones,
          roles.planificadorOperaciones,
          roles.visualizadorDeActividades,
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      categoriaImport: {
        id: 'categoriaImport',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      categoriaCreate: {
        id: 'categoriaCreate',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.categoriaAvatar, storage.categoriaPortada],
      },
      categoriaEdit: {
        id: 'categoriaEdit',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.categoriaAvatar, storage.categoriaPortada],
      },
      categoriaDestroy: {
        id: 'categoriaDestroy',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.categoriaAvatar, storage.categoriaPortada],
      },
      categoriaRead: {
        id: 'categoriaRead',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      categoriaAutocomplete: {
        id: 'categoriaAutocomplete',
        allowedRoles: [roles.admin, roles.administrador, roles.planificadorProcedimientos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      habilidadImport: {
        id: 'habilidadImport',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones, roles.administradorRecursosHumanos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      habilidadCreate: {
        id: 'habilidadCreate',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones, roles.administradorRecursosHumanos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      habilidadEdit: {
        id: 'habilidadEdit',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones, roles.administradorRecursosHumanos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      habilidadDestroy: {
        id: 'habilidadDestroy',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      habilidadRead: {
        id: 'habilidadRead',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones, roles.administradorRecursosHumanos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      habilidadAutocomplete: {
        id: 'habilidadAutocomplete',
        allowedRoles: [
          roles.admin,
          roles.administrador,
          roles.administradorOperaciones,
          roles.administradorRecursosHumanos,
          roles.planificadorProcedimientos,
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      absenceImport: {
        id: 'absenceImport',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorIncidentes, roles.administradorAusencias],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      absenceCreate: {
        id: 'absenceCreate',
        allowedRoles: [
          roles.admin,
          roles.administrador,
          roles.administradorIncidentes,
          roles.administradorAusencias,
          roles.ejecutorIncidentes,
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.absenceAttachedFiles],
      },
      absenceEdit: {
        id: 'absenceEdit',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorIncidentes, roles.administradorAusencias],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.absenceAttachedFiles],
      },
      absenceDestroy: {
        id: 'absenceDestroy',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorIncidentes, roles.administradorAusencias],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.absenceAttachedFiles],
      },
      absenceRead: {
        id: 'absenceRead',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      absenceAutocomplete: {
        id: 'absenceAutocomplete',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorIncidentes, roles.administradorAusencias],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      incidentImport: {
        id: 'incidentImport',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorIncidentes],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      incidentCreate: {
        id: 'incidentCreate',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorIncidentes, roles.ejecutorIncidentes],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.incidentAttachedFiles, storage.absenceAttachedFiles],
      },
      incidentEdit: {
        id: 'incidentEdit',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorIncidentes, roles.ejecutorIncidentes],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.incidentAttachedFiles, storage.absenceAttachedFiles],
      },
      incidentDestroy: {
        id: 'incidentDestroy',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorIncidentes],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.incidentAttachedFiles, storage.absenceAttachedFiles],
      },
      incidentRead: {
        id: 'incidentRead',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorIncidentes, roles.ejecutorIncidentes],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      incidentAutocomplete: {
        id: 'incidentAutocomplete',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorIncidentes, roles.ejecutorIncidentes],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      tareaImport: {
        id: 'tareaImport',
        allowedRoles: [],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      tareaCreate: {
        id: 'tareaCreate',
        allowedRoles: [
          roles.admin,
          roles.administrador,
          roles.administradorOperaciones,
          roles.administradorRecursosHumanos,
          roles.administradorInventario,
          roles.administradorCatalogo,
          roles.administradorSolicitudes,
          roles.planificadorOperaciones,
          roles.planificadorProcedimientos,
          roles.aceptadorPlanificaciones,
          roles.validadorPlanificaciones,
          roles.ejecutor,
          roles.controlInicioActividad,
          roles.editorProgreso,
          roles.ejecutorProgreso,
          roles.reasignadorDeTareas,
          roles.kanbanAdmin,
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.tareaFotosEnviadas,
          storage.tareaDocumentosEnviados,
          storage.tareaFotosAdjuntas,
          storage.tareaDocumentosAdjuntos,
        ],
      },
      tareaKanban: {
        id: 'tareaKanban',
        allowedRoles: [roles.kanbanAdmin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.tareaFotosEnviadas,
          storage.tareaDocumentosEnviados,
          storage.tareaFotosAdjuntas,
          storage.tareaDocumentosAdjuntos,
        ],
      },
      tareaEditClosed: {
        id: 'taskEdit',
        allowedRoles: [roles.taskEdit],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.tareaFotosEnviadas,
          storage.tareaDocumentosEnviados,
          storage.tareaFotosAdjuntas,
          storage.tareaDocumentosAdjuntos,
        ],
      },
      tareaReassign: {
        id: 'tareaReassign',
        allowedRoles: [roles.reasignadorDeTareas],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.tareaFotosEnviadas,
          storage.tareaDocumentosEnviados,
          storage.tareaFotosAdjuntas,
          storage.tareaDocumentosAdjuntos,
        ],
      },
      tareaEdit: {
        id: 'tareaEdit',
        allowedRoles: [
          roles.admin,
          roles.administrador,
          roles.administradorOperaciones,
          roles.administradorRecursosHumanos,
          roles.administradorInventario,
          roles.administradorCatalogo,
          roles.administradorSolicitudes,
          roles.planificadorOperaciones,
          roles.planificadorProcedimientos,
          roles.aceptadorPlanificaciones,
          roles.validadorPlanificaciones,
          roles.ejecutor,
          roles.controlInicioActividad,
          roles.editorProgreso,
          roles.ejecutorProgreso,
          roles.reasignadorDeTareas,
          roles.kanbanAdmin,
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.tareaFotosEnviadas,
          storage.tareaDocumentosEnviados,
          storage.tareaFotosAdjuntas,
          storage.tareaDocumentosAdjuntos,
        ],
      },
      tareaDestroy: {
        id: 'tareaDestroy',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.tareaFotosEnviadas,
          storage.tareaDocumentosEnviados,
          storage.tareaFotosAdjuntas,
          storage.tareaDocumentosAdjuntos,
        ],
      },
      tareaRead: {
        id: 'tareaRead',
        allowedRoles: [
          roles.admin,
          roles.administrador,
          roles.administradorOperaciones,
          roles.administradorRecursosHumanos,
          roles.administradorInventario,
          roles.administradorCatalogo,
          roles.administradorSolicitudes,
          roles.planificadorOperaciones,
          roles.planificadorProcedimientos,
          roles.aceptadorPlanificaciones,
          roles.validadorPlanificaciones,
          roles.ejecutor,
          //roles.signingUser,
          roles.controlInicioActividad,
          roles.editorProgreso,
          roles.ejecutorProgreso,
          roles.reasignadorDeTareas,
          roles.kanbanAdmin,
          roles.visualizadorDeActividades,
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      tareaAutocomplete: {
        id: 'tareaAutocomplete',
        allowedRoles: [
          roles.admin,
          roles.administrador,
          roles.administradorOperaciones,
          roles.administradorRecursosHumanos,
          roles.administradorInventario,
          roles.administradorCatalogo,
          roles.administradorSolicitudes,
          roles.planificadorOperaciones,
          roles.planificadorProcedimientos,
          roles.aceptadorPlanificaciones,
          roles.validadorPlanificaciones,
          roles.ejecutor,
          roles.signingUser,
          roles.controlInicioActividad,
          roles.editorProgreso,
          roles.ejecutorProgreso,
          roles.reasignadorDeTareas,
          roles.kanbanAdmin,
          roles.visualizadorDeActividades,
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      pendienteImport: {
        id: 'pendienteImport',
        allowedRoles: [],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      pendienteCreate: {
        id: 'pendienteCreate',
        allowedRoles: [],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      pendienteEdit: {
        id: 'pendienteEdit',
        allowedRoles: [],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      pendienteDestroy: {
        id: 'pendienteDestroy',
        allowedRoles: [],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      pendienteRead: {
        id: 'pendienteRead',
        allowedRoles: [
          roles.admin,
          roles.administrador,
          roles.administradorOperaciones,
          roles.administradorRecursosHumanos,
          roles.administradorInventario,
          roles.administradorCatalogo,
          roles.administradorSolicitudes,
          roles.planificadorOperaciones,
          roles.planificadorProcedimientos,
          roles.aceptadorPlanificaciones,
          roles.validadorPlanificaciones,
          roles.ejecutor,
          roles.controlInicioActividad,
          roles.editorProgreso,
          roles.ejecutorProgreso,
          roles.reasignadorDeTareas,
          roles.kanbanAdmin,
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      pendienteAutocomplete: {
        id: 'pendienteAutocomplete',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      comunicacionInternaImport: {
        id: 'comunicacionInternaImport',
        allowedRoles: [
          roles.admin,
          roles.administrador,
          roles.administradorOperaciones,
          roles.administradorRecursosHumanos,
          roles.administradorInventario,
          roles.administradorCatalogo,
          roles.administradorSolicitudes,
          roles.planificadorOperaciones,
          roles.planificadorProcedimientos,
          roles.aceptadorPlanificaciones,
          roles.validadorPlanificaciones,
          roles.ejecutor,
          roles.controlInicioActividad,
          roles.editorProgreso,
          roles.ejecutorProgreso,
          roles.reasignadorDeTareas,
          roles.kanbanAdmin,
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      comunicacionInternaCreate: {
        id: 'comunicacionInternaCreate',
        allowedRoles: [
          roles.admin,
          roles.administrador,
          roles.administradorOperaciones,
          roles.administradorRecursosHumanos,
          roles.administradorInventario,
          roles.administradorCatalogo,
          roles.administradorSolicitudes,
          roles.planificadorOperaciones,
          roles.planificadorProcedimientos,
          roles.aceptadorPlanificaciones,
          roles.validadorPlanificaciones,
          roles.ejecutor,
          roles.signingUser,
          roles.controlInicioActividad,
          roles.editorProgreso,
          roles.ejecutorProgreso,
          roles.reasignadorDeTareas,
          roles.kanbanAdmin,
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.comunicacionInternaFotosAdjunta, storage.comunicacionInternaDocumentosAdjuntos],
      },
      comunicacionInternaEdit: {
        id: 'comunicacionInternaEdit',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.comunicacionInternaFotosAdjunta, storage.comunicacionInternaDocumentosAdjuntos],
      },
      comunicacionInternaDestroy: {
        id: 'comunicacionInternaDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.comunicacionInternaFotosAdjunta, storage.comunicacionInternaDocumentosAdjuntos],
      },
      comunicacionInternaRead: {
        id: 'comunicacionInternaRead',
        allowedRoles: [
          roles.admin,
          roles.administrador,
          roles.administradorOperaciones,
          roles.administradorRecursosHumanos,
          roles.administradorInventario,
          roles.administradorCatalogo,
          roles.administradorSolicitudes,
          roles.planificadorOperaciones,
          roles.planificadorProcedimientos,
          roles.aceptadorPlanificaciones,
          roles.validadorPlanificaciones,
          roles.ejecutor,
          roles.signingUser,
          roles.controlInicioActividad,
          roles.editorProgreso,
          roles.ejecutorProgreso,
          roles.reasignadorDeTareas,
          roles.kanbanAdmin,
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      comunicacionInternaAutocomplete: {
        id: 'comunicacionInternaAutocomplete',
        allowedRoles: [
          roles.admin,
          roles.administrador,
          roles.administradorOperaciones,
          roles.administradorRecursosHumanos,
          roles.administradorInventario,
          roles.administradorCatalogo,
          roles.administradorSolicitudes,
          roles.planificadorOperaciones,
          roles.planificadorProcedimientos,
          roles.aceptadorPlanificaciones,
          roles.validadorPlanificaciones,
          roles.ejecutor,
          roles.signingUser,
          roles.controlInicioActividad,
          roles.editorProgreso,
          roles.ejecutorProgreso,
          roles.reasignadorDeTareas,
          roles.kanbanAdmin,
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      temaComunicacionImport: {
        id: 'temaComunicacionImport',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      temaComunicacionCreate: {
        id: 'temaComunicacionCreate',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      temaComunicacionEdit: {
        id: 'temaComunicacionEdit',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      temaComunicacionDestroy: {
        id: 'temaComunicacionDestroy',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      temaComunicacionRead: {
        id: 'temaComunicacionRead',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      temaComunicacionAutocomplete: {
        id: 'temaComunicacionAutocomplete',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      nivelPrioridadImport: {
        id: 'nivelPrioridadImport',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      nivelPrioridadCreate: {
        id: 'nivelPrioridadCreate',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      nivelPrioridadEdit: {
        id: 'nivelPrioridadEdit',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      nivelPrioridadDestroy: {
        id: 'nivelPrioridadDestroy',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      nivelPrioridadRead: {
        id: 'nivelPrioridadRead',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      nivelPrioridadAutocomplete: {
        id: 'nivelPrioridadAutocomplete',
        allowedRoles: [
          roles.admin,
          roles.administrador,
          roles.administradorOperaciones,
          roles.administradorRecursosHumanos,
          roles.administradorInventario,
          roles.administradorCatalogo,
          roles.administradorSolicitudes,
          roles.planificadorOperaciones,
          roles.planificadorProcedimientos,
          roles.aceptadorPlanificaciones,
          roles.validadorPlanificaciones,
          roles.ejecutor,
          roles.controlInicioActividad,
          roles.editorProgreso,
          roles.ejecutorProgreso,
          roles.reasignadorDeTareas,
          roles.kanbanAdmin,
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      mensajeInternoImport: {
        id: 'mensajeInternoImport',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      mensajeInternoCreate: {
        id: 'mensajeInternoCreate',
        allowedRoles: [
          roles.admin,
          roles.administrador,
          roles.administradorOperaciones,
          roles.administradorRecursosHumanos,
          roles.administradorInventario,
          roles.administradorCatalogo,
          roles.administradorSolicitudes,
          roles.planificadorOperaciones,
          roles.planificadorProcedimientos,
          roles.aceptadorPlanificaciones,
          roles.validadorPlanificaciones,
          roles.ejecutor,
          roles.signingUser,
          roles.controlInicioActividad,
          roles.editorProgreso,
          roles.ejecutorProgreso,
          roles.reasignadorDeTareas,
          roles.kanbanAdmin,
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.mensajeInternoFotosAdjunta, storage.mensajeInternoDocumentosAdjuntos],
      },
      mensajeInternoEdit: {
        id: 'mensajeInternoEdit',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.mensajeInternoFotosAdjunta, storage.mensajeInternoDocumentosAdjuntos],
      },
      mensajeInternoDestroy: {
        id: 'mensajeInternoDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.mensajeInternoFotosAdjunta, storage.mensajeInternoDocumentosAdjuntos],
      },
      mensajeInternoRead: {
        id: 'mensajeInternoRead',
        allowedRoles: [
          roles.admin,
          roles.administrador,
          roles.administradorOperaciones,
          roles.administradorRecursosHumanos,
          roles.administradorInventario,
          roles.administradorCatalogo,
          roles.administradorSolicitudes,
          roles.planificadorOperaciones,
          roles.planificadorProcedimientos,
          roles.aceptadorPlanificaciones,
          roles.validadorPlanificaciones,
          roles.ejecutor,
          roles.signingUser,
          roles.controlInicioActividad,
          roles.editorProgreso,
          roles.ejecutorProgreso,
          roles.reasignadorDeTareas,
          roles.kanbanAdmin,
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      mensajeInternoAutocomplete: {
        id: 'mensajeInternoAutocomplete',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      eventoImport: {
        id: 'eventoImport',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      eventoCreate: {
        id: 'eventoCreate',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.eventoFotos],
      },
      eventoEdit: {
        id: 'eventoEdit',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.eventoFotos],
      },
      eventoDestroy: {
        id: 'eventoDestroy',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.eventoFotos],
      },
      eventoRead: {
        id: 'eventoRead',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      eventoAutocomplete: {
        id: 'eventoAutocomplete',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      recordatorioImport: {
        id: 'recordatorioImport',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      recordatorioCreate: {
        id: 'recordatorioCreate',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      recordatorioEdit: {
        id: 'recordatorioEdit',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      recordatorioDestroy: {
        id: 'recordatorioDestroy',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      recordatorioRead: {
        id: 'recordatorioRead',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      recordatorioAutocomplete: {
        id: 'recordatorioAutocomplete',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      notificacionImport: {
        id: 'notificacionImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      notificacionCreate: {
        id: 'notificacionCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.notificacionFoto],
      },
      notificacionEdit: {
        id: 'notificacionEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.notificacionFoto],
      },
      notificacionDestroy: {
        id: 'notificacionDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.notificacionFoto],
      },
      notificacionRead: {
        id: 'notificacionRead',
        allowedRoles: [
          roles.admin,
          roles.administrador,
          roles.administradorOperaciones,
          roles.administradorRecursosHumanos,
          roles.administradorInventario,
          roles.administradorCatalogo,
          roles.administradorSolicitudes,
          roles.planificadorOperaciones,
          roles.planificadorProcedimientos,
          roles.aceptadorPlanificaciones,
          roles.validadorPlanificaciones,
          roles.ejecutor,
          roles.signingUser,
          roles.controlInicioActividad,
          roles.editorProgreso,
          roles.ejecutorProgreso,
          roles.reasignadorDeTareas,
          roles.kanbanAdmin,
          roles.dashboard,
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      notificacionAutocomplete: {
        id: 'notificacionAutocomplete',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      categoriaProductoImport: {
        id: 'categoriaProductoImport',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      categoriaProductoCreate: {
        id: 'categoriaProductoCreate',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      categoriaProductoEdit: {
        id: 'categoriaProductoEdit',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      categoriaProductoDestroy: {
        id: 'categoriaProductoDestroy',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      categoriaProductoRead: {
        id: 'categoriaProductoRead',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      categoriaProductoAutocomplete: {
        id: 'categoriaProductoAutocomplete',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      asignadorFuncionImport: {
        id: 'asignadorFuncionImport',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones, roles.administradorRecursosHumanos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      asignadorFuncionCreate: {
        id: 'asignadorFuncionCreate',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones, roles.administradorRecursosHumanos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      asignadorFuncionEdit: {
        id: 'asignadorFuncionEdit',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones, roles.administradorRecursosHumanos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      asignadorFuncionDestroy: {
        id: 'asignadorFuncionDestroy',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones, roles.administradorRecursosHumanos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      asignadorFuncionRead: {
        id: 'asignadorFuncionRead',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones, roles.administradorRecursosHumanos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      asignadorFuncionAutocomplete: {
        id: 'asignadorFuncionAutocomplete',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones, roles.administradorRecursosHumanos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      funcionImport: {
        id: 'funcionImport',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones, roles.administradorRecursosHumanos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      funcionCreate: {
        id: 'funcionCreate',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones, roles.administradorRecursosHumanos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      funcionEdit: {
        id: 'funcionEdit',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones, roles.administradorRecursosHumanos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      funcionDestroy: {
        id: 'funcionDestroy',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones, roles.administradorRecursosHumanos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      funcionRead: {
        id: 'funcionRead',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones, roles.administradorRecursosHumanos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      funcionAutocomplete: {
        id: 'funcionAutocomplete',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones, roles.administradorRecursosHumanos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      categoriaFormularioImport: {
        id: 'categoriaFormularioImport',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      categoriaFormularioCreate: {
        id: 'categoriaFormularioCreate',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      categoriaFormularioEdit: {
        id: 'categoriaFormularioEdit',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      categoriaFormularioDestroy: {
        id: 'categoriaFormularioDestroy',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      categoriaFormularioRead: {
        id: 'categoriaFormularioRead',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      categoriaFormularioAutocomplete: {
        id: 'categoriaFormularioAutocomplete',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      //Stem
      empresaImport: {
        id: 'empresaImport',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      empresaCreate: {
        id: 'empresaCreate',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.empresaFoto, storage.empresaDocumentos],
      },
      empresaEdit: {
        id: 'empresaEdit',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.empresaFoto, storage.empresaDocumentos],
      },
      empresaDestroy: {
        id: 'empresaDestroy',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.empresaFoto, storage.empresaDocumentos],
      },
      empresaRead: {
        id: 'empresaRead',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      empresaAutocomplete: {
        id: 'empresaAutocomplete',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      ubicacionImport: {
        id: 'ubicacionImport',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones, roles.administradorInventario],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      ubicacionCreate: {
        id: 'ubicacionCreate',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones, roles.administradorInventario],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.ubicacionFoto, storage.ubicacionDocumentos],
      },
      ubicacionEdit: {
        id: 'ubicacionEdit',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones, roles.administradorInventario],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.ubicacionFoto, storage.ubicacionDocumentos],
      },
      ubicacionDestroy: {
        id: 'ubicacionDestroy',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorInventario],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.ubicacionFoto, storage.ubicacionDocumentos],
      },
      ubicacionRead: {
        id: 'ubicacionRead',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones, roles.administradorInventario],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      ubicacionAutocomplete: {
        id: 'ubicacionAutocomplete',
        allowedRoles: [
          roles.admin,
          roles.administrador,
          roles.administradorOperaciones,
          roles.administradorRecursosHumanos,
          roles.administradorInventario,
          roles.administradorCatalogo,
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      activoConfig: {
        id: 'activoConfig',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      activoImport: {
        id: 'activoImport',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      activoCreate: {
        id: 'activoCreate',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorInventario],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.activoFotos, storage.activoDocumentos],
      },
      activoEdit: {
        id: 'activoEdit',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorInventario],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.activoFotos, storage.activoDocumentos],
      },
      activoDestroy: {
        id: 'activoDestroy',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorInventario],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.activoFotos, storage.activoDocumentos],
      },
      activoRead: {
        id: 'activoRead',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorInventario],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      activoAutocomplete: {
        id: 'activoAutocomplete',
        allowedRoles: [
          roles.admin,
          roles.administrador,
          roles.administradorInventario,
          roles.administradorSolicitudes,
          roles.planificadorProcedimientos,
          roles.planificadorOperaciones,
          roles.administradorOperaciones,
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      marcaImport: {
        id: 'marcaImport',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      marcaCreate: {
        id: 'marcaCreate',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      marcaEdit: {
        id: 'marcaEdit',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      marcaDestroy: {
        id: 'marcaDestroy',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      marcaRead: {
        id: 'marcaRead',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      marcaAutocomplete: {
        id: 'marcaAutocomplete',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      modeloImport: {
        id: 'modeloImport',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      modeloCreate: {
        id: 'modeloCreate',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      modeloEdit: {
        id: 'modeloEdit',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      modeloDestroy: {
        id: 'modeloDestroy',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      modeloRead: {
        id: 'modeloRead',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      modeloAutocomplete: {
        id: 'modeloAutocomplete',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      tipoActivoImport: {
        id: 'tipoActivoImport',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorInventario, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      tipoActivoCreate: {
        id: 'tipoActivoCreate',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorInventario, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      tipoActivoEdit: {
        id: 'tipoActivoEdit',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorInventario, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      tipoActivoDestroy: {
        id: 'tipoActivoDestroy',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorInventario, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      tipoActivoRead: {
        id: 'tipoActivoRead',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorInventario, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      tipoActivoAutocomplete: {
        id: 'tipoActivoAutocomplete',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorInventario, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      descRegistroImport: {
        id: 'descRegistroImport',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      descRegistroCreate: {
        id: 'descRegistroCreate',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      descRegistroEdit: {
        id: 'descRegistroEdit',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      descRegistroDestroy: {
        id: 'descRegistroDestroy',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      descRegistroRead: {
        id: 'descRegistroRead',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      descRegistroAutocomplete: {
        id: 'descRegistroAutocomplete',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      tipoEnergiaImport: {
        id: 'tipoEnergiaImport',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      tipoEnergiaCreate: {
        id: 'tipoEnergiaCreate',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      tipoEnergiaEdit: {
        id: 'tipoEnergiaEdit',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      tipoEnergiaDestroy: {
        id: 'tipoEnergiaDestroy',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      tipoEnergiaRead: {
        id: 'tipoEnergiaRead',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      tipoEnergiaAutocomplete: {
        id: 'tipoEnergiaAutocomplete',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      tablaCostoEnergiaImport: {
        id: 'tablaCostoEnergiaImport',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      tablaCostoEnergiaCreate: {
        id: 'tablaCostoEnergiaCreate',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      tablaCostoEnergiaEdit: {
        id: 'tablaCostoEnergiaEdit',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      tablaCostoEnergiaDestroy: {
        id: 'tablaCostoEnergiaDestroy',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      tablaCostoEnergiaRead: {
        id: 'tablaCostoEnergiaRead',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      tablaCostoEnergiaAutocomplete: {
        id: 'tablaCostoEnergiaAutocomplete',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      trazabilidadImport: {
        id: 'trazabilidadImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      trazabilidadCreate: {
        id: 'trazabilidadCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.trazabilidadFotosAdjuntas,
          storage.trazabilidadDocumentosAdjuntos,
          storage.trazabilidadRespaldoEnvia,
          storage.trazabilidadRespaldoRecibe,
        ],
      },
      trazabilidadEdit: {
        id: 'trazabilidadEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.trazabilidadFotosAdjuntas,
          storage.trazabilidadDocumentosAdjuntos,
          storage.trazabilidadRespaldoEnvia,
          storage.trazabilidadRespaldoRecibe,
        ],
      },
      trazabilidadDestroy: {
        id: 'trazabilidadDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.trazabilidadFotosAdjuntas,
          storage.trazabilidadDocumentosAdjuntos,
          storage.trazabilidadRespaldoEnvia,
          storage.trazabilidadRespaldoRecibe,
        ],
      },
      trazabilidadRead: {
        id: 'trazabilidadRead',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      trazabilidadAutocomplete: {
        id: 'trazabilidadAutocomplete',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      unidadMedidaImport: {
        id: 'unidadMedidaImport',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      unidadMedidaCreate: {
        id: 'unidadMedidaCreate',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      unidadMedidaEdit: {
        id: 'unidadMedidaEdit',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      unidadMedidaDestroy: {
        id: 'unidadMedidaDestroy',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      unidadMedidaRead: {
        id: 'unidadMedidaRead',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      unidadMedidaAutocomplete: {
        id: 'unidadMedidaAutocomplete',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      planificacionImport: {
        id: 'planificacionImport',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones, roles.planificadorOperaciones],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      planificacionCreate: {
        id: 'planificacionCreate',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones, roles.planificadorOperaciones],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.planificacionDocumentosAdjuntos, storage.planificacionFotosAdjuntas],
      },
      planificacionEdit: {
        id: 'planificacionEdit',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones, roles.planificadorOperaciones],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.planificacionDocumentosAdjuntos, storage.planificacionFotosAdjuntas],
      },
      planificacionDestroy: {
        id: 'planificacionDestroy',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones, roles.planificadorOperaciones],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.planificacionDocumentosAdjuntos, storage.planificacionFotosAdjuntas],
      },
      planificacionRead: {
        id: 'planificacionRead',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones, roles.planificadorOperaciones],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      planificacionAutocomplete: {
        id: 'planificacionAutocomplete',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones, roles.planificadorOperaciones],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      servicioProveedorImport: {
        id: 'servicioProveedorImport',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      servicioProveedorCreate: {
        id: 'servicioProveedorCreate',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      servicioProveedorEdit: {
        id: 'servicioProveedorEdit',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      servicioProveedorDestroy: {
        id: 'servicioProveedorDestroy',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      servicioProveedorRead: {
        id: 'servicioProveedorRead',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      servicioProveedorAutocomplete: {
        id: 'servicioProveedorAutocomplete',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      campoPersonalizadoImport: {
        id: 'campoPersonalizadoImport',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      campoPersonalizadoCreate: {
        id: 'campoPersonalizadoCreate',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      campoPersonalizadoEdit: {
        id: 'campoPersonalizadoEdit',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      campoPersonalizadoDestroy: {
        id: 'campoPersonalizadoDestroy',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      campoPersonalizadoRead: {
        id: 'campoPersonalizadoRead',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      campoPersonalizadoAutocomplete: {
        id: 'campoPersonalizadoAutocomplete',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      materialImport: {
        id: 'materialImport',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorInventario, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      materialCreate: {
        id: 'materialCreate',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorInventario, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.materialFoto],
      },
      materialEdit: {
        id: 'materialEdit',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorInventario, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.materialFoto],
      },
      materialDestroy: {
        id: 'materialDestroy',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorInventario, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.materialFoto],
      },
      materialRead: {
        id: 'materialRead',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorInventario, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      materialAutocomplete: {
        id: 'materialAutocomplete',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorInventario, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      estadoActivosImport: {
        id: 'estadoActivosImport',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      estadoActivosCreate: {
        id: 'estadoActivosCreate',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      estadoActivosEdit: {
        id: 'estadoActivosEdit',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      estadoActivosDestroy: {
        id: 'estadoActivosDestroy',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      estadoActivosRead: {
        id: 'estadoActivosRead',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      estadoActivosAutocomplete: {
        id: 'estadoActivosAutocomplete',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      cantidadMaterialActivoImport: {
        id: 'cantidadMaterialActivoImport',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      cantidadMaterialActivoCreate: {
        id: 'cantidadMaterialActivoCreate',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      cantidadMaterialActivoEdit: {
        id: 'cantidadMaterialActivoEdit',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      cantidadMaterialActivoDestroy: {
        id: 'cantidadMaterialActivoDestroy',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      cantidadMaterialActivoRead: {
        id: 'cantidadMaterialActivoRead',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      cantidadMaterialActivoAutocomplete: {
        id: 'cantidadMaterialActivoAutocomplete',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      solicitudReciclajeImport: {
        id: 'solicitudReciclajeImport',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorSolicitudes],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      solicitudReciclajeCreate: {
        id: 'solicitudReciclajeCreate',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorSolicitudes],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.solicitudReciclajeComprobantes],
      },
      solicitudReciclajeEdit: {
        id: 'solicitudReciclajeEdit',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorSolicitudes],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.solicitudReciclajeComprobantes],
      },
      solicitudReciclajeDestroy: {
        id: 'solicitudReciclajeDestroy',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorSolicitudes],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.solicitudReciclajeComprobantes],
      },
      solicitudReciclajeRead: {
        id: 'solicitudReciclajeRead',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorSolicitudes],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      solicitudReciclajeAutocomplete: {
        id: 'solicitudReciclajeAutocomplete',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorSolicitudes],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      etiquetaImport: {
        id: 'etiquetaImport',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      etiquetaCreate: {
        id: 'etiquetaCreate',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      etiquetaEdit: {
        id: 'etiquetaEdit',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      etiquetaDestroy: {
        id: 'etiquetaDestroy',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      etiquetaRead: {
        id: 'etiquetaRead',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      etiquetaAutocomplete: {
        id: 'etiquetaAutocomplete',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      perfilEmpleadoImport: {
        id: 'perfilEmpleadoImport',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorRecursosHumanos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      perfilEmpleadoCreate: {
        id: 'perfilEmpleadoCreate',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorRecursosHumanos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.perfilEmpleadoFirma],
      },
      perfilEmpleadoEdit: {
        id: 'perfilEmpleadoEdit',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorRecursosHumanos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.perfilEmpleadoFirma],
      },
      perfilEmpleadoDestroy: {
        id: 'perfilEmpleadoDestroy',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorRecursosHumanos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.perfilEmpleadoFirma],
      },
      perfilEmpleadoRead: {
        id: 'perfilEmpleadoRead',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorRecursosHumanos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      perfilEmpleadoAutocomplete: {
        id: 'perfilEmpleadoAutocomplete',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorRecursosHumanos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      turnoLaboralImport: {
        id: 'turnoLaboralImport',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorRecursosHumanos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      turnoLaboralCreate: {
        id: 'turnoLaboralCreate',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorRecursosHumanos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      turnoLaboralEdit: {
        id: 'turnoLaboralEdit',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorRecursosHumanos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      turnoLaboralDestroy: {
        id: 'turnoLaboralDestroy',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorRecursosHumanos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      turnoLaboralRead: {
        id: 'turnoLaboralRead',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      turnoLaboralAutocomplete: {
        id: 'turnoLaboralAutocomplete',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorRecursosHumanos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      gestorDocumentalImport: {
        id: 'gestorDocumentalImport',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones, roles.administradorRecursosHumanos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      gestorDocumentalCreate: {
        id: 'gestorDocumentalCreate',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones, roles.administradorRecursosHumanos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      gestorDocumentalEdit: {
        id: 'gestorDocumentalEdit',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones, roles.administradorRecursosHumanos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      gestorDocumentalDestroy: {
        id: 'gestorDocumentalDestroy',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      gestorDocumentalRead: {
        id: 'gestorDocumentalRead',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones, roles.administradorRecursosHumanos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      gestorDocumentalAutocomplete: {
        id: 'gestorDocumentalAutocomplete',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones, roles.administradorRecursosHumanos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      diaFestivoImport: {
        id: 'diaFestivoImport',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorRecursosHumanos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      diaFestivoCreate: {
        id: 'diaFestivoCreate',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorRecursosHumanos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      diaFestivoEdit: {
        id: 'diaFestivoEdit',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorRecursosHumanos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      diaFestivoDestroy: {
        id: 'diaFestivoDestroy',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorRecursosHumanos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      diaFestivoRead: {
        id: 'diaFestivoRead',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      diaFestivoAutocomplete: {
        id: 'diaFestivoAutocomplete',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorRecursosHumanos],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      centroCostoImport: {
        id: 'centroCostoImport',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      centroCostoCreate: {
        id: 'centroCostoCreate',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      centroCostoEdit: {
        id: 'centroCostoEdit',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      centroCostoDestroy: {
        id: 'centroCostoDestroy',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      centroCostoRead: {
        id: 'centroCostoRead',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      centroCostoAutocomplete: {
        id: 'centroCostoAutocomplete',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorRecursosHumanos, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      presupuestoImport: {
        id: 'presupuestoImport',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      presupuestoCreate: {
        id: 'presupuestoCreate',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      presupuestoEdit: {
        id: 'presupuestoEdit',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      presupuestoDestroy: {
        id: 'presupuestoDestroy',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      presupuestoRead: {
        id: 'presupuestoRead',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      presupuestoAutocomplete: {
        id: 'presupuestoAutocomplete',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      terceroImport: {
        id: 'terceroImport',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo, roles.administradorSolicitudes],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      terceroCreate: {
        id: 'terceroCreate',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo, roles.administradorSolicitudes],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      terceroEdit: {
        id: 'terceroEdit',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo, roles.administradorSolicitudes],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      terceroDestroy: {
        id: 'terceroDestroy',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo, roles.administradorSolicitudes],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      terceroRead: {
        id: 'terceroRead',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo, roles.administradorSolicitudes],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      terceroAutocomplete: {
        id: 'terceroAutocomplete',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo, roles.administradorSolicitudes],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      clasificacionTerceroImport: {
        id: 'clasificacionTerceroImport',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo, roles.administradorSolicitudes],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      clasificacionTerceroCreate: {
        id: 'clasificacionTerceroCreate',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo, roles.administradorSolicitudes],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      clasificacionTerceroEdit: {
        id: 'clasificacionTerceroEdit',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo, roles.administradorSolicitudes],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      clasificacionTerceroDestroy: {
        id: 'clasificacionTerceroDestroy',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo, roles.administradorSolicitudes],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      clasificacionTerceroRead: {
        id: 'clasificacionTerceroRead',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo, roles.administradorSolicitudes],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      clasificacionTerceroAutocomplete: {
        id: 'clasificacionTerceroAutocomplete',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo, roles.administradorSolicitudes],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      contactoImport: {
        id: 'contactoImport',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo, roles.administradorSolicitudes],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      contactoCreate: {
        id: 'contactoCreate',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo, roles.administradorSolicitudes],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.contactoFoto],
      },
      contactoEdit: {
        id: 'contactoEdit',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo, roles.administradorSolicitudes],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.contactoFoto],
      },
      contactoDestroy: {
        id: 'contactoDestroy',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo, roles.administradorSolicitudes],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.contactoFoto],
      },
      contactoRead: {
        id: 'contactoRead',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo, roles.administradorSolicitudes],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      contactoAutocomplete: {
        id: 'contactoAutocomplete',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo, roles.administradorSolicitudes],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      tipoServicioImport: {
        id: 'tipoServicioImport',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      tipoServicioCreate: {
        id: 'tipoServicioCreate',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      tipoServicioEdit: {
        id: 'tipoServicioEdit',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      tipoServicioDestroy: {
        id: 'tipoServicioDestroy',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      tipoServicioRead: {
        id: 'tipoServicioRead',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      tipoServicioAutocomplete: {
        id: 'tipoServicioAutocomplete',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      servicioImport: {
        id: 'servicioImport',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      servicioCreate: {
        id: 'servicioCreate',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      servicioEdit: {
        id: 'servicioEdit',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      servicioDestroy: {
        id: 'servicioDestroy',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      servicioRead: {
        id: 'servicioRead',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      servicioAutocomplete: {
        id: 'servicioAutocomplete',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      tipoUnidadImport: {
        id: 'tipoUnidadImport',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      tipoUnidadCreate: {
        id: 'tipoUnidadCreate',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      tipoUnidadEdit: {
        id: 'tipoUnidadEdit',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      tipoUnidadDestroy: {
        id: 'tipoUnidadDestroy',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      tipoUnidadRead: {
        id: 'tipoUnidadRead',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      tipoUnidadAutocomplete: {
        id: 'tipoUnidadAutocomplete',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      inventarioImport: {
        id: 'inventarioImport',
        allowedRoles: [roles.admin, roles.administrador],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      inventarioCreate: {
        id: 'inventarioCreate',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorInventario],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      inventarioEdit: {
        id: 'inventarioEdit',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorInventario],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      inventarioDestroy: {
        id: 'inventarioDestroy',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorInventario],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      inventarioRead: {
        id: 'inventarioRead',
        allowedRoles: [
          roles.admin,
          roles.administrador,
          roles.administradorOperaciones,
          roles.planificadorOperaciones,
          roles.administradorInventario,
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      inventarioAutocomplete: {
        id: 'inventarioAutocomplete',
        allowedRoles: [
          roles.admin,
          roles.administrador,
          roles.administradorOperaciones,
          roles.planificadorOperaciones,
          roles.administradorInventario,
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      registroImport: {
        id: 'registroImport',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      registroCreate: {
        id: 'registroCreate',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones, roles.planificadorOperaciones],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      registroEdit: {
        id: 'registroEdit',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      registroDestroy: {
        id: 'registroDestroy',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      registroRead: {
        id: 'registroRead',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones, roles.planificadorOperaciones],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      registroAutocomplete: {
        id: 'registroAutocomplete',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      historialInventarioImport: {
        id: 'historialInventarioImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      historialInventarioCreate: {
        id: 'historialInventarioCreate',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorInventario],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.historialInventarioFotos, storage.historialInventarioDocumentos],
      },
      historialInventarioEdit: {
        id: 'historialInventarioEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.historialInventarioFotos, storage.historialInventarioDocumentos],
      },
      historialInventarioDestroy: {
        id: 'historialInventarioDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.historialInventarioFotos, storage.historialInventarioDocumentos],
      },
      historialInventarioRead: {
        id: 'historialInventarioRead',
        allowedRoles: [
          roles.admin,
          roles.administrador,
          roles.administradorOperaciones,
          roles.planificadorOperaciones,
          roles.administradorInventario,
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      historialInventarioAutocomplete: {
        id: 'historialInventarioAutocomplete',
        allowedRoles: [
          roles.admin,
          roles.administrador,
          roles.administradorInventario,
          roles.administradorOperaciones,
          roles.planificadorOperaciones,
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      firmaImport: {
        id: 'firmaImport',
        allowedRoles: [],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      firmaCreate: {
        id: 'firmaCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      firmaEdit: {
        id: 'firmaEdit',
        allowedRoles: [
          roles.admin,
          roles.administrador,
          roles.administradorOperaciones,
          roles.administradorRecursosHumanos,
          roles.administradorInventario,
          roles.administradorCatalogo,
          roles.administradorSolicitudes,
          roles.planificadorOperaciones,
          roles.planificadorProcedimientos,
          roles.aceptadorPlanificaciones,
          roles.validadorPlanificaciones,
          roles.ejecutor,
          roles.signingUser,
          roles.controlInicioActividad,
          roles.editorProgreso,
          roles.ejecutorProgreso,
          roles.reasignadorDeTareas,
          roles.kanbanAdmin,
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      firmaDestroy: {
        id: 'firmaDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      firmaRead: {
        id: 'firmaRead',
        allowedRoles: [
          roles.admin,
          roles.administrador,
          roles.administradorOperaciones,
          roles.administradorRecursosHumanos,
          roles.administradorInventario,
          roles.administradorCatalogo,
          roles.administradorSolicitudes,
          roles.planificadorOperaciones,
          roles.planificadorProcedimientos,
          roles.aceptadorPlanificaciones,
          roles.validadorPlanificaciones,
          roles.ejecutor,
          roles.signingUser,
          roles.controlInicioActividad,
          roles.editorProgreso,
          roles.ejecutorProgreso,
          roles.reasignadorDeTareas,
          roles.kanbanAdmin,
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      firmaAutocomplete: {
        id: 'firmaAutocomplete',
        allowedRoles: [
          roles.admin,
          roles.administrador,
          roles.administradorOperaciones,
          roles.administradorRecursosHumanos,
          roles.administradorInventario,
          roles.administradorCatalogo,
          roles.administradorSolicitudes,
          roles.planificadorOperaciones,
          roles.planificadorProcedimientos,
          roles.aceptadorPlanificaciones,
          roles.validadorPlanificaciones,
          roles.ejecutor,
          roles.signingUser,
          roles.controlInicioActividad,
          roles.editorProgreso,
          roles.ejecutorProgreso,
          roles.reasignadorDeTareas,
          roles.kanbanAdmin,
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      solicitudImport: {
        id: 'solicitudImport',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorSolicitudes],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      solicitudCreate: {
        id: 'solicitudCreate',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorSolicitudes],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      solicitudEdit: {
        id: 'solicitudEdit',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorSolicitudes],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      solicitudDestroy: {
        id: 'solicitudDestroy',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorSolicitudes],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      solicitudRead: {
        id: 'solicitudRead',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      solicitudAutocomplete: {
        id: 'solicitudAutocomplete',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorSolicitudes],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      solicitudActivoImport: {
        id: 'solicitudActivoImport',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorSolicitudes],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      solicitudActivoCreate: {
        id: 'solicitudActivoCreate',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorSolicitudes],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      solicitudActivoEdit: {
        id: 'solicitudActivoEdit',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorSolicitudes],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      solicitudActivoDestroy: {
        id: 'solicitudActivoDestroy',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorSolicitudes],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      solicitudActivoRead: {
        id: 'solicitudActivoRead',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorSolicitudes],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      solicitudActivoAutocomplete: {
        id: 'solicitudActivoAutocomplete',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorSolicitudes],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      codificacionISOImport: {
        id: 'codificacionISOImport',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones, roles.planificadorOperaciones],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      codificacionISOCreate: {
        id: 'codificacionISOCreate',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones, roles.planificadorOperaciones],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      codificacionISOEdit: {
        id: 'codificacionISOEdit',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones, roles.planificadorOperaciones],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      codificacionISODestroy: {
        id: 'codificacionISODestroy',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones, roles.planificadorOperaciones],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      codificacionISORead: {
        id: 'codificacionISORead',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones, roles.planificadorOperaciones],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      codificacionISOAutocomplete: {
        id: 'codificacionISOAutocomplete',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones, roles.planificadorOperaciones],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      recursoImport: {
        id: 'recursoImport',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      recursoCreate: {
        id: 'recursoCreate',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.recursoFotos, storage.recursoDocumentos],
      },
      recursoEdit: {
        id: 'recursoEdit',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.recursoFotos, storage.recursoDocumentos],
      },
      recursoDestroy: {
        id: 'recursoDestroy',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [storage.recursoFotos, storage.recursoDocumentos],
      },
      recursoRead: {
        id: 'recursoRead',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      recursoAutocomplete: {
        id: 'recursoAutocomplete',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorOperaciones],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      cuentaContableImport: {
        id: 'cuentaContableImport',
        allowedRoles: [
          // roles.admin,
          // roles.administrador,
          // roles.administradorCatalogo,
          // roles.administradorSolicitudes,
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      cuentaContableCreate: {
        id: 'cuentaContableCreate',
        allowedRoles: [
          // roles.admin,
          // roles.administrador,
          // roles.administradorCatalogo,
          // roles.administradorSolicitudes,
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      cuentaContableEdit: {
        id: 'cuentaContableEdit',
        allowedRoles: [
          // roles.admin,
          // roles.administrador,
          // roles.administradorCatalogo,
          // roles.administradorSolicitudes,
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      cuentaContableDestroy: {
        id: 'cuentaContableDestroy',
        allowedRoles: [
          // roles.admin,
          // roles.administrador,
          // roles.administradorCatalogo,
          // roles.administradorSolicitudes,
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      cuentaContableRead: {
        id: 'cuentaContableRead',
        allowedRoles: [
          // roles.admin,
          // roles.administrador,
          // roles.administradorCatalogo,
          // roles.administradorSolicitudes,
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      cuentaContableAutocomplete: {
        id: 'cuentaContableAutocomplete',
        allowedRoles: [
          // roles.admin,
          // roles.administrador,
          // roles.administradorCatalogo,
          // roles.administradorSolicitudes,
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      tipoCentroCostoImport: {
        id: 'tipoCentroCostoImport',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      tipoCentroCostoCreate: {
        id: 'tipoCentroCostoCreate',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      tipoCentroCostoEdit: {
        id: 'tipoCentroCostoEdit',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      tipoCentroCostoDestroy: {
        id: 'tipoCentroCostoDestroy',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      tipoCentroCostoRead: {
        id: 'tipoCentroCostoRead',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      tipoCentroCostoAutocomplete: {
        id: 'tipoCentroCostoAutocomplete',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      tipoPresupuestoImport: {
        id: 'tipoPresupuestoImport',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      tipoPresupuestoCreate: {
        id: 'tipoPresupuestoCreate',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      tipoPresupuestoEdit: {
        id: 'tipoPresupuestoEdit',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      tipoPresupuestoDestroy: {
        id: 'tipoPresupuestoDestroy',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      tipoPresupuestoRead: {
        id: 'tipoPresupuestoRead',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      tipoPresupuestoAutocomplete: {
        id: 'tipoPresupuestoAutocomplete',
        allowedRoles: [roles.admin, roles.administrador, roles.administradorCatalogo],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      tipoCuentaContableImport: {
        id: 'tipoCuentaContableImport',
        allowedRoles: [
          // roles.admin,
          // roles.administrador,
          // roles.administradorCatalogo,
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      tipoCuentaContableCreate: {
        id: 'tipoCuentaContableCreate',
        allowedRoles: [
          // roles.admin,
          // roles.administrador,
          // roles.administradorCatalogo,
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      tipoCuentaContableEdit: {
        id: 'tipoCuentaContableEdit',
        allowedRoles: [
          // roles.admin,
          // roles.administrador,
          // roles.administradorCatalogo,
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      tipoCuentaContableDestroy: {
        id: 'tipoCuentaContableDestroy',
        allowedRoles: [
          // roles.admin,
          // roles.administrador,
          // roles.administradorCatalogo,
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [],
      },
      tipoCuentaContableRead: {
        id: 'tipoCuentaContableRead',
        allowedRoles: [
          // roles.admin,
          // roles.administrador,
          // roles.administradorCatalogo,
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      tipoCuentaContableAutocomplete: {
        id: 'tipoCuentaContableAutocomplete',
        allowedRoles: [
          // roles.admin,
          // roles.administrador,
          // roles.administradorCatalogo,
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
    };
  }

  static get asArray() {
    return Object.keys(this.values).map((value) => {
      return this.values[value];
    });
  }
}

export default Permissions;
