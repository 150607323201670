import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.pendiente.fields.id'),
  },
  {
    name: 'titulo',
    label: i18n('entities.pendiente.fields.titulo'),
  },
  {
    name: 'tipoPendiente',
    label: i18n('entities.pendiente.fields.tipoPendiente'),
  },
  {
    name: 'responsable',
    label: i18n('entities.pendiente.fields.responsable'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'sucursal',
    label: i18n('entities.pendiente.fields.sucursal'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'formulario',
    label: i18n('entities.pendiente.fields.formulario'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'tarea',
    label: i18n('entities.pendiente.fields.tarea'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'estado',
    label: i18n('entities.pendiente.fields.estado'),
  },
  {
    name: 'categoria',
    label: i18n('entities.pendiente.fields.categoria'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'enviado',
    label: i18n('entities.pendiente.fields.enviado'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'calificacionEvaluador',
    label: i18n('entities.pendiente.fields.calificacionEvaluador'),
  },
  {
    name: 'comentarioEvaluador',
    label: i18n('entities.pendiente.fields.comentarioEvaluador'),
  },
  {
    name: 'esEvaluado',
    label: i18n('entities.pendiente.fields.esEvaluado'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'evaluadores',
    label: i18n('entities.pendiente.fields.evaluadores'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'actividad',
    label: i18n('entities.pendiente.fields.actividad'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.pendiente.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.pendiente.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
