import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/categoriaFormulario/importer/categoriaFormularioImporterSelectors';
import CategoriaFormularioService from 'src/modules/categoriaFormulario/categoriaFormularioService';
import fields from 'src/modules/categoriaFormulario/importer/categoriaFormularioImporterFields';
import { i18n } from 'src/i18n';

const categoriaFormularioImporterActions = importerActions(
  'CATEGORIAFORMULARIO_IMPORTER',
  selectors,
  CategoriaFormularioService.import,
  fields,
  i18n('entities.categoriaFormulario.importer.fileName'),
);

export default categoriaFormularioImporterActions;