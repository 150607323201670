import React from 'react';
import {useDispatch} from 'react-redux';
import {i18n} from 'src/i18n';
import actions from 'src/modules/tarea/form/tareaFormActions';
import ContentWrapper from 'src/view/layout/styles/ContentWrapper';
import Breadcrumb from 'src/view/shared/Breadcrumb';
import PageTitle from 'src/view/shared/styles/PageTitle';
import TareaSendForm from './TareaSendForm';

function TareaSendFormPage(props) {
  const dispatch = useDispatch();

  const title = props.title || 'Enviar Tarea';

  const silent = props.silent;
  const drawerId = props.drawerId;

  const doSubmit = (data) => {
    if (data.tarea?.record) data.tarea = data.tarea.record;
    if (silent) {
      data.silent = true;
    }
    if (drawerId) {
      data.drawerId = drawerId;
    }
    console.log('%c⧭ TareaSendFormPage doSubmit', 'color: #408059', {data});
    dispatch(actions.doCreate(data));
  };

  console.log('TareaSendFormPage silent', silent, 'drawerId', drawerId);

  return (
    <>
      {!props.drawer && !props.disabled && (
        <Breadcrumb items={[[i18n('dashboard.menu'), '/'], [i18n('entities.tarea.menu'), '/tarea'], [title]]} />
      )}

      <ContentWrapper>
        <div style={{padding: 10}}>
          <PageTitle>{title}</PageTitle>
        </div>
        <TareaSendForm {...props} onSend={doSubmit} />
      </ContentWrapper>
    </>
  );
}

export default TareaSendFormPage;
