import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';import terceroEnumerators from 'src/modules/tercero/terceroEnumerators';

export default [
  {
    name: 'nombre',
    label: i18n('entities.tercero.fields.nombre'),
    schema: schemas.string(
      i18n('entities.tercero.fields.nombre'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'codigo',
    label: i18n('entities.tercero.fields.codigo'),
    schema: schemas.string(
      i18n('entities.tercero.fields.codigo'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'tipo',
    label: i18n('entities.tercero.fields.tipo'),
    schema: schemas.enumerator(
      i18n('entities.tercero.fields.tipo'),
      {
        "options": terceroEnumerators.tipo
      },
    ),
  },
  {
    name: 'paginaWeb',
    label: i18n('entities.tercero.fields.paginaWeb'),
    schema: schemas.string(
      i18n('entities.tercero.fields.paginaWeb'),
      {},
    ),
  },
  {
    name: 'clasificacion',
    label: i18n('entities.tercero.fields.clasificacion'),
    schema: schemas.relationToMany(
      i18n('entities.tercero.fields.clasificacion'),
      {},
    ),
  },
  {
    name: 'direccion',
    label: i18n('entities.tercero.fields.direccion'),
    schema: schemas.string(
      i18n('entities.tercero.fields.direccion'),
      {},
    ),
  },
  {
    name: 'ciudad',
    label: i18n('entities.tercero.fields.ciudad'),
    schema: schemas.string(
      i18n('entities.tercero.fields.ciudad'),
      {},
    ),
  },
  {
    name: 'region',
    label: i18n('entities.tercero.fields.region'),
    schema: schemas.string(
      i18n('entities.tercero.fields.region'),
      {},
    ),
  },
  {
    name: 'codigoArea',
    label: i18n('entities.tercero.fields.codigoArea'),
    schema: schemas.string(
      i18n('entities.tercero.fields.codigoArea'),
      {},
    ),
  },
  {
    name: 'pais',
    label: i18n('entities.tercero.fields.pais'),
    schema: schemas.string(
      i18n('entities.tercero.fields.pais'),
      {},
    ),
  },
  {
    name: 'ubicacionGoogle',
    label: i18n('entities.tercero.fields.ubicacionGoogle'),
    schema: schemas.string(
      i18n('entities.tercero.fields.ubicacionGoogle'),
      {},
    ),
  },
  {
    name: 'latitud',
    label: i18n('entities.tercero.fields.latitud'),
    schema: schemas.decimal(
      i18n('entities.tercero.fields.latitud'),
      {},
    ),
  },
  {
    name: 'longitud',
    label: i18n('entities.tercero.fields.longitud'),
    schema: schemas.decimal(
      i18n('entities.tercero.fields.longitud'),
      {},
    ),
  },
  {
    name: 'telefonoPrincipal',
    label: i18n('entities.tercero.fields.telefonoPrincipal'),
    schema: schemas.integer(
      i18n('entities.tercero.fields.telefonoPrincipal'),
      {},
    ),
  },
  {
    name: 'telefonoSecundario',
    label: i18n('entities.tercero.fields.telefonoSecundario'),
    schema: schemas.integer(
      i18n('entities.tercero.fields.telefonoSecundario'),
      {},
    ),
  },
  {
    name: 'email',
    label: i18n('entities.tercero.fields.email'),
    schema: schemas.string(
      i18n('entities.tercero.fields.email'),
      {},
    ),
  },
  {
    name: 'fax',
    label: i18n('entities.tercero.fields.fax'),
    schema: schemas.integer(
      i18n('entities.tercero.fields.fax'),
      {},
    ),
  },
  {
    name: 'telefonoSMS',
    label: i18n('entities.tercero.fields.telefonoSMS'),
    schema: schemas.integer(
      i18n('entities.tercero.fields.telefonoSMS'),
      {},
    ),
  },
  {
    name: 'contactos',
    label: i18n('entities.tercero.fields.contactos'),
    schema: schemas.relationToMany(
      i18n('entities.tercero.fields.contactos'),
      {},
    ),
  },
  {
    name: 'servicios',
    label: i18n('entities.tercero.fields.servicios'),
    schema: schemas.relationToMany(
      i18n('entities.tercero.fields.servicios'),
      {},
    ),
  },
  {
    name: 'activosVinculados',
    label: i18n('entities.tercero.fields.activosVinculados'),
    schema: schemas.relationToMany(
      i18n('entities.tercero.fields.activosVinculados'),
      {},
    ),
  },
];