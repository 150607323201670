import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';
import planificacionEnumerators from 'src/modules/planificacion/planificacionEnumerators';

export default [
  {
    name: 'activo',
    label: i18n('entities.planificacion.fields.activo'),
    schema: schemas.relationToOne(
      i18n('entities.planificacion.fields.activo'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'nombre',
    label: i18n('entities.planificacion.fields.nombre'),
    schema: schemas.string(
      i18n('entities.planificacion.fields.nombre'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'descripcion',
    label: i18n(
      'entities.planificacion.fields.descripcion',
    ),
    schema: schemas.string(
      i18n('entities.planificacion.fields.descripcion'),
      {},
    ),
  },
  {
    name: 'fechaEstimadaFinalizacion',
    label: i18n(
      'entities.planificacion.fields.fechaEstimadaFinalizacion',
    ),
    schema: schemas.datetime(
      i18n(
        'entities.planificacion.fields.fechaEstimadaFinalizacion',
      ),
      {},
    ),
  },
  {
    name: 'ubicacionLlegada',
    label: i18n(
      'entities.planificacion.fields.ubicacionLlegada',
    ),
    schema: schemas.relationToOne(
      i18n(
        'entities.planificacion.fields.ubicacionLlegada',
      ),
      {},
    ),
  },
  {
    name: 'responsables',
    label: i18n(
      'entities.planificacion.fields.responsables',
    ),
    schema: schemas.relationToMany(
      i18n('entities.planificacion.fields.responsables'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'ubicacionGoogle',
    label: i18n(
      'entities.planificacion.fields.ubicacionGoogle',
    ),
    schema: schemas.string(
      i18n('entities.planificacion.fields.ubicacionGoogle'),
      {},
    ),
  },
  {
    name: 'latitud',
    label: i18n('entities.planificacion.fields.latitud'),
    schema: schemas.decimal(
      i18n('entities.planificacion.fields.latitud'),
      {},
    ),
  },
  {
    name: 'longitud',
    label: i18n('entities.planificacion.fields.longitud'),
    schema: schemas.decimal(
      i18n('entities.planificacion.fields.longitud'),
      {},
    ),
  },
  {
    name: 'tipo',
    label: i18n(
      'entities.planificacion.fields.clasificacion',
    ),
    schema: schemas.enumerator(
      i18n('entities.planificacion.fields.clasificacion'),
      {
        options: planificacionEnumerators.clasificacion,
      },
    ),
  },
];
