import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/perfilEmpleado/importer/perfilEmpleadoImporterSelectors';
import PerfilEmpleadoService from 'src/modules/perfilEmpleado/perfilEmpleadoService';
import fields from 'src/modules/perfilEmpleado/importer/perfilEmpleadoImporterFields';
import { i18n } from 'src/i18n';

const perfilEmpleadoImporterActions = importerActions(
  'PERFILEMPLEADO_IMPORTER',
  selectors,
  PerfilEmpleadoService.import,
  fields,
  i18n('entities.perfilEmpleado.importer.fileName'),
);

export default perfilEmpleadoImporterActions;