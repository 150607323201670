import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.recurso.fields.id'),
  },
  {
    name: 'descripcion',
    label: i18n('entities.recurso.fields.descripcion'),
  },
  {
    name: 'tipo',
    label: i18n('entities.recurso.fields.tipo'),
  },
  {
    name: 'cuentasContables',
    label: i18n('entities.recurso.fields.cuentasContables'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'fechaPago',
    label: i18n('entities.recurso.fields.fechaPago'),
  },
  {
    name: 'cantidad',
    label: i18n('entities.recurso.fields.cantidad'),
  },
  {
    name: 'empleados',
    label: i18n('entities.recurso.fields.empleados'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'repuestos',
    label: i18n('entities.recurso.fields.repuestos'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'servicios',
    label: i18n('entities.recurso.fields.servicios'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'fotos',
    label: i18n('entities.recurso.fields.fotos'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'documentos',
    label: i18n('entities.recurso.fields.documentos'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'tareasVinculadas',
    label: i18n('entities.recurso.fields.tareasVinculadas'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'subtareasVinculadas',
    label: i18n('entities.recurso.fields.subtareasVinculadas'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.recurso.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.recurso.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
