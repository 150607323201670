import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'nombre',
    label: i18n('entities.respuesta.fields.nombre'),
    schema: schemas.string(
      i18n('entities.respuesta.fields.nombre'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'tienePuntaje',
    label: i18n('entities.respuesta.fields.tienePuntaje'),
    schema: schemas.boolean(
      i18n('entities.respuesta.fields.tienePuntaje'),
      {},
    ),
  },
  {
    name: 'puntaje',
    label: i18n('entities.respuesta.fields.puntaje'),
    schema: schemas.integer(
      i18n('entities.respuesta.fields.puntaje'),
      {},
    ),
  },
];