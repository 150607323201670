import listActions from 'src/modules/comunicacionInterna/list/comunicacionInternaListActions';
import ComunicacionInternaService from 'src/modules/comunicacionInterna/comunicacionInternaService';
import TareaListActions from 'src/modules/tarea/list/tareaListActions';
import Errors from 'src/modules/shared/error/errors';
import {i18n} from 'src/i18n';
import {getHistory} from 'src/modules/store';
import Message from 'src/view/shared/message';
import layoutActions from 'src/modules/layout/layoutActions';
import moment from 'moment-timezone';

const prefix = 'COMUNICACIONINTERNA_DESTROY';

const comunicacionInternaDestroyActions = {
  DESTROY_STARTED: `${prefix}_DESTROY_STARTED`,
  DESTROY_SUCCESS: `${prefix}_DESTROY_SUCCESS`,
  DESTROY_ERROR: `${prefix}_DESTROY_ERROR`,

  DESTROY_ALL_STARTED: `${prefix}_DESTROY_ALL_STARTED`,
  DESTROY_ALL_SUCCESS: `${prefix}_DESTROY_ALL_SUCCESS`,
  DESTROY_ALL_ERROR: `${prefix}_DESTROY_ALL_ERROR`,

  doDestroy:
    (id, location = null) =>
    async dispatch => {
      try {
        dispatch({
          type: comunicacionInternaDestroyActions.DESTROY_STARTED,
        });

        await ComunicacionInternaService.destroyAll([id]);

        dispatch({
          type: comunicacionInternaDestroyActions.DESTROY_SUCCESS,
        });

        Message.success(i18n('entities.comunicacionInterna.destroy.success'));

        if (location === '/tarea') {
          dispatch(TareaListActions.doFetchCurrentFilter());

          dispatch(
            layoutActions.doVisibleDrawer({
              drawerId: 'DrawerComunicacionInternaFormPage',
              drawerVisible: false,
              drawerChanged: moment().unix(),
              drawerUpdateComponent: 'PendienteListPage',
            }),
          );

          return;
        }

        if (location === '/comunicacion-interna') {
          dispatch(listActions.doFetchCurrentFilter());

          dispatch(
            layoutActions.doVisibleDrawer({
              drawerId: 'DrawerComunicacionInternaFormPage',
              drawerVisible: false,
              drawerChanged: moment().unix(),
              drawerUpdateComponent: 'PendienteListPage',
            }),
          );

          return;
        }

        dispatch(listActions.doFetchCurrentFilter());

        getHistory().push('/comunicacion-interna');
      } catch (error) {
        Errors.handle(error);

        dispatch(listActions.doFetchCurrentFilter());

        dispatch({
          type: comunicacionInternaDestroyActions.DESTROY_ERROR,
        });
      }
    },

  doDestroyAll: ids => async dispatch => {
    try {
      dispatch({
        type: comunicacionInternaDestroyActions.DESTROY_ALL_STARTED,
      });

      await ComunicacionInternaService.destroyAll(ids);

      dispatch({
        type: comunicacionInternaDestroyActions.DESTROY_ALL_SUCCESS,
      });

      if (listActions) {
        dispatch(listActions.doClearAllSelected());
        dispatch(listActions.doFetchCurrentFilter());
      }

      Message.success(i18n('entities.comunicacionInterna.destroyAll.success'));

      getHistory().push('/comunicacion-interna');
    } catch (error) {
      Errors.handle(error);

      dispatch(listActions.doFetchCurrentFilter());

      dispatch({
        type: comunicacionInternaDestroyActions.DESTROY_ALL_ERROR,
      });
    }
  },
};

export default comunicacionInternaDestroyActions;
