import {Typography} from '@mui/material';
import * as React from 'react';

export default function PageTitle(props) {
  if (props.caption) {
    return (
      <Typography
        variant="caption"
        sx={{
          margin: 0,
          //fontSize: '1.5em',
          ...props.sx,
        }}
        style={{...props.style}}>
        {props.children}
      </Typography>
    );
  }
  return (
    <Typography
      variant="h1"
      sx={{
        margin: 0,
        marginBottom: '12px',
        fontSize: '1.5em',
        ...props.sx,
      }}
      style={{...props.style}}>
      {props.children}
    </Typography>
  );
}
