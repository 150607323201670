import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';
import comunicacionInternaEnumerators from 'src/modules/comunicacionInterna/comunicacionInternaEnumerators';

export default [
  {
    name: 'titulo',
    label: i18n('entities.comunicacionInterna.fields.titulo'),
    schema: schemas.string(
      i18n('entities.comunicacionInterna.fields.titulo'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'estado',
    label: i18n('entities.comunicacionInterna.fields.estado'),
    schema: schemas.enumerator(
      i18n('entities.comunicacionInterna.fields.estado'),
      {
        "options": comunicacionInternaEnumerators.estado
      },
    ),
  },
  {
    name: 'asunto',
    label: i18n('entities.comunicacionInterna.fields.asunto'),
    schema: schemas.string(
      i18n('entities.comunicacionInterna.fields.asunto'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'mensaje',
    label: i18n('entities.comunicacionInterna.fields.mensaje'),
    schema: schemas.string(
      i18n('entities.comunicacionInterna.fields.mensaje'),
      {},
    ),
  },
  {
    name: 'temaComunicacion',
    label: i18n('entities.comunicacionInterna.fields.temaComunicacion'),
    schema: schemas.relationToOne(
      i18n('entities.comunicacionInterna.fields.temaComunicacion'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'nivelPrioridad',
    label: i18n('entities.comunicacionInterna.fields.nivelPrioridad'),
    schema: schemas.relationToOne(
      i18n('entities.comunicacionInterna.fields.nivelPrioridad'),
      {},
    ),
  },
  {
    name: 'anadirReferencias',
    label: i18n('entities.comunicacionInterna.fields.anadirReferencias'),
    schema: schemas.boolean(
      i18n('entities.comunicacionInterna.fields.anadirReferencias'),
      {},
    ),
  },
  {
    name: 'pendientesAsociados',
    label: i18n('entities.comunicacionInterna.fields.pendientesAsociados'),
    schema: schemas.relationToMany(
      i18n('entities.comunicacionInterna.fields.pendientesAsociados'),
      {},
    ),
  },
  {
    name: 'tiendasAsociadas',
    label: i18n('entities.comunicacionInterna.fields.tiendasAsociadas'),
    schema: schemas.relationToMany(
      i18n('entities.comunicacionInterna.fields.tiendasAsociadas'),
      {},
    ),
  },
  {
    name: 'empleadosAsociados',
    label: i18n('entities.comunicacionInterna.fields.empleadosAsociados'),
    schema: schemas.relationToMany(
      i18n('entities.comunicacionInterna.fields.empleadosAsociados'),
      {},
    ),
  },
  {
    name: 'comunicacionesAsociadas',
    label: i18n('entities.comunicacionInterna.fields.comunicacionesAsociadas'),
    schema: schemas.relationToMany(
      i18n('entities.comunicacionInterna.fields.comunicacionesAsociadas'),
      {},
    ),
  },
  {
    name: 'participantes',
    label: i18n('entities.comunicacionInterna.fields.participantes'),
    schema: schemas.relationToMany(
      i18n('entities.comunicacionInterna.fields.participantes'),
      {},
    ),
  },
  {
    name: 'administradores',
    label: i18n('entities.comunicacionInterna.fields.administradores'),
    schema: schemas.relationToMany(
      i18n('entities.comunicacionInterna.fields.administradores'),
      {},
    ),
  },
];