import React from 'react';
import Spinner from 'src/view/shared/Spinner';
import ActivoFormPage from '../form/ActivoFormPage';

function ActivoView(props) {
  const renderView = () => {
    const {record, drawer} = props;
    return <ActivoFormPage record={record} disabled={true} drawer={drawer} />;
  };

  const {record, loading} = props;

  if (loading || !record) {
    return <Spinner />;
  }

  return renderView();
}

export default ActivoView;
