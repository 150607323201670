import list from 'src/modules/departamento/list/departamentoListReducers';
import form from 'src/modules/departamento/form/departamentoFormReducers';
import view from 'src/modules/departamento/view/departamentoViewReducers';
import destroy from 'src/modules/departamento/destroy/departamentoDestroyReducers';
import importerReducer from 'src/modules/departamento/importer/departamentoImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
