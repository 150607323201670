import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';

export default function BasicItemMenu(props) {
  const anchorRef = React.useRef<any>(null);
  const [anchorEl, setAnchorEl] = React.useState<any>(null);
  const open = Boolean(anchorEl);
  const openClick = () => {
    if (props.anchor) setAnchorEl(props.anchor);
    else setAnchorEl(anchorRef.current);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const optionClick = (option: any) => {
    if (option && option.onClick) option.onClick();
    handleClose();
  };

  const OpenButton =
    props.openButton ||
    ((props) => (
      <Button
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={props.onClick}>
        Open Menu
      </Button>
    ));
  return (
    <div>
      <OpenButton onClick={openClick} />
      <Menu
        onClick={(event) => event.stopPropagation()}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        {props.options
          .filter((i) => !i.hide)
          .map((option, index) => (
            <MenuItem key={index} onClick={() => optionClick(option)}>
              {option.label}
            </MenuItem>
          ))}
      </Menu>
      <div
        style={
          {
            //zoom: 1.25
          }
        }
        ref={anchorRef}></div>
    </div>
  );
}
