import list from 'src/modules/pregunta/list/preguntaListReducers';
import form from 'src/modules/pregunta/form/preguntaFormReducers';
import view from 'src/modules/pregunta/view/preguntaViewReducers';
import destroy from 'src/modules/pregunta/destroy/preguntaDestroyReducers';
import importerReducer from 'src/modules/pregunta/importer/preguntaImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
