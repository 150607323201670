import {ToggleButton, ToggleButtonGroup} from '@mui/material';
import {withStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {useFormContext, useWatch} from 'react-hook-form';

const DAYS = [
  {
    key: 'domingo',
    label: 'DOM',
  },
  {
    key: 'lunes',
    label: 'LUN',
  },
  {
    key: 'martes',
    label: 'MAR',
  },
  {
    key: 'miércoles',
    label: 'MIE',
  },
  {
    key: 'jueves',
    label: 'JUE',
  },
  {
    key: 'viernes',
    label: 'VIE',
  },
  {
    key: 'sábado',
    label: 'SAB',
  },
  {
    key: 'domingo',
    label: 'DOM',
  },
];

const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    margin: theme.spacing(2),
    padding: theme.spacing(0, 1),
    '&:not(:first-child)': {
      border: '1px solid',
      borderColor: '#3f51b5',
      borderRadius: '50%',
    },
    '&:first-child': {
      border: '1px solid',
      borderColor: '#3f51b5',
      borderRadius: '50%',
    },
  },
}))(ToggleButtonGroup);

const StyledToggle = withStyles({
  root: {
    color: '#3f51b5',
    '&$selected': {
      color: 'white',
      background: '#3f51b5',
    },
    '&:hover': {
      borderColor: '#3f51b573',
      background: '#3f51b573',
    },
    '&:hover$selected': {
      borderColor: '#3f51b573',
      background: '#3f51b573',
    },
    minWidth: 32,
    maxWidth: 32,
    height: 32,
    textTransform: 'unset',
    fontSize: '0.75rem',
  },
  selected: {},
})(ToggleButton);

//const dias_semana = ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado', 'domingo'];

export function DayOfWeekPicker(props) {
  const {name, defaultValue, onChange} = props;

  const firstDay = props.firstDay || 'monday';
  const daysOfWeek = firstDay === 'monday' ? DAYS.slice(1) : DAYS.slice(0, -1);

  const {control, getValues, register, setValue} = useFormContext();
  const days: any = useWatch({name: name, control, defaultValue});

  useEffect(() => {
    register(name);
    setValue(name, days);
  }, []);

  const handleDaysChanged = (event, value) => {
    value = value.sort((a, b) => a - b);
    setValue(name, value);
    onChange && onChange(value);
  };

  return (
    <>
      <StyledToggleButtonGroup size="small" arial-label="Días de la semana" value={days} onChange={handleDaysChanged}>
        {daysOfWeek.map((day, index) => (
          <StyledToggle key={day.key} value={firstDay === 'monday' ? index + 1 : index} aria-label={day.key}>
            {day.label}
          </StyledToggle>
        ))}
      </StyledToggleButtonGroup>
    </>
  );
}

DayOfWeekPicker.defaultProps = {
  required: false,
};

DayOfWeekPicker.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  label: PropTypes.string,
  hint: PropTypes.string,
  autoFocus: PropTypes.bool,
  size: PropTypes.string,
  placeholder: PropTypes.string,
  externalErrorMessage: PropTypes.string,
  disabled: PropTypes.bool,
  style: PropTypes.any,
  firstDay: PropTypes.any,
  defaultValue: PropTypes.any,
  onChange: PropTypes.func,
};

export default DayOfWeekPicker;
