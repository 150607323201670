import moment from 'moment-timezone';
import {getLanguageCode} from 'src/i18n';
import actions from 'src/modules/layout/layoutActions';

const initialData = {
  menuVisible: true,
  language: getLanguageCode(),
  loading: false,
  drawerId: null,
  recordId: null,
  drawerVisible: false,
  drawerChanged: moment().unix(),
};

export default (state = initialData, {type, payload}) => {
  if (type === actions.MENU_TOGGLE) {
    return {
      ...state,
      menuVisible: !state.menuVisible,
    };
  }

  if (type === actions.MENU_SHOW) {
    return {
      ...state,
      menuVisible: true,
    };
  }

  if (type === actions.MENU_HIDE) {
    return {
      ...state,
      menuVisible: false,
    };
  }

  if (type === actions.DRAWER_VISIBLE) {
    return {
      ...state,
      drawerId: payload.drawerId,
      drawerVisible: payload.drawerVisible,
      drawerChanged: payload.drawerChanged,
      drawerUpdateComponent: payload.drawerUpdateComponent,
      recordId: payload.recordId,
    };
  }

  return state;
};
