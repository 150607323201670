import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.objetivo.fields.id'),
  },
  {
    name: 'nombre',
    label: i18n('entities.objetivo.fields.nombre'),
  },
  {
    name: 'descripcion',
    label: i18n('entities.objetivo.fields.descripcion'),
  },
  {
    name: 'sucursales',
    label: i18n('entities.objetivo.fields.sucursales'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'categorias',
    label: i18n('entities.objetivo.fields.categorias'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'incluirEstadisticas',
    label: i18n('entities.objetivo.fields.incluirEstadisticas'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.objetivo.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.objetivo.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
