import {styled} from '@mui/material/styles';

const ContentWrapper = styled('div')({
  marginTop: 5,
  padding: '24px',
  paddingTop: '18px',
  paddingBottom: '5px',
  backgroundColor: '#fff',
  width: '100%',
  border: '1px solid #dee2e6 !important',
  borderRadius: '5px',
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
});

export default ContentWrapper;
