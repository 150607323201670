const notificacionEnumerators = {
  tipoDestino: ['Usuarios', 'Puestos'],
  relacionadoA: [
    'Evento',
    'Comunicacion',
    'Mensajes',
    'Usuario',
    'Tarea',
    'Planificacion',
    'Firma',
    'Inventario',
    'Historial de Inventario',
    'GestorDocumental',
    'Tercero',
  ],
  estado: ['Pendiente', 'Enviada'],
};

export default notificacionEnumerators;
