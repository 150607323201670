import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';
import authAxios from 'src/modules/shared/axios/authAxios';

export default class DataService {
  static async destroyAll(entity, data) {
    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(`/tenant/${tenantId}/data/entity/${entity}/delete`, data);

    return response.data;
  }

  static async updateStructure(entity, structure) {
    const tenantId = AuthCurrentTenant.get();

    const body = {
      structure: structure,
    };

    const response = await authAxios.post(`/tenant/${tenantId}/data/entity/${entity}/update-structure`, body);

    return response.data;
  }

  static async updatePermissions(entity, users) {
    const tenantId = AuthCurrentTenant.get();

    const body = {
      users: users,
    };

    const response = await authAxios.post(`/tenant/${tenantId}/data/entity/${entity}/update-permissions`, body);

    return response.data;
  }

  static async upsertOne(entity, data, action = 'create') {
    const tenantId = AuthCurrentTenant.get();

    const body = {
      data: [data],
      action: action,
    };

    const response = await authAxios.post(`/tenant/${tenantId}/data/entity/${entity}/upsert`, body);

    return response.data;
  }

  static async import(entity, values, importHash) {
    const body = {
      data: values,
      importHash,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(`/tenant/${tenantId}/data/entity/${entity}/upsert`, body);
    //const response = await authAxios.post(`/tenant/${tenantId}/data/entity/${entity}/import`, body);

    return response.data;
  }

  static async find(entity, data) {
    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(`/tenant/${tenantId}/data/entity/${entity}/find-one`, data);

    return response.data;
  }

  static async list(entity, {filter, orderBy, limit, offset, exportAllRows = undefined}: any) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
      exportAllRows,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(`/tenant/${tenantId}/data/entity/${entity}/list`, params);

    return response.data;
  }

  // static async listAutocomplete(query, limit) {
  //   const params = {
  //     query,
  //     limit,
  //   };

  //   const tenantId = AuthCurrentTenant.get();

  //   const response = await authAxios.get(`/tenant/${tenantId}/data-lake/autocomplete`, {
  //     params,
  //   });

  //   return response.data;
  // }

  static makeStructure(items, structure = {}) {
    if (!structure) structure = {};
    const defaultStructure: any = {
      type: 'string',
      required: false,
      default: null,
      options: null,
      fixed: false,
    };

    const itemsTypes = DataService.getItemAttributeDataTypes(items);

    Object.keys(itemsTypes).forEach((field) => {
      const type = itemsTypes[field];
      if (structure[field] && structure[field].fixed) {
        return;
      }
      structure[field] = {
        ...defaultStructure,
        ...structure[field],
        type: type,
      };
    });

    const fixedFields = ['dataId', 'dateStart', 'dateEnd'];
    //console.log('%c⧭ structure, fixedFields', 'color: #8c0038', structure, fixedFields);
    const entityCustomFields = Object.keys(structure).filter((field) => !fixedFields.includes(field));
    return {
      entityStructure: structure,
      entityCustomFields: entityCustomFields,
    };
  }

  static getDataType(value) {
    if (typeof value === 'object' && value instanceof Date) {
      return 'datetime';
    } else if (typeof value === 'string') {
      const dateTimeFormats = [
        /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/, // ISO 8601
        /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/, // ISO 8601 without milliseconds
        /^\d{4}\/\d{2}\/\d{2} \d{2}:\d{2}:\d{2}$/, // yyyy/MM/dd HH:mm:ss
        /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/, // yyyy-MM-dd HH:mm:ss
      ];
      const dateFormats = [
        /^\d{4}\/\d{2}\/\d{2}$/, // yyyy/MM/dd
        /^\d{4}-\d{2}-\d{2}$/, // yyyy-MM-dd
      ];
      if (dateTimeFormats.some((format) => format.test(value))) {
        return 'datetime';
      }
      if (dateFormats.some((format) => format.test(value))) {
        return 'date';
      }
    }

    return typeof value;
  }

  static getItemAttributeDataTypes(items) {
    const attributeDataTypes = {};

    items?.forEach((item) => {
      for (const key in item) {
        if (item.hasOwnProperty(key)) {
          if (!item[key]) continue;

          const valueType = DataService.getDataType(item[key]);

          attributeDataTypes[key] = valueType;
          // if (!attributeDataTypes[key]) {
          //   attributeDataTypes[key] = [valueType];
          // } else if (!attributeDataTypes[key].includes(valueType)) {
          //   attributeDataTypes[key].push(valueType);
          // }
        }
      }
    });

    return attributeDataTypes;
  }
}
