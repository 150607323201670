import list from 'src/modules/firma/list/firmaListReducers';
import form from 'src/modules/firma/form/firmaFormReducers';
import view from 'src/modules/firma/view/firmaViewReducers';
import destroy from 'src/modules/firma/destroy/firmaDestroyReducers';
import importerReducer from 'src/modules/firma/importer/firmaImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
