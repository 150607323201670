import {yupResolver} from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import {Button, Grid} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {i18n} from 'src/i18n';
import settingsThemeConverter from 'src/modules/settings/settingsThemeConverter';
import yupFormSchemas from 'src/modules/shared/yup/yupFormSchemas';
import {magicFlexBox} from 'src/theme/schemes/CustomTheme';
import MaterialUIColorTool from 'src/view/settings/MaterialUIColorTool';
import InputFormItem from 'src/view/shared/form/items/InputFormItem';
import InputNumberFormItem from 'src/view/shared/form/items/InputNumberFormItem';
import FormWrapper, {FormButtons} from 'src/view/shared/styles/FormWrapper';
import * as yup from 'yup';
import nivelPrioridadEnumerators from '../../../modules/nivelPrioridad/nivelPrioridadEnumerators';
import SelectFormItem from '../../shared/form/items/SelectFormItem';
import TextAreaFormItem from '../../shared/form/items/TextAreaFormItem';

const schema = yup.object().shape({
  clasificacion: yupFormSchemas.enumerator(i18n('entities.nivelPrioridad.fields.clasificacion'), {
    options: nivelPrioridadEnumerators.clasificacion,
    required: true,
  }),
  nombre: yupFormSchemas.string(i18n('entities.nivelPrioridad.fields.nombre'), {
    required: true,
  }),
  indicaciones: yupFormSchemas.string(i18n('entities.nivelPrioridad.fields.indicaciones'), {
    required: false,
  }),
  nivel: yupFormSchemas.integer(i18n('entities.nivelPrioridad.fields.nivel'), {
    required: true,
  }),
  color: yup
    .string()
    .transform((cv, ov) => {
      return ov === '' ? null : cv;
    })
    .nullable(true)
    .trim()
    .label(i18n('entities.nivelPrioridad.fields.color'))
    .required()
    .transform((_, originalValue) => {
      return settingsThemeConverter.toString(originalValue);
    }),
});

function NivelPrioridadForm(props) {
  const [materialUIColorToolKey, setMaterialUIColorToolKey] = useState(0);

  const [initialValues] = useState(() => {
    const record = props.record || {};

    return {
      color: settingsThemeConverter.fromString(record && record.color),
      nombre: record.nombre,
      clasificacion: record.clasificacion,
      indicaciones: record.indicaciones,
      nivel: record.nivel,
    };
  });

  const form = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: initialValues as any,
  });

  const onSubmit = (values) => {
    props.onSubmit(props.record?.id, values);
  };

  const onReset = () => {
    Object.keys(initialValues).forEach((key) => {
      form.setValue(key, initialValues[key]);
    });
  };

  useEffect(() => {
    form.register('color');
  }, []);

  const {saveLoading, modal} = props;

  return (
    <FormWrapper>
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          style={
            {
              ...magicFlexBox,
              overflow: 'auto',
              paddingRight: 16,
              marginBottom: 8,
            } as any
          }>
          <Grid spacing={2} container>
            <Grid item lg={7} md={8} sm={12} xs={12}>
              <SelectFormItem
                name="clasificacion"
                label={i18n('entities.nivelPrioridad.fields.clasificacion')}
                options={nivelPrioridadEnumerators.clasificacion.map((value) => ({
                  value,
                  label: i18n(`entities.nivelPrioridad.enumerators.clasificacion.${value}`),
                }))}
                required={true}
              />
            </Grid>

            <Grid item lg={7} md={8} sm={12} xs={12}>
              <InputFormItem name="nombre" label={i18n('entities.nivelPrioridad.fields.nombre')} required={true} autoFocus />
            </Grid>

            <Grid item lg={7} md={8} sm={12} xs={12}>
              <TextAreaFormItem
                name="indicaciones"
                label={i18n('entities.nivelPrioridad.fields.indicaciones')}
                required={false}
                autoFocus
              />
            </Grid>
            <Grid item lg={7} md={8} sm={12} xs={12}>
              <InputNumberFormItem name="nivel" label={i18n('entities.nivelPrioridad.fields.nivel')} required={true} />
            </Grid>

            <Grid item lg={7} md={8} sm={12} xs={12}>
              <MaterialUIColorTool
                key={materialUIColorToolKey}
                onChange={(value) => form.setValue('color', value, {shouldValidate: true})}
                defaultValue={form.watch('color')}
              />
            </Grid>
          </Grid>
        </form>
        <FormButtons
          style={{
            flexDirection: modal ? 'row-reverse' : undefined,
          }}>
          <Button
            variant="contained"
            color="primary"
            disabled={saveLoading}
            type="button"
            onClick={form.handleSubmit(onSubmit)}
            startIcon={<SaveIcon />}
            size="small">
            {i18n('common.save')}
          </Button>

          <Button disabled={saveLoading} onClick={onReset} type="button" startIcon={<UndoIcon />} size="small">
            {i18n('common.reset')}
          </Button>

          {props.onCancel ? (
            <Button disabled={saveLoading} onClick={() => props.onCancel()} type="button" startIcon={<CloseIcon />} size="small">
              {i18n('common.cancel')}
            </Button>
          ) : null}
        </FormButtons>
      </FormProvider>
    </FormWrapper>
  );
}

export default NivelPrioridadForm;
