import React, {useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {useSelector} from 'react-redux';
import selectors from 'src/modules/planificacion/planificacionSelectors';
import PlanificacionService from 'src/modules/planificacion/planificacionService';
import PlanificacionFormModal from 'src/view/planificacion/form/PlanificacionFormModal';
import AutocompleteInMemoryFormItem from 'src/view/shared/form/items/AutocompleteInMemoryFormItem';

function PlanificacionAutocompleteFormItem(props) {
  const {setValue, getValues} = useFormContext();

  const [modalVisible, setModalVisible] = useState(false);

  const hasPermissionToCreate = useSelector(selectors.selectPermissionToCreate);

  const doCloseModal = () => {
    setModalVisible(false);
  };

  const doOpenModal = () => {
    setModalVisible(true);
  };

  const doCreateSuccess = (record) => {
    const {name, mode} = props;

    if (mode && mode === 'multiple') {
      setValue(name, [...(getValues()[name] || []), record]);
    } else {
      setValue(name, record);
    }

    doCloseModal();
  };

  const fetchFn = (value, limit) => {
    if (props.filter) {
      return PlanificacionService.listAutocomplete({value, filter: props.filter}, limit);
    } else {
      return PlanificacionService.listAutocomplete(value, limit);
    }
  };

  const mapper = {
    toAutocomplete(originalValue) {
      if (!originalValue) {
        return null;
      }

      const value = originalValue.id;
      let label = originalValue.label;

      if (originalValue.nombre) {
        label = originalValue.nombre;
      }

      return {
        key: value,
        value,
        label,
        fechaEstimadaInicio: originalValue.fechaEstimadaInicio,
        fechaEstimadaFin: originalValue.fechaEstimadaFin,
        presupuestoEstimado: originalValue.presupuestoEstimado,
      };
    },

    toValue(originalValue) {
      if (!originalValue) {
        return null;
      }

      return {
        id: originalValue.value,
        label: originalValue.label,
        fechaEstimadaInicio: originalValue.fechaEstimadaInicio,
        fechaEstimadaFin: originalValue.fechaEstimadaFin,
        presupuestoEstimado: originalValue.presupuestoEstimado,
      };
    },
  };

  return (
    <>
      <AutocompleteInMemoryFormItem
        {...props}
        entity="planificacion"
        fetchFn={fetchFn}
        mapper={mapper}
        onOpenModal={doOpenModal}
        hasPermissionToCreate={hasPermissionToCreate}
      />

      {modalVisible && <PlanificacionFormModal onClose={doCloseModal} onSuccess={doCreateSuccess} />}
    </>
  );
}

export default PlanificacionAutocompleteFormItem;
