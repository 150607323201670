import * as React from 'react';
import {useEffect, useState} from 'react';

import {Box, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Typography} from '@mui/material';
import {styled} from '@mui/material/styles';
import {useTranslation} from 'react-i18next';
import {i18n} from 'src/i18n';

const TableWrapper = styled(Box)(
  ({theme}: any) => `
  border: 1px solid ${theme.colors.alpha.black[10]};
  border-bottom: 0;
  margin: ${theme.spacing(4)} 0;
  margin-top: 0;
`,
);

export default function RecursoTableRows(props) {
  const {rows} = props;
  const {t} = useTranslation();
  const [total, setTotal] = useState(0);

  useEffect(() => {
    let total = 0;

    rows.map((item) => (total = total + item.total));
    setTotal(total);
  }, [rows]);

  return (
    <TableWrapper>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('Detalle')}</TableCell>
              <TableCell>{t('Tipo')}</TableCell>
              <TableCell>{t('Cantidad')}</TableCell>
              <TableCell>{t('Costo Unitario')}</TableCell>
              <TableCell>{t('Total')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((item) => (
              <TableRow key={item.id}>
                <TableCell>
                  <Typography noWrap>{item.descripcion}</Typography>
                </TableCell>
                <TableCell>{item.tipo ? i18n(`entities.recurso.enumerators.tipo.${item.tipo}`) : null}</TableCell>
                <TableCell>{item.cantidad}</TableCell>
                <TableCell>
                  {new Intl.NumberFormat('es-CL', {
                    currency: 'CLP',
                    style: 'decimal',
                  }).format(item.total / item.cantidad)}
                </TableCell>
                <TableCell>
                  {new Intl.NumberFormat('es-CL', {
                    currency: 'CLP',
                    style: 'decimal',
                  }).format(item.total)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={0}></TableCell>
              <TableCell colSpan={4} align="right">
                <Typography gutterBottom variant="caption" color="text.secondary" fontWeight="bold">
                  {t('Total')}:
                </Typography>
                <Typography variant="h3" fontWeight="bold">
                  {new Intl.NumberFormat('es-CL', {
                    currency: 'CLP',
                    style: 'decimal',
                  }).format(total)}
                </Typography>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </TableWrapper>
  );
}
