import {yupResolver} from '@hookform/resolvers/yup';
import {Add, Remove} from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import {Button, Card, Grid, IconButton, TextField, Typography} from '@mui/material';
import React, {useState} from 'react';
import {FormProvider, useFieldArray, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {i18n} from 'src/i18n';
import authSelectors from 'src/modules/auth/authSelectors';
import yupFormSchemas from 'src/modules/shared/yup/yupFormSchemas';
import actions from 'src/modules/user/form/userFormActions';
import userEnumerators from 'src/modules/user/userEnumerators';
import {magicFlexBox} from 'src/theme/schemes/CustomTheme';
import InputFormItem from 'src/view/shared/form/items/InputFormItem';
import SelectFormItem from 'src/view/shared/form/items/SelectFormItem';
import FormWrapper, {FormButtons} from 'src/view/shared/styles/FormWrapper';
import * as yup from 'yup';

const schema = yup.object().shape({
  roles: yupFormSchemas.stringArray(i18n('user.fields.roles')),
  sucursales: yupFormSchemas.relationToMany(i18n('user.fields.sucursales'), {}),
  puesto: yupFormSchemas.relationToOne(i18n('user.fields.puesto'), {}),
});

function UserEditForm(props) {
  const dispatch = useDispatch();

  const [initialValues] = useState(() => {
    const initUser = props.user || {};
    if (!initUser.dashboardLinks) {
      initUser.dashboardLinks = [{slug: '', name: '', description: '', img: '', link: ''}];
    }
    return initUser;
  });

  const form = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: initialValues,
  });

  const {
    fields: dashboardLinks,
    remove: dashboardLinksRemove,
    append: dashboardLinksAppend,
    insert: dashboardLinksInsert,
    move: dashboardLinksMove,
  } = useFieldArray({
    control: form.control,
    name: 'dashboardLinks',
  });

  const onSubmit = (values) => {
    const data = {
      id: props.user.id,
      ...initialValues,
      ...values,
    };
    delete data.email;
    dispatch(actions.doUpdate(data, props.drawer));
    if (props.onSubmit) props.onSubmit(values);
  };

  const onReset = () => {
    Object.keys(initialValues).forEach((key) => {
      form.setValue(key, initialValues[key]);
    });
  };

  const currentTenant = useSelector(authSelectors.selectCurrentTenant);
  const currentUser = useSelector(authSelectors.selectCurrentUser);

  const isLuxottica = currentTenant?.id === '66900c307b9c0b7b3e460a64';

  var roles: any;
  roles = [];

  currentUser.tenants
    .filter((tenant) => tenant.tenant.id === currentTenant.id)
    .map((user) => {
      user.roles.map((role) => {
        roles.push(role);
      });
    });

  return (
    <FormWrapper>
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          style={
            {
              ...magicFlexBox,
              paddingBottom: 18,
              paddingRight: 18,
              overflow: 'auto',
            } as any
          }>
          <Grid spacing={2} container>
            {props.drawer && (
              <Grid item xs={12}>
                <h1>{i18n('user.edit.title')}</h1>
              </Grid>
            )}
            <Grid item lg={7} md={8} sm={12} xs={12}>
              <TextField
                id="email"
                name="email"
                label={i18n('user.fields.email')}
                value={props.user.email}
                fullWidth
                margin={props.margin || 'normal'}
                InputProps={{
                  readOnly: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                size="small"
              />
            </Grid>

            <Grid item lg={7} md={8} sm={12} xs={12}>
              <SelectFormItem
                name="roles"
                label={i18n('user.fields.roles')}
                options={userEnumerators.roles
                  .filter((value) => (value === 'admin' && roles.includes('admin')) || value !== 'admin')
                  .map((value) => ({
                    value,
                    label: i18n(`roles.${value}.label`),
                  }))}
                mode="multiple"
              />
            </Grid>

            {!isLuxottica && (
              <Grid item lg={7} md={8} sm={12} xs={12}>
                <InputFormItem name="dashboardLink" required={false} label={i18n('user.fields.dashboardLink')} />
              </Grid>
            )}
            {isLuxottica && (
              <Grid item xs={12}>
                <Typography variant="h6">Dashboard Links</Typography>
                <Grid container gap={2}>
                  {dashboardLinks.map((item, index) => (
                    <Grid key={item.id} item lg={7} md={8} sm={11} xs={11}>
                      <Card sx={{p: 2}}>
                        <InputFormItem name={`dashboardLinks[${index}].slug`} label="Slug" required />
                        <InputFormItem name={`dashboardLinks[${index}].name`} label="Nombre" required />
                        <InputFormItem name={`dashboardLinks[${index}].link`} label="Link" required />
                        <InputFormItem name={`dashboardLinks[${index}].description`} label="Descripción" />
                        <InputFormItem name={`dashboardLinks[${index}].img`} label="Image URL" />
                      </Card>
                      <Grid item xs={1} sx={{float: 'right'}}>
                        <IconButton color="secondary" onClick={() => dashboardLinksRemove(index)}>
                          <Remove />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
                <Button
                  type="button"
                  disabled={props.saveLoading}
                  onClick={() => dashboardLinksAppend({slug: '', name: '', description: '', img: '', link: ''})}
                  startIcon={<Add />}
                  size="small">
                  Agregar Link
                </Button>
              </Grid>
            )}

            {/* <Grid item lg={7} md={8} sm={12} xs={12}>
              <SucursalAutocompleteFormItem
                name="sucursales"
                label={i18n('user.fields.sucursales')}
                required={false}
                showCreate={!props.modal}
                mode="multiple"
              />
            </Grid> */}

            {/* <Grid item lg={7} md={8} sm={12} xs={12}>
              <PuestoAutocompleteFormItem
                name="puesto"
                label={i18n('user.fields.puesto')}
                required={false}
                showCreate={!props.modal}
              />
            </Grid> */}
          </Grid>
        </form>

        <FormButtons>
          <Button
            variant="contained"
            color="primary"
            disabled={props.saveLoading}
            type="button"
            onClick={form.handleSubmit(onSubmit)}
            startIcon={<SaveIcon />}
            size="small">
            {i18n('common.save')}
          </Button>

          <Button disabled={props.saveLoading} onClick={onReset} type="button" startIcon={<UndoIcon />} size="small">
            {i18n('common.reset')}
          </Button>

          {props.onCancel ? (
            <Button
              disabled={props.saveLoading}
              onClick={() => props.onCancel()}
              type="button"
              startIcon={<CloseIcon />}
              size="small">
              {i18n('common.cancel')}
            </Button>
          ) : null}
        </FormButtons>
      </FormProvider>
    </FormWrapper>
  );
}

export default UserEditForm;
