import moment from 'moment-timezone';
import {i18n} from 'src/i18n';

const exporterRenders = {
  stringArray: () => (value) => (value || []).join(' '),
  json: () => (value) => value ? JSON.stringify(value, null, 2) : null,
  decimal: (fractionDigits?) => (value) =>
    value ? (fractionDigits ? Number(value).toFixed(fractionDigits) : Number(value)) : null,
  boolean: () => (value) => String(Boolean(value)),
  relationToOneMap: (mapFn) => (value) => mapFn(value) || null,
  relationToOne: () => (value) => (value && value.id) || null,
  relationToManyMap: (mapFn) => (value) => (value || []).map(mapFn).join(', '),
  relationToMany: () => (value) => (value || []).map((item) => item.id).join(' '),
  filesOrImages: () => (value) => (value || []).map((item) => item.downloadUrl).join(' '),
  datetime: () => (value) => value ? moment(value).format('DD-MM-YYYY HH:mm') : null,
  enumerator: (i18nLabel, defaultValue) => (value) => {
    if (!value && !defaultValue) {
      return value;
    }
    const label = i18n(`${i18nLabel}.${value || defaultValue}`);
    return label;
  },
};

export default exporterRenders;
