import {yupResolver} from '@hookform/resolvers/yup';
import {Search as SearchIcon} from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import UndoIcon from '@mui/icons-material/Undo';
import {AccordionDetails, AccordionSummary, Button, Grid} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {i18n} from 'src/i18n';
import gestorDocumentalEnumerators from 'src/modules/gestorDocumental/gestorDocumentalEnumerators';
import actions from 'src/modules/gestorDocumental/list/gestorDocumentalListActions';
import selectors from 'src/modules/gestorDocumental/list/gestorDocumentalListSelectors';
import filterRenders from 'src/modules/shared/filter/filterRenders';
import yupFilterSchemas from 'src/modules/shared/yup/yupFilterSchemas';
import FilterAccordion from 'src/view/shared/filter/FilterAccordion';
import FilterPreview from 'src/view/shared/filter/FilterPreview';
import DatePickerRangeFormItem from 'src/view/shared/form/items/DatePickerRangeFormItem';
import InputFormItem from 'src/view/shared/form/items/InputFormItem';
import SelectFormItem from 'src/view/shared/form/items/SelectFormItem';
import FilterWrapper, {FilterButtons} from 'src/view/shared/styles/FilterWrapper';
import UbicacionAutocompleteFormItem from 'src/view/ubicacion/autocomplete/UbicacionAutocompleteFormItem';
import * as yup from 'yup';

const schema = yup.object().shape({
  garantia: yupFilterSchemas.boolean(i18n('entities.gestorDocumental.fields.garantia')),
  documentoPrincipal: yupFilterSchemas.relationToOne(i18n('entities.gestorDocumental.fields.documentoPrincipal')),
  nombre: yupFilterSchemas.string(i18n('entities.gestorDocumental.fields.nombre')),
  descripcion: yupFilterSchemas.string(i18n('entities.gestorDocumental.fields.descripcion')),
  tipoDocumento: yupFilterSchemas.enumerator(i18n('entities.gestorDocumental.fields.tipoDocumento')),
  version: yupFilterSchemas.string(i18n('entities.gestorDocumental.fields.version')),
  validoDesdeRange: yupFilterSchemas.dateRange(i18n('entities.gestorDocumental.fields.validoDesdeRange')),
  validoHastaRange: yupFilterSchemas.dateRange(i18n('entities.gestorDocumental.fields.validoHastaRange')),
  autor: yupFilterSchemas.string(i18n('entities.gestorDocumental.fields.autor')),
  filtroVencimiento: yupFilterSchemas.enumerator(i18n('entities.gestorDocumental.fields.filtroVencimiento')),
  locations: yupFilterSchemas.relationToMany(i18n('common.locations')),
});

const emptyValues = {
  garantia: null,
  documentoPrincipal: null,
  nombre: null,
  descripcion: null,
  tipoDocumento: null,
  version: null,
  validoDesdeRange: [null, null],
  validoHastaRange: [null, null],
  autor: null,
  filtroVencimiento: null,
  locations: null,
};

const previewRenders = {
  garantia: {
    label: i18n('entities.gestorDocumental.fields.garantia'),
    render: filterRenders.boolean(),
  },
  documentoPrincipal: {
    label: i18n('entities.gestorDocumental.fields.documentoPrincipal'),
    render: filterRenders.relationToOne(),
  },
  nombre: {
    label: i18n('entities.gestorDocumental.fields.nombre'),
    render: filterRenders.generic(),
  },
  descripcion: {
    label: i18n('entities.gestorDocumental.fields.descripcion'),
    render: filterRenders.generic(),
  },
  tipoDocumento: {
    label: i18n('entities.gestorDocumental.fields.tipoDocumento'),
    render: filterRenders.enumerator('entities.gestorDocumental.enumerators.tipoDocumento'),
  },
  version: {
    label: i18n('entities.gestorDocumental.fields.version'),
    render: filterRenders.generic(),
  },
  validoDesdeRange: {
    label: i18n('entities.gestorDocumental.fields.validoDesdeRange'),
    render: filterRenders.dateRange(),
  },
  validoHastaRange: {
    label: i18n('entities.gestorDocumental.fields.validoHastaRange'),
    render: filterRenders.dateRange(),
  },
  autor: {
    label: i18n('entities.gestorDocumental.fields.autor'),
    render: filterRenders.generic(),
  },
  filtroVencimiento: {
    label: i18n('entities.gestorDocumental.fields.filtroVencimiento'),
    render: filterRenders.enumerator('entities.gestorDocumental.enumerators.filtroVencimiento'),
  },
  locations: {
    label: i18n('common.locations'),
    render: filterRenders.relationToMany(),
  },
};

function GestorDocumentalListFilter(props) {
  const rawFilter = useSelector(selectors.selectRawFilter);
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(false);

  const [initialValues] = useState(() => {
    return {
      ...emptyValues,
      ...rawFilter,
    };
  });

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialValues,
    mode: 'all',
  });

  useEffect(() => {
    dispatch(actions.doFetch(schema.cast(initialValues), rawFilter));
    // eslint-disable-next-line
  }, [dispatch]);

  const onSubmit = (values) => {
    const rawValues = form.getValues();
    dispatch(actions.doFetch(values, rawValues));
    setExpanded(false);
  };

  const onReset = () => {
    Object.keys(emptyValues).forEach((key) => {
      form.setValue(key, emptyValues[key]);
    });
    dispatch(actions.doReset());
    setExpanded(false);
  };

  const onRemove = (key) => {
    form.setValue(key, emptyValues[key]);
    return form.handleSubmit(onSubmit)();
  };

  return (
    <FilterWrapper>
      <FilterAccordion expanded={expanded} onChange={(event, isExpanded) => setExpanded(isExpanded)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <FilterPreview values={rawFilter} renders={previewRenders} expanded={expanded} onRemove={onRemove} />
        </AccordionSummary>
        <AccordionDetails>
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <Grid container columnSpacing={2}>
                <Grid item lg={6} xs={12}>
                  <SelectFormItem
                    name="garantia"
                    label={i18n('entities.gestorDocumental.fields.garantia')}
                    options={[
                      {
                        value: true,
                        label: i18n('common.yes'),
                      },
                      {
                        value: false,
                        label: i18n('common.no'),
                      },
                    ]}
                  />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <InputFormItem name="nombre" label={i18n('entities.gestorDocumental.fields.nombre')} />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <InputFormItem name="descripcion" label={i18n('entities.gestorDocumental.fields.descripcion')} />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <SelectFormItem
                    name="tipoDocumento"
                    label={i18n('entities.gestorDocumental.fields.tipoDocumento')}
                    options={gestorDocumentalEnumerators.tipoDocumento.map((value) => ({
                      value,
                      label: i18n(`entities.gestorDocumental.enumerators.tipoDocumento.${value}`),
                    }))}
                  />
                </Grid>
                <Grid item lg={3} xs={12}>
                  <SelectFormItem
                    name="filtroVencimiento"
                    label={i18n('entities.gestorDocumental.fields.filtroVencimiento')}
                    options={gestorDocumentalEnumerators.filtroVencimiento.map((value) => ({
                      value,
                      label: i18n(`entities.gestorDocumental.enumerators.filtroVencimiento.${value}`),
                    }))}
                  />
                </Grid>
                <Grid item lg={3} xs={12}>
                  <InputFormItem name="version" label={i18n('entities.gestorDocumental.fields.version')} />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <DatePickerRangeFormItem
                    name="validoDesdeRange"
                    label={i18n('entities.gestorDocumental.fields.validoDesdeRange')}
                  />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <DatePickerRangeFormItem
                    name="validoHastaRange"
                    label={i18n('entities.gestorDocumental.fields.validoHastaRange')}
                  />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <InputFormItem name="autor" label={i18n('entities.gestorDocumental.fields.autor')} />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <UbicacionAutocompleteFormItem name="locations" label={i18n('common.locations')} mode="multiple" />
                </Grid>
              </Grid>

              <FilterButtons>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={props.loading}
                  startIcon={<SearchIcon />}
                  size="small">
                  {i18n('common.search')}
                </Button>

                <Button type="button" onClick={onReset} disabled={props.loading} startIcon={<UndoIcon />} size="small">
                  {i18n('common.reset')}
                </Button>
              </FilterButtons>
            </form>
          </FormProvider>
        </AccordionDetails>
      </FilterAccordion>
    </FilterWrapper>
  );
}

export default GestorDocumentalListFilter;
