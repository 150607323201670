import moment from 'moment-timezone';
import 'moment/locale/es';
import React from 'react';
import {i18n} from 'src/i18n';

import {Add, ArrowDropDown, ArrowRight, Delete, Edit, Map, Search, Sms} from '@mui/icons-material';
import {Box, Checkbox, IconButton, LinearProgress, TableCell, TableRow, Tooltip, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import ActivoListItem from 'src/view/activo/list/ActivoListItem';
import CategoriaListItem from 'src/view/categoria/list/CategoriaListItem';
import ComunicacionInternaFormPage from 'src/view/comunicacionInterna/form/ComunicacionInternaFormPage';
import ComunicacionInternaListItem from 'src/view/comunicacionInterna/list/ComunicacionInternaListItem';
import PlanificacionListItem from 'src/view/planificacion/list/PlanificacionListItem';
import RecursoListPage from 'src/view/recurso/list/RecursoListPage';
import GeolocalizationModal from 'src/view/shared/modals/GeolocalizationModal';
import TableCellCustom from 'src/view/shared/table/TableCellCustom';
import ClasificacionTagView from 'src/view/shared/view/ClasificacionTagView';
import DrawerButton from 'src/view/shared/view/DrawerButton';
import TerceroListItem from 'src/view/tercero/list/TerceroListItem';
import UbicacionListItem from 'src/view/ubicacion/list/UbicacionListItem';
import UserListItem from 'src/view/user/list/UserListItem';
import TareaFormPage from '../form/TareaFormPage';
import TareaListItem from './TareaListItem';

const rgbaLevel = (level, active, hover = false) => {
  //rgba(0, 0, 0, 0); //level 0 inactive
  //rgba(0, 0, 0, 0.05); //level 0 hover inactive
  //rgba(0, 0, 0, 0.08); //level 0 active
  //rgba(0, 150, 250, 0.05); //level even inactive
  //rgba(0, 150, 250, 0.08); //level even hover inactive
  //rgba(0, 150, 250, 0.15); //level even active
  //rgba(25, 25, 255, 0.05); //level uneven inactive
  //rgba(25, 25, 255, 0.08); //level uneven hover inactive
  //rgba(25, 25, 255, 0.15); //level uneven active
  // if (level === 1) { // probar con level 1
  //   if (hover) {
  //     return `rgba(0, 0, 0, 0.05)`;
  //   }
  //   if (active) {
  //     return `rgba(0, 0, 0, 0.08)`;
  //   }
  //   return `rgba(0, 0, 0, 0)`;
  // }
  // if (level % 2 === 0) {
  //   if (hover) {
  //     return `rgba(0, 150, 250, 0.08)`;
  //   }
  //   if (active) {
  //     return `rgba(0, 150, 250, 0.11)`;
  //   }
  //   return `rgba(0, 150, 250, 0.05)`;
  // }
  //
  let levelOpacity = 0.0;
  if (hover) {
    levelOpacity += 0.03;
  }
  if (active) {
    levelOpacity += 0.1;
  }
  levelOpacity += level * 0.05;
  return `rgba(80, 100, 255, ${levelOpacity})`;
  //
  // if (level % 2 === 1) {
  // if (hover) {
  //   return `rgba(25, 25, 255, 0.08)`;
  // }
  // if (active) {
  //   return `rgba(25, 25, 255, 0.11)`;
  // }
  // return `rgba(25, 25, 255, 0.05)`;
};
const borderSides = (level, rowIsActive, isParent, childIndex, childrenLength) => {
  // if top level: no border
  if (level === 0) {
    return rowIsActive && isParent ? `1px 1px 0px 1px` : `0px`;
  }
  // if (childrenLength === 1){
  //   return `1px`; // only child
  // }
  // if (childIndex === 0){
  //   return `1px 1px 0px 1px`; // first child
  // }
  if (childrenLength === 1 || childIndex === childrenLength - 1) {
    return `0px 1px 1px 1px`; // last child
  }
  return `0px 1px 0px 1px`; // middle child
};
const borderLevel = () => {
  let rgba = rgbaLevel(5, false);
  return `solid ${rgba}`;
};

const useStyles = makeStyles((theme: any) => ({
  activeRow: ({level, isParent, rowIsActive, childIndex, childrenLength}: any) => ({
    border: borderLevel(),
    borderWidth: borderSides(level, rowIsActive, isParent, childIndex, childrenLength),
    backgroundColor: rgbaLevel(level, rowIsActive),
    '&:hover': {
      backgroundColor: rgbaLevel(level, rowIsActive, true),
    },
  }),
}));

function TareaNestedRow(props) {
  const {row, level, childIndex, childrenLength} = props;
  const {
    doToggleOneSelected,
    selectedKeys,
    handleRowClick,
    activeRows,
    hasPermissionToCreateRecurso,
    hasPermissionToEdit,
    hasPermissionToDestroy,
    doOpenDestroyConfirmModal,
  } = props.props;

  const [isOpenGeolocation, setIsOpenGeolocation] = React.useState(false);

  const rowIsActive = activeRows.includes(row);
  const isParent = row.children && row.children.length > 0;

  const classes = useStyles({level, isParent, rowIsActive, childIndex, childrenLength});

  const rowClassName = classes.activeRow;

  const canEdit = hasPermissionToEdit && ['Abierta', 'Rechazada', 'FueraPlazo'].includes(row.estado);
  const editTitle = canEdit ? i18n('common.edit') : i18n('common.view');

  const geolocation = row.latitud && row.longitud ? {lat: row.latitud, lng: row.longitud} : null;

  return (
    <React.Fragment key={row.id}>
      <TableRow className={rowClassName} onClick={() => handleRowClick(row)}>
        <TableCell padding="checkbox">
          <IconButton
            size="small"
            disabled={row.children && row.children.length > 0}
            // onClick={(event) => {
            //   event.stopPropagation();
            // }}
          >
            {row.children && row.children.length > 0 ? (
              rowIsActive ? (
                <ArrowDropDown style={{fontSize: 30}} />
              ) : (
                <ArrowRight style={{fontSize: 30}} />
              )
            ) : (
              <ArrowRight style={{visibility: 'hidden'}} />
            )}
          </IconButton>
        </TableCell>
        {!['IncidentFormSeguimiento', 'ActivoForm'].includes(props.from) && (
          <TableCell padding="checkbox">
            <Checkbox checked={selectedKeys.includes(row.id)} onChange={() => doToggleOneSelected(row.id)} size="small" />
          </TableCell>
        )}
        <TableCellCustom size="pad0">
          {!row.comunicacionInterna ? (
            <DrawerButton
              drawerId={'DrawerComunicacionInternaFormPage'}
              type={'icon'}
              width={window.innerWidth * 0.55}
              tooltipTitle={i18n('common.new')}
              buttonTitle={i18n('common.new')}
              buttonIcon={<Sms />}
              stopPropagation={true}
              component={
                <ComunicacionInternaFormPage
                  passProps={{
                    tipo: 'Tarea',
                    id: row.id,
                  }}
                  drawer={true}
                />
              }
            />
          ) : (
            <div style={{marginTop: 6}}>
              <ComunicacionInternaListItem hideButtonTitle value={row.comunicacionInterna} />
            </div>
          )}
        </TableCellCustom>

        <TableCellCustom size="pad0">
          {hasPermissionToCreateRecurso && row.estado !== 'Cerrada' && (
            <DrawerButton
              drawerId={'DrawerRecursoListTable'}
              type={'icon'}
              stopPropagation={true}
              tooltipTitle={i18n('common.addRecursos')}
              buttonTitle={i18n('common.addRecursos')}
              buttonIcon={<Add />}
              width={window.innerWidth * 0.55}
              component={
                <RecursoListPage
                  drawer={true}
                  row={{...row}}
                  filter={{
                    tareasVinculadas: [
                      {
                        id: row.id,
                      },
                    ],
                  }}
                />
              }
            />
          )}
        </TableCellCustom>

        <TableCellCustom size="pad0">
          {geolocation && (
            <Tooltip followCursor title={'Geolocalización'}>
              <IconButton onClick={() => setIsOpenGeolocation(true)}>
                <Map />
              </IconButton>
            </Tooltip>
          )}
          {geolocation && isOpenGeolocation && (
            <GeolocalizationModal record={row} item={geolocation} onClose={() => setIsOpenGeolocation(false)} />
          )}
        </TableCellCustom>

        <TableCellCustom size="pad0">
          <DrawerButton
            drawerId={'DrawerTareaFormPage'}
            type={'icon'}
            stopPropagation={true}
            tooltipTitle={editTitle}
            buttonTitle={editTitle}
            buttonIcon={canEdit ? <Edit /> : <Search />}
            width={window.innerWidth * 0.65}
            preventBackdrop={!!row.id}
            promptBeforeExit={row.id ? '¿Desea salir de la visualización?' : undefined}
            component={<TareaFormPage sendFormSilent={props.sendFormSilent} tarea={row} disabled={!canEdit} drawer={true} />}
          />
        </TableCellCustom>

        <TableCellCustom size="pad0" customStyle={{paddingRight: '15px !important'}}>
          {hasPermissionToDestroy && (
            <Tooltip followCursor title={i18n('common.destroy')}>
              <IconButton onClick={() => doOpenDestroyConfirmModal(row.id)}>
                <Delete />
              </IconButton>
            </Tooltip>
          )}
        </TableCellCustom>
        <TableCell>
          <ClasificacionTagView value={row.prioridad} entity="tarea" fieldName="prioridad" />
        </TableCell>
        {/* <TableCell style={{whiteSpace: 'nowrap'}}>{row.titulo}</TableCell> */}
        <TableCell>
          {/* <ModeloTareaListItem value={row.modeloTarea} /> */}
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            sx={{
              mt: '4px',
              mb: '-4px',
            }}>
            {row.tituloTarea && (
              <Typography
                style={
                  {
                    width: 'max-content',
                    maxWidth: '300px',
                    textWrap: 'pretty',
                  } as any
                }>
                {row.titulo} {row.tituloTarea}
              </Typography>
            )}
            {row.subtitulo && (
              <Typography
                variant="caption"
                style={
                  {
                    width: 'max-content',
                    maxWidth: '300px',
                    textWrap: 'pretty',
                  } as any
                }>
                {row.subtitulo}
              </Typography>
            )}
            <Box
              onClick={props.onClick}
              display="flex"
              alignItems="center"
              sx={{
                mt: '2px',
                mb: '-2px',
              }}>
              <LinearProgress
                sx={{flex: 1, mr: 1, opacity: '0.6', height: '5px'}}
                value={Number(row.progreso?.toFixed(0) || 0)}
                color="primary"
                variant="determinate"
              />
              <Typography variant="subtitle1" fontSize="0.9em">
                {row.progreso?.toFixed(0) || 0}%
              </Typography>
            </Box>
          </Box>
        </TableCell>
        <TableCell>
          <TareaListItem value={row.parentTask} />
        </TableCell>
        {/* <TableCell>{row.dirigidoA ? i18n(`entities.tarea.enumerators.dirigidoA.${row.dirigidoA}`) : null}</TableCell> */}
        <TableCell>
          <ClasificacionTagView value={row.estado} entity="tarea" fieldName="estado" />
          {row.secondaryState && <ClasificacionTagView value={row.secondaryState} entity="tarea" fieldName="secondaryState" />}
        </TableCell>
        {/* <TableCell>
        <ClasificacionTagView value={row.prioridad} entity="tarea" fieldName="prioridad" />
        <ClasificacionTagView value={row.estado} entity="tarea" fieldName="estado" />
      </TableCell> */}
        {/* <TableCell>{row.estadoActivacion ? i18n('common.yes') : i18n('common.no')}</TableCell> */}
        {/* <TableCell>
        <ImagesListView value={row.fotosAdjuntas} />
      </TableCell>
      <TableCell>
        <FilesListView value={row.documentosAdjuntos} />
      </TableCell> */}
        {/* <TableCell>
        <ClasificacionTagView value={row.tipoMantenimiento} entity="tarea" fieldName="tipoMantenimiento" />
      </TableCell> */}
        {/* <TableCell>
        <PlanificadorTareaListItem value={row.planificadorTarea} />
      </TableCell> */}
        <TableCell>
          <UserListItem value={row.ejecutor} />
        </TableCell>
        <TableCell>
          <PlanificacionListItem value={row.planificacion} />
        </TableCell>
        <TableCell>
          <ActivoListItem value={row.activo} />
        </TableCell>
        <TableCell>
          <TerceroListItem value={row.tercero} />
        </TableCell>
        <TableCell style={{whiteSpace: 'nowrap'}}>
          {row.inicioEstimado
            ? moment(row.inicioEstimado).format('DD-MM-YYYY HH:mm')
            : moment(row.fechaProgramacion).format('DD-MM-YYYY')}
        </TableCell>
        <TableCell style={{whiteSpace: 'nowrap'}}>
          {row.finEstimado ? moment(row.finEstimado).format('DD-MM-YYYY HH:mm') : ''}
        </TableCell>
        <TableCell style={{whiteSpace: 'nowrap'}}>
          {row.comienzo ? moment(row.comienzo).format('DD-MM-YYYY HH:mm') : null}
        </TableCell>
        <TableCellCustom size={row.fin ? 'custom110' : undefined}>
          {row.fin ? moment(row.fin).format('DD-MM-YYYY HH:mm') : null}
        </TableCellCustom>
        <TableCell>
          {(() => {
            if (row.comienzo && row.fin) {
              const duration = moment.duration(moment(row.fin).diff(moment(row.comienzo)));
              return `${duration.days()}d ${duration.hours()}h ${duration.minutes()}m`;
            } else if (row.comienzo) {
              const duration = moment.duration(moment().diff(moment(row.comienzo)));
              return `${duration.days()}d ${duration.hours()}h ${duration.minutes()}m`;
            }
            return null;
          })()}
        </TableCell>
        <TableCell style={{whiteSpace: 'nowrap'}}>
          {row.fechaLimite ? moment(row.fechaLimite).format('DD-MM-YYYY') : null}
        </TableCell>
        <TableCell style={{whiteSpace: 'nowrap'}}>{row.expiredAt ? moment(row.expiredAt).format('DD-MM-YYYY') : null}</TableCell>
        <TableCell>
          <CategoriaListItem value={row.categoria} />
        </TableCell>
        <TableCell>
          <UserListItem value={row.aceptadores} />
          <UserListItem value={row.validadores} />
        </TableCell>
        <TableCell>{row.certificated ? 'Sí' : 'No'}</TableCell>
        <TableCell>
          {row.tipoVisualizacion ? i18n(`entities.tarea.enumerators.tipoVisualizacion.${row.tipoVisualizacion}`) : null}
        </TableCell>
        <TableCell>
          <UbicacionListItem value={row.locations} />
        </TableCell>
        <TableCell>
          <UserListItem value={row.createdBy} />
        </TableCell>
        {/* <TableCell>
        {row.enviada
          ? i18n('common.yes')
          : i18n('common.no')}
      </TableCell> */}
        {/* <TableCell>
        {row.esEvaluada
          ? i18n('common.yes')
          : i18n('common.no')}
      </TableCell> */}
      </TableRow>
      {rowIsActive &&
        row.children?.map((cRow, childIndex) => (
          <TareaNestedRow
            key={'TareaNestedRow_' + childIndex}
            {...props}
            row={cRow}
            level={level + 1}
            childIndex={childIndex}
            childrenLength={row.children.length}
          />
        ))}
    </React.Fragment>
  );
}
export default TareaNestedRow;
