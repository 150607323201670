import list from 'src/modules/nivelPrioridad/list/nivelPrioridadListReducers';
import form from 'src/modules/nivelPrioridad/form/nivelPrioridadFormReducers';
import view from 'src/modules/nivelPrioridad/view/nivelPrioridadViewReducers';
import destroy from 'src/modules/nivelPrioridad/destroy/nivelPrioridadDestroyReducers';
import importerReducer from 'src/modules/nivelPrioridad/importer/nivelPrioridadImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
