import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';
import planificadorTareaEnumerators from 'src/modules/planificadorTarea/planificadorTareaEnumerators';

export default [
  {
    name: 'nombre',
    label: i18n('entities.planificadorTarea.fields.nombre'),
    schema: schemas.string(
      i18n('entities.planificadorTarea.fields.nombre'),
      {},
    ),
  },
  {
    name: 'dirigidoA',
    label: i18n('entities.planificadorTarea.fields.dirigidoA'),
    schema: schemas.enumerator(
      i18n('entities.planificadorTarea.fields.dirigidoA'),
      {
        "options": planificadorTareaEnumerators.dirigidoA
      },
    ),
  },
  {
    name: 'empleados',
    label: i18n('entities.planificadorTarea.fields.empleados'),
    schema: schemas.relationToMany(
      i18n('entities.planificadorTarea.fields.empleados'),
      {},
    ),
  },
  {
    name: 'puestos',
    label: i18n('entities.planificadorTarea.fields.puestos'),
    schema: schemas.relationToMany(
      i18n('entities.planificadorTarea.fields.puestos'),
      {},
    ),
  },
  {
    name: 'sucursales',
    label: i18n('entities.planificadorTarea.fields.sucursales'),
    schema: schemas.relationToMany(
      i18n('entities.planificadorTarea.fields.sucursales'),
      {},
    ),
  },
  {
    name: 'tareas',
    label: i18n('entities.planificadorTarea.fields.tareas'),
    schema: schemas.relationToMany(
      i18n('entities.planificadorTarea.fields.tareas'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'activarTiempoLimite',
    label: i18n('entities.planificadorTarea.fields.activarTiempoLimite'),
    schema: schemas.boolean(
      i18n('entities.planificadorTarea.fields.activarTiempoLimite'),
      {},
    ),
  },
  {
    name: 'tipoTiempoLimite',
    label: i18n('entities.planificadorTarea.fields.tipoTiempoLimite'),
    schema: schemas.enumerator(
      i18n('entities.planificadorTarea.fields.tipoTiempoLimite'),
      {
        "options": planificadorTareaEnumerators.tipoTiempoLimite
      },
    ),
  },
  {
    name: 'tiempoLimite',
    label: i18n('entities.planificadorTarea.fields.tiempoLimite'),
    schema: schemas.integer(
      i18n('entities.planificadorTarea.fields.tiempoLimite'),
      {},
    ),
  },
  {
    name: 'programada',
    label: i18n('entities.planificadorTarea.fields.programada'),
    schema: schemas.boolean(
      i18n('entities.planificadorTarea.fields.programada'),
      {},
    ),
  },
  {
    name: 'programar',
    label: i18n('entities.planificadorTarea.fields.programar'),
    schema: schemas.boolean(
      i18n('entities.planificadorTarea.fields.programar'),
      {},
    ),
  },
  {
    name: 'inicioProgramacion',
    label: i18n('entities.planificadorTarea.fields.inicioProgramacion'),
    schema: schemas.date(
      i18n('entities.planificadorTarea.fields.inicioProgramacion'),
      {},
    ),
  },
  {
    name: 'finProgramacion',
    label: i18n('entities.planificadorTarea.fields.finProgramacion'),
    schema: schemas.date(
      i18n('entities.planificadorTarea.fields.finProgramacion'),
      {},
    ),
  },
  {
    name: 'tipoPeriodicidad',
    label: i18n('entities.planificadorTarea.fields.tipoPeriodicidad'),
    schema: schemas.enumerator(
      i18n('entities.planificadorTarea.fields.tipoPeriodicidad'),
      {
        "options": planificadorTareaEnumerators.tipoPeriodicidad
      },
    ),
  },
  {
    name: 'periodicidad',
    label: i18n('entities.planificadorTarea.fields.periodicidad'),
    schema: schemas.integer(
      i18n('entities.planificadorTarea.fields.periodicidad'),
      {},
    ),
  },
  {
    name: 'evaluadores',
    label: i18n('entities.planificadorTarea.fields.evaluadores'),
    schema: schemas.relationToMany(
      i18n('entities.planificadorTarea.fields.evaluadores'),
      {},
    ),
  },
];