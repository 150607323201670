import {yupResolver} from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import {Button, Grid} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {i18n} from 'src/i18n';
import recordatorioEnumerators from 'src/modules/recordatorio/recordatorioEnumerators';
import yupFormSchemas from 'src/modules/shared/yup/yupFormSchemas';
import InputNumberFormItem from 'src/view/shared/form/items/InputNumberFormItem';
import RadioFormItem from 'src/view/shared/form/items/RadioFormItem';
import FormWrapper, {FormButtons} from 'src/view/shared/styles/FormWrapper';
import * as yup from 'yup';

const schema = yup.object().shape({
  nombre: yupFormSchemas.string(i18n('entities.recordatorio.fields.nombre'), {
    required: true,
  }),
  tipo: yupFormSchemas.enumerator(i18n('entities.recordatorio.fields.tipo'), {
    options: recordatorioEnumerators.tipo,
  }),
  cantidad: yupFormSchemas.integer(i18n('entities.recordatorio.fields.cantidad'), {
    required: true,
  }),
});

function RecordatorioForm(props) {
  const [initialValues] = useState(() => {
    const record = props.record || {};

    return {
      nombre: record.nombre,
      tipo: record.tipo,
      cantidad: record.cantidad,
    };
  });

  useEffect(() => {
    form.register('nombre');
  }, []);

  const form = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: initialValues as any,
  });

  const onSubmit = (values) => {
    props.onSubmit(props.record?.id, values);
  };

  const onReset = () => {
    Object.keys(initialValues).forEach((key) => {
      form.setValue(key, initialValues[key]);
    });
  };

  const {saveLoading, modal} = props;

  return (
    <FormWrapper>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Grid spacing={2} container>
            <Grid item lg={3} md={8} sm={12} xs={12}>
              <InputNumberFormItem
                name="cantidad"
                required={true}
                placeholder="Seleccione cantidad de días, meses, años"
                onChange={() =>
                  form.setValue(
                    'nombre',
                    form.watch('cantidad') +
                      ' ' +
                      i18n(`entities.recordatorio.enumerators.tipo.${form.watch('tipo')}`) +
                      ' antes',
                  )
                }
              />
            </Grid>
            <Grid style={{marginTop: 15}} item lg={4} md={8} sm={12} xs={12}>
              <RadioFormItem
                name="tipo"
                options={recordatorioEnumerators.tipo.map((value) => ({
                  value,
                  label: i18n(`entities.recordatorio.enumerators.tipo.${value}`),
                }))}
                onChange={() =>
                  form.setValue(
                    'nombre',
                    form.watch('cantidad') +
                      ' ' +
                      i18n(`entities.recordatorio.enumerators.tipo.${form.watch('tipo')}`) +
                      ' antes',
                  )
                }
                required={false}
              />
            </Grid>
            <Grid item lg={7} md={8} sm={12} xs={12}>
              {form.watch('cantidad') && form.watch('tipo') && <h2 style={{color: 'gray'}}>{form.watch('nombre')}</h2>}
            </Grid>
          </Grid>
          <FormButtons
            style={{
              flexDirection: modal ? 'row-reverse' : undefined,
            }}>
            <Button
              variant="contained"
              color="primary"
              disabled={saveLoading}
              type="button"
              onClick={form.handleSubmit(onSubmit)}
              startIcon={<SaveIcon />}
              size="small">
              {i18n('common.save')}
            </Button>

            <Button disabled={saveLoading} onClick={onReset} type="button" startIcon={<UndoIcon />} size="small">
              {i18n('common.reset')}
            </Button>

            {props.onCancel ? (
              <Button
                disabled={saveLoading}
                onClick={() => props.onCancel()}
                type="button"
                startIcon={<CloseIcon />}
                size="small">
                {i18n('common.cancel')}
              </Button>
            ) : null}
          </FormButtons>
        </form>
      </FormProvider>
    </FormWrapper>
  );
}

export default RecordatorioForm;
