import {combineReducers} from 'redux';
import destroy from 'src/modules/absence/destroy/absenceDestroyReducers';
import form from 'src/modules/absence/form/absenceFormReducers';
import importerReducer from 'src/modules/absence/importer/absenceImporterReducers';
import list from 'src/modules/absence/list/absenceListReducers';
import view from 'src/modules/absence/view/absenceViewReducers';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
