import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import {i18n} from 'src/i18n';
import recursoEnumerators from 'src/modules/recurso/recursoEnumerators';
import moment from 'moment-timezone';

export default [
  {
    name: 'descripcion',
    label: i18n('entities.recurso.fields.descripcion'),
    schema: schemas.string(i18n('entities.recurso.fields.descripcion'), {
      required: true,
    }),
  },
  {
    name: 'tipo',
    label: i18n('entities.recurso.fields.tipo'),
    schema: schemas.enumerator(i18n('entities.recurso.fields.tipo'), {
      required: true,
      options: recursoEnumerators.tipo,
    }),
  },
  {
    name: 'cuentasContables',
    label: i18n('entities.recurso.fields.cuentasContables'),
    schema: schemas.relationToMany(i18n('entities.recurso.fields.cuentasContables'), {}),
  },
  {
    name: 'fechaPago',
    label: i18n('entities.recurso.fields.fechaPago'),
    schema: schemas.date(i18n('entities.recurso.fields.fechaPago'), {}),
    render: value => (value && value instanceof Date ? moment(value).format('YYYY-MM-DD') : value),
  },
  {
    name: 'cantidad',
    label: i18n('entities.recurso.fields.cantidad'),
    schema: schemas.integer(i18n('entities.recurso.fields.cantidad'), {}),
  },
  {
    name: 'empleados',
    label: i18n('entities.recurso.fields.empleados'),
    schema: schemas.relationToMany(i18n('entities.recurso.fields.empleados'), {}),
  },
  {
    name: 'repuestos',
    label: i18n('entities.recurso.fields.repuestos'),
    schema: schemas.relationToMany(i18n('entities.recurso.fields.repuestos'), {}),
  },
  {
    name: 'servicios',
    label: i18n('entities.recurso.fields.servicios'),
    schema: schemas.relationToMany(i18n('entities.recurso.fields.servicios'), {}),
  },
  {
    name: 'fotos',
    label: i18n('entities.recurso.fields.fotos'),
    schema: schemas.images(i18n('entities.recurso.fields.fotos'), {}),
  },
  {
    name: 'documentos',
    label: i18n('entities.recurso.fields.documentos'),
    schema: schemas.files(i18n('entities.recurso.fields.documentos'), {}),
  },
  {
    name: 'tareasVinculadas',
    label: i18n('entities.recurso.fields.tareasVinculadas'),
    schema: schemas.relationToMany(i18n('entities.recurso.fields.tareasVinculadas'), {}),
  },
  {
    name: 'subtareasVinculadas',
    label: i18n('entities.recurso.fields.subtareasVinculadas'),
    schema: schemas.relationToMany(i18n('entities.recurso.fields.subtareasVinculadas'), {}),
  },
];
