import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/tipoServicio/importer/tipoServicioImporterSelectors';
import TipoServicioService from 'src/modules/tipoServicio/tipoServicioService';
import fields from 'src/modules/tipoServicio/importer/tipoServicioImporterFields';
import { i18n } from 'src/i18n';

const tipoServicioImporterActions = importerActions(
  'TIPOSERVICIO_IMPORTER',
  selectors,
  TipoServicioService.import,
  fields,
  i18n('entities.tipoServicio.importer.fileName'),
);

export default tipoServicioImporterActions;