import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useRouteMatch} from 'react-router-dom';
import {i18n} from 'src/i18n';
import actions from 'src/modules/activo/form/activoFormActions';
import selectors from 'src/modules/activo/form/activoFormSelectors';
import listSelectors from 'src/modules/activo/list/activoListSelectors';
import layoutActions from 'src/modules/layout/layoutActions';
import {getHistory} from 'src/modules/store';
import ActivoForm from 'src/view/activo/form/ActivoForm';
import ActivoFormFieldConfig from 'src/view/activo/form/ActivoFormFieldConfig';
import ContentWrapper from 'src/view/layout/styles/ContentWrapper';
import Breadcrumb from 'src/view/shared/Breadcrumb';
import Spinner from 'src/view/shared/Spinner';
import PageTitle from 'src/view/shared/styles/PageTitle';

function ActivoFormPage(props) {
  const [dispatched, setDispatched] = useState(false);
  const dispatch = useDispatch();
  const match = useRouteMatch();

  const initLoading = useSelector(selectors.selectInitLoading);
  const saveLoading = useSelector(selectors.selectSaveLoading);
  const record = useSelector(selectors.selectRecord);
  const fieldConfigList = useSelector(listSelectors.selectFieldConfig);
  const fieldConfigForm = useSelector(selectors.selectFieldConfig);
  let fieldConfig = fieldConfigList?.fields ? fieldConfigList : fieldConfigForm;

  const isEditing = Boolean(props.record?.id || match.params.id);

  const title = props.fieldConfig
    ? i18n('common.fieldConfig') + ' (' + i18n('entities.activo.name') + ')'
    : isEditing
    ? i18n('entities.activo.edit.title')
    : i18n('entities.activo.new.title');

  useEffect(() => {
    dispatch(actions.doInit(props.record?.id || match.params.id, fieldConfig));
    setDispatched(true);
  }, [dispatch, match.params.id]);

  const doSubmit = (id, data) => {
    if (props.fieldConfig) {
      dispatch(actions.doSaveFieldConfig(data));
    } else if (isEditing) {
      dispatch(actions.doUpdate(id, data));
    } else {
      dispatch(actions.doCreate(data));
    }
  };

  const closeDrawer = () =>
    dispatch(
      layoutActions.doVisibleDrawer({
        drawerId: 'DrawerActivoFormPage',
        drawerVisible: false,
        drawerChanged: moment().unix(),
        drawerUpdateComponent: 'ActivoListPage',
      }),
    );

  return (
    <>
      {!props.drawer ||
        (!props.disabled && (
          <Breadcrumb items={[[i18n('dashboard.menu'), '/'], [i18n('entities.activo.menu'), '/activo'], [title]]} />
        ))}

      <ContentWrapper>
        {!props.disabled && <PageTitle>{title}</PageTitle>}

        {initLoading && <Spinner />}

        {dispatched &&
          !initLoading &&
          (props.fieldConfig ? (
            <ActivoFormFieldConfig
              disabled={props.disabled}
              drawer={props.drawer}
              saveLoading={saveLoading}
              initLoading={initLoading}
              fieldConfig={fieldConfig}
              isEditing={isEditing}
              onSubmit={doSubmit}
              onCancel={() => (props.drawer ? closeDrawer() : getHistory().push('/activo'))}
            />
          ) : (
            <ActivoForm
              disabled={props.disabled}
              drawer={props.drawer}
              saveLoading={saveLoading}
              initLoading={initLoading}
              record={record}
              fieldConfig={fieldConfig}
              isEditing={isEditing}
              onSubmit={doSubmit}
              onCancel={() => (props.drawer ? closeDrawer() : getHistory().push('/activo'))}
            />
          ))}
      </ContentWrapper>
    </>
  );
}

export default ActivoFormPage;
