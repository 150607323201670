import {Box, Checkbox, FormControlLabel, FormGroup, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {Controller, useFieldArray, useFormContext, useWatch} from 'react-hook-form';
import TextAreaFormItem from './TextAreaFormItem';

export function MultiCheckboxFormItem(props) {
  const {name, disabled, defaultValue, size, allowOthersOption, nameOthers, required} = props;

  const {register, setValue, control, getValues, clearErrors, setError} = useFormContext();

  const {fields, replace} = useFieldArray({
    control,
    name,
  });

  const [isTouched, setIsTouched] = useState<any>(false);

  const originalValue = useWatch({name, control, defaultValue});
  const othersValue = useWatch({name: nameOthers, control});

  const theValue = disabled ? defaultValue : originalValue;
  //console.log('%c⧭', 'color: #aa00ff', {disabled, theValue});

  // useEffect(() => {
  //   originalValue.forEach((item, i) => {
  //     const i_name = `${name}[${i}].value`;
  //     console.log('%c⧭ MultiCheckbox useEffect item', 'color: #7f0fa1', {i_name, item});
  //     register(i_name);
  //     setValue(i_name, item.value);
  //   });
  // }, []);

  // const findValueIndex = (index) => {
  //   return originalValue?.findIndex((item, i) => item.value && index === i);
  // };

  //console.log('%c⧭ MultiCheckboxFormItem', 'color: #00b300', {onlyOneOption: props.onlyOneOption});

  const noneSelected = theValue?.every((item: any) => !item.value);
  useEffect(() => {
    //console.log('noneSelected changed', noneSelected);
    if (required && noneSelected) {
      setError(name, {type: name, message: required});
    } else if (required) {
      clearErrors(name);
    }
  }, [noneSelected]);
  return (
    <FormGroup>
      {fields?.map((item: any, i) => {
        const i_name = `${name}[${i}].value`;
        //console.log('%c⧭ fields.map item', 'color: #ace2e6', {i_name, item, value: item.value});
        const inputRef: any = register(i_name, {
          onChange: (e) => {
            //console.log('%c⧭ lista checkbox values', 'color: #86bf60', 'antes de set value: ' + i_name);
            const checked = Boolean(e.target.value);
            //console.log(i_name, checked, e);
            //setValue(i_name, checked);
            const values = getValues(name);
            values.forEach((row, index) => {
              if (i === index) {
                values[index].value = !!checked;
                //originalValue[index].value = checked;
                //setValue(`${name}[${index}].value`, checked);
              } else if (props.onlyOneOption) {
                values[index].value = false;
                //originalValue[index].value = false;
                //setValue(`${name}[${index}].value`, false);
              }
            });
            //setValue(name, values);
            //console.log('%c⧭ lista checkbox values', 'color: #cc7033', i, values);
            //replace(values);
            if (!isTouched) {
              setIsTouched(true);
            }
            props.onChange && props.onChange(values);
          },
        });
        return (
          <FormControlLabel
            {...inputRef}
            id={i_name}
            key={item.id}
            labelPlacement="end"
            label={
              disabled ? (
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: 500,
                    fontSize: '1.1em',
                  }}>
                  {item.label}
                </Typography>
              ) : (
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 400,
                    fontSize: '1em',
                  }}>
                  {item.label}
                </Typography>
              )
            }
            inputRef={inputRef.ref}
            style={{width: 'fit-content', cursor: disabled ? 'default' : 'pointer'}}
            value={item.value}
            control={
              <Controller
                name={i_name}
                control={control}
                render={({field}) => (
                  <Checkbox
                    {...field}
                    checked={field.value}
                    style={disabled ? {color: '#223354', cursor: 'default'} : {}}
                    color="primary"
                    size={size || 'small'}
                    onChange={(e) => {
                      //console.log('%c⧭ onChange', 'color: #cc0088', e);
                      if (!disabled) {
                        field.onChange(Boolean(e.target.checked));
                      }
                    }}
                  />
                )}
              />
            }
          />
        );
      })}

      {allowOthersOption && theValue?.find((row) => row.id === 'others')?.value && (
        <>
          {/* <label
            style={{
              color: 'gray',
              fontWeight: 600,
              fontSize: 14,
              marginTop: 15,
            }}>
            {'Motivo'}
          </label> */}
          <Box
            sx={
              disabled
                ? {
                    p: 1,
                    mb: 2,
                    border: '1px solid lightgray',
                    borderRadius: '5px',
                  }
                : undefined
            }>
            {disabled ? (
              <Typography
                component="pre"
                variant="subtitle2"
                sx={{
                  fontWeight: 'inherit',
                  fontSize: 'inherit',
                  fontFamily: 'inherit',
                  whiteSpace: 'pre-wrap',
                }}>
                {othersValue}
              </Typography>
            ) : (
              <TextAreaFormItem
                rows={3}
                disabled={disabled}
                name={nameOthers}
                placeholder="Si tu respuesta es otros, por favor especifica"
              />
            )}
          </Box>
        </>
      )}

      {/* {isTouched && required && noneSelected && ( */}
      {required && noneSelected && (
        <Typography
          variant="subtitle2"
          sx={{
            color: '#FF1943',
            fontWeight: 'bold',
            fontSize: '0.9em;',
            fontFamily: 'inherit',
            letterSpacing: '0.03333em',
          }}>
          {required}
        </Typography>
      )}
    </FormGroup>
  );
}

MultiCheckboxFormItem.defaultProps = {};

MultiCheckboxFormItem.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  label: PropTypes.string,
  hint: PropTypes.string,
  externalErrorMessage: PropTypes.string,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.array,
  size: PropTypes.string,
  nameOthers: PropTypes.string,
  allowOthersOption: PropTypes.bool,
  onlyOneOption: PropTypes.bool,
};

export default MultiCheckboxFormItem;
