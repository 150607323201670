import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'comunicacionInterna',
    label: i18n('entities.mensajeInterno.fields.comunicacionInterna'),
    schema: schemas.relationToOne(
      i18n('entities.mensajeInterno.fields.comunicacionInterna'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'mensaje',
    label: i18n('entities.mensajeInterno.fields.mensaje'),
    schema: schemas.string(
      i18n('entities.mensajeInterno.fields.mensaje'),
      {},
    ),
  },
  {
    name: 'fotosAdjunta',
    label: i18n('entities.mensajeInterno.fields.fotosAdjunta'),
    schema: schemas.images(
      i18n('entities.mensajeInterno.fields.fotosAdjunta'),
      {},
    ),
  },
  {
    name: 'documentosAdjuntos',
    label: i18n('entities.mensajeInterno.fields.documentosAdjuntos'),
    schema: schemas.files(
      i18n('entities.mensajeInterno.fields.documentosAdjuntos'),
      {},
    ),
  },
];