import activoEnumerators from 'src/modules/activo/activoEnumerators';
import activoImporterFields from 'src/modules/activo/importer/activoImporterFields';

const labelOf = (name) => {
  const field = activoImporterFields.find((field) => field.name === name);
  if (field) {
    let label = field.label;
    if (label && label.substr(label.length - 1) === '*') return label.slice(0, -1);
    return label;
  }
  return name;
};
const valoresHeader: any = [];
let valoresPermitidos: any = {};
Object.keys(activoEnumerators).forEach((key) => {
  if (key !== 'tipoDepreciacion') {
    const header = labelOf(key);
    valoresHeader.push(header);
    valoresHeader.push('');
    activoEnumerators[key].forEach((valor, index) => {
      if (!valoresPermitidos[index]) valoresPermitidos[index] = {};
      valoresPermitidos[index][header] = valor;
    });
  }
});
valoresPermitidos = Object.keys(valoresPermitidos).map((key) => valoresPermitidos[key]);
const options: any = {
  withLocalizacion: true,
  withTerceros: true,
  appendSheets: [
    {
      name: 'tipos_validos',
      json: valoresPermitidos,
      header: valoresHeader,
      skipHeader: false,
    },
  ],
};
export default options;
