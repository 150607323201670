import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'nombre',
    label: i18n('entities.objetivo.fields.nombre'),
    schema: schemas.string(
      i18n('entities.objetivo.fields.nombre'),
      {},
    ),
  },
  {
    name: 'descripcion',
    label: i18n('entities.objetivo.fields.descripcion'),
    schema: schemas.string(
      i18n('entities.objetivo.fields.descripcion'),
      {},
    ),
  },
  {
    name: 'sucursales',
    label: i18n('entities.objetivo.fields.sucursales'),
    schema: schemas.relationToMany(
      i18n('entities.objetivo.fields.sucursales'),
      {},
    ),
  },
  {
    name: 'categorias',
    label: i18n('entities.objetivo.fields.categorias'),
    schema: schemas.relationToMany(
      i18n('entities.objetivo.fields.categorias'),
      {},
    ),
  },
  {
    name: 'incluirEstadisticas',
    label: i18n('entities.objetivo.fields.incluirEstadisticas'),
    schema: schemas.boolean(
      i18n('entities.objetivo.fields.incluirEstadisticas'),
      {},
    ),
  },
];