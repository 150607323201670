import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.mensajeInterno.fields.id'),
  },
  {
    name: 'comunicacionInterna',
    label: i18n('entities.mensajeInterno.fields.comunicacionInterna'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'mensaje',
    label: i18n('entities.mensajeInterno.fields.mensaje'),
  },
  {
    name: 'fotosAdjunta',
    label: i18n('entities.mensajeInterno.fields.fotosAdjunta'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'documentosAdjuntos',
    label: i18n('entities.mensajeInterno.fields.documentosAdjuntos'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.mensajeInterno.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.mensajeInterno.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
