import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';
import planificadorFormularioEnumerators from 'src/modules/planificadorFormulario/planificadorFormularioEnumerators';

export default [
  {
    name: 'titulo',
    label: i18n('entities.planificadorFormulario.fields.titulo'),
    schema: schemas.string(
      i18n('entities.planificadorFormulario.fields.titulo'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'tipoFormulario',
    label: i18n('entities.planificadorFormulario.fields.tipoFormulario'),
    schema: schemas.enumerator(
      i18n('entities.planificadorFormulario.fields.tipoFormulario'),
      {
        "required": true,
        "options": planificadorFormularioEnumerators.tipoFormulario
      },
    ),
  },
  {
    name: 'dirigidoA',
    label: i18n('entities.planificadorFormulario.fields.dirigidoA'),
    schema: schemas.enumerator(
      i18n('entities.planificadorFormulario.fields.dirigidoA'),
      {
        "required": true,
        "options": planificadorFormularioEnumerators.dirigidoA
      },
    ),
  },
  {
    name: 'evaluadores',
    label: i18n('entities.planificadorFormulario.fields.evaluadores'),
    schema: schemas.relationToMany(
      i18n('entities.planificadorFormulario.fields.evaluadores'),
      {},
    ),
  },
  {
    name: 'sucursales',
    label: i18n('entities.planificadorFormulario.fields.sucursales'),
    schema: schemas.relationToMany(
      i18n('entities.planificadorFormulario.fields.sucursales'),
      {},
    ),
  },
  {
    name: 'puestos',
    label: i18n('entities.planificadorFormulario.fields.puestos'),
    schema: schemas.relationToMany(
      i18n('entities.planificadorFormulario.fields.puestos'),
      {},
    ),
  },
  {
    name: 'evaluados',
    label: i18n('entities.planificadorFormulario.fields.evaluados'),
    schema: schemas.relationToMany(
      i18n('entities.planificadorFormulario.fields.evaluados'),
      {},
    ),
  },
  {
    name: 'modeloFormulario',
    label: i18n('entities.planificadorFormulario.fields.modeloFormulario'),
    schema: schemas.relationToOne(
      i18n('entities.planificadorFormulario.fields.modeloFormulario'),
      {},
    ),
  },
  {
    name: 'programado',
    label: i18n('entities.planificadorFormulario.fields.programado'),
    schema: schemas.boolean(
      i18n('entities.planificadorFormulario.fields.programado'),
      {},
    ),
  },
  {
    name: 'programar',
    label: i18n('entities.planificadorFormulario.fields.programar'),
    schema: schemas.boolean(
      i18n('entities.planificadorFormulario.fields.programar'),
      {},
    ),
  },
  {
    name: 'inicioProgramacion',
    label: i18n('entities.planificadorFormulario.fields.inicioProgramacion'),
    schema: schemas.date(
      i18n('entities.planificadorFormulario.fields.inicioProgramacion'),
      {},
    ),
  },
  {
    name: 'finProgramacion',
    label: i18n('entities.planificadorFormulario.fields.finProgramacion'),
    schema: schemas.date(
      i18n('entities.planificadorFormulario.fields.finProgramacion'),
      {},
    ),
  },
  {
    name: 'tipoPeriodicidad',
    label: i18n('entities.planificadorFormulario.fields.tipoPeriodicidad'),
    schema: schemas.enumerator(
      i18n('entities.planificadorFormulario.fields.tipoPeriodicidad'),
      {
        "options": planificadorFormularioEnumerators.tipoPeriodicidad
      },
    ),
  },
  {
    name: 'periodicidad',
    label: i18n('entities.planificadorFormulario.fields.periodicidad'),
    schema: schemas.integer(
      i18n('entities.planificadorFormulario.fields.periodicidad'),
      {},
    ),
  },
  {
    name: 'encuestados',
    label: i18n('entities.planificadorFormulario.fields.encuestados'),
    schema: schemas.relationToMany(
      i18n('entities.planificadorFormulario.fields.encuestados'),
      {},
    ),
  },
  {
    name: 'activarTiempoLimite',
    label: i18n('entities.planificadorFormulario.fields.activarTiempoLimite'),
    schema: schemas.boolean(
      i18n('entities.planificadorFormulario.fields.activarTiempoLimite'),
      {},
    ),
  },
  {
    name: 'tipoTiempoLimite',
    label: i18n('entities.planificadorFormulario.fields.tipoTiempoLimite'),
    schema: schemas.enumerator(
      i18n('entities.planificadorFormulario.fields.tipoTiempoLimite'),
      {
        "options": planificadorFormularioEnumerators.tipoTiempoLimite
      },
    ),
  },
  {
    name: 'tiempoLimite',
    label: i18n('entities.planificadorFormulario.fields.tiempoLimite'),
    schema: schemas.integer(
      i18n('entities.planificadorFormulario.fields.tiempoLimite'),
      {},
    ),
  },
];