import list from 'src/modules/tercero/list/terceroListReducers';
import form from 'src/modules/tercero/form/terceroFormReducers';
import view from 'src/modules/tercero/view/terceroViewReducers';
import destroy from 'src/modules/tercero/destroy/terceroDestroyReducers';
import importerReducer from 'src/modules/tercero/importer/terceroImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
