import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useRouteMatch} from 'react-router-dom';
import {i18n} from 'src/i18n';
import layoutActions from 'src/modules/layout/layoutActions';
import actions from 'src/modules/modeloTarea/form/modeloTareaFormActions';
import selectors from 'src/modules/modeloTarea/form/modeloTareaFormSelectors';
import {getHistory} from 'src/modules/store';
import ContentWrapper from 'src/view/layout/styles/ContentWrapper';
import ModeloTareaForm from 'src/view/modeloTarea/form/ModeloTareaForm';
import Breadcrumb from 'src/view/shared/Breadcrumb';
import Spinner from 'src/view/shared/Spinner';
import PageTitle from 'src/view/shared/styles/PageTitle';

function ModeloTareaFormPage(props) {
  const [dispatched, setDispatched] = useState(false);
  const dispatch = useDispatch();
  const match = useRouteMatch();

  const initLoading = useSelector(selectors.selectInitLoading);
  const saveLoading = useSelector(selectors.selectSaveLoading);
  const record = useSelector(selectors.selectRecord);

  const isEditing = Boolean(props.record?.id || match.params.id);

  const title = isEditing ? i18n('entities.modeloTarea.edit.title') : i18n('entities.modeloTarea.new.title');

  useEffect(() => {
    dispatch(actions.doInit(props.record?.id || match.params.id));
    setDispatched(true);
  }, [dispatch, match.params.id]);

  const tareaUpdated = props.updated;
  const doSubmit = (id, data) => {
    if (props.planificacion && !isEditing) {
      data = {...data, planificaciones: [props.planificacion]};
      console.log('%c⧭', 'color: #99adcc', {data});
    }
    if (isEditing) {
      dispatch(actions.doUpdate(id, data, tareaUpdated));
    } else {
      dispatch(actions.doCreate(data));
    }
  };

  const closeDrawer = () =>
    dispatch(
      layoutActions.doVisibleDrawer({
        drawerId: 'DrawerModeloTareaFormPage',
        drawerVisible: false,
        drawerChanged: moment().unix(),
        drawerUpdateComponent: 'ModeloTareaListPage',
      }),
    );

  return (
    <>
      {!props.drawer ||
        (!props.disabled && (
          <Breadcrumb items={[[i18n('dashboard.menu'), '/'], [i18n('entities.modeloTarea.menu'), '/modelo-tarea'], [title]]} />
        ))}

      <ContentWrapper>
        {!props.disabled && <PageTitle>{title}</PageTitle>}

        {initLoading && <Spinner />}

        {dispatched && !initLoading && (
          <ModeloTareaForm
            disabled={props.disabled}
            drawer={props.drawer}
            saveLoading={saveLoading}
            initLoading={initLoading}
            record={record}
            isEditing={isEditing}
            onSubmit={doSubmit}
            onCancel={() => (props.drawer ? closeDrawer() : getHistory().push('/modelo-tarea'))}
          />
        )}
      </ContentWrapper>
    </>
  );
}

export default ModeloTareaFormPage;
