import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';
import authAxios from 'src/modules/shared/axios/authAxios';

export default class TareaService {
  static async syncWorkOrders(fullSync = false, updatedSince = undefined) {
    const body = {
      fullSync,
      updatedSince,
    };
    const tenantId = AuthCurrentTenant.get();
    const response = await authAxios.post(`/tenant/${tenantId}/tarea-sync-analytics`, body);
    return response.data;
  }
  static async reassignTasks(ids, ejecutor) {
    const body = {
      ids,
      ejecutor,
    };
    const tenantId = AuthCurrentTenant.get();
    const response = await authAxios.post(`/tenant/${tenantId}/tarea/reassign-tasks`, body);
    return response.data;
  }

  static async update(id, data) {
    const body = {
      id,
      data,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.put(`/tenant/${tenantId}/tarea/${id}`, body);

    return response.data;
  }

  static async destroyAll(ids) {
    const params = {
      ids,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.delete(`/tenant/${tenantId}/tarea`, {
      params,
    });

    return response.data;
  }

  static async create(data) {
    const body = {
      data,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(`/tenant/${tenantId}/tarea`, body);

    return response.data;
  }

  static async import(values, importHash) {
    const body = {
      data: values,
      importHash,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(`/tenant/${tenantId}/tarea/import`, body);

    return response.data;
  }

  static async downloadPdf(id, tId = null) {
    const tenantId = tId || AuthCurrentTenant.get();

    const response = await authAxios.get(`/tenant/${tenantId}/task-pdf/${id}`);

    return response.data;
  }

  static async findForPdf(id, tId = null) {
    const tenantId = tId || AuthCurrentTenant.get();

    const response = await authAxios.get(`/tenant/${tenantId}/tarea-pdf/${id}`);

    return response.data;
  }

  static async find(id) {
    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(`/tenant/${tenantId}/tarea/${id}`);

    return response.data;
  }

  static async fetchUpdatedItems(params) {
    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(`/tenant/${tenantId}/tarea-fetch-updated`, params);

    return response.data;
  }

  static async listGroup(params) {
    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(`/tenant/${tenantId}/tarea-list-group`, {
      params,
    });

    return response.data;
  }

  static async list(filter, orderBy, limit, offset) {
    if (filter?.activo && filter.activo.id) {
      filter.activo = filter.activo.id;
    }

    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(`/tenant/${tenantId}/tarea`, {
      params,
    });

    return response.data;
  }

  static async listAutocomplete(query, limit) {
    const params = {
      query,
      limit,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(`/tenant/${tenantId}/tarea/autocomplete`, {
      params,
    });

    return response.data;
  }
}
