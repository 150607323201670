import {yupResolver} from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import {Button, Grid} from '@mui/material';
import React, {useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useSelector} from 'react-redux';
import {i18n} from 'src/i18n';
import authSelectors from 'src/modules/auth/authSelectors';
import yupFormSchemas from 'src/modules/shared/yup/yupFormSchemas';
import userEnumerators from 'src/modules/user/userEnumerators';
import InputFormItem from 'src/view/shared/form/items/InputFormItem';
import SelectFormItem from 'src/view/shared/form/items/SelectFormItem';
import TagsFormItem from 'src/view/shared/form/items/TagsFormItem';
import FormWrapper, {FormButtons} from 'src/view/shared/styles/FormWrapper';
import * as yup from 'yup';

const singleSchema = yup.object().shape({
  email: yupFormSchemas.email(i18n('user.fields.email')),
  roles: yupFormSchemas.stringArray(i18n('user.fields.roles'), {required: true, min: 1}),
  sucursales: yupFormSchemas.relationToMany(i18n('user.fields.sucursales'), {}),
  puesto: yupFormSchemas.relationToOne(i18n('user.fields.puesto'), {}),
});

const multipleSchema = yup.object().shape({
  emails: yup
    .array()
    .label(i18n('user.fields.emails'))
    .of(
      yup
        .string()
        .transform((cv, ov) => {
          return ov === '' ? null : cv;
        })
        .email(i18n('user.validations.email'))
        .label(i18n('user.fields.email'))
        .max(255)
        .required(),
    )
    .required(),
  roles: yupFormSchemas.stringArray(i18n('user.fields.roles'), {required: true, min: 1}),
  sucursales: yupFormSchemas.relationToMany(i18n('user.fields.sucursales'), {}),
  puesto: yupFormSchemas.relationToOne(i18n('user.fields.puesto'), {}),
});

function UserNewForm(props) {
  const {single, saveLoading, modal} = props;

  const schema = props.single ? singleSchema : multipleSchema;

  const [initialValues] = useState(() => ({
    emails: [],
    email: '',
    roles: [],
  }));

  const form = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: initialValues,
  });

  const onSubmit = (values) => {
    const {...data} = values;

    if (data.email) {
      data.emails = [data.email];
      delete data.email;
    }

    props.onSubmit(null, data);
  };

  const onReset = () => {
    Object.keys(initialValues).forEach((key) => {
      form.setValue(key as any, initialValues[key]);
    });
  };

  const currentTenant = useSelector(authSelectors.selectCurrentTenant);

  const currentUser = useSelector(authSelectors.selectCurrentUser);

  var roles: any;
  roles = [];

  currentUser.tenants
    .filter((tenant) => tenant.tenant.id === currentTenant.id)
    .map((user) => {
      user.roles.map((role) => {
        roles.push(role);
      });
    });

  return (
    <FormWrapper>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Grid spacing={2} container>
            <Grid item lg={7} md={8} sm={12} xs={12}>
              {single ? (
                <InputFormItem name="email" label={i18n('user.fields.email')} required={true} autoFocus />
              ) : (
                <TagsFormItem
                  name="emails"
                  label={i18n('user.fields.emails')}
                  notFoundContent={i18n('user.new.emailsHint')}
                  required={true}
                />
              )}
            </Grid>

            <Grid item lg={7} md={8} sm={12} xs={12}>
              <SelectFormItem
                name="roles"
                label={i18n('user.fields.roles')}
                options={userEnumerators.roles
                  .filter((value) => (value === 'admin' && roles.includes('admin')) || value !== 'admin')
                  .map((value) => ({
                    value,
                    label: i18n(`roles.${value}.label`),
                  }))}
                mode="multiple"
              />
            </Grid>

            {/* <Grid item lg={7} md={8} sm={12} xs={12}>
              <SucursalAutocompleteFormItem
                name="sucursales"
                label={i18n('user.fields.sucursales')}
                required={false}
                showCreate={!props.modal}
                mode="multiple"
              />
            </Grid> */}

            {/* <Grid item lg={7} md={8} sm={12} xs={12}>
              <PuestoAutocompleteFormItem
                name="puesto"
                label={i18n('user.fields.puesto')}
                required={false}
                showCreate={!props.modal}
              />
            </Grid> */}
          </Grid>

          <FormButtons
            style={{
              flexDirection: modal ? 'row-reverse' : undefined,
            }}>
            <Button
              variant="contained"
              color="primary"
              disabled={saveLoading}
              type="button"
              onClick={form.handleSubmit(onSubmit)}
              startIcon={<SaveIcon />}
              size="small">
              {i18n('common.save')}
            </Button>

            <Button disabled={saveLoading} onClick={onReset} type="button" startIcon={<UndoIcon />} size="small">
              {i18n('common.reset')}
            </Button>

            {props.onCancel ? (
              <Button
                disabled={saveLoading}
                onClick={() => props.onCancel()}
                type="button"
                startIcon={<CloseIcon />}
                size="small">
                {i18n('common.cancel')}
              </Button>
            ) : null}
          </FormButtons>
        </form>
      </FormProvider>
    </FormWrapper>
  );
}

export default UserNewForm;
