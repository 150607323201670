import {i18n} from 'src/i18n';
import dataLakeEnumerators from 'src/modules/dataLake/dataLakeEnumerators';
import schemas from 'src/modules/shared/yup/yupImporterSchemas';

export default [
  {
    name: 'type',
    label: i18n('entities.dataLake.fields.type'),
    schema: schemas.enumerator(i18n('entities.dataLake.fields.type'), {
      required: true,
      options: dataLakeEnumerators.type,
    }),
  },
  {
    name: 'dataId',
    label: i18n('entities.dataLake.fields.dataId'),
    schema: schemas.string(i18n('entities.dataLake.fields.dataId'), {
      required: true,
    }),
  },
  {
    name: 'dateStart',
    label: i18n('entities.dataLake.fields.dateStart'),
    schema: schemas.date(i18n('entities.dataLake.fields.dateStart'), {
      required: false,
    }),
  },
  {
    name: 'dateEnd',
    label: i18n('entities.dataLake.fields.dateEnd'),
    schema: schemas.date(i18n('entities.dataLake.fields.dateEnd'), {
      required: false,
    }),
  },
  {
    name: 'name',
    label: i18n('entities.dataLake.fields.name'),
    schema: schemas.string(i18n('entities.dataLake.fields.name'), {
      required: false,
    }),
  },
  {
    name: 'content',
    label: i18n('entities.dataLake.fields.content'),
    schema: schemas.string(i18n('entities.dataLake.fields.content'), {
      required: true,
    }),
  },
];
