import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.gestorDocumental.fields.id'),
  },
  {
    name: 'garantia',
    label: i18n('entities.gestorDocumental.fields.garantia'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'documentoPrincipal',
    label: i18n('entities.gestorDocumental.fields.documentoPrincipal'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'nombre',
    label: i18n('entities.gestorDocumental.fields.nombre'),
  },
  {
    name: 'descripcion',
    label: i18n('entities.gestorDocumental.fields.descripcion'),
  },
  {
    name: 'tipoDocumento',
    label: i18n('entities.gestorDocumental.fields.tipoDocumento'),
  },
  {
    name: 'version',
    label: i18n('entities.gestorDocumental.fields.version'),
  },
  {
    name: 'validoDesde',
    label: i18n('entities.gestorDocumental.fields.validoDesde'),
  },
  {
    name: 'validoHasta',
    label: i18n('entities.gestorDocumental.fields.validoHasta'),
  },
  {
    name: 'codigoISO',
    label: i18n('entities.gestorDocumental.fields.codigoISO'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'autor',
    label: i18n('entities.gestorDocumental.fields.autor'),
  },
  {
    name: 'perfilesEmpleados',
    label: i18n('entities.gestorDocumental.fields.perfilesEmpleados'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'activosVinculados',
    label: i18n('entities.gestorDocumental.fields.activosVinculados'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'registros',
    label: i18n('entities.gestorDocumental.fields.registros'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.gestorDocumental.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.gestorDocumental.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
