import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { i18n } from 'src/i18n';
import actions from 'src/modules/planificadorTarea/view/planificadorTareaViewActions';
import selectors from 'src/modules/planificadorTarea/view/planificadorTareaViewSelectors';
import PlanificadorTareaView from 'src/view/planificadorTarea/view/PlanificadorTareaView';
import PlanificadorTareaViewToolbar from 'src/view/planificadorTarea/view/PlanificadorTareaViewToolbar';
import ContentWrapper from 'src/view/layout/styles/ContentWrapper';
import Breadcrumb from 'src/view/shared/Breadcrumb';
import PageTitle from 'src/view/shared/styles/PageTitle';
import DocumentView from './DocumentView';
import Spinner from 'src/view/shared/Spinner';

function PlanificadorTareaPage(props) {
  const dispatch = useDispatch();
  const match = useRouteMatch();

  const loading = useSelector(selectors.selectLoading);
  const record = useSelector(selectors.selectRecord);

  useEffect(() => {
    dispatch(
      actions.doFind(match.params.id || props.record?.id),
    );
  }, [dispatch, match.params.id, props.record?.id]);

  if (loading || !record) {
    return <Spinner />;
  }

  return (
    <>
      {/* <Breadcrumb
        items={[
          [i18n('dashboard.menu'), '/'],
          [
            i18n('entities.planificadorTarea.menu'),
            '/planificador-tarea',
          ],
          [i18n('entities.planificadorTarea.view.title')],
        ]}
      /> */}

      <ContentWrapper>
        {/* <PageTitle>
          {i18n('entities.planificadorTarea.view.title')}
        </PageTitle>

        <PlanificadorTareaViewToolbar match={match} /> */}

        {record && (
          <DocumentView loading={loading} record={record} />
        )}
      </ContentWrapper>
    </>
  );
}

export default PlanificadorTareaPage;
