import {yupResolver} from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import {Button, Grid} from '@mui/material';
import moment from 'moment-timezone';
import React, {useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import QRCode from 'react-qr-code';
import config from 'src/config';
import {i18n} from 'src/i18n';
import yupFormSchemas from 'src/modules/shared/yup/yupFormSchemas';
import turnoLaboralEnumerators from 'src/modules/turnoLaboral/turnoLaboralEnumerators';
import PerfilEmpleadoAutocompleteFormItem from 'src/view/perfilEmpleado/autocomplete/PerfilEmpleadoAutocompleteFormItem';
import InputFormItem from 'src/view/shared/form/items/InputFormItem';
import InputNumberFormItem from 'src/view/shared/form/items/InputNumberFormItem';
import SelectFormItem from 'src/view/shared/form/items/SelectFormItem';
import TimePickerFormItem from 'src/view/shared/form/items/TimePickerFormItem';
import FormWrapper, {FormButtons} from 'src/view/shared/styles/FormWrapper';
import * as yup from 'yup';

const schema = yup.object().shape({
  descripcion: yupFormSchemas.string(i18n('entities.turnoLaboral.fields.descripcion'), {
    required: true,
  }),
  perfilesEmpleados: yupFormSchemas.relationToMany(i18n('entities.turnoLaboral.fields.perfilesEmpleados'), {
    required: true,
  }),
  dias: yupFormSchemas.stringArray(i18n('entities.turnoLaboral.fields.dias'), {}),
  turno: yupFormSchemas.enumerator(i18n('entities.turnoLaboral.fields.turno'), {
    required: true,
    options: turnoLaboralEnumerators.turno,
  }),
  horaInicio: yupFormSchemas.string(i18n('entities.turnoLaboral.fields.horaInicio'), {}),
  horaFinalizacion: yupFormSchemas.string(i18n('entities.turnoLaboral.fields.horaFinalizacion'), {}),
  costoHora: yupFormSchemas.integer(i18n('entities.turnoLaboral.fields.costoHora'), {}),
});

function TurnoLaboralForm(props) {
  const [initialValues] = useState(() => {
    const record = props.record || {};

    return {
      descripcion: record.descripcion,
      perfilesEmpleados: record.perfilesEmpleados || [],
      dias: record.dias || [],
      turno: record.turno,
      horaInicio: record.horaInicio,
      horaFinalizacion: record.horaFinalizacion,
      costoHora: record.costoHora,
    };
  });

  const form = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: initialValues as any,
  });

  const onSubmit = (values) => {
    props.onSubmit(props.record?.id, values);
  };

  const onReset = () => {
    Object.keys(initialValues).forEach((key) => {
      form.setValue(key, initialValues[key]);
    });
  };

  const {saveLoading, modal} = props;

  return (
    <FormWrapper>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Grid spacing={2} container>
            <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
              <h1>{form.watch('descripcion')}</h1>

              <div style={{marginBottom: 15}}>
                <QRCode
                  value={`${config.frontendUrl.protocol}://${config.frontendUrl.host}/turno-laboral/${props.record?.id}`}
                  size={140}
                />
              </div>
            </Grid>
            <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
              <InputFormItem
                name="descripcion"
                disabled={props.disabled}
                label={i18n('entities.turnoLaboral.fields.descripcion')}
                required={true}
                autoFocus
              />
            </Grid>
            {form.watch('descripcion') && (
              <React.Fragment>
                <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
                  <PerfilEmpleadoAutocompleteFormItem
                    name="perfilesEmpleados"
                    disabled={props.disabled}
                    label={i18n('entities.turnoLaboral.fields.perfilesEmpleados')}
                    required={false}
                    showCreate={!props.modal}
                    mode="multiple"
                  />
                </Grid>
                {form.watch('perfilesEmpleados').length > 0 && (
                  <React.Fragment>
                    <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
                      <SelectFormItem
                        name="dias"
                        disabled={props.disabled}
                        label={i18n('entities.turnoLaboral.fields.dias')}
                        options={turnoLaboralEnumerators.dias.map((value) => ({
                          value,
                          label: i18n(`entities.turnoLaboral.enumerators.dias.${value}`),
                        }))}
                        required={false}
                        mode="multiple"
                      />
                    </Grid>
                    {form.watch('dias').length > 0 && (
                      <React.Fragment>
                        <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
                          <SelectFormItem
                            name="turno"
                            disabled={props.disabled}
                            label={i18n('entities.turnoLaboral.fields.turno')}
                            options={turnoLaboralEnumerators.turno.map((value) => ({
                              value,
                              label: i18n(`entities.turnoLaboral.enumerators.turno.${value}`),
                            }))}
                            required={true}
                          />
                        </Grid>

                        {form.watch('turno') && (
                          <React.Fragment>
                            <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
                              <TimePickerFormItem
                                name="horaInicio"
                                disabled={props.disabled}
                                label={i18n('entities.turnoLaboral.fields.horaInicio')}
                                required={false}
                              />
                            </Grid>
                            {form.watch('horaInicio') && (
                              <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
                                <TimePickerFormItem
                                  name="horaFinalizacion"
                                  disabled={props.disabled}
                                  minTime={moment(form.watch('horaInicio')).toDate()}
                                  label={i18n('entities.turnoLaboral.fields.horaFinalizacion')}
                                  required={false}
                                />
                              </Grid>
                            )}

                            {form.watch('horaInicio') && form.watch('horaFinalizacion') && (
                              <Grid item lg={props.drawer ? 12 : 7} md={8} sm={12} xs={12}>
                                <InputNumberFormItem
                                  name="costoHora"
                                  disabled={props.disabled}
                                  label={i18n('entities.turnoLaboral.fields.costoHora')}
                                  required={false}
                                />
                              </Grid>
                            )}
                          </React.Fragment>
                        )}
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </Grid>
          {!props.disabled && (
            <FormButtons
              style={{
                flexDirection: modal ? 'row-reverse' : undefined,
              }}>
              <Button
                variant="contained"
                color="primary"
                disabled={saveLoading}
                type="button"
                onClick={form.handleSubmit(onSubmit)}
                startIcon={<SaveIcon />}
                size="small">
                {i18n('common.save')}
              </Button>

              <Button disabled={saveLoading} onClick={onReset} type="button" startIcon={<UndoIcon />} size="small">
                {i18n('common.reset')}
              </Button>

              {props.onCancel ? (
                <Button
                  disabled={saveLoading}
                  onClick={() => props.onCancel()}
                  type="button"
                  startIcon={<CloseIcon />}
                  size="small">
                  {i18n('common.cancel')}
                </Button>
              ) : null}
            </FormButtons>
          )}
        </form>
      </FormProvider>
    </FormWrapper>
  );
}

export default TurnoLaboralForm;
