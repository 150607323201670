import {Box} from '@mui/material';
import {styled} from '@mui/material/styles';
import * as React from 'react';

const PaginationWrapperComponent = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  marginTop: '8px',
  overflow: 'hidden',
});

export default function PaginationWrapper(props) {
  return (
    <Box
      style={{
        display: 'flex',
        flexShrink: 0,
        justifyContent: 'flex-end',
      }}>
      <PaginationWrapperComponent>{props.children}</PaginationWrapperComponent>
    </Box>
  );
}
