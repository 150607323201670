import {i18n} from 'src/i18n';
import listActions from 'src/modules/planificadorTareaCustom/list/planificadorTareaCustomListActions';
import PlanificadorTareaCustomService from 'src/modules/planificadorTareaCustom/planificadorTareaCustomService';
import Errors from 'src/modules/shared/error/errors';
import {getHistory} from 'src/modules/store';
import Message from 'src/view/shared/message';

const prefix = 'PLANIFICADORTAREACUSTOM_DESTROY';

const planificadorTareaCustomDestroyActions = {
  DESTROY_STARTED: `${prefix}_DESTROY_STARTED`,
  DESTROY_SUCCESS: `${prefix}_DESTROY_SUCCESS`,
  DESTROY_ERROR: `${prefix}_DESTROY_ERROR`,

  DESTROY_ALL_STARTED: `${prefix}_DESTROY_ALL_STARTED`,
  DESTROY_ALL_SUCCESS: `${prefix}_DESTROY_ALL_SUCCESS`,
  DESTROY_ALL_ERROR: `${prefix}_DESTROY_ALL_ERROR`,

  doDestroy: (id) => async (dispatch) => {
    try {
      dispatch({
        type: planificadorTareaCustomDestroyActions.DESTROY_STARTED,
      });

      await PlanificadorTareaCustomService.destroyAll([id]);

      dispatch({
        type: planificadorTareaCustomDestroyActions.DESTROY_SUCCESS,
      });

      Message.success(i18n('entities.planificadorTareaCustom.destroy.success'));

      dispatch(listActions.doFetchCurrentFilter());

      getHistory().push('/planificador-tarea');
    } catch (error) {
      Errors.handle(error);

      dispatch(listActions.doFetchCurrentFilter());

      dispatch({
        type: planificadorTareaCustomDestroyActions.DESTROY_ERROR,
      });
    }
  },

  doDestroyAll: (ids) => async (dispatch) => {
    try {
      dispatch({
        type: planificadorTareaCustomDestroyActions.DESTROY_ALL_STARTED,
      });

      await PlanificadorTareaCustomService.destroyAll(ids);

      dispatch({
        type: planificadorTareaCustomDestroyActions.DESTROY_ALL_SUCCESS,
      });

      if (listActions) {
        dispatch(listActions.doClearAllSelected());
        dispatch(listActions.doFetchCurrentFilter());
      }

      Message.success(i18n('entities.planificadorTareaCustom.destroyAll.success'));

      getHistory().push('/planificador-tarea');
    } catch (error) {
      Errors.handle(error);

      dispatch(listActions.doFetchCurrentFilter());

      dispatch({
        type: planificadorTareaCustomDestroyActions.DESTROY_ALL_ERROR,
      });
    }
  },
};

export default planificadorTareaCustomDestroyActions;
