import list from 'src/modules/subTarea/list/subTareaListReducers';
import form from 'src/modules/subTarea/form/subTareaFormReducers';
import view from 'src/modules/subTarea/view/subTareaViewReducers';
import destroy from 'src/modules/subTarea/destroy/subTareaDestroyReducers';
import importerReducer from 'src/modules/subTarea/importer/subTareaImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
