import {Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useState} from 'react';

import {useSelector} from 'react-redux';
import {i18n} from 'src/i18n';
import selectors from 'src/modules/activo/activoSelectors';
import ActivoView from 'src/view/activo/view/ActivoView';
import DrawerButton from 'src/view/shared/view/DrawerButton';

function ActivoListItem(props) {
  const hasPermissionToRead = useSelector(selectors.selectPermissionToRead);

  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };

  const valueAsArray = () => {
    const {value} = props;

    if (!value) {
      return [];
    }

    if (Array.isArray(value)) {
      return value;
    }

    return [value];
  };

  const displayableRecord = (record) => {
    if (!record) return;

    return (
      <div key={record.id}>
        <DrawerButton
          {...props}
          drawerId={'DrawerActivoViewPage'}
          type={props.type || 'chip'}
          entity={'activo'}
          tooltipTitle={i18n(record.nombre)}
          buttonTitle={i18n(record.nombre)}
          width={window.innerWidth * 0.65}
          record={record}
          component={<ActivoView record={record} drawer={true} />}
        />
      </div>
    );
  };

  if (!valueAsArray().length) {
    return null;
  }

  const displayedValues = isExpanded ? valueAsArray() : valueAsArray().slice(0, 2);
  return (
    <>
      {displayedValues.map((value) => displayableRecord(value))}
      {!isExpanded && valueAsArray().length > 2 && (
        <Typography
          style={{
            cursor: 'pointer',
            color: '#3f51b5',
          }}
          variant="caption"
          onClick={handleExpandClick}>
          Ver más
        </Typography>
      )}
      {isExpanded && valueAsArray().length > 2 && (
        <Typography
          style={{
            cursor: 'pointer',
            color: '#3f51b5',
          }}
          variant="caption"
          onClick={handleExpandClick}>
          Ver menos
        </Typography>
      )}
    </>
  );
}

ActivoListItem.propTypes = {
  value: PropTypes.any,
  type: PropTypes.string,
  size: PropTypes.number,
};

export default ActivoListItem;
