import React, {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Route, Switch} from 'react-router-dom';
import authSelectors from 'src/modules/auth/authSelectors';
import layoutSelectors from 'src/modules/layout/layoutSelectors';
import pendienteListActions from 'src/modules/pendiente/list/pendienteListActions';
import Permissions from 'src/security/permissions';
import routes from 'src/view/routes';
import CustomLoadable from 'src/view/shared/CustomLoadable';
import ProgressBar from 'src/view/shared/ProgressBar';
import EmailUnverifiedRoute from 'src/view/shared/routes/EmailUnverifiedRoute';
import EmptyPermissionsRoute from 'src/view/shared/routes/EmptyPermissionsRoute';
import EmptyTenantRoute from 'src/view/shared/routes/EmptyTenantRoute';
import PrivateRoute from 'src/view/shared/routes/PrivateRoute';
import PublicRoute from 'src/view/shared/routes/PublicRoute';

const permissions = Permissions.values;

function RoutesComponent(props) {
  const isInitialMount = useRef(true);
  const dispatch = useDispatch();

  const authLoading = useSelector(authSelectors.selectLoadingInit);
  const layoutLoading = useSelector(layoutSelectors.selectLoading);
  const loading = authLoading || layoutLoading;
  const currentUser = useSelector(authSelectors.selectCurrentUser);
  const currentTenant = useSelector(authSelectors.selectCurrentTenant);
  const tenantUser = useSelector(authSelectors.selectTenantUser);

  const dashboardLinks = tenantUser?.dashboardLinks;
  const isLuxottica = currentTenant?.id === '66900c307b9c0b7b3e460a64';

  let privateRoutes = routes.privateRoutes;
  if (isLuxottica) {
    const dashboardRoutes = dashboardLinks.map((dashboardLink) => ({
      path: `/dashboard/:slug`,
      loader: () => import('src/view/dashboard/IFrameDashboard'),
      permissionRequired: permissions.dashboardRead,
      exact: true,
    }));
    privateRoutes = [...privateRoutes, ...dashboardRoutes];
  }

  useEffect(() => {
    if (tenantUser && tenantUser.data?.pendingGroupByFields) {
      // console.log({
      //   tenatUser: tenantUser,
      //   pendingGroupByFields: tenantUser.data?.pendingGroupByFields,
      // });
      dispatch(
        pendienteListActions.patchValues({
          groupByFields: tenantUser.data?.pendingGroupByFields,
        }),
      );
    }
  }, [tenantUser]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      ProgressBar.start();
      return;
    }

    if (!loading) {
      ProgressBar.done();
    }
  }, [loading]);

  if (loading) {
    return <div />;
  }

  return (
    <Switch>
      {routes.publicRoutes.map((route) => (
        <PublicRoute
          key={route.path}
          exact
          path={route.path}
          currentUser={currentUser}
          currentTenant={currentTenant}
          component={CustomLoadable({
            loader: route.loader,
          })}
        />
      ))}

      {routes.emailUnverifiedRoutes.map((route) => (
        <EmailUnverifiedRoute
          key={route.path}
          exact
          path={route.path}
          currentUser={currentUser}
          currentTenant={currentTenant}
          component={CustomLoadable({
            loader: route.loader,
          })}
        />
      ))}

      {routes.emptyTenantRoutes.map((route) => (
        <EmptyTenantRoute
          key={route.path}
          exact
          path={route.path}
          currentUser={currentUser}
          currentTenant={currentTenant}
          component={CustomLoadable({
            loader: route.loader,
          })}
        />
      ))}

      {routes.emptyPermissionsRoutes.map((route) => (
        <EmptyPermissionsRoute
          key={route.path}
          exact
          path={route.path}
          currentUser={currentUser}
          currentTenant={currentTenant}
          component={CustomLoadable({
            loader: route.loader,
          })}
        />
      ))}

      {privateRoutes.map((route) => (
        <PrivateRoute
          key={route.path}
          currentUser={currentUser}
          currentTenant={currentTenant}
          permissionRequired={route.permissionRequired}
          path={route.path}
          component={CustomLoadable({
            loader: route.loader,
          })}
          exact={Boolean(route.exact)}
        />
      ))}

      {routes.simpleRoutes.map((route) => (
        <Route
          key={route.path}
          exact
          path={route.path}
          component={CustomLoadable({
            loader: route.loader,
          })}
        />
      ))}
    </Switch>
  );
}

export default RoutesComponent;
