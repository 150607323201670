import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.servicio.fields.id'),
  },
  {
    name: 'descripcion',
    label: i18n('entities.servicio.fields.descripcion'),
  },
  {
    name: 'tipoServicio',
    label: i18n('entities.servicio.fields.tipoServicio'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'centrosCostos',
    label: i18n('entities.servicio.fields.centrosCostos'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'costoHora',
    label: i18n('entities.servicio.fields.costoHora'),
  },
  {
    name: 'detalles',
    label: i18n('entities.servicio.fields.detalles'),
  },
  {
    name: 'cadaFrecuencia',
    label: i18n('entities.servicio.fields.cadaFrecuencia'),
  },
  {
    name: 'frecuencia',
    label: i18n('entities.servicio.fields.frecuencia'),
  },
  {
    name: 'tercerosVinculados',
    label: i18n('entities.servicio.fields.tercerosVinculados'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.servicio.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.servicio.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
