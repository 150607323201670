import {Check, PersonPinCircle, ScheduleOutlined} from '@mui/icons-material';
import {Button, Grid} from '@mui/material';
import moment from 'moment-timezone';
import 'moment/locale/es';
import React, {useEffect} from 'react';
import {useFieldArray, useFormContext} from 'react-hook-form';
import TextAreaFormItem from 'src/view/shared/form/items/TextAreaFormItem';

import {CheckCircleOutline} from '@mui/icons-material';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import Step from '@mui/material/Step';
import StepContent from '@mui/material/StepContent';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import {makeStyles} from '@mui/styles';
import Geocode from 'react-geocode';
import {useSelector} from 'react-redux';
import {compose, withProps} from 'recompose';
import {i18n} from 'src/i18n';
import authSelectors from 'src/modules/auth/authSelectors';
import Storage from 'src/security/storage';
import FilesFormItem from 'src/view/shared/form/items/FilesFormItem';
import FilesViewItem from 'src/view/shared/view/FilesViewItem';

const {withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow} = require('react-google-maps');

Geocode.setApiKey('AIzaSyBdnksXevtjy1scvJVYtZ0HlEgkQkOp8Xk');
Geocode.setLanguage('es');
Geocode.setRegion('cl');

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

const GoogleMapComponent = compose(
  withProps({
    loadingElement: <div style={{height: `100%`}} />,
    containerElement: <div style={{height: `250px`}} />,
    mapElement: <div style={{height: `100%`}} />,
  }),
  withGoogleMap,
)((props) => <GoogleMap {...props} />);

function getSteps(modeloTarea) {
  return modeloTarea.subTareasRelacionadas.map((item) => item);
  //return _.orderBy(modeloTarea.subTareasRelacionadas, ['posicion'], ['asc']).map((item) => item);
}

export default function PasosTareaForm({name, readOnly, record}) {
  const {control, getValues, watch, register, setValue} = useFormContext();
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(watch('pasoActual') ? watch('pasoActual') : 0);
  const steps = getSteps(record.modeloTarea);

  const currentUser = useSelector(authSelectors.selectCurrentUser);

  const {fields, remove, append, insert} = useFieldArray({
    control,
    name,
  }) as any;

  useEffect(() => {
    if (['Abierta', 'Rechazada', 'FueraPlazo'].includes(watch('estado')) && watch('ejecutor')?.id === currentUser.id) {
      setValue('pasoActual', activeStep);
      setValue(`${name}[${activeStep}].fecha`, moment().format('YYYY-MM-DD HH:mm:ss'));

      navigator.geolocation.getCurrentPosition(
        (pos) => {
          const coords = pos.coords;
          console.log(coords);

          Geocode.fromLatLng(coords.latitude, coords.longitude).then(
            (response) => {
              console.log(response);
              console.log(response.results[0].formatted_address);

              const address = response.results[0].formatted_address;

              setValue(`${name}[${activeStep}].ubicacion`, {
                latitud: coords.latitude,
                longitud: coords.longitude,
                address: address,
              });

              setValue('ultimaUbicacion', {
                latitud: coords.latitude,
                longitud: coords.longitude,
                address: address,
              });
            },
            (error) => {
              console.error(error);
            },
          );
        },
        (err) => {
          console.warn('ERROR(' + err.code + '): ' + err.message);
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        },
      );
    }
  }, [activeStep]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  useEffect(() => {
    fields.map((item, index) => {
      register(`${name}[${index}].comentario`);
      register(`${name}[${index}].documento`);
      register('pasoActual');
      register(`${name}[${activeStep}].fecha`);
      register('ultimaUbicacion');
      register(`${name}[${activeStep}].ubicacion`);

      setValue(`${name}[${index}].comentario`, item.comentario);

      setValue(`${name}[${index}].documento`, item.documento);

      setValue(`${name}[${activeStep}].ubicacion`, item.ubicacion);

      setValue('ultimaUbicacion', watch('ultimaUbicacion'));
    });
  }, []);

  return (
    <div className={classes.root}>
      <Grid style={{marginBottom: 20}} item lg={12}>
        <Grid container alignItems={'center'} justifyContent={'center'}>
          <Typography style={{fontWeight: 600, marginBottom: 10}} variant={'h4'}>
            {((activeStep * 100) / record.modeloTarea?.subTareasRelacionadas?.length).toFixed(1)} %
          </Typography>
        </Grid>

        <LinearProgress variant="determinate" value={(activeStep * 100) / record.modeloTarea?.subTareasRelacionadas?.length} />
      </Grid>

      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((item: any, index) => (
          <Step disabled={readOnly} expanded={readOnly} key={item.id}>
            <StepLabel>
              <Typography variant="h6">{item.nombre}</Typography>
            </StepLabel>
            <StepContent>
              <Typography variant="subtitle2">{item.descripcion}</Typography>

              <Grid container direction="row">
                <Grid item lg={12}>
                  {!readOnly && (
                    <React.Fragment>
                      <TextAreaFormItem
                        name={`${name}[${index}].comentario`}
                        defaultValue={watch(`${name}[${index}].comentario`)}
                        label={'Comentario' + ' (Opcional)'}
                        rows={4}
                      />

                      <FilesFormItem
                        name={`${name}[${index}].documento`}
                        required={false}
                        label={i18n('entities.tarea.fields.documentos') + ' (Opcional)'}
                        storage={Storage.values.tareaDocumentosEnviados}
                        max={undefined}
                        formats={undefined}
                      />
                    </React.Fragment>
                  )}

                  {readOnly && fields[index] && (
                    <React.Fragment>
                      <Grid container>
                        <label
                          style={{
                            marginTop: 10,
                            marginBottom: 10,
                          }}>
                          {fields[index].comentario}
                        </label>
                        <FilesViewItem value={fields[index].documento} />
                      </Grid>
                    </React.Fragment>
                  )}
                </Grid>
                <Grid item lg={12}>
                  {fields[index] ? (
                    <React.Fragment>
                      {fields[index].ubicacion ? (
                        <Typography style={{marginTop: 10}} variant="body1" color="textSecondary">
                          <PersonPinCircle /> {fields[index].ubicacion?.address}
                        </Typography>
                      ) : null}
                      <Typography
                        style={{
                          marginTop: 5,
                          marginBottom: 10,
                        }}
                        variant="body2"
                        color="textSecondary">
                        <ScheduleOutlined /> {moment(fields[index].fecha).format('LLLL')}
                      </Typography>

                      {fields[index].ubicacion ? (
                        <GoogleMapComponent
                          defaultCenter={{
                            lat: fields[index].ubicacion?.latitud,
                            lng: fields[index].ubicacion?.longitud,
                          }}
                          center={{
                            lat: fields[index].ubicacion?.latitud,
                            lng: fields[index].ubicacion?.longitud,
                          }}
                          defaultZoom={12}>
                          <Marker
                            position={{
                              lat: fields[index].ubicacion?.latitud,
                              lng: fields[index].ubicacion?.longitud,
                            }}
                            defaultPosition={{
                              lat: fields[index].ubicacion?.latitud,
                              lng: fields[index].ubicacion?.longitud,
                            }}
                            draggable={true}></Marker>
                        </GoogleMapComponent>
                      ) : null}
                    </React.Fragment>
                  ) : null}
                </Grid>
              </Grid>

              {!readOnly && (
                <div className={classes.actionsContainer}>
                  <div>
                    <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                      Volver atrás
                    </Button>
                    <Button variant="contained" color="primary" onClick={handleNext} className={classes.button}>
                      {activeStep === steps.length - 1 ? (
                        'Finalizar'
                      ) : (
                        <Grid container direction="row" justifyContent="center" alignItems="center">
                          <Check style={{marginRight: 5}} /> Siguiente
                        </Grid>
                      )}
                    </Button>
                  </div>
                </div>
              )}
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {!readOnly && activeStep === steps.length && (
        <Paper square elevation={0} className={classes.resetContainer}>
          <h2>
            <CheckCircleOutline style={{color: 'green', marginRight: 5}} />
            Todos los pasos completados
          </h2>
          <Button onClick={handleReset} className={classes.button}>
            Volver a empezar
          </Button>
        </Paper>
      )}
    </div>
  );
}
