import {i18n} from 'src/i18n';
import exporterFields from 'src/modules/mensajeInterno/list/mensajeInternoListExporterFields';
import selectors from 'src/modules/mensajeInterno/list/mensajeInternoListSelectors';
import MensajeInternoService from 'src/modules/mensajeInterno/mensajeInternoService';
import Errors from 'src/modules/shared/error/errors';
import Exporter from 'src/modules/shared/exporter/exporter';

const prefix = 'MENSAJEINTERNO_LIST';

const mensajeInternoListActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  RESETED: `${prefix}_RESETED`,
  TOGGLE_ONE_SELECTED: `${prefix}_TOGGLE_ONE_SELECTED`,
  TOGGLE_ALL_SELECTED: `${prefix}_TOGGLE_ALL_SELECTED`,
  CLEAR_ALL_SELECTED: `${prefix}_CLEAR_ALL_SELECTED`,

  PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,
  SORTER_CHANGED: `${prefix}_SORTER_CHANGED`,

  EXPORT_STARTED: `${prefix}_EXPORT_STARTED`,
  EXPORT_SUCCESS: `${prefix}_EXPORT_SUCCESS`,
  EXPORT_ERROR: `${prefix}_EXPORT_ERROR`,

  doClearAllSelected() {
    return {
      type: mensajeInternoListActions.CLEAR_ALL_SELECTED,
    };
  },

  doToggleAllSelected() {
    return {
      type: mensajeInternoListActions.TOGGLE_ALL_SELECTED,
    };
  },

  doToggleOneSelected(id) {
    return {
      type: mensajeInternoListActions.TOGGLE_ONE_SELECTED,
      payload: id,
    };
  },

  doReset: () => async (dispatch) => {
    dispatch({
      type: mensajeInternoListActions.RESETED,
    });

    dispatch(mensajeInternoListActions.doFetch());
  },

  doExport: () => async (dispatch, getState) => {
    try {
      if (!exporterFields || !exporterFields.length) {
        throw new Error('exporterFields is required');
      }

      dispatch({
        type: mensajeInternoListActions.EXPORT_STARTED,
      });

      const filter = selectors.selectFilter(getState());
      const response = await MensajeInternoService.list(filter, selectors.selectOrderBy(getState()), null, null);

      new Exporter(exporterFields, i18n('entities.mensajeInterno.exporterFileName')).transformAndExportAsExcelFile(response.rows);

      dispatch({
        type: mensajeInternoListActions.EXPORT_SUCCESS,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: mensajeInternoListActions.EXPORT_ERROR,
      });
    }
  },

  doChangePagination: (pagination) => async (dispatch, getState) => {
    dispatch({
      type: mensajeInternoListActions.PAGINATION_CHANGED,
      payload: pagination,
    });

    dispatch(mensajeInternoListActions.doFetchCurrentFilter());
  },

  doChangeSort: (sorter) => async (dispatch, getState) => {
    dispatch({
      type: mensajeInternoListActions.SORTER_CHANGED,
      payload: sorter,
    });

    dispatch(mensajeInternoListActions.doFetchCurrentFilter());
  },

  doFetchCurrentFilter: () => async (dispatch, getState) => {
    const filter = selectors.selectFilter(getState());
    const rawFilter = selectors.selectRawFilter(getState());
    dispatch(mensajeInternoListActions.doFetch(filter, rawFilter, true));
  },

  doFetch:
    (filter?, rawFilter?, keepPagination = false) =>
    async (dispatch, getState) => {
      try {
        if (filter.comunicacionInterna) {
          dispatch({
            type: mensajeInternoListActions.FETCH_STARTED,
            payload: {filter, rawFilter, keepPagination},
          });

          let response: any = {rows: [], count: 0};
          response = await MensajeInternoService.list(
            filter,
            selectors.selectOrderBy(getState()),
            selectors.selectLimit(getState()),
            selectors.selectOffset(getState()),
          );

          dispatch({
            type: mensajeInternoListActions.FETCH_SUCCESS,
            payload: {
              rows: response.rows,
              count: response.count,
            },
          });
        }
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: mensajeInternoListActions.FETCH_ERROR,
        });
      }
    },
};

export default mensajeInternoListActions;
