import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import esLocale from 'date-fns/locale/es';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {useFormContext, useWatch} from 'react-hook-form';
import FormErrors from 'src/view/shared/form/formErrors';

import MobileTimePicker from '@mui/lab/MobileTimePicker';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import {DisabledText} from './shared/formItemWrappers';

export function TimePickerFormItem(props) {
  const {
    label,
    name,
    hint,
    placeholder,
    autoFocus,
    autoComplete,
    externalErrorMessage,
    required,
    showTime,
    disabled,
    minDate,
    maxDate,
    resetValueFormItemName,
    maxTime,
    minTime,
  } = props;

  const {
    register,
    formState: {touchedFields, isSubmitted, errors},
    setValue,
    control,
  } = useFormContext();

  useEffect(() => {
    register(name);
  }, []);

  const errorMessage = FormErrors.errorMessage(name, errors, touchedFields, isSubmitted, externalErrorMessage);

  const watchName: any = useWatch({name, control});
  if (disabled) {
    return <DisabledText label={label} value={watchName ? moment(watchName).format('HH:mm') : null} />;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
      <Stack spacing={3}>
        <MobileTimePicker
          openTo="hours"
          inputFormat="HH:mm"
          label={label}
          minTime={minTime}
          maxTime={maxTime}
          clearable={true}
          value={watchName}
          onChange={(value) => {
            setValue(name, value);
            props.onChange && props.onChange(value);
          }}
          renderInput={(params) => <TextField {...params} />}
        />
      </Stack>
    </LocalizationProvider>
  );
}

TimePickerFormItem.defaultProps = {
  required: false,
};

TimePickerFormItem.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  label: PropTypes.string,
  hint: PropTypes.string,
  autoFocus: PropTypes.bool,
  size: PropTypes.string,
  prefix: PropTypes.string,
  placeholder: PropTypes.string,
  externalErrorMessage: PropTypes.string,
  showTime: PropTypes.bool,
  disabled: PropTypes.bool,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  resetValueFormItemName: PropTypes.string,
  maxTime: PropTypes.any,
  minTime: PropTypes.any,
};

export default TimePickerFormItem;
