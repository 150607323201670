import CloseIcon from '@mui/icons-material/Close';
import {Dialog, DialogContent, DialogTitle, IconButton} from '@mui/material';
import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import {i18n} from 'src/i18n';
import PlanificadorTareaService from 'src/modules/planificadorTarea/planificadorTareaService';
import Errors from 'src/modules/shared/error/errors';
import PlanificadorTareaForm from 'src/view/planificadorTarea/form/PlanificadorTareaForm';

function PlanificadorTareaFormModal(props) {
  const [saveLoading, setSaveLoading] = useState(false);

  const doSubmit = async (_, data) => {
    try {
      setSaveLoading(true);
      const {id} = await PlanificadorTareaService.create(data);
      const record = await PlanificadorTareaService.find(id);
      props.onSuccess(record);
    } catch (error) {
      Errors.handle(error);
    } finally {
      setSaveLoading(false);
    }
  };

  const doClose = () => {
    return props.onClose();
  };

  return ReactDOM.createPortal(
    <Dialog open={true} onClose={doClose} maxWidth="md" fullWidth={true}>
      <DialogTitle
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <h2 style={{margin: 0}}>{i18n('entities.planificadorTarea.new.title')}</h2>
        <IconButton onClick={doClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <PlanificadorTareaForm saveLoading={saveLoading} onSubmit={doSubmit} onCancel={doClose} modal={false} />
      </DialogContent>
    </Dialog>,
    (document as any).getElementById('modal-root'),
  );
}

export default PlanificadorTareaFormModal;
