import {Box, Typography} from '@mui/material';
import Chip from '@mui/material/Chip';
import {brown, purple} from '@mui/material/colors';
import React from 'react';

function ColoredChip(props) {
  const {color, label, style: inheritedStyle, wrap} = props;

  const backgroundColors = {
    gray: '#6c757d',
    blue: '#124272',
    green: '#118730',
    red: '#DA0012',
    yellow: '#FCCF08',
    orange: '#ED7E07',
    brown: brown[500],
    purple: purple[500],
    cian: '#17a2b8',
  };

  const textColors = {
    gray: '#fff',
    blue: '#fff',
    green: '#fff',
    yellow: '#000',
    red: '#fff',
    orange: '#fff',
    brown: '#fff',
    purple: '#fff',
    cian: '#fff',
  };

  const style = color
    ? {
        backgroundColor: backgroundColors[color],
        color: textColors[color],
        fontWeight: '500',
        ...inheritedStyle,
      }
    : {...inheritedStyle};

  if (wrap) {
    return (
      <Box
        sx={{
          display: 'flex',
        }}>
        <Box
          sx={{
            maxWidth: '-webkit-fill-available',
            ...inheritedStyle,
          }}>
          <Typography
            style={{
              backgroundColor: backgroundColors[color],
              borderRadius: '15px',
              padding: '4px 7px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              textAlign: 'center',
              color: textColors[color],
              fontSize: '0.78rem',
              fontWeight: 500,
              whiteSpace: 'nowrap',
            }}>
            {label}
          </Typography>
        </Box>
      </Box>
    );
  }

  return <Chip size="medium" style={style} label={label} />;
}

export default ColoredChip;
