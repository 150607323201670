import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/categoriaProducto/importer/categoriaProductoImporterSelectors';
import CategoriaProductoService from 'src/modules/categoriaProducto/categoriaProductoService';
import fields from 'src/modules/categoriaProducto/importer/categoriaProductoImporterFields';
import { i18n } from 'src/i18n';

const categoriaProductoImporterActions = importerActions(
  'CATEGORIAPRODUCTO_IMPORTER',
  selectors,
  CategoriaProductoService.import,
  fields,
  i18n('entities.categoriaProducto.importer.fileName'),
);

export default categoriaProductoImporterActions;