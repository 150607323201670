import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.comunicacionInterna.fields.id'),
  },
  {
    name: 'titulo',
    label: i18n('entities.comunicacionInterna.fields.titulo'),
  },
  {
    name: 'estado',
    label: i18n('entities.comunicacionInterna.fields.estado'),
  },
  {
    name: 'asunto',
    label: i18n('entities.comunicacionInterna.fields.asunto'),
  },
  {
    name: 'mensaje',
    label: i18n('entities.comunicacionInterna.fields.mensaje'),
  },
  {
    name: 'temaComunicacion',
    label: i18n('entities.comunicacionInterna.fields.temaComunicacion'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'nivelPrioridad',
    label: i18n('entities.comunicacionInterna.fields.nivelPrioridad'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'anadirReferencias',
    label: i18n('entities.comunicacionInterna.fields.anadirReferencias'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'pendientesAsociados',
    label: i18n('entities.comunicacionInterna.fields.pendientesAsociados'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'tiendasAsociadas',
    label: i18n('entities.comunicacionInterna.fields.tiendasAsociadas'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'empleadosAsociados',
    label: i18n('entities.comunicacionInterna.fields.empleadosAsociados'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'comunicacionesAsociadas',
    label: i18n('entities.comunicacionInterna.fields.comunicacionesAsociadas'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'participantes',
    label: i18n('entities.comunicacionInterna.fields.participantes'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'administradores',
    label: i18n('entities.comunicacionInterna.fields.administradores'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.comunicacionInterna.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.comunicacionInterna.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
