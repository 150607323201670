import AddIcon from '@mui/icons-material/Add';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import DescriptionIcon from '@mui/icons-material/Description';
import HistoryIcon from '@mui/icons-material/History';
import {Button, Tooltip} from '@mui/material';
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {i18n} from 'src/i18n';
import auditLogSelectors from 'src/modules/auditLog/auditLogSelectors';
import authSelectors from 'src/modules/auth/authSelectors';
import destroyActions from 'src/modules/recurso/destroy/recursoDestroyActions';
import destroySelectors from 'src/modules/recurso/destroy/recursoDestroySelectors';
import actions from 'src/modules/recurso/list/recursoListActions';
import selectors from 'src/modules/recurso/list/recursoListSelectors';
import recursoSelectors from 'src/modules/recurso/recursoSelectors';
import ConfirmModal from 'src/view/shared/modals/ConfirmModal';
import ToolbarWrapper from 'src/view/shared/styles/ToolbarWrapper';
import DrawerButton from 'src/view/shared/view/DrawerButton';
import RecursoFormPage from '../form/RecursoFormPage';

function RecursoToolbar(props) {
  const [destroyAllConfirmVisible, setDestroyAllConfirmVisible] = useState(false);

  const dispatch = useDispatch();

  const selectedKeys = useSelector(selectors.selectSelectedKeys);
  const loading = useSelector(selectors.selectLoading);
  const destroyLoading = useSelector(destroySelectors.selectLoading);
  const exportLoading = useSelector(selectors.selectExportLoading);
  const hasRows = useSelector(selectors.selectHasRows);
  const hasPermissionToAuditLogs = useSelector(auditLogSelectors.selectPermissionToRead);
  const hasPermissionToDestroy = useSelector(recursoSelectors.selectPermissionToDestroy);
  const hasPermissionToCreate = useSelector(recursoSelectors.selectPermissionToCreate);
  const hasPermissionToImport = useSelector(recursoSelectors.selectPermissionToImport);

  const doOpenDestroyAllConfirmModal = () => {
    setDestroyAllConfirmVisible(true);
  };

  const doCloseDestroyAllConfirmModal = () => {
    setDestroyAllConfirmVisible(false);
  };

  const doExport = () => {
    dispatch(actions.doExport());
  };

  const doDestroyAllSelected = () => {
    doCloseDestroyAllConfirmModal();

    dispatch(destroyActions.doDestroyAll(selectedKeys));
  };

  const roles = useSelector(authSelectors.selectRoles);
  const renderExportButton = () => {
    if (!roles?.includes('admin')) {
      return null;
    }
    const disabledWithTooltip = !hasRows || loading;

    const button = (
      <Button
        type="button"
        disabled={disabledWithTooltip || exportLoading}
        onClick={doExport}
        startIcon={<DescriptionIcon />}
        size="small">
        {i18n('common.export')}
      </Button>
    );

    if (!disabledWithTooltip) {
      return button;
    }

    return (
      <>
        <Tooltip followCursor title={i18n('common.noDataToExport')}>
          <span>{button}</span>
        </Tooltip>
      </>
    );
  };

  const renderDestroyButton = () => {
    if (!hasPermissionToDestroy) {
      return null;
    }

    const disabled = !selectedKeys.length || loading;

    const button = (
      <Button
        variant="contained"
        color="primary"
        type="button"
        disabled={destroyLoading || disabled}
        onClick={doOpenDestroyAllConfirmModal}
        startIcon={<DeleteIcon />}
        size="small">
        {i18n('common.destroy')}
      </Button>
    );

    if (disabled) {
      return (
        <Tooltip followCursor title={i18n('common.mustSelectARow')}>
          <span>{button}</span>
        </Tooltip>
      );
    }

    return button;
  };

  return (
    <ToolbarWrapper>
      {hasPermissionToCreate && (
        <DrawerButton
          drawerId={'DrawerRecursoFormPage'}
          type={'button'}
          width={window.innerWidth * 0.55}
          tooltipTitle={i18n('common.new')}
          buttonTitle={i18n('common.new')}
          buttonIcon={<AddIcon />}
          component={<RecursoFormPage tareasVinculadas={props.tareasVinculadas} drawer={true} />}
        />
      )}

      {hasPermissionToImport && (
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to="/recurso/importer"
          startIcon={<CloudUploadIcon />}
          size="small">
          {i18n('common.import')}
        </Button>
      )}

      {renderDestroyButton()}

      {hasPermissionToAuditLogs && (
        <Button component={Link} to="/audit-logs?entityNames=recurso" startIcon={<HistoryIcon />} size="small">
          {i18n('auditLog.menu')}
        </Button>
      )}

      {renderExportButton()}

      {destroyAllConfirmVisible && (
        <ConfirmModal
          title={i18n('common.areYouSure')}
          onConfirm={() => doDestroyAllSelected()}
          onClose={() => doCloseDestroyAllConfirmModal()}
          okText={i18n('common.yes')}
          cancelText={i18n('common.no')}
        />
      )}
    </ToolbarWrapper>
  );
}

export default RecursoToolbar;
