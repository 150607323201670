import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/notificacion/importer/notificacionImporterSelectors';
import NotificacionService from 'src/modules/notificacion/notificacionService';
import fields from 'src/modules/notificacion/importer/notificacionImporterFields';
import { i18n } from 'src/i18n';

const notificacionImporterActions = importerActions(
  'NOTIFICACION_IMPORTER',
  selectors,
  NotificacionService.import,
  fields,
  i18n('entities.notificacion.importer.fileName'),
);

export default notificacionImporterActions;