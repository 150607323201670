import {styled} from '@mui/material/styles';

const ToolbarWrapper = styled('div')({
  display: 'flex',
  alignItems: 'flex-start',
  marginBottom: '8px',
  flexWrap: 'wrap',
  gap: 4,
  // '& > *': {
  //   marginBottom: '4px',
  //   marginRight: '4px',
  // },
});

export default ToolbarWrapper;
