const pendienteEnumerators = {
  tipoPendiente: [
    'Formulario',
    'Tarea',
    'Actividad',
  ],
  estado: [
    'Abierta',
    'Cerrada',
    'Anulada',
  ],
};

export default pendienteEnumerators;
