import {i18n} from 'src/i18n';
import fields from 'src/modules/incident/importer/incidentImporterFields';
import selectors from 'src/modules/incident/importer/incidentImporterSelectors';
import IncidentService from 'src/modules/incident/incidentService';
import importerActions from 'src/modules/shared/importer/importerActions';

const incidentImporterActions = importerActions(
  'INCIDENT_IMPORTER',
  selectors,
  IncidentService.import,
  fields,
  i18n('entities.incident.importer.fileName'),
);

export default incidentImporterActions;
