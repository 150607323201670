import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Typography from '@mui/material/Typography';
import InventarioListItem from 'src/view/inventario/list/InventarioListItem';
import UserListItem from 'src/view/user/list/UserListItem';
import ServicioListItem from 'src/view/servicio/list/ServicioListItem';

export default function RecursoCardAlignItemsList(props) {
  const { record } = props;

  return (
    <List
      sx={{
        width: '100%',
        maxWidth: '100%',
        bgcolor: 'rgb(18, 18, 18)',
        borderRadius: 2,
      }}
    >
      {record?.tipo === 'Inventario' && (
        <React.Fragment>
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <InventarioListItem
                type={'avatar'}
                value={record?.repuesto}
              />
            </ListItemAvatar>
            <ListItemText
              sx={{ color: 'white' }}
              primary={`$ ${new Intl.NumberFormat('es-CL', {
                currency: 'CLP',
                style: 'decimal',
              }).format(record?.total)}`}
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{
                      display: 'inline',
                      color: 'rgba(255, 255, 255, 0.7)',
                    }}
                    component="span"
                    variant="body1"
                    color="text.secondary"
                  >
                    {`${
                      record?.repuesto?.activo?.nombre || 0
                    } x ${record?.cantidad || 0}`}
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />
        </React.Fragment>
      )}

      {record?.tipo === 'Recurso Humano' && (
        <React.Fragment>
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <UserListItem
                type={'avatar'}
                value={record?.ejecutor}
              />
            </ListItemAvatar>
            <ListItemText
              sx={{ color: 'white' }}
              primary={`$ ${new Intl.NumberFormat('es-CL', {
                currency: 'CLP',
                style: 'decimal',
              }).format(record?.total)}`}
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{
                      display: 'inline',
                      color: 'rgba(255, 255, 255, 0.7)',
                    }}
                    component="span"
                    variant="body1"
                    color="text.secondary"
                  >
                    {`${record?.ejecutor?.fullName} x ${record?.cantidad} HH`}
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />
        </React.Fragment>
      )}

      {record?.tipo === 'Servicios' && (
        <React.Fragment>
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <ServicioListItem
                type={'avatar'}
                value={record?.servicio}
              />
            </ListItemAvatar>
            <ListItemText
              sx={{ color: 'white' }}
              primary={`$ ${new Intl.NumberFormat('es-CL', {
                currency: 'CLP',
                style: 'decimal',
              }).format(record?.total)}`}
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{
                      display: 'inline',
                      color: 'rgba(255, 255, 255, 0.7)',
                    }}
                    component="span"
                    variant="body1"
                    color="text.secondary"
                  >
                    {`${record?.servicio?.descripcion} x ${
                      record?.cantidad || 0
                    } HH`}
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />
        </React.Fragment>
      )}
    </List>
  );
}
