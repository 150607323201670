import listActions from 'src/modules/modeloFormulario/list/modeloFormularioListActions';
import ModeloFormularioService from 'src/modules/modeloFormulario/modeloFormularioService';
import Errors from 'src/modules/shared/error/errors';
import { i18n } from 'src/i18n';
import { getHistory } from 'src/modules/store';
import Message from 'src/view/shared/message';

const prefix = 'MODELOFORMULARIO_DESTROY';

const modeloFormularioDestroyActions = {
  DESTROY_STARTED: `${prefix}_DESTROY_STARTED`,
  DESTROY_SUCCESS: `${prefix}_DESTROY_SUCCESS`,
  DESTROY_ERROR: `${prefix}_DESTROY_ERROR`,

  DESTROY_ALL_STARTED: `${prefix}_DESTROY_ALL_STARTED`,
  DESTROY_ALL_SUCCESS: `${prefix}_DESTROY_ALL_SUCCESS`,
  DESTROY_ALL_ERROR: `${prefix}_DESTROY_ALL_ERROR`,

  doDestroy: (id) => async (dispatch) => {
    try {
      dispatch({
        type: modeloFormularioDestroyActions.DESTROY_STARTED,
      });

      await ModeloFormularioService.destroyAll([id]);

      dispatch({
        type: modeloFormularioDestroyActions.DESTROY_SUCCESS,
      });

      Message.success(
        i18n('entities.modeloFormulario.destroy.success'),
      );

      dispatch(listActions.doFetchCurrentFilter());

      getHistory().push('/modelo-formulario');
    } catch (error) {
      Errors.handle(error);

      dispatch(listActions.doFetchCurrentFilter());

      dispatch({
        type: modeloFormularioDestroyActions.DESTROY_ERROR,
      });
    }
  },

  doDestroyAll: (ids) => async (dispatch) => {
    try {
      dispatch({
        type: modeloFormularioDestroyActions.DESTROY_ALL_STARTED,
      });

      await ModeloFormularioService.destroyAll(ids);

      dispatch({
        type: modeloFormularioDestroyActions.DESTROY_ALL_SUCCESS,
      });

      if (listActions) {
        dispatch(listActions.doClearAllSelected());
        dispatch(listActions.doFetchCurrentFilter());
      }

      Message.success(
        i18n('entities.modeloFormulario.destroyAll.success'),
      );

      getHistory().push('/modelo-formulario');
    } catch (error) {
      Errors.handle(error);

      dispatch(listActions.doFetchCurrentFilter());

      dispatch({
        type: modeloFormularioDestroyActions.DESTROY_ALL_ERROR,
      });
    }
  },
};

export default modeloFormularioDestroyActions;
