import {
  Breadcrumbs,
  Box,
  Grid,
  Link,
  Typography,
  Tooltip,
  Button,
  Container,
  IconButton,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ArrowBackTwoToneIcon from '@mui/icons-material/ArrowBackTwoTone';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

const PageHeader = ({ record }) => {
  const { t } = useTranslation();

  const handleBack = () => {};
  return (
    <Container maxWidth="lg">
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            {/* <Tooltip followCursor
              arrow
              placement="top"
              title={t('Go back')}
            >
              <IconButton
                onClick={handleBack}
                color="primary"
                sx={{ p: 2, mr: 2 }}
              >
                <ArrowBackTwoToneIcon />
              </IconButton>
            </Tooltip> */}
            <Box>
              <Typography
                variant="h3"
                component="h3"
                gutterBottom
              >
                #{record.number}
              </Typography>
              {/* <Breadcrumbs
                maxItems={2}
                aria-label="breadcrumb"
              >
                <Link color="inherit" href="#">
                  {t('Home')}
                </Link>
                <Link color="inherit" href="#">
                  {t('Management')}
                </Link>
                <Link color="inherit" href="#">
                  {t('Commerce')}
                </Link>
                <Link
                  color="inherit"
                  component={RouterLink}
                  to="/management/invoices"
                >
                  {t('Invoices')}
                </Link>
                <Typography color="text.primary">
                  {t('Invoice')} #{record.number}
                </Typography>
              </Breadcrumbs> */}
            </Box>
          </Box>
        </Grid>
        {/* <Grid item>
          <Button
            sx={{ mt: { xs: 2, sm: 0 } }}
            onClick={handleBack}
            variant="contained"
          >
            {t('View all invoices')}
          </Button>
        </Grid> */}
      </Grid>
    </Container>
  );
};

PageHeader.propTypes = {
  // @ts-ignore
  invoice: PropTypes.object.isRequired,
};

export default PageHeader;
