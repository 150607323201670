import list from 'src/modules/descRegistro/list/descRegistroListReducers';
import form from 'src/modules/descRegistro/form/descRegistroFormReducers';
import view from 'src/modules/descRegistro/view/descRegistroViewReducers';
import destroy from 'src/modules/descRegistro/destroy/descRegistroDestroyReducers';
import importerReducer from 'src/modules/descRegistro/importer/descRegistroImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
