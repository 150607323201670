import React from 'react';
import {i18n} from 'src/i18n';
import GestorDocumentalListFilter from 'src/view/gestorDocumental/list/GestorDocumentalListFilter';
import GestorDocumentalListTable from 'src/view/gestorDocumental/list/GestorDocumentalListTable';
import GestorDocumentalListToolbar from 'src/view/gestorDocumental/list/GestorDocumentalListToolbar';
import ContentWrapper from 'src/view/layout/styles/ContentWrapper';
import Breadcrumb from 'src/view/shared/Breadcrumb';
import PageTitle from 'src/view/shared/styles/PageTitle';
import GestorDocumentalListTableDrawer from './GestorDocumentalListTableDrawer';

function GestorDocumentalListPage(props) {
  return (
    <>
      {!props.drawer && <Breadcrumb items={[[i18n('dashboard.menu'), '/'], [i18n('entities.gestorDocumental.menu')]]} />}

      <ContentWrapper>
        <PageTitle>{i18n('entities.gestorDocumental.list.title')}</PageTitle>

        <GestorDocumentalListToolbar />
        <GestorDocumentalListFilter />

        {props.drawer && (
          <>
            <h3>Seleccione sus elementos:</h3>
            <hr></hr>
          </>
        )}

        {!props.drawer ? <GestorDocumentalListTable /> : <GestorDocumentalListTableDrawer {...props} />}
      </ContentWrapper>
    </>
  );
}

export default GestorDocumentalListPage;
