import list from 'src/modules/planificacion/list/planificacionListReducers';
import form from 'src/modules/planificacion/form/planificacionFormReducers';
import view from 'src/modules/planificacion/view/planificacionViewReducers';
import destroy from 'src/modules/planificacion/destroy/planificacionDestroyReducers';
import importerReducer from 'src/modules/planificacion/importer/planificacionImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
