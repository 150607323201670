import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/tarea/importer/tareaImporterSelectors';
import TareaService from 'src/modules/tarea/tareaService';
import fields from 'src/modules/tarea/importer/tareaImporterFields';
import { i18n } from 'src/i18n';

const tareaImporterActions = importerActions(
  'TAREA_IMPORTER',
  selectors,
  TareaService.import,
  fields,
  i18n('entities.tarea.importer.fileName'),
);

export default tareaImporterActions;