import {i18n} from 'src/i18n';
import schemas from 'src/modules/shared/yup/yupImporterSchemas';

export default [
  {
    name: 'descripcion',
    label: i18n('entities.inventario.fields.descripcion'),
    schema: schemas.string(i18n('entities.inventario.fields.descripcion'), {
      required: true,
    }),
  },
  {
    name: 'activo',
    label: i18n('entities.inventario.fields.activo'),
    schema: schemas.relationToOne(i18n('entities.inventario.fields.activo'), {
      required: true,
    }),
  },
  // {
  //   name: 'ubicacion',
  //   label: i18n('entities.inventario.fields.ubicacion'),
  //   schema: schemas.relationToOne(
  //     i18n('entities.inventario.fields.ubicacion'),
  //     {
  //       required: true,
  //     },
  //   ),
  // },
  {
    name: 'stockDisponible',
    label: i18n('entities.inventario.fields.stockDisponible'),
    schema: schemas.integer(i18n('entities.inventario.fields.stockDisponible'), {
      required: true,
    }),
  },
  {
    name: 'stockCritico',
    label: i18n('entities.inventario.fields.stockCritico'),
    schema: schemas.integer(i18n('entities.inventario.fields.stockCritico'), {}),
  },
  {
    name: 'stockMaximo',
    label: i18n('entities.inventario.fields.stockMaximo'),
    schema: schemas.integer(i18n('entities.inventario.fields.stockMaximo'), {}),
  },
  // {
  //   name: 'notas',
  //   label: i18n('entities.inventario.fields.notas'),
  //   schema: schemas.relationToMany(
  //     i18n('entities.inventario.fields.notas'),
  //     {},
  //   ),
  // },
  // {
  //   name: 'restock',
  //   label: i18n('entities.inventario.fields.restock'),
  //   schema: schemas.relationToMany(
  //     i18n('entities.inventario.fields.restock'),
  //     {},
  //   ),
  // },
];
