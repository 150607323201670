import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import {i18n} from 'src/i18n';
import gestorDocumentalEnumerators from 'src/modules/gestorDocumental/gestorDocumentalEnumerators';
import moment from 'moment-timezone';

export default [
  {
    name: 'garantia',
    label: i18n('entities.gestorDocumental.fields.garantia'),
    schema: schemas.boolean(i18n('entities.gestorDocumental.fields.garantia'), {}),
  },
  {
    name: 'documentoPrincipal',
    label: i18n('entities.gestorDocumental.fields.documentoPrincipal'),
    schema: schemas.relationToOne(i18n('entities.gestorDocumental.fields.documentoPrincipal'), {}),
  },
  {
    name: 'nombre',
    label: i18n('entities.gestorDocumental.fields.nombre'),
    schema: schemas.string(i18n('entities.gestorDocumental.fields.nombre'), {
      required: true,
    }),
  },
  {
    name: 'descripcion',
    label: i18n('entities.gestorDocumental.fields.descripcion'),
    schema: schemas.string(i18n('entities.gestorDocumental.fields.descripcion'), {}),
  },
  {
    name: 'tipoDocumento',
    label: i18n('entities.gestorDocumental.fields.tipoDocumento'),
    schema: schemas.enumerator(i18n('entities.gestorDocumental.fields.tipoDocumento'), {
      required: true,
      options: gestorDocumentalEnumerators.tipoDocumento,
    }),
  },
  {
    name: 'version',
    label: i18n('entities.gestorDocumental.fields.version'),
    schema: schemas.string(i18n('entities.gestorDocumental.fields.version'), {}),
  },
  {
    name: 'validoDesde',
    label: i18n('entities.gestorDocumental.fields.validoDesde'),
    schema: schemas.date(i18n('entities.gestorDocumental.fields.validoDesde'), {}),
    render: value => (value && value instanceof Date ? moment(value).format('YYYY-MM-DD') : value),
  },
  {
    name: 'validoHasta',
    label: i18n('entities.gestorDocumental.fields.validoHasta'),
    schema: schemas.date(i18n('entities.gestorDocumental.fields.validoHasta'), {}),
    render: value => (value && value instanceof Date ? moment(value).format('YYYY-MM-DD') : value),
  },
  {
    name: 'codigoISO',
    label: i18n('entities.gestorDocumental.fields.codigoISO'),
    schema: schemas.relationToMany(i18n('entities.gestorDocumental.fields.codigoISO'), {}),
  },
  {
    name: 'autor',
    label: i18n('entities.gestorDocumental.fields.autor'),
    schema: schemas.string(i18n('entities.gestorDocumental.fields.autor'), {}),
  },
  {
    name: 'perfilesEmpleados',
    label: i18n('entities.gestorDocumental.fields.perfilesEmpleados'),
    schema: schemas.relationToMany(i18n('entities.gestorDocumental.fields.perfilesEmpleados'), {}),
  },
  {
    name: 'activosVinculados',
    label: i18n('entities.gestorDocumental.fields.activosVinculados'),
    schema: schemas.relationToMany(i18n('entities.gestorDocumental.fields.activosVinculados'), {}),
  },
  {
    name: 'registros',
    label: i18n('entities.gestorDocumental.fields.registros'),
    schema: schemas.relationToMany(i18n('entities.gestorDocumental.fields.registros'), {}),
  },
];
