import {i18n} from 'src/i18n';
import DataService from 'src/modules/data/dataService';
import selectors from 'src/modules/data/list/dataListSelectors';
import Errors from 'src/modules/shared/error/errors';
import Exporter from 'src/modules/shared/exporter/exporter';

const prefix = 'DATA_LIST';

const dataListActions = {
  SELECTED_ENTITY: `${prefix}_SELECTED_ENTITY`,
  SET_STRUCTURE: `${prefix}_SET_STRUCTURE`,
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  RESETED: `${prefix}_RESETED`,
  TOGGLE_ONE_SELECTED: `${prefix}_TOGGLE_ONE_SELECTED`,
  TOGGLE_ALL_SELECTED: `${prefix}_TOGGLE_ALL_SELECTED`,
  CLEAR_ALL_SELECTED: `${prefix}_CLEAR_ALL_SELECTED`,

  PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,
  SORTER_CHANGED: `${prefix}_SORTER_CHANGED`,

  EXPORT_STARTED: `${prefix}_EXPORT_STARTED`,
  EXPORT_SUCCESS: `${prefix}_EXPORT_SUCCESS`,
  EXPORT_ERROR: `${prefix}_EXPORT_ERROR`,

  doClearAllSelected() {
    return {
      type: dataListActions.CLEAR_ALL_SELECTED,
    };
  },

  doToggleAllSelected() {
    return {
      type: dataListActions.TOGGLE_ALL_SELECTED,
    };
  },

  doToggleOneSelected(id) {
    return {
      type: dataListActions.TOGGLE_ONE_SELECTED,
      payload: id,
    };
  },

  doReset: (defaultFilter) => async (dispatch) => {
    dispatch({
      type: dataListActions.RESETED,
      payload: {
        defaultFilter,
      },
    });

    dispatch(dataListActions.doFetch(defaultFilter, defaultFilter));
  },

  doExport: () => async (dispatch, getState) => {
    try {
      const entityStructure = selectors.selectEntityStructure(getState());
      const generateExporterFields = () => {
        const fieldsResult = Object.keys(entityStructure)
          //.filter((key) => key !== 'dateEnd')
          .map((key) => {
            return {
              name: key,
              label: key, //entityStructure[key].label || key,
            };
          });
        //console.log('%c⧭ data export fields', 'color: #00ff88', fieldsResult);
        return fieldsResult;
      };

      const exporterFields = generateExporterFields();
      if (!exporterFields || !exporterFields.length) {
        throw new Error('exporterFields is required');
      }

      dispatch({
        type: dataListActions.EXPORT_STARTED,
      });

      const filter = selectors.selectFilter(getState());
      const response = await DataService.list(selectors.selectEntity(getState()) || 'employee', {
        filter,
        orderBy: selectors.selectOrderBy(getState()),
        limit: null,
        offset: null,
        exportAllRows: true,
      });

      new Exporter(exporterFields, i18n('entities.data.exporterFileName')).transformAndExportAsExcelFile(response.rows);

      dispatch({
        type: dataListActions.EXPORT_SUCCESS,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: dataListActions.EXPORT_ERROR,
      });
    }
  },

  doChangePagination: (pagination) => async (dispatch, getState) => {
    dispatch({
      type: dataListActions.PAGINATION_CHANGED,
      payload: pagination,
    });

    dispatch(dataListActions.doFetchCurrentFilter());
  },

  doSelectEntity: (entity) => async (dispatch, getState) => {
    dispatch({
      type: dataListActions.SELECTED_ENTITY,
      payload: {entity},
    });
    //dispatch(dataListActions.doFetchCurrentFilter()); // no es necesario, se hace reset desde el listFilter
  },

  doChangeSort: (sorter) => async (dispatch, getState) => {
    dispatch({
      type: dataListActions.SORTER_CHANGED,
      payload: sorter,
    });

    dispatch(dataListActions.doFetchCurrentFilter());
  },

  doFetchCurrentFilter: () => async (dispatch, getState) => {
    const filter = selectors.selectFilter(getState());
    const rawFilter = selectors.selectRawFilter(getState());
    dispatch(dataListActions.doFetch(filter, rawFilter, true));
  },

  doFetch:
    (filter?, rawFilter?, keepPagination = false) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: dataListActions.FETCH_STARTED,
          payload: {filter, rawFilter, keepPagination},
        });

        //console.log('%c⧭', 'color: #997326', filter, rawFilter);

        const currentEntity = selectors.selectEntity(getState());
        if (!currentEntity) return;

        const response = await DataService.list(currentEntity, {
          filter,
          orderBy: selectors.selectOrderBy(getState()),
          limit: selectors.selectLimit(getState()),
          offset: selectors.selectOffset(getState()),
        });
        if (currentEntity === selectors.selectEntity(getState())) {
          dispatch({
            type: dataListActions.FETCH_SUCCESS,
            payload: {
              structure: response.structure,
              rows: response.rows,
              count: response.count,
            },
          });
        } else {
          console.log(
            'Se descartó la respuesta de la petición para la entidad: ' +
              currentEntity +
              ' ya que se cambió la entidad seleccionada',
          );
        }
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: dataListActions.FETCH_ERROR,
        });
      }
    },
  setStructure: (structure, entity) => async (dispatch, getState) => {
    try {
      const currentEntity = selectors.selectEntity(getState()) || 'employee';
      if (currentEntity === entity) {
        dispatch({
          type: dataListActions.SET_STRUCTURE,
          payload: {structure},
        });
      }
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: dataListActions.FETCH_ERROR,
      });
    }
  },
};

export default dataListActions;
