import RegistroService from 'src/modules/registro/registroService';
import Errors from 'src/modules/shared/error/errors';
import Message from 'src/view/shared/message';
import {getHistory} from 'src/modules/store';
import {i18n} from 'src/i18n';
import registroListActions from '../list/registroListActions';
import layoutActions from 'src/modules/layout/layoutActions';
import moment from 'moment-timezone';

const prefix = 'REGISTRO_FORM';

const registroFormActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  CREATE_STARTED: `${prefix}_CREATE_STARTED`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  doInit: id => async dispatch => {
    try {
      dispatch({
        type: registroFormActions.INIT_STARTED,
      });

      let record = {};

      const isEdit = Boolean(id);

      if (isEdit) {
        record = await RegistroService.find(id);
      }

      dispatch({
        type: registroFormActions.INIT_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: registroFormActions.INIT_ERROR,
      });

      getHistory().push('/registro');
    }
  },

  doCreateMulti: values => async dispatch => {
    try {
      dispatch({
        type: registroFormActions.CREATE_STARTED,
      });

      await RegistroService.createMulti(values);

      dispatch({
        type: registroFormActions.CREATE_SUCCESS,
      });

      Message.success(i18n('entities.registro.create.success'));

      dispatch(registroListActions.doFetchCurrentFilter());

      dispatch(
        layoutActions.doVisibleDrawer({
          drawerId: 'DrawerRegistroMultiFormPage',
          drawerVisible: false,
          drawerChanged: moment().unix(),
          drawerUpdateComponent: 'RegistroListPage',
        }),
      );
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: registroFormActions.CREATE_ERROR,
      });
    }
  },

  doCreate: values => async dispatch => {
    try {
      dispatch({
        type: registroFormActions.CREATE_STARTED,
      });

      await RegistroService.create(values);

      dispatch({
        type: registroFormActions.CREATE_SUCCESS,
      });

      Message.success(i18n('entities.registro.create.success'));

      dispatch(registroListActions.doFetchCurrentFilter());

      dispatch(
        layoutActions.doVisibleDrawer({
          drawerId: 'DrawerRegistroFormPage',
          drawerVisible: false,
          drawerChanged: moment().unix(),
          drawerUpdateComponent: 'RegistroListPage',
        }),
      );
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: registroFormActions.CREATE_ERROR,
      });
    }
  },

  doUpdate: (id, values) => async (dispatch, getState) => {
    try {
      dispatch({
        type: registroFormActions.UPDATE_STARTED,
      });

      await RegistroService.update(id, values);

      dispatch({
        type: registroFormActions.UPDATE_SUCCESS,
      });

      dispatch(registroListActions.doFetchCurrentFilter());

      dispatch(
        layoutActions.doVisibleDrawer({
          drawerId: 'DrawerRegistroFormPage',
          drawerVisible: false,
          drawerChanged: moment().unix(),
          drawerUpdateComponent: 'RegistroListPage',
        }),
      );
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: registroFormActions.UPDATE_ERROR,
      });
    }
  },
};

export default registroFormActions;
