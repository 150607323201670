import {CircularProgress} from '@mui/material';
import React from 'react';

function Spinner(props) {
  return (
    <div
      style={{
        width: '100%',
        marginTop: '24px',
        marginBottom: '24px',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        ...props.style,
      }}>
      <CircularProgress color={props.color || 'secondary'} style={{...props.iconStyle}} />
    </div>
  );
}

export default Spinner;
