import list from 'src/modules/comunicacionInterna/list/comunicacionInternaListReducers';
import form from 'src/modules/comunicacionInterna/form/comunicacionInternaFormReducers';
import view from 'src/modules/comunicacionInterna/view/comunicacionInternaViewReducers';
import destroy from 'src/modules/comunicacionInterna/destroy/comunicacionInternaDestroyReducers';
import importerReducer from 'src/modules/comunicacionInterna/importer/comunicacionInternaImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
