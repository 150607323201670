import {combineReducers} from 'redux';
import destroy from 'src/modules/incident/destroy/incidentDestroyReducers';
import form from 'src/modules/incident/form/incidentFormReducers';
import importerReducer from 'src/modules/incident/importer/incidentImporterReducers';
import list from 'src/modules/incident/list/incidentListReducers';
import view from 'src/modules/incident/view/incidentViewReducers';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
