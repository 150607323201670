import {Box} from '@mui/material';
import moment from 'moment-timezone';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useRouteMatch} from 'react-router-dom';
import {i18n} from 'src/i18n';
import layoutActions from 'src/modules/layout/layoutActions';
import layoutSelectors from 'src/modules/layout/layoutSelectors';
import Errors from 'src/modules/shared/error/errors';
import {getHistory} from 'src/modules/store';
import {default as actions} from 'src/modules/tarea/form/tareaFormActions';
import selectors from 'src/modules/tarea/form/tareaFormSelectors';
import TareaService from 'src/modules/tarea/tareaService';
import ContentWrapper from 'src/view/layout/styles/ContentWrapper';
import Breadcrumb from 'src/view/shared/Breadcrumb';
import Spinner from 'src/view/shared/Spinner';
import PageTitle from 'src/view/shared/styles/PageTitle';
import TareaForm from 'src/view/tarea/form/TareaForm';

function TareaFormPage(props) {
  const [dispatched, setDispatched] = useState(false);
  const dispatch = useDispatch();
  const match = useRouteMatch();

  const [initLoading, setInitLoading] = useState(true);
  const saveLoading = useSelector(selectors.selectSaveLoading);
  const [record, setRecord] = useState<any>(null);

  const [isTaskEditing, setIsTaskEditing] = useState(false);
  const isEditing = Boolean(props.tarea?.id || match.params.id || isTaskEditing);
  const title = isTaskEditing
    ? i18n('entities.tarea.editClosed.title')
    : isEditing
    ? i18n('entities.tarea.edit.title')
    : i18n('entities.tarea.new.title');

  const init = async () => {
    //dispatch(actions.doInit(props.tarea?.id || match.params.id));
    try {
      setInitLoading(true);

      const id = props.tarea?.id || match.params.id;
      const isEdit = Boolean(id);
      let result = {};

      if (isEdit) {
        result = await TareaService.find(id);
      }
      setRecord(result);

      setInitLoading(false);
      setDispatched(true);
    } catch (error) {
      Errors.handle(error);
    }
  };

  const layout: any = useSelector(layoutSelectors.selectRaw);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (
      layout.drawerUpdateComponent === 'TareaFormPage' &&
      props.tarea?.id &&
      layout.recordId &&
      layout.recordId === props.tarea?.id
    ) {
      init();
    }
  }, [dispatch, layout.drawerChanged]);

  const doSubmit = async (id, data) => {
    if (isEditing) {
      const result = await dispatch(actions.doUpdate(id, data, props.edited));
      if (!data.updating) {
        setTimeout(() => {
          dispatch(
            layoutActions.doVisibleDrawer({
              drawerId: 'DrawerTareaFormPage',
              drawerVisible: false,
              drawerChanged: moment().unix(),
              drawerUpdateComponent: 'TareaListPage',
            }),
          );
        }, 500);
      }
      return result;
    } else {
      return dispatch(actions.doCreate(data));
    }
  };

  return (
    <>
      {!props.drawer && !props.disabled && (
        <Breadcrumb items={[[i18n('dashboard.menu'), '/'], [i18n('entities.tarea.menu'), '/tarea'], [title]]} />
      )}
      {initLoading ? (
        <ContentWrapper>
          <PageTitle>{i18n('common.loading')}</PageTitle>
          <Box>
            <Spinner />
          </Box>
        </ContentWrapper>
      ) : (
        dispatched &&
        !initLoading && (
          <TareaForm
            key={record.id + (isTaskEditing ? 'editing' : '')}
            drawer={props.drawer}
            tarea={props.tarea}
            isTaskEditing={isTaskEditing}
            setIsTaskEditing={setIsTaskEditing}
            saveLoading={saveLoading}
            initLoading={initLoading}
            record={record}
            isEditing={isEditing}
            onSubmit={doSubmit}
            onCancel={() => getHistory().goBack()}
            hideComunicacionInterna={props.hideComunicacionInterna}
            title={title}
            setRecord={setRecord}
          />
        )
      )}
    </>
  );
}

export default TareaFormPage;
