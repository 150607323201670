import React from 'react';
import Spinner from 'src/view/shared/Spinner';
import PlanificacionFormPage from '../form/PlanificacionFormPage';

function PlanificacionView(props) {
  const renderView = () => {
    const {record, drawer} = props;
    return <PlanificacionFormPage record={record} disabled={true} drawer={drawer} />;
  };
  const {record, loading} = props;

  if (loading || !record) {
    return <Spinner />;
  }

  return renderView();
}

export default PlanificacionView;
