import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/clasificacionTercero/importer/clasificacionTerceroImporterSelectors';
import ClasificacionTerceroService from 'src/modules/clasificacionTercero/clasificacionTerceroService';
import fields from 'src/modules/clasificacionTercero/importer/clasificacionTerceroImporterFields';
import { i18n } from 'src/i18n';

const clasificacionTerceroImporterActions = importerActions(
  'CLASIFICACIONTERCERO_IMPORTER',
  selectors,
  ClasificacionTerceroService.import,
  fields,
  i18n('entities.clasificacionTercero.importer.fileName'),
);

export default clasificacionTerceroImporterActions;