import {
  Avatar,
  Chip,
  Typography,
  Tooltip,
} from '@mui/material';
import React from 'react';
import Roles from 'src/security/roles';
import Spinner from 'src/view/shared/Spinner';
import CustomViewItem from 'src/view/shared/view/CustomViewItem';
import ImagesViewItem from 'src/view/shared/view/ImagesViewItem';
import TextViewItem from 'src/view/shared/view/TextViewItem';
import UserStatusView from 'src/view/user/view/UserStatusView';
import { i18n } from 'src/i18n';
import config from 'src/config';
import { getHistory } from 'src/modules/store';
import { useSelector } from 'react-redux';
import authSelectors from 'src/modules/auth/authSelectors';
import userSelectors from 'src/modules/user/userSelectors';
import ubicacionSelectors from 'src/modules/ubicacion/ubicacionSelectors';

function ChipPresupuestoView(props) {
  const { record, type, size } = props;

  const hasPermissionToRead = useSelector(
    ubicacionSelectors.selectPermissionToRead,
  );

  const redirectTo = () => {
    if (hasPermissionToRead) {
      getHistory().push(`/presupuesto/` + record.id);
    }
  };

  const photoUrl = record?.descripcion;

  const label = record?.descripcion;

  if (type === 'chip') {
    return (
      <Tooltip followCursor title={label}>
        <Chip
          avatar={
            <Avatar src={photoUrl || ''} alt={label} />
          }
          label={label}
          onClick={() => console.log('onClick')}
          variant="outlined"
          color="primary"
        />
      </Tooltip>
    );
  }

  if (type === 'avatar') {
    return (
      <Tooltip followCursor title={label}>
        <Avatar
          onClick={() => console.log('onClick')}
          style={{ width: size, height: size }}
          src={photoUrl || ''}
          alt={label}
        />
      </Tooltip>
    );
  }

  return <div></div>;
}

export default ChipPresupuestoView;
