import {Search as SearchIcon} from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {Box} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment';
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {i18n} from 'src/i18n';
import destroyActions from 'src/modules/gestorDocumental/destroy/gestorDocumentalDestroyActions';
import destroySelectors from 'src/modules/gestorDocumental/destroy/gestorDocumentalDestroySelectors';
import gestorDocumentalSelectors from 'src/modules/gestorDocumental/gestorDocumentalSelectors';
import actions from 'src/modules/gestorDocumental/list/gestorDocumentalListActions';
import selectors from 'src/modules/gestorDocumental/list/gestorDocumentalListSelectors';
import ActivoListItem from 'src/view/activo/list/ActivoListItem';
import CodificacionISOListItem from 'src/view/codificacionISO/list/CodificacionISOListItem';
import GestorDocumentalListItem from 'src/view/gestorDocumental/list/GestorDocumentalListItem';
import PerfilEmpleadoListItem from 'src/view/perfilEmpleado/list/PerfilEmpleadoListItem';
import PlanificacionListItem from 'src/view/planificacion/list/PlanificacionListItem';
import RegistroListItem from 'src/view/registro/list/RegistroListItem';
import Spinner from 'src/view/shared/Spinner';
import ConfirmModal from 'src/view/shared/modals/ConfirmModal';
import Pagination from 'src/view/shared/table/Pagination';
import TableCellCustom from 'src/view/shared/table/TableCellCustom';
import TableWrapper from 'src/view/shared/table/styles/TableWrapper';
import ClasificacionTagView from 'src/view/shared/view/ClasificacionTagView';
import DrawerButton from 'src/view/shared/view/DrawerButton';
import GestorDocumentalFormPage from '../form/GestorDocumentalFormPage';

function GestorDocumentalListTable(props) {
  const [recordIdToDestroy, setRecordIdToDestroy] = useState(null);
  const dispatch = useDispatch();

  const findLoading = useSelector(selectors.selectLoading);

  const destroyLoading = useSelector(destroySelectors.selectLoading);

  const loading = findLoading || destroyLoading;

  const rows = useSelector(selectors.selectRows);
  const pagination = useSelector(selectors.selectPagination);
  const selectedKeys = useSelector(selectors.selectSelectedKeys);
  const hasRows = useSelector(selectors.selectHasRows);
  const sorter = useSelector(selectors.selectSorter);
  const isAllSelected = useSelector(selectors.selectIsAllSelected);
  const hasPermissionToEdit = useSelector(gestorDocumentalSelectors.selectPermissionToEdit);
  const hasPermissionToDestroy = useSelector(gestorDocumentalSelectors.selectPermissionToDestroy);

  const doOpenDestroyConfirmModal = (id) => {
    setRecordIdToDestroy(id);
  };

  const doCloseDestroyConfirmModal = () => {
    setRecordIdToDestroy(null);
  };

  const doChangeSort = (field) => {
    const order = sorter.field === field && sorter.order === 'asc' ? 'desc' : 'asc';

    dispatch(
      actions.doChangeSort({
        field,
        order,
      }),
    );
  };

  const doChangePagination = (pagination) => {
    dispatch(actions.doChangePagination(pagination));
  };

  const doDestroy = (id) => {
    doCloseDestroyConfirmModal();

    dispatch(destroyActions.doDestroy(id));
  };

  const doToggleAllSelected = () => {
    dispatch(actions.doToggleAllSelected());
  };

  const doToggleOneSelected = (id) => {
    dispatch(actions.doToggleOneSelected(id));
  };

  const validoHastaColor = (row) => {
    if (!row?.validoHasta) return 'grey';
    if (moment(row.validoHasta).isSameOrBefore(moment(), 'day')) {
      return 'red';
    } else if (moment(row.fechaRecordatorio).isSameOrBefore(moment(), 'day')) {
      return 'yellow';
    } else {
      return 'green';
    }
  };

  const validoHastaHumanize = (row) => {
    if (!row?.validoHasta) return 'Sin fecha';
    const today = moment().startOf('day');
    const date = moment(row.validoHasta).startOf('day');
    const diff = date.diff(today, 'days');
    if (diff != 0) {
      const duration = moment.duration(diff, 'days');
      return (diff > 0 ? 'Vence ' : 'Venció ') + duration.humanize(true);
    }
    return 'Venció hoy';
  };

  return (
    <>
      <TableWrapper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCellCustom padding="checkbox">
                {hasRows && <Checkbox checked={Boolean(isAllSelected)} onChange={() => doToggleAllSelected()} size="small" />}
              </TableCellCustom>
              <TableCellCustom size="md" />
              <TableCellCustom
                onSort={doChangeSort}
                hasRows={hasRows}
                sorter={sorter}
                name={'nombre'}
                label={i18n('entities.gestorDocumental.fields.nombre')}
              />
              <TableCellCustom
                onSort={doChangeSort}
                hasRows={hasRows}
                sorter={sorter}
                name={'garantia'}
                label={i18n('entities.gestorDocumental.fields.garantia')}
              />
              <TableCellCustom label={i18n('entities.gestorDocumental.fields.documentoPrincipal')} />
              {/* <TableCellCustom
                onSort={doChangeSort}
                hasRows={hasRows}
                sorter={sorter}
                name={'descripcion'}
                label={i18n(
                  'entities.gestorDocumental.fields.descripcion',
                )}
              /> */}
              <TableCellCustom
                onSort={doChangeSort}
                hasRows={hasRows}
                sorter={sorter}
                name={'tipoDocumento'}
                label={i18n('entities.gestorDocumental.fields.tipoDocumento')}
              />
              <TableCellCustom
                onSort={doChangeSort}
                hasRows={hasRows}
                sorter={sorter}
                name={'version'}
                label={i18n('entities.gestorDocumental.fields.version')}
              />
              <TableCellCustom
                onSort={doChangeSort}
                hasRows={hasRows}
                sorter={sorter}
                name={'validoDesde'}
                label={i18n('entities.gestorDocumental.fields.validoDesde')}
              />
              <TableCellCustom
                onSort={doChangeSort}
                hasRows={hasRows}
                sorter={sorter}
                name={'validoHasta'}
                label={i18n('entities.gestorDocumental.fields.validoHasta')}
              />
              <TableCellCustom label={i18n('entities.gestorDocumental.fields.codigoISO')} />
              <TableCellCustom
                onSort={doChangeSort}
                hasRows={hasRows}
                sorter={sorter}
                name={'autor'}
                label={i18n('entities.gestorDocumental.fields.autor')}
              />
              <TableCellCustom label={i18n('entities.gestorDocumental.fields.perfilesEmpleados')} />
              <TableCellCustom label={i18n('entities.gestorDocumental.fields.activosVinculados')} />
              <TableCellCustom label={i18n('entities.gestorDocumental.fields.planificacionesVinculadas')} />
              <TableCellCustom label={i18n('entities.gestorDocumental.fields.registros')} />
            </TableRow>
          </TableHead>
          <TableBody>
            {loading && (
              <TableRow>
                <TableCell
                  colSpan={100}
                  sx={{
                    borderBottom: '0px solid white',
                  }}>
                  <Spinner />
                </TableCell>
              </TableRow>
            )}
            {!loading && !hasRows && (
              <TableRow>
                <TableCell
                  colSpan={100}
                  sx={{
                    borderBottom: '0px solid white',
                  }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}>
                    {i18n('table.noData')}
                  </div>
                </TableCell>
              </TableRow>
            )}
            {!loading &&
              rows.map((row) => (
                <TableRow key={row.id}>
                  <TableCell padding="checkbox">
                    <Checkbox checked={selectedKeys.includes(row.id)} onChange={() => doToggleOneSelected(row.id)} size="small" />
                  </TableCell>
                  <TableCell>
                    <Box display="flex" justifyContent="flex-end">
                      <Tooltip followCursor title={i18n('common.view')}>
                        <IconButton component={Link} color="primary" to={`/gestor-documental/${row.id}`}>
                          <SearchIcon />
                        </IconButton>
                      </Tooltip>
                      {hasPermissionToEdit && (
                        <DrawerButton
                          drawerId={'DrawerGestorDocumentalFormPage'}
                          type={'icon'}
                          tooltipTitle={i18n('common.edit')}
                          buttonTitle={i18n('common.edit')}
                          buttonIcon={<EditIcon />}
                          width={window.innerWidth * 0.55}
                          component={<GestorDocumentalFormPage record={row} disabled={false} drawer={true} />}
                        />
                      )}
                      {hasPermissionToDestroy && (
                        <Tooltip followCursor title={i18n('common.destroy')}>
                          <IconButton color="primary" onClick={() => doOpenDestroyConfirmModal(row.id)}>
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Box>
                  </TableCell>
                  <TableCell>{row.nombre}</TableCell>
                  <TableCell>{row.garantia ? i18n('common.yes') : i18n('common.no')}</TableCell>
                  <TableCell>
                    <GestorDocumentalListItem value={row.documentoPrincipal} />
                  </TableCell>
                  {/* <TableCell>{row.descripcion}</TableCell> */}
                  <TableCell>
                    {row.tipoDocumento ? i18n(`entities.gestorDocumental.enumerators.tipoDocumento.${row.tipoDocumento}`) : null}
                  </TableCell>
                  <TableCell>{row.version}</TableCell>
                  <TableCell>{row.validoDesde ? moment(row.validoDesde).format('DD/MM/YYYY') : null}</TableCell>
                  <TableCell>
                    {row.validoHasta && (
                      <Tooltip followCursor title={validoHastaHumanize(row)}>
                        <span>
                          <ClasificacionTagView
                            value={validoHastaColor(row)}
                            isColor
                            label={moment(row.validoHasta).format('DD/MM/YYYY')}
                          />
                        </span>
                      </Tooltip>
                    )}
                  </TableCell>
                  <TableCell>
                    <CodificacionISOListItem value={row.codigoISO} />
                  </TableCell>
                  <TableCell>{row.autor}</TableCell>
                  <TableCell>
                    <PerfilEmpleadoListItem value={row.perfilesEmpleados} />
                  </TableCell>
                  <TableCell>
                    <ActivoListItem value={row.activosVinculados} />
                  </TableCell>
                  <TableCell>
                    <PlanificacionListItem value={row.planificacionesVinculadas} />
                  </TableCell>
                  <TableCell>
                    <RegistroListItem value={row.registros} />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableWrapper>

      <Pagination onChange={doChangePagination} pagination={pagination} />

      {recordIdToDestroy && (
        <ConfirmModal
          title={i18n('common.areYouSure')}
          onConfirm={() => doDestroy(recordIdToDestroy)}
          onClose={() => doCloseDestroyConfirmModal()}
          okText={i18n('common.yes')}
          cancelText={i18n('common.no')}
        />
      )}
    </>
  );
}

export default GestorDocumentalListTable;
