import {Search as SearchIcon} from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {Box} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment-timezone';
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {i18n} from 'src/i18n';
import destroyActions from 'src/modules/recurso/destroy/recursoDestroyActions';
import destroySelectors from 'src/modules/recurso/destroy/recursoDestroySelectors';
import actions from 'src/modules/recurso/list/recursoListActions';
import selectors from 'src/modules/recurso/list/recursoListSelectors';
import recursoSelectors from 'src/modules/recurso/recursoSelectors';
import InventarioListItem from 'src/view/inventario/list/InventarioListItem';
import PresupuestoListItem from 'src/view/presupuesto/list/PresupuestoListItem';
import ServicioListItem from 'src/view/servicio/list/ServicioListItem';
import Spinner from 'src/view/shared/Spinner';
import ConfirmModal from 'src/view/shared/modals/ConfirmModal';
import Pagination from 'src/view/shared/table/Pagination';
import TableCellCustom from 'src/view/shared/table/TableCellCustom';
import TableWrapper from 'src/view/shared/table/styles/TableWrapper';
import DrawerButton from 'src/view/shared/view/DrawerButton';
import TareaListItem from 'src/view/tarea/list/TareaListItem';
import UserListItem from 'src/view/user/list/UserListItem';
import RecursoFormPage from '../form/RecursoFormPage';
import RecursoView from '../view/RecursoView';

function RecursoListTable(props) {
  const [recordIdToDestroy, setRecordIdToDestroy] = useState(null);
  const dispatch = useDispatch();

  const findLoading = useSelector(selectors.selectLoading);

  const destroyLoading = useSelector(destroySelectors.selectLoading);

  const loading = findLoading || destroyLoading;

  const rows = useSelector(selectors.selectRows);
  const pagination = useSelector(selectors.selectPagination);
  const selectedKeys = useSelector(selectors.selectSelectedKeys);
  const hasRows = useSelector(selectors.selectHasRows);
  const sorter = useSelector(selectors.selectSorter);
  const isAllSelected = useSelector(selectors.selectIsAllSelected);
  const hasPermissionToEdit = useSelector(recursoSelectors.selectPermissionToEdit);
  const hasPermissionToDestroy = useSelector(recursoSelectors.selectPermissionToDestroy);

  const doOpenDestroyConfirmModal = (id) => {
    setRecordIdToDestroy(id);
  };

  const doCloseDestroyConfirmModal = () => {
    setRecordIdToDestroy(null);
  };

  const doChangeSort = (field) => {
    const order = sorter.field === field && sorter.order === 'asc' ? 'desc' : 'asc';

    dispatch(
      actions.doChangeSort({
        field,
        order,
      }),
    );
  };

  const doChangePagination = (pagination) => {
    dispatch(actions.doChangePagination(pagination));
  };

  const doDestroy = (id) => {
    doCloseDestroyConfirmModal();

    dispatch(destroyActions.doDestroy(id));
  };

  const doToggleAllSelected = () => {
    dispatch(actions.doToggleAllSelected());
  };

  const doToggleOneSelected = (id) => {
    dispatch(actions.doToggleOneSelected(id));
  };

  return (
    <>
      <TableWrapper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCellCustom padding="checkbox">
                {hasRows && <Checkbox checked={Boolean(isAllSelected)} onChange={() => doToggleAllSelected()} size="small" />}
              </TableCellCustom>
              <TableCellCustom
                onSort={doChangeSort}
                hasRows={hasRows}
                sorter={sorter}
                name={'descripcion'}
                label={i18n('entities.recurso.fields.descripcion')}
              />
              <TableCellCustom
                onSort={doChangeSort}
                hasRows={hasRows}
                sorter={sorter}
                name={'total'}
                label={i18n('entities.recurso.fields.total')}
              />
              <TableCellCustom
                onSort={doChangeSort}
                hasRows={hasRows}
                sorter={sorter}
                name={'tipo'}
                label={i18n('entities.recurso.fields.tipo')}
              />
              <TableCellCustom label={i18n('entities.recurso.fields.presupuesto')} />
              {/* <TableCellCustom
                onSort={doChangeSort}
                hasRows={hasRows}
                sorter={sorter}
                name={'fechaPago'}
                label={i18n(
                  'entities.recurso.fields.fechaPago',
                )}
              /> */}
              <TableCellCustom
                onSort={doChangeSort}
                hasRows={hasRows}
                sorter={sorter}
                name={'cantidad'}
                label={i18n('entities.recurso.fields.cantidad')}
                align="right"
              />
              <TableCellCustom label={i18n('entities.recurso.fields.ejecutor')} />
              <TableCellCustom label={i18n('entities.recurso.fields.repuesto')} />
              <TableCellCustom label={i18n('entities.recurso.fields.servicio')} />
              {/* <TableCellCustom
                label={i18n(
                  'entities.recurso.fields.fotos',
                )}
              />
              <TableCellCustom
                label={i18n(
                  'entities.recurso.fields.documentos',
                )}
              /> */}
              <TableCellCustom label={i18n('entities.recurso.fields.tareasVinculadas')} />
              <TableCellCustom
                onSort={doChangeSort}
                hasRows={hasRows}
                sorter={sorter}
                name={'fechaEstimadaUso'}
                label={i18n('entities.recurso.fields.fechaEstimadaUso')}
              />
              {/* <TableCellCustom
                label={i18n(
                  'entities.recurso.fields.subtareasVinculadas',
                )}
              /> */}
              <TableCellCustom size="md" />
            </TableRow>
          </TableHead>
          <TableBody>
            {loading && (
              <TableRow>
                <TableCell
                  colSpan={100}
                  sx={{
                    borderBottom: '0px solid white',
                  }}>
                  <Spinner />
                </TableCell>
              </TableRow>
            )}
            {!loading && !hasRows && (
              <TableRow>
                <TableCell
                  colSpan={100}
                  sx={{
                    borderBottom: '0px solid white',
                  }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}>
                    {i18n('table.noData')}
                  </div>
                </TableCell>
              </TableRow>
            )}
            {!loading &&
              rows.map((row) => (
                <TableRow key={row.id}>
                  <TableCell padding="checkbox">
                    <Checkbox checked={selectedKeys.includes(row.id)} onChange={() => doToggleOneSelected(row.id)} size="small" />
                  </TableCell>
                  <TableCell>{row.descripcion}</TableCell>
                  <TableCell>
                    ${' '}
                    {new Intl.NumberFormat('es-CL', {
                      currency: 'CLP',
                      style: 'decimal',
                    }).format(row.total)}
                  </TableCell>
                  <TableCell>{row.tipo ? i18n(`entities.recurso.enumerators.tipo.${row.tipo}`) : null}</TableCell>
                  <TableCell>
                    <PresupuestoListItem value={row.presupuesto} />
                  </TableCell>
                  {/* <TableCell>{row.fechaPago}</TableCell> */}
                  <TableCell align="right">{row.cantidad}</TableCell>
                  <TableCell>
                    <UserListItem value={row.ejecutor} />
                  </TableCell>
                  <TableCell>
                    <InventarioListItem value={row.repuesto} />
                  </TableCell>
                  <TableCell>
                    <ServicioListItem value={row.servicio} />
                  </TableCell>
                  {/* <TableCell>
                    <ImagesListView value={row.fotos} />
                  </TableCell>
                  <TableCell>
                    <FilesListView value={row.documentos} />
                  </TableCell> */}
                  <TableCell>
                    <TareaListItem value={row.tareasVinculadas} />
                  </TableCell>
                  <TableCell>{row.fechaEstimadaUso ? moment(row.fechaEstimadaUso).format('DD-MM-YYYY') : null}</TableCell>
                  {/* <TableCell>
                    <SubTareaListItem
                      value={row.subtareasVinculadas}
                    />
                  </TableCell> */}
                  <TableCell>
                    <Box display="flex" justifyContent="flex-end">
                      <DrawerButton
                        drawerId={'DrawerRecursoViewPage'}
                        type={'icon'}
                        tooltipTitle={i18n('common.view')}
                        buttonTitle={i18n('common.view')}
                        buttonIcon={<SearchIcon />}
                        width={window.innerWidth * 0.55}
                        component={<RecursoView record={row} drawer={true} />}
                      />
                      {hasPermissionToEdit && !row.validado && (
                        <DrawerButton
                          drawerId={'DrawerRecursoFormPage'}
                          type={'icon'}
                          tooltipTitle={i18n('common.edit')}
                          buttonTitle={i18n('common.edit')}
                          buttonIcon={<EditIcon />}
                          width={window.innerWidth * 0.55}
                          component={<RecursoFormPage record={row} disabled={false} drawer={true} />}
                        />
                      )}
                      {hasPermissionToDestroy && !row.validado && (
                        <Tooltip followCursor title={i18n('common.destroy')}>
                          <IconButton color="primary" onClick={() => doOpenDestroyConfirmModal(row.id)}>
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableWrapper>

      <Pagination onChange={doChangePagination} pagination={pagination} />

      {recordIdToDestroy && (
        <ConfirmModal
          title={i18n('common.areYouSure')}
          onConfirm={() => doDestroy(recordIdToDestroy)}
          onClose={() => doCloseDestroyConfirmModal()}
          okText={i18n('common.yes')}
          cancelText={i18n('common.no')}
        />
      )}
    </>
  );
}

export default RecursoListTable;
