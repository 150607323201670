import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.producto.fields.id'),
  },
  {
    name: 'foto',
    label: i18n('entities.producto.fields.foto'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'productoCompleto',
    label: i18n('entities.producto.fields.productoCompleto'),
  },
  {
    name: 'codBarra',
    label: i18n('entities.producto.fields.codBarra'),
  },
  {
    name: 'codGrupo',
    label: i18n('entities.producto.fields.codGrupo'),
  },
  {
    name: 'codProd',
    label: i18n('entities.producto.fields.codProd'),
  },
  {
    name: 'codRapido',
    label: i18n('entities.producto.fields.codRapido'),
  },
  {
    name: 'codSubGr',
    label: i18n('entities.producto.fields.codSubGr'),
  },
  {
    name: 'codUMed',
    label: i18n('entities.producto.fields.codUMed'),
  },
  {
    name: 'desProd',
    label: i18n('entities.producto.fields.desProd'),
  },
  {
    name: 'desProd2',
    label: i18n('entities.producto.fields.desProd2'),
  },
  {
    name: 'precioBol',
    label: i18n('entities.producto.fields.precioBol'),
  },
  {
    name: 'precioVta',
    label: i18n('entities.producto.fields.precioVta'),
  },
  {
    name: 'ean13',
    label: i18n('entities.producto.fields.ean13'),
  },
  {
    name: 'sku',
    label: i18n('entities.producto.fields.sku'),
  },
  {
    name: 'imgUrl',
    label: i18n('entities.producto.fields.imgUrl'),
  },
  {
    name: 'categorias',
    label: i18n('entities.producto.fields.categorias'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.producto.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.producto.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
