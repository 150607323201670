import list from 'src/modules/temaComunicacion/list/temaComunicacionListReducers';
import form from 'src/modules/temaComunicacion/form/temaComunicacionFormReducers';
import view from 'src/modules/temaComunicacion/view/temaComunicacionViewReducers';
import destroy from 'src/modules/temaComunicacion/destroy/temaComunicacionDestroyReducers';
import importerReducer from 'src/modules/temaComunicacion/importer/temaComunicacionImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
