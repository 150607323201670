import {i18n} from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.tarea.fields.id'),
  },
  {
    name: 'planificacion',
    label: i18n('entities.tarea.fields.planificacion'),
    render: exporterRenders.relationToOneMap((value) => value?.nombre || value),
  },
  {
    name: 'numeroTarea',
    label: i18n('entities.tarea.fields.numeroTarea'),
  },
  {
    name: 'tituloTarea',
    label: i18n('entities.tarea.fields.tituloTarea'),
  },
  {
    name: 'estado',
    label: i18n('entities.tarea.fields.estado'),
  },
  {
    name: 'comienzo',
    label: i18n('entities.tarea.fields.comienzo'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'fin',
    label: i18n('entities.tarea.fields.fin'),
    render: exporterRenders.datetime(),
  },
  // {
  //   name: 'fechaProgramacion',
  //   label: i18n('entities.tarea.fields.fechaProgramacion'),
  //   render: exporterRenders.datetime(),
  // },
  {
    name: 'ejecutor',
    label: i18n('entities.tarea.fields.ejecutor'),
    render: exporterRenders.relationToOneMap((user) => {
      if (!user) return null;
      if (!user.email) {
        return user;
      }
      if (!user.fullName) {
        return user.email;
      }
      return `${user.fullName} <${user.email}>`;
    }),
  },
  // {
  //   name: 'fechaLimite',
  //   label: i18n('entities.tarea.fields.fechaLimite'),
  //   render: exporterRenders.datetime(),
  // },
  {
    name: 'activo',
    label: i18n('entities.tarea.fields.activo'),
  },
  {
    name: 'listaProcedimiento',
    label: i18n('entities.tarea.fields.listaProcedimiento'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.tarea.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.tarea.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
