import moment from 'moment-timezone';
import {i18n} from 'src/i18n';
import layoutActions from 'src/modules/layout/layoutActions';
import exporterFields from 'src/modules/planificacion/list/planificacionListExporterFields';
import selectors from 'src/modules/planificacion/list/planificacionListSelectors';
import PlanificacionService from 'src/modules/planificacion/planificacionService';
import Errors from 'src/modules/shared/error/errors';
import Exporter from 'src/modules/shared/exporter/exporter';
import TareaService from 'src/modules/tarea/tareaService';
import Message from 'src/view/shared/message';

const addCopyString = (string) => {
  var match = string.match(/(\sCopia\s)(\d+)$/);
  if (match) {
    var num = parseInt(match[2]);
    return string.replace(/(\sCopia\s)(\d+)$/, (match, p1, p2) => {
      return p1 + (num + 1);
    });
  } else {
    return string + ' Copia 1';
  }
};

const prefix = 'PLANIFICACION_LIST';

const planificacionListActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  COPY_STARTED: `${prefix}_COPY_STARTED`,
  COPY_SUCCESS: `${prefix}_COPY_SUCCESS`,
  COPY_ERROR: `${prefix}_COPY_ERROR`,

  RESETED: `${prefix}_RESETED`,
  TOGGLE_ONE_SELECTED: `${prefix}_TOGGLE_ONE_SELECTED`,
  TOGGLE_ALL_SELECTED: `${prefix}_TOGGLE_ALL_SELECTED`,
  CLEAR_ALL_SELECTED: `${prefix}_CLEAR_ALL_SELECTED`,
  CLEAR_SOME_SELECTED: `${prefix}_CLEAR_SOME_SELECTED`,

  PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,
  SORTER_CHANGED: `${prefix}_SORTER_CHANGED`,
  ROWS_CHANGED: `${prefix}_ROWS_CHANGED`,

  EXPORT_STARTED: `${prefix}_EXPORT_STARTED`,
  EXPORT_SUCCESS: `${prefix}_EXPORT_SUCCESS`,
  EXPORT_ERROR: `${prefix}_EXPORT_ERROR`,

  SELECTED_TAB_CHANGED: `${prefix}_SELECTED_TAB_CHANGED`,

  onSelectedTabChanged: (tabSelected) => async (dispatch, getState) => {
    const currentTab = selectors.selectSelectedTab(getState());
    const currentIsPlanningTemplates = currentTab === 'planningTemplates';
    const newIsPlanningTemplates = tabSelected === 'planningTemplates';
    dispatch({
      type: planificacionListActions.SELECTED_TAB_CHANGED,
      payload: tabSelected,
    });
    if (currentIsPlanningTemplates !== newIsPlanningTemplates) {
      //console.log('isPlanningTemplates changed after changing tab, fetching new data');
      dispatch(planificacionListActions.doFetchCurrentFilter());
    }
  },

  doClearAllSelected() {
    return {
      type: planificacionListActions.CLEAR_ALL_SELECTED,
    };
  },

  doToggleAllSelected() {
    return {
      type: planificacionListActions.TOGGLE_ALL_SELECTED,
    };
  },

  doClearSomeSelected(payload) {
    return {
      type: planificacionListActions.CLEAR_SOME_SELECTED,
      payload,
    };
  },

  doToggleOneSelected(payload) {
    return {
      type: planificacionListActions.TOGGLE_ONE_SELECTED,
      payload,
    };
  },

  doReset:
    (defaultValues = {}) =>
    async (dispatch) => {
      dispatch({
        type: planificacionListActions.RESETED,
      });

      dispatch(planificacionListActions.doFetch(defaultValues));
    },

  doExport: (filter) => async (dispatch, getState) => {
    try {
      if (!exporterFields || !exporterFields.length) {
        throw new Error('exporterFields is required');
      }

      dispatch({
        type: planificacionListActions.EXPORT_STARTED,
      });

      const response = await PlanificacionService.listTree(filter, null);

      new Exporter(exporterFields, i18n('entities.planificacion.exporterFileName')).transformAndExportAsExcelFile(
        response.result,
      );

      dispatch({
        type: planificacionListActions.EXPORT_SUCCESS,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: planificacionListActions.EXPORT_ERROR,
      });
    }
  },

  doChangePagination: (pagination) => async (dispatch, getState) => {
    dispatch({
      type: planificacionListActions.PAGINATION_CHANGED,
      payload: pagination,
    });

    dispatch(planificacionListActions.doFetchCurrentFilter());
  },

  doChangeSort: (sorter) => async (dispatch, getState) => {
    dispatch({
      type: planificacionListActions.SORTER_CHANGED,
      payload: sorter,
    });

    dispatch(planificacionListActions.doFetchCurrentFilter());
  },

  doFetchCurrentFilter: () => async (dispatch, getState) => {
    const filter = selectors.selectFilter(getState());
    const rawFilter = selectors.selectRawFilter(getState());
    dispatch(planificacionListActions.doFetch(filter, rawFilter, true));
  },

  doCopyRecursive:
    (record, raiz, parentId, isFirst, created: any = null) =>
    async (dispatch) => {
      try {
        dispatch({
          type: planificacionListActions.COPY_STARTED,
        });

        record.programada = false;
        record.estado = 'Pendiente';
        if (isFirst) {
          // nombre sin Copia
          const nombreOg = record.nombre.replace(/(\sCopia\s)(\d+)$/, '');
          // obtener planificaciones con "Copia" en el nombre
          const copias = await PlanificacionService.list(
            {nombre: nombreOg + ' Copia', planificacionPadre: record.planificacionPadre},
            '',
            0,
            0,
          );
          if (copias && copias.rows && copias.rows.length > 0) {
            // obtener el numero de copia mas alto
            const max = Math.max(
              ...copias.rows.map((copia) => {
                const match = copia.nombre.match(/(\sCopia\s)(\d+)$/);
                if (match) {
                  return parseInt(match[2]);
                }
                return 0;
              }),
            );
            record.nombre = nombreOg + ' Copia ' + (max + 1);
          } else {
            record.nombre = nombreOg + ' Copia 1';
          }
          //record.nombre = addCopyString(record.nombre);
        }
        if (parentId) {
          record.planificacionPadre = parentId;
          record.planificacionRaiz = raiz;
        }
        const copyId = record.id;

        delete record.id;
        delete record._id;
        delete record.tenant;
        delete record.createdAt;
        delete record.createdBy;
        delete record.updatedAt;
        delete record.updatedBy;

        const copy = await PlanificacionService.create(record);
        copy.hasPlanChildren = record.hasPlanChildren;
        copy.hasTaskChildren = record.hasTaskChildren;
        if (!parentId) {
          raiz = copy.id;
        }

        const children = await PlanificacionService.list({planificacionPadre: copyId}, 'createdAt_ASC', 0, 0);

        if (children && children.rows && children.rows.length > 0) {
          for (let i = 0; i < children.rows.length; i++) {
            await dispatch(planificacionListActions.doCopyRecursive(children.rows[i], raiz, copy.id, false));
          }
        }

        if (isFirst) {
          dispatch({
            type: planificacionListActions.COPY_SUCCESS,
          });

          Message.success(i18n('entities.planificacion.create.success'));

          dispatch(
            layoutActions.doVisibleDrawer({
              drawerId: 'DrawerPlanificacionFormPage',
              drawerVisible: false,
              drawerChanged: moment().unix(),
            }),
          );

          dispatch(planificacionListActions.doFetchCurrentFilter());
        }
        if (created) created(copy);
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: planificacionListActions.COPY_ERROR,
        });
      }
    },

  doFetch:
    (filter?, rawFilter?, keepPagination = false) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: planificacionListActions.FETCH_STARTED,
          payload: {filter, rawFilter, keepPagination},
        });

        const selectedTab = selectors.selectSelectedTab(getState());
        const isPlanningTemplates = selectedTab === 'planningTemplates';
        filter.isTemplate = isPlanningTemplates;

        const response = await PlanificacionService.list(
          filter,
          selectors.selectOrderBy(getState()),
          selectors.selectLimit(getState()),
          selectors.selectOffset(getState()),
        );

        dispatch({
          type: planificacionListActions.FETCH_SUCCESS,
          payload: {
            rows: response.rows,
            count: response.count,
          },
        });
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: planificacionListActions.FETCH_ERROR,
        });
      }
    },
  getFilter: () => async (dispatch, getState) => {
    const filter = selectors.selectFilter(getState());
    return filter;
  },
  fetchNodeChildren: (nodeId) => async (dispatch, getState) => {
    try {
      // dispatch({
      //   type: planificacionListActions.FETCH_STARTED,
      // });
      let selectRows = selectors.selectRows(getState());
      const filter = selectors.selectFilter(getState());
      const recursiveLoadTareasEnviadas = async (tareas) => {
        for (let tarea of tareas) {
          const tareasEnviadas = await TareaService.list({parentTask: tarea.id}, 'createdAt_ASC', 0, 0);
          tarea.tareasEnviadas = await recursiveLoadTareasEnviadas(tareasEnviadas.rows);
        }
        return tareas;
      };
      const recursiveLoadNodeChildren = async (id, rows) => {
        for (let row of rows) {
          if (row.id === id) {
            const planificaciones = await PlanificacionService.list(
              {...filter, planificacionPadre: row.id},
              'createdAt_ASC',
              20,
              0,
            );
            row.children = planificaciones.rows;
            // Cargar ordenes de trabajo :
            if (!row.nextPage) row.nextPage = 1;
            if (!row.pageSize) row.pageSize = 10;
            const offset = (row.nextPage - 1) * row.pageSize;
            const tareasEnviadas = await TareaService.list(
              {planificacion: row.id, parentTask: null},
              'createdAt_ASC',
              row.pageSize,
              offset,
            );
            // load children of tareasEnviadas
            const tareasEnviadasWithChildren = await recursiveLoadTareasEnviadas(tareasEnviadas.rows);
            row.nextPage += 1;
            //console.log('%c⧭ recursiveLoadNodeChildren', 'color: #731d1d', {tareasEnviadasAntes: row.tareasEnviadas});
            row.tareasEnviadas = row.tareasEnviadas
              ? row.tareasEnviadas.concat(tareasEnviadasWithChildren)
              : tareasEnviadasWithChildren;
            //console.log('%c⧭ recursiveLoadNodeChildren', 'color: #731d1d', {tareasEnviadasDespues: row.tareasEnviadas});
            row.total = tareasEnviadas.count;
            row.totalReached = row.tareasEnviadas.length >= row.total;
            dispatch({
              type: planificacionListActions.ROWS_CHANGED,
              payload: {
                rows: selectRows,
                rowToExpand: row,
                rerender: true,
              },
            });
            return true;
          } else if (row.children && (await recursiveLoadNodeChildren(id, row.children))) return true;
        }
        return false;
      };

      const nodeFound: boolean = await recursiveLoadNodeChildren(nodeId, selectRows);
      if (!nodeFound) {
        throw new Error('Error: Nodo no encontrado');
      }
      return nodeFound;
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: planificacionListActions.FETCH_ERROR,
      });
    }
  },
};

export default planificacionListActions;
